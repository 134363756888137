// import _ from "lodash";
import { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

// custom

import { FallbackView } from "../../_metronic/partials";
import AccountPage from "../modules/accounts/AccountPage";

import UserManagement from "../modules/userManagement/UserManagement";
import PartnerManagement from "../modules/partnerManagement/PartnerManagement";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import FileManagerPage from "../modules/filemanager/FilemanagerPage";
import RolesAndPermissionsPage from "../modules/RolesAndPermissions/RolesAndPermissionsPage";
import {
  getResourcesList,
  getRoleDetails,
  getRolesList,
  actions as rolesAndPermissionsActions,
} from "../modules/RolesAndPermissions/redux";
import { useDispatch } from "react-redux";
import { getGroupsList } from "../modules/masterdata/redux/GroupsAPI";
import { getCategoriesList } from "../modules/masterdata/redux/CategoriesAPI";
import { getTagsList } from "../modules/masterdata/redux/TagsAPI";
import { getIndustryGroups } from "../modules/partnersMasterData/redux/PartnersMasterdataAPI";
import { getLabelsList } from "../modules/masterdata/redux/LabelsAPI";
import { actions as masterdataActions } from "../modules/masterdata/redux";
import { actions as projectmasterDataActions } from "../modules/projectMasterData/redux";
import MasterdataPage from "../modules/masterdata/MasterdataPage";
import CustomerManagement from "../modules/customerManagement/CustomerManagement";
import TeamsPage from "../modules/teams/TeamsPage";
import ProductsPage from "../modules/products/ProductsPage";
import { getProductTypesList } from "../modules/masterdata/redux/ProductTypesAPI";
import ProjectsMasterData from "../modules/projectMasterData/ContentMasterPage";
import PipelinesPage from "../modules/pipelines/PipelinesPage";
import { getFieldsList } from "../modules/projectMasterData/redux/FieldsAPI";
import { getTabsList } from "../modules/projectMasterData/redux/TabsAPI";
import { getPipelinesList } from "../modules/pipelines/redux/PipelinesAPI";
import { actions as PipelineAction } from "../modules/pipelines/redux/PipelinesRedux";
import DealsPage from "../modules/deals/DealsPage";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import { UserType } from "../modules/userManagement/types/getUsersListResponseType";
import { actionTypes, actions } from "../modules/auth";
import { getDealPipelines } from "../modules/deals/redux/DealsAPI";
import { actions as DealActions } from "../modules/deals/redux/DealsRedux";
import ArchivedPage from "../modules/archived/ArchivedPage";
import GooglePage from "../modules/google/GooglePage";
import CalendarPage from "../modules/calendar/CalendarPage";

export function PrivateRoutes() {
  const user = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const dispatch = useDispatch();
  useEffect(() => {
    getPipelinesList().then(({ data: { pipelines } }) => {
      dispatch(PipelineAction.setPipelinesList(pipelines));
    });
    getTabsList().then(({ data: { data } }) => {
      dispatch(projectmasterDataActions.setTabsList(data));
    });
    getProductTypesList().then(({ data: { data } }) => {
      dispatch(masterdataActions.setProductTypesList(data));
    });
    getGroupsList().then(({ data: { data } }) => {
      dispatch(masterdataActions.setGroupsList(data));
    });
    getCategoriesList().then(({ data: { data } }) => {
      dispatch(masterdataActions.setCategoriesList(data));
    });
    getTagsList().then(({ data: { data } }) => {
      dispatch(masterdataActions.setTagsList(data));
    });
    getIndustryGroups().then(({ data: { data = [] } }) => {
      dispatch(masterdataActions.setIndustryGroupsList(data));
    });
    getLabelsList().then(({ data: { data = [] } }) => {
      dispatch(masterdataActions.setLabelsList(data));
    });
    getFieldsList().then(({ data: { fields } }) => {
      dispatch(projectmasterDataActions.setFieldssList(fields));
    });
    getRoleDetails(user?.role.id).then(({ data: { rolesDetails } }) => {
      dispatch(actions.setRoleDetails(rolesDetails));
    });

    getDealPipelines().then(({ data: { pipelines } }) => {
      dispatch(DealActions.setDealPipelines(pipelines));
    });

    getRolesList().then(({ data: { rolesDetails = [] } }) => {
      dispatch(rolesAndPermissionsActions.setRolesList(rolesDetails));
    });
    getResourcesList();
  }, []);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/account" component={AccountPage} />
        <Route path="/system/user" component={UserManagement} />
        <Route path="/crm/partner" component={PartnerManagement} />
        <Route path="/crm/contacts" component={CustomerManagement} />
        <Route path="/file-manager" component={FileManagerPage} />
        <Route
          path="/system/roles-permissions"
          component={RolesAndPermissionsPage}
        />
        <Route path="/system/teams" component={TeamsPage} />
        <Route path="/master-data" component={MasterdataPage} />
        <Route path="/projects/master-data" component={ProjectsMasterData} />
        <Route path="/projects/pipelines" component={PipelinesPage} />
        <Route path="/sales/products" component={ProductsPage} />
        <Route path={`/content/deals`} component={DealsPage} />
        <Route path={`/deals/archived`} component={ArchivedPage} />
        <Route path={`/interfaces/google`} component={GooglePage} />
        <Route path={`/crm/calendar`} component={CalendarPage} />
        <Redirect from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
