import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { SuccessAlert } from "../../../sharedComponents/Alert";
import clsx from "clsx";
import { addDeal } from "../redux/DealsAPI";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { PartnerType } from "../../partnerManagement/types/getPartnersListResponseType";
import SelectCustomerModal from "../../customerManagement/components/SelectCustomerModal";
import SelectPartnersModal from "../../../sharedComponents/SelectPartnersModal/SelectPartnersModal";
import { UserType } from "../../userManagement/types/getUsersListResponseType";

interface Props {
  show: boolean;
  closeModal: () => void;
  getDealPhaseAPI: () => void;
  pipelineId: number;
}

const AddNewDealModal: FC<Props> = ({
  show,
  closeModal,
  getDealPhaseAPI,
  pipelineId,
}) => {
  const intl = useIntl();
  const ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS" })
  ) as {
    id: number;
    name: string;
  }[];
  //   state
  const [loadng, setLoading] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerType>();
  const [selectedContact, setSelectedContact] = useState<UserType>();
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [selectType, setSelectType] = useState<{
    id: number;
    name: string;
  }>();

  const initialValues = {
    name: "",
    started_on: "",
    tags_id: undefined,
    partner_id: false,
    contact_id: false,
  };

  const AddNewDealSchema = Yup.object().shape({
    // name: Yup.string()
    //   .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
    //   .required(intl.formatMessage({ id: "PROJECTS_PIPELINE_NAME_REQUIRED" })),
    partner_id: Yup.boolean().when("contact_id", {
      is: false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    // contact_id: Yup.boolean().when("partner_id", {
    //   is: true,
    //   then: (schema) => schema.required(),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewDealSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (pipelineId && (selectedContact?.id || selectedPartner?.id)) {
        setLoading(true);
        addDeal(
          pipelineId,
          values.name,
          selectedPartner?.id,
          selectedContact?.id
        )
          .then((data) => {
            closeModal();
            SuccessAlert(
              intl.formatMessage({ id: "DEAL_ADD_SUCCESS_MESSAGE" }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((err) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "DEAL_ADD_FAILURE_MESSAGE",
                  })
                );
          })
          .finally(() => {
            setLoading(false);
            getDealPhaseAPI();
          });
      }
    },
  });

  return (
    <>
      {openContactModal && (
        <SelectCustomerModal
          show={openContactModal}
          closeModal={() => setOpenContactModal(false)}
          onSelectCustomers={(customers) => {
            // console.log(customers);
            setSelectedContact(customers[0]);
            setOpenContactModal(false);
          }}
          isSelectionModal={true}
          selectedContacts={selectedContact}
        />
      )}
      {openPartnerModal && (
        <SelectPartnersModal
          show={openPartnerModal}
          closeModal={() => setOpenPartnerModal(false)}
          onSelectPartner={(partners) => {
            // console.log(partners);
            setOpenPartnerModal(false);
            setSelectedPartner(partners[0]);
          }}
          isSelectionModal={true}
        />
        // <></>
      )}
      <Modal
        show={show}
        centered
        contentClassName={loadng ? "pe-none" : "AddNew"}
        dialogClassName={"medium-size-modal"}
        backdrop="static"
        className={`${
          openContactModal || openPartnerModal ? "d-none" : "d-block"
        }`}
      >
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({ id: "DEAL_ADD_NEW_TITLE" })}
          </Modal.Title>
        </Modal.Header>
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_add_category_form"
        >
          <Modal.Body>
            <div className="p-4">
              {formik.status && (
                <div className="mb-10 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}

              <div className="mb-4">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="">
                    {intl.formatMessage({
                      id: "DEAL_NAME_FIELD",
                    })}
                  </span>
                </label>

                <input
                  placeholder={intl.formatMessage({
                    id: "DEAL_NAME_FIELD_PLACEHOLDER",
                  })}
                  {...formik.getFieldProps("name")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  name="name"
                  autoComplete="off"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.name}</span>
                  </div>
                )}
              </div>
              <div className="mb-4">
                <span className="text-muted fs-6 required">
                  {intl.formatMessage({ id: "ADD_NEW_DEAL_NOTE" })}
                </span>
              </div>

              <div className="mb-4">
                <DropDown
                  hideSearch
                  id="users-titles-dropdown"
                  items={ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS}
                  displayFunc={(item) => {
                    return item.name;
                  }}
                  displayValue={
                    ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS.find(
                      (TITLES_OPTION) => TITLES_OPTION.name === selectType?.name
                    )?.name || ""
                  }
                  onSelectItem={(item) => {
                    setSelectType(item);
                    if (item.name === "Organisation") {
                      setOpenPartnerModal(true);
                      setSelectedContact(undefined);
                    } else {
                      setOpenContactModal(true);
                      setSelectedPartner(undefined);
                    }
                  }}
                  selectedItem={ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS.find(
                    (TITLES_OPTION) => TITLES_OPTION.name === selectType?.name
                  )}
                />
              </div>

              {selectedPartner && selectedPartner.id && (
                <div className="mb-4">
                  <label className=" fw-bold fs-6 mb-2 mb-2">
                    {intl.formatMessage({ id: "ADD_NEW_DEAL_PARTNER_TITLE" })}
                  </label>
                  <div className="d-flex">
                    <input
                      placeholder={intl.formatMessage({
                        id: "ADD_DEAL_PARTNER_PLACEHOLDER",
                      })}
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      value={
                        `${selectedPartner?.name}${" "}${
                          (selectedPartner?.city &&
                            ", " + selectedPartner?.city + ", ") ||
                          ""
                        }${
                          (selectedPartner?.zip_code &&
                            selectedPartner?.zip_code) ||
                          ""
                        }` || ""
                      }
                      name="Partner"
                      autoComplete="off"
                      disabled
                    />
                  </div>
                </div>
              )}
              {selectedContact && selectedContact.id && (
                <div className="mb-4">
                  <label className=" fw-bold fs-6 mb-2 mb-2">
                    {intl.formatMessage({ id: "ADD_NEW_DEAL_CONTACT_TITLE" })}
                  </label>
                  <div className="d-flex">
                    <input
                      placeholder={intl.formatMessage({
                        id: "ADD_DEAL_CONTACT_PLACEHOLDER",
                      })}
                      className={clsx(
                        "form-control form-control-lg form-control-solid "
                      )}
                      value={
                        selectedContact
                          ? `${selectedContact.firstname} ${selectedContact.lastname}, ${selectedContact.email}` +
                            //@ts-ignore
                            (selectedContact?.personalAddress?.address_1
                               //@ts-ignore
                              ? `, ${selectedContact.personalAddress?.address_1}`
                              : "")
                          : ""
                      }
                      name="Contact"
                      autoComplete="off"
                      disabled
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
            >
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {!loadng && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loadng && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_TAGS_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddNewDealModal;
