import {Action} from '@reduxjs/toolkit'
// import {GroupsResponseType, GroupType} from '../../masterdata/types/GetGroupsListResponseType'
import {FilemanagerDataType, FileType, FolderType} from '../types/getFilemanagerDataResponseType'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SET_FILEMANAGER_DATA: 'SET_FILEMANAGER_DATA',
}

const initialState: FilemanagerDataType = {
  total_size: 0,
  total_items: 0,
  breadcrumbs: [],
  folders: [],
  files: [],
}

export interface FilemanagerInitState {
  total_size: number
  total_items: number
  breadcrumbs?: FolderType[]
  folders: FolderType[]
  files: FileType[]
}

export const FilemanagerReducer = (
  state = initialState,
  action: ActionWithPayload<FilemanagerInitState>
) => {
  switch (action.type) {
    case actionTypes.SET_FILEMANAGER_DATA: {
      const data = action.payload
      return {
        ...state,
        total_size: data?.total_size,
        total_items: data?.total_items,
        breadcrumbs: data?.breadcrumbs,
        folders: data?.folders,
        files: data?.files,
      }
    }

    default:
      return state
  }
}

export const actions = {
  setFilemanagerData: (filemanagerData: FilemanagerInitState) => ({
    type: actionTypes.SET_FILEMANAGER_DATA,
    payload: filemanagerData,
  }),
}
