import React, {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import Filemanager from '../../modules/filemanager/Filemanager'
import {FileType} from '../../modules/filemanager/types/getFilemanagerDataResponseType'
import CloseButton from '../closeButton/CloseButton'
import './selectFileModal.scss'

interface Props {
  show: boolean
  closeModal: () => void
  onSelectFile: (file: FileType | FileType[]) => void
  allowedMimetypes?: string[]
  isMultiple?: boolean
  isSelectionModal?: boolean
  selectedFiles?: any[]
}

const SelectFileModal: FC<Props> = ({
  show,
  closeModal,
  onSelectFile,
  allowedMimetypes,
  isMultiple,
  isSelectionModal,
  selectedFiles
}) => {
  const intl = useIntl()
  return (
    <Modal
      show={show}
      centered
      dialogClassName='extra-large-size-modal xl-filemanager'
      // contentClassName={'extra-large-filemanager'}
      backdrop='static'
    >
      <Modal.Header>
        <div className='d-flex flex-row flex-fill align-items-center justify-content-between  '>
          <Modal.Title>
            {intl.formatMessage({id: 'WEBSITE_META_DETAILS_SELECT_FILE_MODAL_TITLE'})}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${isSelectionModal ? 'selections_modal' : ''}`}
        style={{padding: '0px'}}
      >
        <Filemanager
          type={'selectfile'}
          onSelectFile={onSelectFile}
          allowedMimetypes={allowedMimetypes}
          isMultiple={isMultiple}
          isSelectionModal={isSelectionModal}
          selectedFiles={selectedFiles}
        />
      </Modal.Body>
      {/* <Modal.Footer className='justify-content-center'>
        <Button variant='secondary' onClick={closeModal}>
          Close
        </Button>
        <Button variant='primary' onClick={() => {}}>
          Submit
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default SelectFileModal
