import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { useIntl } from "react-intl";

// custom
import { updateCustomerComment } from "../redux";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import clsx from "clsx";

interface Props {
  userId: number;
  comment: string;
  getCustomerDetailsAPI: () => void;
}

interface ModalComponentProps {
  users_id: number;
  comments: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  getCustomerDetailsAPI: () => void;
}

const CustomerNoticeModal: FC<ModalComponentProps> = ({
  users_id,
  comments,
  showModal,
  setShowModal,
  getCustomerDetailsAPI,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    users_id: users_id,
    notice: comments ? comments : "",
  };

  const EditCustomerNoticeSchema = Yup.object().shape({
    users_id: Yup.string().required(),
    notice: Yup.string(),
  });

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {comments
            ? intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_EDIT_MODAL_TITLE",
              })
            : intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_MODAL_TITLE",
              })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={EditCustomerNoticeSchema}
        enableReinitialize={true}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setLoading(true);
          console.log(values);
          updateCustomerComment(values.users_id, values.notice)
            .then(() => {
              SuccessAlert(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_SUCCESS_MESSAGE",
                }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch(() => {
              errorAlert(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getCustomerDetailsAPI();
              setLoading(false);
              setShowModal(false);
            });
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          status,
        }) => (
          <>
            <Modal.Body>
              <div className="p-4">
                {status && (
                  <div className="mb-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span>
                    {intl.formatMessage({
                      id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_MODAL_FIELD_NAME",
                    })}
                  </span>
                </label>

                <textarea
                  placeholder={intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_MODAL_FILED_PLACEHOLDER",
                  })}
                  className={clsx(
                    "form-control form-control-lg form-control-solid customer-notice-textarea"
                  )}
                  name="notice"
                  autoComplete="off"
                  onChange={handleChange("notice")}
                  value={values.notice}
                  rows={5}
                />
                {touched.notice && errors.notice && (
                  <div className="text-danger">
                    <span role="alert">{errors.notice}</span>
                  </div>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={closeModal}>
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "MASTERDATA_CONTACT_TITLE_LOADING_MESSAGE",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const CustomerNotice: FC<Props> = ({
  userId,
  comment,
  getCustomerDetailsAPI,
}) => {
  const intl = useIntl();

  // state
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <CustomerNoticeModal
        users_id={userId}
        comments={comment}
        showModal={showModal}
        setShowModal={setShowModal}
        getCustomerDetailsAPI={getCustomerDetailsAPI}
      />
      <div className="card card-flush py-4 customer-notice-card">
        <div className="card-header card-notice-header py-2">
          <div className="card-title">
            <h2 className="text-danger">
              {intl.formatMessage({ id: "CONTACTS_NOTICE_TITLE" })}
            </h2>
          </div>
        </div>

        <div className="card-body d-flex align-items-center py-0">
          {comment ? (
            <p className="fs-6 text-gray-700">{comment}</p>
          ) : (
            <p className="fs-6 text-gray-700">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_NO_DATA",
              })}
            </p>
          )}
        </div>
        <div className="card-footer pt-4 d-flex justify-content-end">
          <button
            className="btn btn-sm btn-light-primary"
            onClick={() => setShowModal(true)}
          >
            {comment
              ? intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_EDIT_BTN",
                })
              : intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_BTN",
                })}
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomerNotice;
