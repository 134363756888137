import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

import { useHistory } from "react-router-dom";
import { ErrorProps } from "../types/DealPropsType";

interface Props {
  show: boolean;
  closeModal: () => void;
  type?: string;
  tempModalProps?: ErrorProps;
}

const ErrorModal: FC<Props> = ({ show, closeModal, type, tempModalProps }) => {
  const intl = useIntl();
  //   state
  const [loadng, setLoading] = useState(false);
  const history = useHistory();
  return (
    <Modal
      show={show}
      centered
      contentClassName={loadng ? "pe-none" : "AddNew"}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "DEAL_ERROR_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={() => {}}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body>
          <div className="pt-4">
            <div className="d-flex align-items-center ">
              <i className="bi bi-info-circle-fill fs-3 text-danger"></i>{" "}
              <span className="mx-2">
                {intl.formatMessage({ id: "DEAL_ERROR_MODAL_INFO" })}
              </span>
            </div>
            <div className="mt-4 alert alert-warning d-flex align-items-center">
              {intl.formatMessage({ id: "DEAL_ERROR_MODAL_INFO1" })}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          {type && type === "listModal" && (
            <Button
              variant="primary"
              onClick={() => {
                history.push(`/content/deals/editDeal`, {
                  dealDetails: tempModalProps?.dealDetails,
                });
              }}
            >
              {intl.formatMessage({ id: "DEAL_ERROR_MODAL_GO_TO_EDIT_PAGE" })}
            </Button>
          )}
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ErrorModal;
