import { useFormik } from "formik";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import CloseButton from "../../../sharedComponents/closeButton/CloseButton";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSubmit: (is_approved: number, notes: string) => void;
}

const AcceptDealModal: FC<Props> = ({ show, onSubmit, closeModal }) => {
  // selector
  const intl = useIntl();

  //   state
  const [approved, setApproved] = useState<number>(1);

  const initialValues = {
    notes: "",
  };

  const acceptApprovalSchema = Yup.object().shape({
    notes: Yup.string().required(
      intl.formatMessage({ id: "ACCEPT_APPROVAL_NOTES_REQUIRED_MESSAGE" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: acceptApprovalSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (values.notes) {
        onSubmit(approved, values.notes);
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between  ">
          <Modal.Title>
            {intl.formatMessage({ id: "ACCEPT_APPROVAL_MODAL_TITLE" })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="p-4">
          {formik.status && (
            <div className="mb-10 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* <div className="mb-10 alert alert-warning">
            <div className="alert-text font-weight-bold">
              {intl.formatMessage({ id: "ACCEPT_APPROVAL_NOTES_LABEL" })}
            </div>
          </div> */}

          <div className="fv-row mt-4">
            <label className=" fw-bold fs-6 mb-2 mb-2">
              {intl.formatMessage({ id: "ACCEPT_APPROVAL_NOTES_LABEL" })}
            </label>
            <textarea
              rows={4}
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder=""
              {...formik.getFieldProps("notes")}
              style={{ resize: "none" }}
            ></textarea>
            {formik.touched.notes && formik.errors.notes && (
              <div className="text-danger">
                <span role="alert">{formik.errors.notes}</span>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button
          variant="danger"
          onClick={() => {
            setApproved(0);
            formik.handleSubmit();
          }}
        >
          {intl.formatMessage({ id: "REJECT_BUTTON" })}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setApproved(1);
            formik.handleSubmit();
          }}
        >
          {intl.formatMessage({ id: "APPROVE_BUTTON" })}
        </Button>
        {/* </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptDealModal;
