import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import CloseButton from "../../../sharedComponents/closeButton/CloseButton";

// custom
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { PartnersContactDetailType } from "../../partnerManagement/types/getPartnersListResponseType";
import UsersList from "../../userManagement/components/UsersList";
import { TeamUserType } from "../types/TeamsResponseType";
import { ResponsiblePersonType } from "../../deals/types/DealResponseType";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectUsers: (users: UserType[]) => void;
  isSelectionModal?: boolean;
  selectedContacts?: TeamUserType[] | ResponsiblePersonType[] | undefined | any;
}

const SelectedUsersModal: FC<Props> = ({
  show,
  closeModal,
  onSelectUsers,
  isSelectionModal,
  selectedContacts,
}) => {
  const intl = useIntl();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      className={`${isFilterOpen ? "d-none" : "d-block"}`}
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between  ">
          <Modal.Title>
            {intl.formatMessage({ id: "SELECT_USERS_MODAL_TITLE" })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${isSelectionModal ? "selections_modal" : ""}`}
        style={{ padding: "0px" }}
      >
        <UsersList
          type="select"
          onSelectUsers={onSelectUsers}
          isSelectionModal={isSelectionModal}
          selectedContacts={selectedContacts}
          setIsFilterOpen={setIsFilterOpen}
        />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default SelectedUsersModal;
