import axios from 'axios'
import {CreateNewFolderResponseType} from '../types/createNewFolderResponseType'

// custom
import {
  FilemanagerDataType,
  FolderResponseType,
  getFileResponseType,
} from '../types/getFilemanagerDataResponseType'

// get all groups list
export function getFilemanagerData(id: number | null) {
  return axios.get<FilemanagerDataType>(`/mediaManager${id ? '/' + id : ''}`)
}

//get folders
export function getFilemanagerFoldersData() {
  return axios.get<FolderResponseType>(`/mediaManager/folders`)
}

// create new folder
export function createNewFolder(id: number | null, name: string) {
  return axios.post<CreateNewFolderResponseType>(`/mediaManager/folder${id ? '/' + id : ''}`, {
    name: name,
  })
}

//getFile
export function getFile(id: number) {
  return axios.get<getFileResponseType>(`mediaManager/getFile/${id}`)
}

// show existing folder
export function showFolder(id: number) {
  return axios.get<FilemanagerDataType>(`/mediaManager/${id}`)
}

// rename existing folder
export function renameFolder(id: number, parent_id: number, name: string) {
  return axios.post<CreateNewFolderResponseType>(
    `/mediaManager/renameFolder/${id}${parent_id ? '/' + parent_id : ''}`,
    {
      name: name,
    }
  )
}

// delete existing group
export function deleteFolder(id: number) {
  return axios.delete<CreateNewFolderResponseType>(`/mediaManager/folder/${id}`)
}

// upload files
export function uploadfiles(
  id: number | null,
  medias: any,
  onUploadProgressCb: (progressEvent: ProgressEvent) => void
) {
  return axios.post<CreateNewFolderResponseType>(
    `/mediaManager/files${id ? '/' + id : ''}`,
    medias,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // @ts-ignore
      onUploadProgress: (progressEvent: ProgressEvent) => {
        onUploadProgressCb(progressEvent)
      },
    }
  )
}

// delete existing file
export function deleteFile(id: number) {
  return axios.delete<CreateNewFolderResponseType>(`/mediaManager/file/${id}`)
}

// rename existing file
export function renameFile(id: number, folder_id: number, name: string) {
  return axios.post<CreateNewFolderResponseType>(
    `/mediaManager/renameFile/${id}${folder_id ? '/' + folder_id : ''}`,
    {
      name: name,
    }
  )
}

// mass delete existing files or folders
export function massDeleteFoldersOrFiles(folders_id: Array<number>, files_id: Array<number>) {
  return axios.post<CreateNewFolderResponseType>(`/mediaManager/massDelete`, {
    folders_id,
    files_id,
  })
}

//update file
export function updateFile(id: number, folder_id: number, name: string, copyright_text: string) {
  return axios.post(`/mediaManager/renameFile/${id}?${folder_id}`, {
    name,
    copyright_text,
  })
}

export function downloadFileAPI(id: number) {
  return axios.get<Blob>(`/mediaManager/downloadFile/${id}`)
}

//move file
export function moveFile(folders_id: any, files_id: any, destination_id: string) {
  return axios.post(`/mediaManager/move`, {
    folders_id,
    files_id,
    destination_id,
  })
}

//optimize files
export function optimizeFiles(notified_by: number, files_id: number[]) {
  return axios.post(`/mediaManager/optimizeFiles`, {
    notified_by,
    files_id,
  })
}

//zip file
export function zipFile(folders: any, files: any) {
  return axios.post(
    `/mediaManager/downloadZip`,
    {
      folders,
      files,
    },
    {responseType: 'blob'}
  )
}
