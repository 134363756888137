import axios from "axios";
import {
  PhaseResponseType,
  PipelineResponseByID,
  PipelineResponseType,
} from "../types/PipelinesResponseType";

export function getPipelinesList() {
  return axios.get<PipelineResponseType>(`/pipelines`);
}

export function createPipeline(name: any, transition_type: string) {
  return axios.post(`/pipelines`, {
    name,
    transition_type,
  });
}

export function getPipelineByID(id: number) {
  return axios.get<PipelineResponseByID>(`/pipelines/${id}`);
}

export function updatePipelines(
  id: number,
  name: string,
  transition_type: string
) {
  return axios.post(`/pipelines/${id}`, {
    name,
    transition_type,
  });
}

export function deletePipeline(id: number) {
  return axios.delete(`/pipelines/${id}`);
}

export function massDeletePipelines(pipelines_id: any[]) {
  return axios.post(`/pipelines/massDelete`, {
    pipelines_id,
  });
}

export function Sortpipeline(pipelines_sorting: any[]) {
  return axios.post(`/pipelines/sorting`, {
    pipelines_sorting,
  });
}

export function createPipelinePhase(
  id: number,
  name: string,
  is_required: number,
  is_endPhase: number
) {
  return axios.post(`/pipeline/phases/${id}`, {
    name,
    is_required,
    is_endPhase,
  });
}

export function deletePipelinePhase(id: number) {
  return axios.delete(`/pipeline/phases/${id}`);
}

export function addPhaseRoles(phase_id: number, role_id: any[]) {
  return axios.post(`pipeline/phases/attach/roles/${phase_id}`, {
    role_id,
  });
}

export function updatePhase(
  id: number,
  phase_id: number,
  name: string,
  is_required: any,
  is_endPhase: number
) {
  return axios.post(`/pipeline/phases/${id}/${phase_id}`, {
    name,
    is_required,
    is_endPhase,
  });
}

export function addPhaseTabs(phase_id: number, tab_id: any[]) {
  return axios.post(`/pipeline/phases/attach/tabs/${phase_id}`, {
    tab_id,
  });
}

export function deletePhaseTabs(phase_id: number, tab_id: any[]) {
  return axios.post(`/pipeline/phases/delete/tabs/${phase_id}`, {
    tab_id,
  });
}

export function SortPipelinePhases(
  id: number,
  pipelines_phases_sorting: any[]
) {
  return axios.post(`/pipeline/phases/sorting/${id}`, {
    pipelines_phases_sorting,
  });
}

export function SortPhasesTabs(phase_id: number, phases_tabs_sorting: any[]) {
  return axios.post(`/pipeline/phases/sorting/tabs/${phase_id}`, {
    phases_tabs_sorting,
  });
}

export function getPipelinePhaseById(phase_id: number) {
  return axios.get<PhaseResponseType>(`/pipeline/phases/${phase_id}`);
}

export function updateDisplayInMobile(
  phase_id: number,
  display_in_mobile: number
) {
  return axios.post(`/pipeline/phases/updateDisplayInMobile/${phase_id}`, {
    display_in_mobile,
  });
}

export function needApproval(phase_id: number, need_approvals: number) {
  return axios.post(`/pipeline/phase/approvals/${phase_id}`, {
    need_approvals,
  });
}

export function updateApprovers(phase_id: number, data: any) {
  return axios.post(`/pipeline/phase/approvers/${phase_id}`, {
    data,
  });
}
