import React, { FC, useEffect, useState } from "react";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";

// custom
import Constants from "../../../config/Constants";
import SelectFileModal from "../../../sharedComponents/selectFileModal/selectFileModal";
import { FileType } from "../../filemanager/types/getFilemanagerDataResponseType";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { useIntl } from "react-intl";

import {
  SuccessAlert,
  deleteConfirmAlert,
  errorAlert,
} from "../../../sharedComponents/Alert";
// import {getResolutionFileUrl} from '../../../utils/getResolutionFile'
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import {
  deleteProfilePicture,
  uploadCustomerProfilePicture,
  uploadProfilePicture,
} from "../redux";

interface Props {
  customer: UserType;
  getCustomerDetailsAPI: () => void;
}

interface FileUploadType {
  file: FileType;
  status?: "success" | "failure" | "loading";
}

const UploadEventTypeFile: FC<Props> = ({
  customer,
  getCustomerDetailsAPI,
}) => {
  const [mainFile, setMainFile] = useState<FileUploadType | undefined>();
  const [showFileSelectModal, setShowFileSelectModal] =
    useState<boolean>(false);
  const intl = useIntl();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<any>();

  useEffect(() => {
    setProfilePhotoUrl(customer?.profile_photo_url);
  }, [customer]);

  // const closeMainFileModal = () => {
  //   setShowFileSelectModal(false);
  // };

  // const onFileSelect = (file: any) => {
  //   closeMainFileModal();
  //   setMainFile({ file, status: "loading" });
  //   uploadCustomerProfilePicture(customer?.id, file.id)
  //     .then(() => {
  //       setMainFile({ file, status: "success" });
  //       successToast(
  //         intl.formatMessage({ id: "CUSTOMER_UPLOAD_PROFILEPIC_SUCCESS" })
  //       );
  //     })
  //     .catch(() => {
  //       setMainFile({ file, status: "failure" });
  //       errorToast(
  //         intl.formatMessage({ id: "CUSTOMER_UPLOAD_PROFILEPIC_FAILURE" })
  //       );
  //     })
  //     .finally(() => {
  //       getCustomerDetailsAPI?.();
  //     });
  // };

  const removeMainFile = (type?: string) => {
    uploadCustomerProfilePicture(customer?.id, "")
      .then(() => {
        setProfilePhotoUrl(undefined);
      })

      .finally(() => {
        getCustomerDetailsAPI?.();
      });
  };

  const handleProfileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    // console.log(files);
    const selectedFiles = files as FileList;
    setProfilePhotoUrl(URL.createObjectURL(selectedFiles?.[0]));
    const formData = new FormData();
    formData.append("profile_photo", selectedFiles?.[0]);

    uploadProfilePicture(customer?.id, formData)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "USERS_ADDED_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(intl.formatMessage({ id: "USERS_ADDED_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        getCustomerDetailsAPI();
      });
  };
  // const handleProfileDelete = () => {
  //   deleteProfilePicture(customer?.id)
  //     .then(() => {
  //       setFile(null);
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       getCustomerDetailsAPI();
  //     });
  // };

  return (
    <div className="card card-flush border-0">
      {/* {showFileSelectModal && (
        <SelectFileModal
          show={showFileSelectModal}
          closeModal={closeMainFileModal}
          onSelectFile={onFileSelect}
          allowedMimetypes={Constants.mimeTypes.image}
          isMultiple={false}
          isSelectionModal={true}
        />
      )} */}

      <div className="card-header px-0">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "CUSTOMER_UPLOAD_PROFILEPIC_SECTION_TITLE",
            })}
          </h2>
        </div>
      </div>

      <div className="card-body pt-0 px-0">
        <div className="d-flex justify-content-start mb-3">
          <div className="image-input image-input-outline">
            <img
              // src={mainFile?.file.file_path || event.event_image_url || BlankAvatar}
              src={
                profilePhotoUrl ? profilePhotoUrl : BlankAvatar || BlankAvatar
              }
              alt="img"
              style={{ objectFit: "fill" }}
              className="image-input-wrapper w-125px h-125px"
              draggable={false}
            />

            <label
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Change avatar"
              htmlFor="file"
            >
              <input
                type="file"
                id="file"
                accept="image/jpeg,image/jpg,image/png"
                multiple={false}
                data-original-title="upload photos"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleProfileUpload(e);
                }}
              />
              <i className="bi bi-pencil-fill fs-7"></i>
            </label>
            {/* {profilePhotoUrl ? (
              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute"
                data-bs-toggle="tooltip"
                style={{ bottom: -5, right: -2 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteConfirmAlert(
                    intl.formatMessage({
                      id: "CUSTOMER_PROFILE_CONFIRM_DELETE_MESSAGE",
                    }),
                    (data) => {
                      if (data === "Yes") removeMainFile();
                    }
                  );
                }}
              >
                <i className="bi bi-x fs-2"></i>
              </span>
            ) : null} */}
          </div>
        </div>
        <div className="text-muted fs-7 text-start mt-3">
          {intl.formatMessage({ id: "PARTNERS_LOGO_ALLOWED_MESSAGE" })}
        </div>
      </div>
    </div>
  );
};

export default UploadEventTypeFile;
