import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { ProductType } from "../types/ProductsResponseType";
import { useFormik } from "formik";
import { updateProductGeneralDetails } from "../redux/ProductsAPI";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import SunEditorComponent from "../../../sharedComponents/textEditor/SunEditor";

interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
}

const ProductGeneralDetails: FC<Props> = ({
  product,
  getProductDetailsAPI,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();
  const [currProduct, setCurrProduct] = useState<ProductType>(product);

  const categoriesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.categoriesList
  ) as GroupType[];

  // useEffect(() => {
  //   setCurrProduct(product);
  // }, [product]);

  const initialValues = {
    name: currProduct?.name || "",
    category_id: currProduct?.category_id || "",
    description: currProduct?.description || "",
  };

  const EditProductSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "PRODUCT_NAME_REQUIRED_MESSAGE" })
    ),
    category_id: Yup.string().required(
      intl.formatMessage({ id: "PRODUCT_CATEGORY_REQUIRED_MESSAGE" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "PRODUCT_DESCRIOTION_REQUIRED_MESSAGE" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: EditProductSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      updateProductGeneralDetails(
        currProduct.id,
        values.category_id,
        values.name,
        values.description
      )
        .then(() => {
          SuccessAlert(
            intl.formatMessage({
              id: "PRODUCT_GENERAL_DETAILS_SUCCESS_MESSAGE",
            }),
            () => {},
            intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorAlert(
            intl.formatMessage({
              id: "PRODUCT_GENERAL_DETAILS_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setLoading(false);
          getProductDetailsAPI();
        });
    },
  });

  return (
    <div className={loading ? "pe-none" : ""}>
      <div className="card card-flush py-4">
        <div className="card-header">
          <div className="card-title">
            <h2>
              {intl.formatMessage({ id: "PRODUCTS_GENERAL_DETAILS_TTILE" })}
            </h2>
          </div>
        </div>
        <div className={clsx("card-body pt-0")}>
          {/* Name */}
          <div className="mb-8">
            <label className="form-label required">
              {intl.formatMessage({
                id: "PRODUCT_GENERAL_NAME_TITLE",
              })}
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger">
                <span role="alert">{formik.errors.name}</span>
              </div>
            )}
          </div>
          {/* Category */}
          <div className="mb-8">
            <label className="form-label fw-bold fs-6">
              <span className="required">
                {intl.formatMessage({
                  id: "PRODUCT_GENERAL_CATEGORY_TITLE",
                })}
              </span>
            </label>

            <div className="fv-row">
              <DropDown
                id="customer_saluation"
                hideSearch
                items={categoriesList || []}
                displayFunc={(item) => {
                  return item.name;
                }}
                displayValue={
                  categoriesList.find(
                    (item) => item.id === formik.values.category_id
                  )?.name || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("category_id", item.id);
                }}
                selectedItem={categoriesList.find(
                  (item) => item.id === formik.values.category_id
                )}
              />

              {formik.touched.category_id && formik.errors.category_id && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.category_id}</span>
                </div>
              )}
            </div>
          </div>
          <div className="mb-8">
            <label className="form-label required">
              {intl.formatMessage({
                id: "PRODUCT_GENERAL_DESCRIPTION_TITLE",
              })}
            </label>
            <SunEditorComponent
              initHtml={formik.values.description || ""}
              handleBlur={(html) => {
                formik.setFieldValue("description", html);
              }}
              wrapperPosition={0}
              loading={loading}
              hideSubmit={false}
            />
            {formik.touched.description && formik.errors.description && (
              <div className="text-danger">
                <span role="alert">{formik.errors.description}</span>
              </div>
            )}
          </div>

          {/* submit button */}
          <div className="d-flex justify-content-end mt-5">
            <button
              className="btn btn-primary min-w-100px"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {!loading &&
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_SAVE_BTN",
                })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_PROFILE_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGeneralDetails;
