import { useIntl } from "react-intl";

export const useFullName = () => {
  const intl = useIntl();

  return (salutation_id: number = 0, firstname: string, lastname: string) => {
    const USERS_SALUATION_OPTIONS = JSON.parse(
      intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
    ) as any[];

    const saultation = USERS_SALUATION_OPTIONS.find(
      (USERS_SALUATION_OPTION) => USERS_SALUATION_OPTION.id === salutation_id
    );

    return (
      saultation?.name ||
      "".concat(" ", firstname || "").concat(" ", lastname || "")
    );
  };
};
