import React, { useEffect, useState } from "react";
import { DealType } from "../../deals/types/DealResponseType";
import { useIntl } from "react-intl";
import { getArchivedDeals } from "../redux/ArchiveAPI";
import { PaginationType } from "../../partnerManagement/types/getPartnersListResponseType";
import Constants from "../../../config/Constants";
import _ from "lodash";
import { SVGICON } from "../../../../_metronic/helpers";

import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import ArchivedListActionsDropdown from "./ArchivedListDropdown";
import moment from "moment";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

const ArchiveList = () => {
  const intl = useIntl();
  const lang = useLang();

  // state
  const [archivedList, setArchivedList] = useState<DealType[]>([]);
  const [displayDealList, setDisplayDealList] = useState<DealType[]>([]);
  const [checkedDeals, setCheckedDeals] = useState<DealType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [APILoading, setAPILoading] = useState(false);

  const getArchivedDealsList = () => {
    setAPILoading(true);
    getArchivedDeals()
      .then(({ data: { deals } }) => {
        setArchivedList(deals);
        setDisplayDealList(deals);
      })
      .finally(() => {
        setAPILoading(false);
        setCheckAllItems(false);
        setCheckedDeals([]);
      });
  };

  const updateDisplayTagsList = () => {
    const updatedTagsList = archivedList.filter((tag) => {
      if (
        (tag.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayDealList(updatedTagsList);
  };

  useEffect(() => {
    getArchivedDealsList();
  }, []);

  useEffect(() => {
    updateDisplayTagsList();

    return () => {
      setDisplayDealList(archivedList);
    };
  }, [archivedList, searchText]);

  useEffect(() => {
    // update checked items
    checkedDeals.length === 0 && setCheckAllItems(false);
    checkedDeals.length !== 0 &&
      checkedDeals.length === displayDealList.length &&
      setCheckAllItems(true);
  }, [checkedDeals]);

  const onSearchTextChange = (text: string) => {
    setSearchText(text || "");
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedDeals(displayDealList);
    } else {
      setCheckedDeals([]);
    }
  };

  const onCheckedChange = (deal: DealType) => {
    let checkedDealCopy = _.clone(checkedDeals);
    const index = checkedDealCopy.findIndex(
      (checkedProduct) => checkedProduct.id === deal.id
    );
    if (index > -1) {
      checkedDealCopy.splice(index, 1);
      setCheckAllItems(false);
    } else {
      checkedDealCopy.push(deal);
    }
    setCheckedDeals(checkedDealCopy);
  };

  const isChecked = (deal: DealType) => {
    const index = checkedDeals.findIndex(
      (checkedProduct) => checkedProduct.id === deal.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedArchived =
    displayDealList && displayDealList.length > 10
      ? displayDealList.slice(startIndex, startIndex + itemsPerPage)
      : displayDealList;

  useEffect(() => {
    if (paginatedArchived.length === 0 && displayDealList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedArchived]);

  return (
    <div className="borderNone card">
      {/* begin::Header */}
      <div
        className={`card-header border-0 pt-8 d-flex flex-row justify-content-between mb-2`}
      >
        <div className="d-flex align-items-center" style={{ borderWidth: 1 }}>
          <div className="position-relative">
            <input
              type="text"
              className="form-control form-control-solid w-250px position-relative"
              placeholder={intl.formatMessage({
                id: "ARCHIVED_DEALS_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingRight: "30px",
              }}
              value={searchText}
              onKeyDown={(e) => {}}
            />
          </div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {}}
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              padding: "11px",
            }}
          >
            <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {checkedDeals.length > 0 && (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedDeals.length}{" "}
                {intl.formatMessage({ id: "ARCHIVED_DEALS_LIST_SELECTED" })}
              </span>
              <button className={"btn btn-danger"} onClick={() => {}}>
                {intl.formatMessage({
                  id: "ARCHIVED_DEALS_RESTORE_SELECTED",
                })}
              </button>
            </>
          )}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            {/* begin::Table head */}
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 ">
                <th className="w-25px">
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <Checkbox
                      onChange={onAllItemsCheckChange}
                      checked={checkAllItems}
                    />
                  </div>
                </th>

                <th className="min-w-125px">
                  {intl.formatMessage({ id: "ARCHIVED_DEAL_NAME" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "DEAL_RESPONSIBLE_TABLE_NAME" })}
                </th>

                <th className="min-w-125px">
                  {intl.formatMessage({ id: "ARCHIVED_DEAL_CREATED_AT" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "ARCHIVED_DEAL_DELETED_AT" })}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {APILoading ? (
                <td valign="top" colSpan={7} className="dataTables_empty">
                  <SimpleLoader />
                </td>
              ) : (
                <>
                  {paginatedArchived.map((deal, i) => {
                    const displayUsers = (deal.responsible_users || []).slice(
                      0,
                      5
                    );
                    const remainingUsers =
                      (deal.responsible_users || []).length -
                      displayUsers.length;
                    return (
                      <tr key={i}>
                        {/* checkbox*/}

                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                            <Checkbox
                              onChange={() => {
                                onCheckedChange(deal);
                              }}
                              checked={isChecked(deal)}
                              disabled={false}
                            />
                          </div>
                        </td>

                        {/* user name,email,pic */}
                        <td>
                          <div className="d-flex  flex-column">
                            <div className="d-flex justify-content-start ">
                              <span
                                className="text-gray-800 fw-bold text-hover-primary mb-1"
                                role="button"
                                onClick={() => {}}
                              >
                                {deal.name}
                              </span>
                            </div>
                            <div
                              className="badge"
                              style={{
                                background: deal?.tag_details?.colour || "",
                                width: "fit-content",
                              }}
                            >
                              <span>{deal?.tag_details?.name || ""}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              {displayUsers && displayUsers.length > 0 && (
                                <div className="">
                                  <div className="symbol-group symbol-hover flex-nowrap">
                                    {displayUsers?.map((user, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="symbol symbol-35px symbol-circle"
                                          style={{ position: "relative" }}
                                          onMouseOver={() => {
                                            const tooltipEle =
                                              document.getElementById(
                                                `role-user-tooltip-${index}${deal.id}`
                                              );
                                            if (tooltipEle) {
                                              tooltipEle.style.display = "flex";
                                            }
                                          }}
                                          onMouseOut={() => {
                                            const tooltipEle =
                                              document.getElementById(
                                                `role-user-tooltip-${index}${deal.id}`
                                              );
                                            if (tooltipEle) {
                                              tooltipEle.style.display = "none";
                                            }
                                          }}
                                        >
                                          <div
                                            id={`role-user-tooltip-${index}${deal.id}`}
                                            className="position-absolute bg-light px-3 py-2 fs-8"
                                            style={{
                                              display: "none",
                                              backgroundColor: "white",
                                              top: -60,
                                              borderRadius: 5,
                                            }}
                                          >
                                            {`${user.firstname}  ${user.lastname}`}
                                          </div>
                                          {
                                            <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                              {(user.firstname ||
                                                " ")[0].toUpperCase()}
                                            </span>
                                          }
                                        </div>
                                      );
                                    })}
                                    {remainingUsers > 0 && (
                                      <div
                                        className="symbol symbol-35px symbol-circle"
                                        onClick={() => {}}
                                      >
                                        <span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                                          +{remainingUsers}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-gray-600 fw-bold">
                            {moment(deal.created_at).format(
                              lang === "en" ? "DD-MM-YYYY" : "DD.MM.YYYY"
                            )}
                          </span>
                        </td>
                        <td>
                          {" "}
                          <span className="text-gray-600 fw-bold">
                            {moment(deal.deleted_at).format(
                              lang === "en" ? "DD-MM-YYYY" : "DD.MM.YYYY"
                            )}
                          </span>
                        </td>

                        {/* actions */}
                        {
                          <td>
                            <ArchivedListActionsDropdown
                              dealDetails={deal}
                              getArchivedDealsListAPI={getArchivedDealsList}
                            />{" "}
                          </td>
                        }
                      </tr>
                    );
                  })}
                </>
              )}

              {/* no data */}
              {archivedList.length === 0 && (
                <tr className="odd">
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <img
                        alt="not-found"
                        src={notFound}
                        className="mw-400px"
                      />
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        {intl.formatMessage({
                          id: "PARTNERS_LIST_NOITEMS_FOUND",
                        })}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>

          {/* end::Table */}
          {archivedList.length > 0 && (
            <Pagination
              totalPages={Math.ceil(archivedList.length / itemsPerPage)}
              activePage={
                Math.ceil(archivedList.length / itemsPerPage) === 1
                  ? 1
                  : activePage
              }
              onPageClick={onPageClick}
              noCard
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
              }}
            />
          )}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  );
};

export default ArchiveList;
