import React from 'react'
import {Helmet} from 'react-helmet'
import {useIntl} from 'react-intl'
import {Redirect, Route, Switch} from 'react-router'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {TablesWidget10} from '../../../_metronic/partials/widgets'
import config from '../../config'
import EditUserDetails from './components/EditUserDetails'
import UsersList from './components/UsersList'

// styles
import './UserManagement.scss'

const UserManagement = () => {
  const intl = useIntl()

  const userManagementBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU_DASHBOARD'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({id: 'MENU_USERMANAGEMENT'}),
      path: '/system/user',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <div>
      <Helmet>
        <title>{config.APP_NAME}-Users</title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path='/system/user/overview'>
          <PageTitle
            breadcrumbs={[
              ...userManagementBreadCrumbs,
              {
                title: intl.formatMessage({id: 'ROUTES_OVERVIEW'}),
                path: '/system/user/overview',
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({id: 'USERS_TITLE'})}
          </PageTitle>
          <UsersList />
        </Route>
        <Route path='/system/user/edituser'>
          <PageTitle
            breadcrumbs={[
              ...userManagementBreadCrumbs,
              {
                title: intl.formatMessage({id: 'ROUTES_USERMANAGEMENT_EDITUSER'}),
                path: '/system/user/edituser',
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({id: 'USERS_TITLE'})}
          </PageTitle>
          <EditUserDetails />
        </Route>

        {/* roles and resources */}
        {/* <Route path='/system/user/roles-resources'>
          <PageTitle
            breadcrumbs={[
              ...userManagementBreadCrumbs,
              {
                title: intl.formatMessage({id: 'ROUTES_USERMANAGEMENT_EDITUSER'}),
                path: '/system/user/edituser',
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({id: 'USERS_TITLE'})}
          </PageTitle>
          <UserRolesAndResources />
        </Route> */}

        <Redirect from='/system/user' exact={true} to='/system/user/overview' />
        <Redirect to='/system/user/overview' />
      </Switch>
    </div>
  )
}

export default UserManagement
