import clsx from 'clsx'
import {FieldInputProps} from 'formik'
import {FC} from 'react'

interface Props {
  onColorChange?: (color: string) => void
  formikProps?: FieldInputProps<any>
  size?: 'small' | 'medium' | 'large'
  resetColor?: any
}

const ColorPickerInput: FC<Props> = ({
  onColorChange,
  formikProps = {},
  size = 'large',
  resetColor,
}) => {
  return (
    <div className='position-relative'>
      <input
        type='color'
        autoComplete='off'
        className='position-absolute'
        style={{
          border: '0.2px solid black',
          padding: '0',
          left: '7px',
          top: '7px',
          height: '60%',
          width: size === 'small' ? '30px' : '50px',
        }}
        {...formikProps}
      />
      <input
        type='text'
        autoComplete='off'
        className={clsx(
          'form-control form-control-solid',
          size === 'small'
            ? 'form-control-sm'
            : size === 'medium'
            ? 'form-control-md'
            : 'form-control-lg'
        )}
        style={{paddingLeft: size === 'small' ? '45px' : '65px', textTransform: 'uppercase'}}
        {...formikProps}
      />
      {/* @ts-ignore */}
      {formikProps.value === null || formikProps.value === '' || !resetColor ? null : (
        <div
          onClick={() => {
            resetColor()
          }}
          className=' d-flex justify-content-center align-items-center position-absolute'
          style={{width: '20px', height: '20px', borderRadius: '20px', top: '13px', right: '17px'}}
        >
          <i className='bi bi-arrow-counterclockwise' style={{fontSize: '14px'}}></i>
        </div>
      )}
    </div>
  )
}

export default ColorPickerInput
