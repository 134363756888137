import { FC, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import NumberItemsPerPage from "../NumberItemsPerPage/NumberItemsPerPage";

interface Props {
  totalPages: number;
  activePage: number;
  onPageClick: (page: number) => void;
  showItemsPerPage?: boolean;
  itemsPerPage?: number;
  setItemsPerPage?: (count: number) => void;
  noCard?: boolean;
}

const Pagination: FC<Props> = ({
  totalPages,
  activePage,
  onPageClick,
  showItemsPerPage,
  itemsPerPage,
  setItemsPerPage,
  noCard,
}) => {
  const [searchActivePage, setSearchActivePage] = useState<any>(activePage);
  const [propsActivePage, setPropsActivePage] = useState<number>(activePage);

  useEffect(() => {
    setPropsActivePage(activePage);
    setSearchActivePage(activePage);
  }, [activePage]);

  return (
    <div
      className={`d-flex align-items-center justify-content-end ${
        noCard ? "mt-6" : "mt-12"
      } mb-6`}
    >
      {showItemsPerPage && itemsPerPage && (
        <div className="me-4">
          <NumberItemsPerPage
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}

      <div
        className="d-flex flex-row justify-content-end dataTables_paginate paging_simple_numbers  "
        id="kt_table_users_paginate"
      >
        <ReactPaginate
          breakLabel="..."
          containerClassName={`pagination align-self-center`}
          previousLabel={
            <i className="bi bi-chevron-left fw-bolder fs-4 text-dark"></i>
          }
          previousClassName={`paginate_button page-item previous `}
          previousLinkClassName={`page-link`}
          nextLabel={
            <i className="bi bi-chevron-right fw-bolder fs-6 text-dark"></i>
          }
          nextClassName={`paginate_button page-item next`}
          nextLinkClassName={`page-link`}
          breakClassName={`paginate_button page-item`}
          breakLinkClassName={`page-link`}
          pageClassName={`paginate_button page-item`}
          pageLinkClassName={`page-link`}
          activeClassName={`paginate_button page-item active`}
          activeLinkClassName={`page-link btn-light-primary`}
          onPageChange={({ selected }) => {
            onPageClick(selected + 1);
            setSearchActivePage(selected + 1);
          }}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          disabledClassName={`disabled`}
          prevRel={null}
          forcePage={propsActivePage - 1}
        />
        {totalPages && totalPages > 10 ? (
          <div className="d-flex justify-content-center">
            <input
              type="number"
              className="form-control form-control-solid w-80px mx-2 "
              onChange={(e) => {
                if (Number(e.target.value) <= totalPages) {
                  setSearchActivePage(Number(e.target.value));
                }
              }}
              value={searchActivePage ? searchActivePage : ""}
            />
            <button
              type="button"
              className="btn btn-light-primary"
              onClick={() => {
                if (searchActivePage === 0) {
                  setSearchActivePage(1);
                  setPropsActivePage(1);
                  onPageClick(1);
                } else {
                  setPropsActivePage(searchActivePage);
                  onPageClick(searchActivePage);
                }
              }}
            >
              Go
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Pagination;
