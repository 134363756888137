import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
// import {WordpressSiteType} from '../../wordpress/types/getWordpressSitesListResposeType'
import CloseButton from "../../../sharedComponents/closeButton/CloseButton";
// import {addWordpressSite, updateWordpressSite} from '../../wordpress/redux'
import { SuccessAlert } from "../../../sharedComponents/Alert";
import { useIntl } from "react-intl";
import { GoogleAccountType } from "../types/googleResponseType";
import { addGoogleAccount, updateGoogleAccount } from "../redux/GoogleAPI";

interface ModalProps {
  show: boolean;
  type?: string;
  account?: GoogleAccountType;
}

interface WordpressSitesModalProps {
  modalProps: ModalProps;
  closeModal: () => void;
  getGoogleAccountsListAPI: () => void;
}

const AddGoogleAccountModel: FC<WordpressSitesModalProps> = ({
  modalProps,
  closeModal,
  getGoogleAccountsListAPI,
}) => {
  const { show, type, account } = modalProps;

  // state
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const initialValues = {
    app_name: type === "edit" ? account?.app_name || "" : "",
    client_id: type === "edit" ? account?.client_id || "" : "",
    client_secret: "",
    callback_url: `${window.location.origin}/authcode`,
    // callback_url: 'https://frontend.omnics.in/authcode',
    scope: type === "edit" ? account?.scope || "" : "",
  };

  const GoogleAccountsModalSchema = Yup.object().shape({
    app_name: Yup.string().required(
      intl.formatMessage({ id: "GOOGLE_APP_NAME_VALIDATION" })
    ),
    client_id: Yup.string().required(
      intl.formatMessage({ id: "GOOGLE_CLIENT_ID_VALIDATION" })
    ),
    callback_url: Yup.string().required(
      intl.formatMessage({ id: "GOOGLE_URL_VALIDATION" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: GoogleAccountsModalSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        setLoading(true);
        addGoogleAccount(
          values.app_name,
          values.client_id,
          values.client_secret,
          values.callback_url,
          values.scope
        )
          .then(() => {
            closeModal();
            SuccessAlert(
              intl.formatMessage({ id: "GOOGLE_ADD_ACCOUNT_SUCCESS" }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((err) => {
            setStatus(
              // err.response.data?.message ||
              intl.formatMessage({ id: "GOOGLE_ADD_ACCOUNT_FAILURE" })
            );
          })
          .finally(() => {
            setLoading(false);
            getGoogleAccountsListAPI?.();
          });
      } else {
        if (account?.id) {
          setLoading(true);
          updateGoogleAccount(
            account?.id,
            values.app_name,
            values.client_id,
            values.client_secret,
            values.callback_url,
            values.scope
          )
            .then(() => {
              closeModal();
              SuccessAlert(
                intl.formatMessage({ id: "GOOGLE_ACCOUNT_UPDATE_SUCCESS" }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch((err) => {
              setStatus(
                err.response.data?.message ||
                  intl.formatMessage({ id: "GOOGLE_ACCOUNT_UPDATE_FAILURE" })
              );
            })
            .finally(() => {
              setLoading(false);
              getGoogleAccountsListAPI();
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
          <Modal.Title>
            {type === "edit"
              ? intl.formatMessage({ id: "GOOGLE_EDIT_ACCOUNT" })
              : intl.formatMessage({ id: "GOOGLE_NEW_ACCOUNT" })}
          </Modal.Title>
          {/* <h2>{type === 'edit' ? 'Edit category' : 'Create new category'}</h2> */}
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body>
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* app name */}
            <div className="">
              <label className="required fs-5 fw-bold mb-2">
                {intl.formatMessage({ id: "GOOGLE_APP_NAME" })}
              </label>
              <input
                className="form-control form-control-lg form-control-solid"
                {...formik.getFieldProps("app_name")}
                autoComplete="off"
              />
              {formik.touched.app_name && formik.errors.app_name && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.app_name}</span>
                </div>
              )}
            </div>

            {/* client id */}
            <div className="mt-8">
              <label className="required fs-5 fw-bold mb-2">
                {intl.formatMessage({ id: "GOOGLE_CLIENT_ID" })}
              </label>
              <input
                {...formik.getFieldProps("client_id")}
                className="form-control form-control-lg form-control-solid"
                autoComplete="off"
              />
              {formik.touched.client_id && formik.errors.client_id && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.client_id}</span>
                </div>
              )}
            </div>

            {/* Client secret */}
            <div className="mt-8">
              <label className="fs-5 fw-bold mb-2">
                {intl.formatMessage({ id: "GOOGLE_CLIENT_SECRET" })}
              </label>
              <input
                {...formik.getFieldProps("client_secret")}
                className="form-control form-control-lg form-control-solid"
                autoComplete="off"
              />
              {formik.touched.client_secret && formik.errors.client_secret && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.client_secret}</span>
                </div>
              )}
            </div>

            {/* callback URL */}
            <div className="mt-8">
              <label className="required fs-5 fw-bold mb-2">
                {intl.formatMessage({ id: "GOOGLE_CALLBACK_URL" })}
              </label>
              <input
                {...formik.getFieldProps("callback_url")}
                className="form-control form-control-lg form-control-solid"
                autoComplete="off"
              />
              {formik.touched.callback_url && formik.errors.callback_url && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.callback_url}</span>
                </div>
              )}
            </div>
            {/* scope */}
            <div className="mt-8">
              <label className=" fs-5 fw-bold mb-2">
                {intl.formatMessage({ id: "GOOGLE_SCOPE" })}
              </label>
              <input
                className="form-control form-control-lg form-control-solid"
                {...formik.getFieldProps("scope")}
                autoComplete="off"
              />
              {formik.touched.scope && formik.errors.scope && (
                <div className="text-danger">
                  {/* @ts-ignore */}
                  <span role="alert">{formik.errors.scope}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "FORM_VALIDATION_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddGoogleAccountModel;
