import React, { FC } from "react";
import { useIntl } from "react-intl";
// import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
// import {RootState} from '../../../../setup'

// images
import MenuIcon from "../../../../_metronic/assets/icons/menu.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { deletePartner } from "../redux";
import { PartnerType } from "../types/getPartnersListResponseType";

interface Props {
  partner: PartnerType;
  getPartnersListAPI: () => void;
}

const PartnersListActionsDropdown: FC<Props> = ({
  partner,
  getPartnersListAPI,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEditPartnerClick = () => {
    history.push(`/crm/partner/editpartner`, {
      partnerDetails: partner,
    });
  };

  const deleteSelectedUser = () => {
    setDeleteModalLoading(true);
    deletePartner(partner.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "PARTNERS_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "PARTNERS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getPartnersListAPI?.();
      });
  };

  const dropdownOptions = [
    {
      name: intl.formatMessage({ id: "COMMON_EDIT" }),
      onClick: onEditPartnerClick,
    },
    {
      name: intl.formatMessage({ id: "COMMON_DELETE" }),
      onClick: () => {
        showDeleteConfirmModal(
          intl.formatMessage({ id: "PARTNERS_DELETE_CONFIRMATION_MESSAGE" }),
          deleteSelectedUser
        );
      },
      className: "text-danger",
    },
  ];

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
      <ActionsDropdown options={dropdownOptions} />
    </div>
  );
};

export default PartnersListActionsDropdown;
