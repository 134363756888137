import axios from "axios";
import {
  ProductActivityResponsType,
  ProductByIdResponseType,
  ProductCreateResponseType,
  ProductResponseType,
} from "../types/ProductsResponseType";
import { GenericResponseType } from "../../../types/GenericReponse";

export function getProducts(
  category_id: any,
  storage_location: any,
  article_number: any,
  product_type_ids: any,
  ean: any,
  description: any,
  assembly_instructions: any,
  limit?: number,
  search_keyword?: null | string,
  page?: number | null
) {
  let path = "/products/filter/all";
  if (page) {
    path = path + `?page=${page}`;
  }
  // if (page && limit) {
  //   path = path + `?limit=${limit}`;
  // }
  return axios.post<ProductResponseType>(path, {
    category_id,
    storage_location,
    article_number,
    product_type_ids: product_type_ids
      ? JSON.stringify(product_type_ids)
      : null,
    ean,
    description,
    assembly_instructions,
    search_keyword,
    limit,
  });
}

export function createProduct(category_id: any, name: any) {
  return axios.post<ProductCreateResponseType>(`/products`, {
    category_id,
    name,
  });
}

export function getByProductID(id: number) {
  return axios.get<ProductByIdResponseType>(`/products/${id}`);
}

export function updateProductGeneralDetails(
  id: number,
  category_id: any,
  name: any,
  description: string
) {
  return axios.post(`/products/update/general/${id}`, {
    category_id,
    name,
    description,
  });
}

export function updateProductManufacturerDetails(
  id: number,
  storage_location: any,
  manufacturer_number: any,
  ean: any,
  article_number: any
) {
  return axios.post(`/products/update/manufacturing/details/${id}`, {
    storage_location,
    manufacturer_number,
    ean,
    article_number,
  });
}

export function updateProductAssembleInstructions(
  id: number,
  assembly_instructions: any
) {
  return axios.post(`/products/update/assembly/instructions/${id}`, {
    assembly_instructions,
  });
}

export function updateProductSellingPrice(id: number, selling_price: any) {
  return axios.post(`/products/update/selling/price/${id}`, {
    selling_price,
  });
}

export function deleteProduct(id: number) {
  return axios.delete(`/products/${id}`);
}

export function deleteMultipleProducts(product_id: any[]) {
  return axios.post(`/products/massDelete`, {
    product_id,
  });
}

export function updateProductDetailsType(id: number, data: any[]) {
  return axios.post(`/products/update/product/types/${id}`, {
    data,
  });
}

export function addProductFile(id: number, product_files: any) {
  return axios.post<GenericResponseType>(
    `/products/add/files/${id}`,
    product_files,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        // console.log('progressEventprogressEventprogressEvent', progressEvent)
      },
    }
  );
}

export function deleteProductFile(id: number, file_ids: any[]) {
  return axios.post<GenericResponseType>(`/products/delete/file/${id}`, {
    file_ids,
  });
}

export function getProductActivities(id: number) {
  return axios.get<ProductActivityResponsType>(`/products/activity/${id}`);
}

export function createProductAcitivity(
  product_id: number,
  date: string,
  units: any,
  notes: string,
  input: any,
  price?: any
) {
  return axios.post(`/products/create/product/activities/${product_id}`, {
    date,
    units,
    notes,
    input,
    price,
  });
}

export function getProductActivityById(id: number) {
  return axios.get(`/products/product/activity/${id}`);
}

export function updateProductTypes(id: number, data: any[]) {
  return axios.post(`/products/update/product/types/${id}`, {
    data,
  });
}

export function deleteProductTypes(id: number, product_type_ids: any[]) {
  return axios.post(`products/delete/product/types/${id}`, {
    product_type_ids,
  });
}
