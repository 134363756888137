import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import clsx from "clsx";
import * as Yup from "yup";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import DatePicker from "react-datepicker";

import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import { SVGICON } from "../../../../_metronic/helpers";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import LabelIcon from "../../../../_metronic/assets/icons/label.svg";
import CallIcon from "../../../../_metronic/assets/icons/telephone.svg";
import EmailIcon from "../../../../_metronic/assets/icons/envelope.svg";
import OthersIcon from "../../../../_metronic/assets/icons/clipboard.svg";
import FilterIcon from "../../../../_metronic/assets/icons/filter.svg";
import AddUserIcon from "../../../../_metronic/assets/icons/add-user.svg";
import StopwatchIcon from "../../../../_metronic/assets/icons/stopwatch.svg";
import ListCheckIcon from "../../../../_metronic/assets/icons/list-check.svg";

// custom
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import {
  createTask,
  updateTask,
  // handleCreateTaskAndAssignUsers,
} from "../redux/tasksAPI";
import { RootState } from "../../../../setup";
import SelectedUsersModal from "../../teams/components/SelectUsersModal";
import { UserType } from "../../userManagement/types/getUsersListResponseType";

import { getUsers, actions } from "../../userManagement/redux";
import { TaskType } from "../types/TasksResponseType";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";

export interface TaskModalProps {
  deals_id: number;
  show: boolean;
  action?: string;
  taskDetails?: TaskType;
}

export interface ModalComponentProps {
  modalProps: TaskModalProps;
  closeModal?: () => void;
  getTasksListAPI?: () => void;
}

interface UserSymbolProps {
  data: UserType;
  handleRemove: (user_id: number) => void;
}

const UserSymbol: FC<UserSymbolProps> = ({ data, handleRemove }) => {
  return (
    <>
      <div
        className={`symbol symbol-35px symbol-circle toolip_container_${data.id}`}
        style={{ position: "relative" }}
        onMouseOver={() => {
          const tooltipEle = document.getElementById(
            `role-user-tooltip-${data.firstname}${data.id}`
          );
          if (tooltipEle) {
            tooltipEle.style.display = "flex";
          }
        }}
        onMouseOut={() => {
          const tooltipEle = document.getElementById(
            `role-user-tooltip-${data.firstname}${data.id}`
          );
          if (tooltipEle) {
            tooltipEle.style.display = "none";
          }
        }}
      >
        <span className="symbol-label bg-warning text-inverse-warning fw-bold">
          {data.firstname[0]}
        </span>
        <span
          onClick={() => handleRemove(data.id)}
          className="bg-danger symbol-delete-icon"
        >
          x
        </span>
      </div>
      <Tooltip anchorSelect={`.toolip_container_${data.id}`}>
        {data?.firstname ? (
          <div>
            {(data?.firstname ? data.firstname : "") +
              " " +
              (data?.lastname ? data.lastname : "")}
          </div>
        ) : (
          <div>{data?.username}</div>
        )}
      </Tooltip>
    </>
  );
};

const AddTaskModal: FC<ModalComponentProps> = ({
  modalProps,
  closeModal,
  getTasksListAPI,
}) => {
  const intl = useIntl();
  const local = useLang();
  const dispatch = useDispatch();

  const { deals_id, show, action, taskDetails } = modalProps;

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const [showUsersModal, setShowUsersModal] = useState<boolean>(false);
  const usersList = useSelector<RootState>(
    // @ts-ignore
    ({ userManagement }) => userManagement.users
  ) as UserType[];

  const PRIORITY_TYPES = JSON.parse(
    intl.formatMessage({ id: "TASKS_ADD_TASK_PRIORITY_TYPES" })
  ) as any[];
  const TYPE_TYPES = JSON.parse(
    intl.formatMessage({ id: "TASKS_ADD_TASK_TYPE_TYPES" })
  ) as any[];
  const STATUS_TYPES = JSON.parse(
    intl.formatMessage({ id: "TASKS_ADD_TASK_STATUS_TYPES" })
  ) as any[];

  const initialValues = {
    deals_id: deals_id,
    title: taskDetails?.title || "",
    type: taskDetails?.type || "",
    module: taskDetails?.module || (deals_id ? "deals" : "contacts"),
    status: taskDetails?.status || "to-do",
    due_date: taskDetails?.due_date || "",
    priority: taskDetails?.priority || "",
    description: taskDetails?.description || "",
    assignees_ids:
      (taskDetails?.assignees &&
        taskDetails?.assignees?.length > 0 &&
        taskDetails?.assignees.map((item) => item.user_id)) ||
      [],
  };

  const validationSchema = Yup.object().shape({
    deals_id: Yup.string().required(),
    type: Yup.string()
      .required()
      .oneOf(
        [...TYPE_TYPES.map((item) => item.name.toLocaleLowerCase())],
        "Please select only one of these -> call, email or other."
      ),
    title: Yup.string().required(),
    module: Yup.string().required(),
    status: Yup.string().required(),
    due_date: Yup.string().required(),
    priority: Yup.string()
      .required()
      .oneOf(
        [...PRIORITY_TYPES.map((item) => item.name.toLocaleLowerCase())],
        "Please select only one of these -> high, medium or low."
      ),
    description: Yup.string().required(
      intl.formatMessage({
        id: "TASKS_DESCRIPTION_VALIDATION_MESSAGE",
      })
    ),
    assignees_ids: Yup.array()
      .of(
        Yup.number()
          .required("Each number is required")
          .oneOf(
            // update a condition to only select users which are not already selected
            [...usersList.map((user) => user.id)],
            "Each number must be one of 1, 2, 3, 4, or 5."
          )
      )
      .min(
        1,
        intl.formatMessage({
          id: "TASKS_RESPONSIBLE_PERSON_VALIDATION_MESSAGE",
        })
      )
      .required(
        intl.formatMessage({
          id: "TASKS_RESPONSIBLE_PERSON_VALIDATION_MESSAGE",
        })
      ),
  });

  const handleUpdate = (
    task_id: number,
    values: any,
    setStatus: (status: string) => void
  ) => {
    updateTask(task_id, values)
      .then((data) => {
        closeModal?.();
        SuccessAlert(
          intl.formatMessage({ id: "TASKS_EDIT_TASK_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch((err) => {
        err.response?.data?.errors?.email
          ? setStatus(err.response.data?.errors?.email)
          : setStatus(
              intl.formatMessage({ id: "TASKS_EDIT_TASK_ERROR_MESSAGE" })
            );
      })
      .finally(() => {
        getTasksListAPI?.();
        setLoading(false);
      });
  };

  const handleCreate = (values: any, setStatus: (status: string) => void) => {
    createTask(values)
      .then((data) => {
        closeModal?.();
        SuccessAlert(
          intl.formatMessage({ id: "TASKS_ADD_NEW_TASK_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch((err) => {
        err.response?.data?.errors?.email
          ? setStatus(err.response.data?.errors?.email)
          : setStatus(
              intl.formatMessage({ id: "TASKS_ADD_NEW_TASK_ERROR_MESSAGE" })
            );
      })
      .finally(() => {
        getTasksListAPI?.();
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if (action === "edit") {
        // @ts-ignore
        handleUpdate(taskDetails?.id, values, setStatus);
      } else {
        handleCreate(values, setStatus);
      }
    },
  });

  const getUsersListAPI = () => {
    getUsers()
      .then(({ data: { users } }) => {
        dispatch(actions.setUsersList(users));
      })
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    getUsersListAPI();
  }, []);

  const addUserHandle = (users: UserType[]) => {
    const ids = users.map((checkedTeam) => {
      return checkedTeam.id;
    });

    const existingUsersIds = formik.values.assignees_ids.map((checkedUser) => {
      return checkedUser;
    });
    const finalIds = [...ids, ...existingUsersIds];
    formik.setFieldValue("assignees_ids", finalIds);
  };

  const removeUserhandle = (user_id: number) => {
    const existingUsersIds = formik.values.assignees_ids.filter(
      (id) => id !== user_id
    );
    formik.setFieldValue("assignees_ids", existingUsersIds);
  };

  // const displayAssignee = (formik.values.assignees_ids || []).slice(0, 4);
  // const remainingAssignee =
  //   (formik.values.assignees_ids || []).length - displayAssignee.length;

  const responsiblePersons = usersList.filter((user) =>
    //@ts-ignore
    formik.values.assignees_ids.includes(user.id)
  );

  return (
    <>
      {showUsersModal && (
        <SelectedUsersModal
          show={showUsersModal}
          closeModal={() => setShowUsersModal(false)}
          onSelectUsers={(customers) => {
            setShowUsersModal(false);
            addUserHandle(customers);
          }}
          isSelectionModal={true}
          selectedContacts={usersList.filter((user) =>
            //@ts-ignore
            formik.values.assignees_ids.includes(user.id)
          )}
        />
      )}
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        contentClassName={showUsersModal ? "d-none" : "d-block"}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            {action === "new" ? <>Add New Task</> : <>Edit Task</>}
            {/* {intl.formatMessage({ id: "PRODUCTS_ADD_NEW_MODAL_TITLE" })} */}
          </Modal.Title>
        </Modal.Header>
        <form
          onSubmit={formik.handleSubmit}
          style={{ overflowY: "scroll" }}
          noValidate
          id="kt_login_signin_form"
        >
          <Modal.Body>
            <div className="p-4">
              {formik.status && (
                <div className="mb-10 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}

              {/* <p className="text-info fw-bold mb-0">
                Formik Values:{" "}
                <span className="text-muted">
                  {JSON.stringify(formik.values)}
                </span>
              </p>
              <p className="text-info fw-bold mb-0">
                Formik Error:{" "}
                <span className="text-muted">
                  {JSON.stringify(formik.errors)}
                </span>
              </p>
              <br /> */}

              {/* Task Title */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <SVGICON src={LabelIcon} className="svg-icon-1 me-0" />
                </div>
                <div className="col-11">
                  <input
                    type="text"
                    autoComplete="off"
                    {...formik.getFieldProps("title")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                    placeholder="Task Title"
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.title}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Due Date and Priority */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <SVGICON src={StopwatchIcon} className="svg-icon-1 me-0" />
                </div>
                <div className="col-11">
                  <div className="row align-items-start">
                    {/* Due Date */}
                    <div className="col-7">
                      <DatePicker
                        name="due_date"
                        className="form-control form-control-lg form-control-solid"
                        selected={
                          formik.values.due_date
                            ? new Date(formik.values.due_date)
                            : null
                        }
                        onChange={(val) =>
                          formik.setFieldValue(
                            "due_date",
                            moment(val).format("YYYY-MM-DD HH:mm:ss")
                          )
                        }
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={10}
                        timeCaption="Time"
                        dateFormat={
                          local === "de"
                            ? "dd.MM.yyyy HH:mm"
                            : "yyyy-MM-dd h:mm aa"
                        }
                        placeholderText="Due Date"
                      />
                      {formik.touched.due_date && formik.errors.due_date && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.due_date}</span>
                        </div>
                      )}
                    </div>
                    {/* Priority */}
                    <div className="col-5">
                      <DropDown
                        id="priority"
                        items={PRIORITY_TYPES || []}
                        onSelectItem={(item) => {
                          formik.setFieldValue(
                            "priority",
                            item.name.toLocaleLowerCase()
                          );
                        }}
                        displayValue={
                          PRIORITY_TYPES.filter(
                            (item) =>
                              item.name.toLocaleLowerCase() ===
                              formik.values.priority
                          )[0]?.name
                        }
                        displayFunc={(item) => item.name}
                      />
                      {formik.touched.priority && formik.errors.priority && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.priority}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Task Type */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <SVGICON src={FilterIcon} className="svg-icon-1 me-0" />
                </div>
                <div className="col-11">
                  <DropDown
                    id="type"
                    items={TYPE_TYPES || []}
                    onSelectItem={(item) => {
                      formik.setFieldValue(
                        "type",
                        item.name.toLocaleLowerCase()
                      );
                    }}
                    displayValue={
                      TYPE_TYPES.filter(
                        (item) =>
                          item.name.toLocaleLowerCase() === formik.values.type
                      )[0]?.name
                    }
                    displayFunc={(item) => {
                      return (
                        <span className="d-flex align-items-center">
                          <SVGICON
                            src={
                              item.name === "Call"
                                ? CallIcon
                                : item.name === "Email"
                                  ? EmailIcon
                                  : item.name === "Other"
                                    ? OthersIcon
                                    : null
                            }
                            className="svg-icon-3 me-4"
                          />
                          {item.name}
                        </span>
                      );
                    }}
                  />
                  {formik.touched.type && formik.errors.type && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.type}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Status Type */}
              {action === "edit" && (
                <div className="row mb-8">
                  <div className="col-1 py-3">
                    <SVGICON src={FilterIcon} className="svg-icon-1 me-0" />
                  </div>
                  <div className="col-11">
                    <DropDown
                      id="status"
                      items={STATUS_TYPES || []}
                      // @ts-ignore
                      selectedItem={formik.values.status}
                      onSelectItem={(item) => {
                        formik.setFieldValue(
                          "status",
                          item.name.toLocaleLowerCase()
                        );
                      }}
                      displayValue={
                        STATUS_TYPES.filter(
                          (item) =>
                            item.name.toLocaleLowerCase() ===
                            formik.values.status
                        )[0]?.name
                      }
                      displayFunc={(item) => item.name}
                    />
                    {formik.touched.status && formik.errors.status && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.status}</span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Task Description */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <SVGICON src={ListCheckIcon} className="svg-icon-1 me-0" />
                </div>
                <div className="col-11">
                  <textarea
                    autoComplete="off"
                    {...formik.getFieldProps("description")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid task-description-textarea"
                    )}
                    rows={5}
                    placeholder="Description"
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.description}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Add Responsible Persons */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <SVGICON src={AddUserIcon} className="svg-icon-1 me-0" />
                </div>
                <div className="col-11">
                  <div className="d-flex justify-content-start">
                    {/* <button
                      className="btn btn-lg btn-icon btn-block btn-light btn-active-light-primary min-w-100px me-8"
                      onClick={() => {
                        setShowUsersModal(true);
                      }}
                    >
                      {intl.formatMessage({ id: "TEAMS_ADD_USER_BUTTON" })}
                    </button> */}
                    <div className="symbol-group symbol-hover flex-nowrap">
                      {responsiblePersons &&
                        responsiblePersons?.length > 0 &&
                        responsiblePersons?.map((data, i) => (
                          <React.Fragment key={i}>
                            <UserSymbol
                              data={data}
                              handleRemove={removeUserhandle}
                            />
                          </React.Fragment>
                        ))}
                      <>
                        <div
                          className={`symbol symbol-35px symbol-circle toolip_container_plus`}
                          style={{ position: "relative" }}
                          role="button"
                          onMouseOver={() => {
                            const tooltipEle = document.getElementById(
                              `role-user-tooltip-plus`
                            );
                            if (tooltipEle) {
                              tooltipEle.style.display = "flex";
                            }
                          }}
                          onMouseOut={() => {
                            const tooltipEle = document.getElementById(
                              `role-user-tooltip-plus`
                            );
                            if (tooltipEle) {
                              tooltipEle.style.display = "none";
                            }
                          }}
                          onClick={() => {
                            setShowUsersModal(true);
                          }}
                        >
                          <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                            <SVGICON
                              src={PlusIcon}
                              className="svg-icon-2 svg-icon-white"
                            />
                          </span>
                        </div>
                        <Tooltip anchorSelect={`.toolip_container_plus`}>
                          Add User
                        </Tooltip>
                      </>
                      {/* {remainingAssignee > 0 && (
                        <div className="d-flex align-items-center mt-3  text-primary fw-bold">
                          <span className="me-2"></span>
                          <em
                            role={"button"}
                            // onClick={() => {
                            //   setShowAllUsersModal({
                            //     show: true,
                            //     team: usersList,
                            //   });
                            //   onHideModal?.(true);
                            // }}
                          >
                            {`...  `}
                            {`and ${remainingAssignee} more`}
                          </em>
                        </div>
                      )} */}
                    </div>
                  </div>
                  {formik.touched.assignees_ids &&
                    formik.errors.assignees_ids && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.assignees_ids}</span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={closeModal}>
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </Button>
            <Button
              type="submit"
              id="kt_sign_in_submit"
              className="btn  btn-primary"
            >
              {!loading && (
                <span className="indicator-label">
                  {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                </span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LOADING_MESSAGE",
                  })}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddTaskModal;
