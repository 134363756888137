import axios from 'axios'
import {GenericResponseType} from '../../../types/GenericReponse'
import {ResourcesListResponseType} from '../types/getResourcesListResponseType'
import {RoleDetailsResponseType} from '../types/getRoleDetailsResponseType'
import {RolesResponseType} from '../types/getRolesResponseType'

export function getResourcesList() {
  return axios.get<ResourcesListResponseType>(`/resources`)
}

export function addResource(name: string) {
  return axios.post<GenericResponseType>(`/resources`, {
    name,
  })
}

export function updateResource(resource_id: number, name: string) {
  return axios.post<GenericResponseType>(`/resources/${resource_id}`, {
    name,
  })
}

export function deleteResource(resource_id: number) {
  return axios.delete<ResourcesListResponseType>(`/resources/${resource_id}`)
}

// get roles list
export function getRolesList() {
  return axios.get<RolesResponseType>(`/roles`)
}

// update role resources and permissions
export function updateRolesAndPermissions(role_id: number, payload: any) {
  return axios.post<GenericResponseType>(`/roles/resources/${role_id}`, {
    ...payload,
  })
}

// add new role
export function addnewRole(name: string) {
  return axios.post<GenericResponseType>(`/roles`, {
    name,
  })
}

// delete  role
export function deleteRole(role_id: number) {
  return axios.delete<GenericResponseType>(`/roles/${role_id}`)
}

// get role details by id
export function getRoleDetails(role_id: number) {
  return axios.get<RoleDetailsResponseType>(`/roles/${role_id}`)
}
