export const getFileDuration = (file: File) =>
  new Promise((resolve, reject) => {
    // @ts-ignore
    const fileURL = URL.createObjectURL(file)
    let media = new Audio(fileURL)
    media.onloadedmetadata = function () {
      resolve(Math.round(media.duration))
    }
    media.onerror = (error) => {
      reject(error)
    }
  })
