import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import {
  AddressType,
  CompanyAddressType,
  CustomerType,
} from "../../auth/types/LoginResponseTypes";
import {
  deleteCustomerCompanyAddress,
  deleteCustomerPersonalAddress,
  updateCustomerCompanyAddress,
} from "../redux";

// custom
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import AddPersonalAddressModal from "./AddPersonalAddressModal";
import { PartnerType } from "../../partnerManagement/types/getPartnersListResponseType";
import SelectPartnersModal from "../../../sharedComponents/SelectPartnersModal/SelectPartnersModal";

interface Props {
  userDetails: UserType;
  customerDetails: CustomerType;
  getCustomerDetailsAPI: (callback?: (customer: CustomerType) => void) => void;
}

interface AddAddressProps {
  title: string;
  type: "personal" | "company";
}
interface DisplayAddressProps {
  address: AddressType | CompanyAddressType;
  title: string;
  type: "personal" | "company";
}

interface AddpersonalAddressModalProps {
  show: boolean;
  type?: string;
  address?: AddressType;
}

const BillingAddress: FC<Props> = ({
  userDetails,
  customerDetails,
  getCustomerDetailsAPI,
}) => {
  const intl = useIntl();

  // context
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();

  // state
  const [showAddPersonalAddressModal, setShowAddPersonalAddressModal] =
    useState<AddpersonalAddressModalProps>({ show: false });
  const [showAddCompanyAddressModal, setShowAddCompanyAddressModal] =
    useState<boolean>(false);
  // const [addAddressModalType, setAddAddressModalType] = useState<'personal' | 'company'>()
  const [customer, setCustomer] = useState<CustomerType>(customerDetails);

  useEffect(() => {
    setCustomer(customerDetails);
  }, [customerDetails]);

  const closeModal = () => {
    setShowAddPersonalAddressModal({
      show: false,
      type: "",
      address: undefined,
    });
  };

  const closeCompanyModal = () => {
    setShowAddCompanyAddressModal(false);
  };

  const getAddress = (adress: AddressType) => {
    let address = "";
    if (adress?.address_1) {
      address = address + adress?.address_1;
    }
    if (adress?.address_2) {
      address = address + `${address ? ", " : ""}${adress?.address_2}`;
    }
    if (adress?.city) {
      address = address + `${address ? ", " : ""}${adress?.city}`;
    }
    if (adress?.zip_code) {
      address = address + `${address ? ", " : ""}${adress?.zip_code}`;
    }

    return address.length > 0
      ? address
      : intl.formatMessage({ id: "NO_ADDRESS_MESSAGE_INFO" });
  };

  const AddAddress: FC<AddAddressProps> = ({ title = "", type }) => {
    return (
      <div className="col-xl-6">
        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed flex-stack h-xl-100 mb-10 p-6">
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            <div className="mb-3 mb-md-0 fw-bold">
              <h4 className="text-gray-900 fw-bolder">{title}</h4>
              <div className="fs-6 text-gray-700 pe-7">
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_BILLING_ADDRESS_MESSAGE",
                })}
              </div>
            </div>

            <span
              role="button"
              className="btn btn-primary px-6 align-self-center text-nowrap"
              onClick={() => {
                // setAddAddressModalType(type)
                if (type === "personal") {
                  setShowAddPersonalAddressModal({
                    show: true,
                    type: "new",
                    address: undefined,
                  });
                } else {
                  setShowAddCompanyAddressModal(true);
                }
              }}
            >
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_BILLING_ADDRESS_NEW_ADDRESS",
              })}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const DisplayAddress: FC<DisplayAddressProps> = ({
    address,
    title,
    type,
  }) => {
    return (
      <div className="col-xl-6">
        <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
          <div className="d-flex flex-column mt-2">
            <div className="d-flex align-items-center fs-5 fw-bolder mb-3">
              {title}
            </div>
            {type === "personal" && (
              <div className="fs-6  text-gray-700">{getAddress(address)}</div>
            )}
            {type === "company" && (
              <div className="fs-6  text-gray-700">{getAddress(address)}</div>
            )}
          </div>

          {/* {type === 'personal' && ( */}
          <div className="d-flex align-items-center mt-4">
            <button
              type="reset"
              className="btn btn-sm btn-light btn-active-light-primary me-3"
              onClick={() => {
                showDeleteConfirmModal(
                  intl.formatMessage({ id: "CUSTOMER_DELETE_CONFIRM_MESSAGE" }),
                  () => {
                    if (type === "personal") {
                      deletePersonalAddress();
                    } else {
                      // @ts-ignore
                      handleDeleteCustomerCompanyAddress(address.code);
                    }
                  }
                );
              }}
            >
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_BILLING_ADDRESS_DELETE",
              })}
            </button>
            <button
              className="btn btn-sm btn-light btn-active-light-primary"
              onClick={() => {
                if (type === "personal") {
                  setShowAddPersonalAddressModal({
                    show: true,
                    type: "edit",
                    address,
                  });
                } else {
                  setShowAddCompanyAddressModal(true);
                }
              }}
            >
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_BILLING_ADDRESS_EDIT",
              })}
            </button>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  };

  const deletePersonalAddress = () => {
    setDeleteModalLoading(true);
    deleteCustomerPersonalAddress(customerDetails?.personalInformation?.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "BILLING_ADDRESS_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
        setCustomer({
          ...customerDetails,
          personalAddress: null,
        });
        getCustomerDetailsAPI();
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "BILLING_ADDRESS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  };

  const handleUpdateCustomerCompanyAddress = (partners: PartnerType[]) => {
    const partner = partners?.[0] || ({} as PartnerType);
    updateCustomerCompanyAddress(userDetails?.id, partner.code)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "BILLING_ADDRESS_UPDATE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "BILLING_ADDRESS_UPDATE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        closeCompanyModal();
        getCustomerDetailsAPI();
      });
  };

  const handleDeleteCustomerCompanyAddress = (code: string) => {
    setDeleteModalLoading(true);
    deleteCustomerCompanyAddress(userDetails?.id, code)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "BILLING_ADDRESS_COMPANY_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "BILLING_ADDRESS_COMPANY_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getCustomerDetailsAPI();
      });
  };

  return (
    <>
      {/* add new address Modal */}
      {showAddPersonalAddressModal.show && (
        <AddPersonalAddressModal
          modalProps={showAddPersonalAddressModal}
          closeModal={closeModal}
          customerDetails={customer}
          setCustomer={setCustomer}
        />
      )}

      {showAddCompanyAddressModal && (
        <SelectPartnersModal
          show={showAddCompanyAddressModal}
          closeModal={closeCompanyModal}
          onSelectPartner={handleUpdateCustomerCompanyAddress}
          isSelectionModal={true}
        />
        // <></>
      )}

      <div className="card card-flush border-0">
        <div className="card-header px-0">
          <div className="card-title">
            <h2>
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_BILLING_ADDRESS_TITLE",
              })}
            </h2>
          </div>
        </div>

        <div className="card-body pt-0 px-0">
          <div className="row gx-9 gy-6">
            {customer?.personalAddress ? (
              <DisplayAddress
                address={customer.personalAddress}
                title={intl.formatMessage({
                  id: "BILLING_PERSONAL_ADDRESS_TITLE",
                })}
                type={"personal"}
              />
            ) : (
              <AddAddress
                title={intl.formatMessage({
                  id: "BILLING_PERSONAL_ADDRESS_TITLE",
                })}
                type={"personal"}
              />
            )}
            {customer?.companyAddress ? (
              <DisplayAddress
                address={customer.companyAddress}
                title={intl.formatMessage({
                  id: "BILLING_COMPANY_ADDRESS_TITLE",
                })}
                type={"company"}
              />
            ) : (
              <AddAddress
                title={intl.formatMessage({
                  id: "BILLING_COMPANY_ADDRESS_TITLE",
                })}
                type={"company"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingAddress;
