import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  deleteIndustryGroup,
  deleteIndustrySector,
  getIndustryGroups,
} from "../redux/PartnersMasterdataAPI";
import {
  IndustryGroupType,
  IndustrySectorType,
} from "../types/getIndustryGroupsResponseType";
import AddNewIndustryGroupModal from "./AddNewIndustryGroupModal";
import AddNewIndustrySectorModal from "./AddNewIndustrySectorModal";
import DisplayIndustryGroupModal from "./DisplayIndustryGroupModal";

import { useIntl } from "react-intl";
import { actions as masterdataActions } from "../../masterdata/redux";

interface IndustriesAndSectorsProps {}

interface IndustrySectorModalProps {
  show: boolean;
  industryGroup: IndustryGroupType | undefined;
}

interface IndustryGroupModalProps {
  show: boolean;
  type: "edit" | "new";
  industryGroup: IndustryGroupType | undefined;
}

const IndustriesAndSectors: FC<IndustriesAndSectorsProps> = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // state
  const [industryGroups, setIndustryGroups] = useState<IndustryGroupType[]>([]);
  const [displayIndustryGroups, setDisplayIndustryGroups] = useState<
    IndustryGroupType[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [showIndustrySectorModalProps, setShowIndustrySectorModalProps] =
    useState<IndustrySectorModalProps>({
      show: false,
      industryGroup: undefined,
    });
  const [
    showDisplayIndustrySectorModalProps,
    setShowDisplayIndustrySectorModalProps,
  ] = useState<IndustrySectorModalProps>({
    show: false,
    industryGroup: undefined,
  });
  const [showIndustryGroupModalProps, setShowIndustryGroupModalProps] =
    useState<IndustryGroupModalProps>({
      show: false,
      type: "new",
      industryGroup: undefined,
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const updateDisplayCategoriesList = () => {
    const updatedCategoriesList = industryGroups.filter((industryGroup) => {
      if (
        (industryGroup.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayIndustryGroups(updatedCategoriesList);
  };

  const getIndustryGroupsAPI = () => {
    setIsLoading(true);
    getIndustryGroups()
      .then(({ data: { data = [] } }) => {
        setIndustryGroups([...data]);
        dispatch(masterdataActions.setIndustryGroupsList(data));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getIndustryGroupsAPI();

    return () => {
      setIndustryGroups([]);
    };
  }, []);

  useEffect(() => {
    updateDisplayCategoriesList();
  }, [industryGroups, searchText]);

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteSelectedIndustryGroup = (industryGroup: IndustryGroupType) => {
    setDeleteModalLoading(true);
    deleteIndustryGroup(industryGroup.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_GROUP_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_GROUP_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getIndustryGroupsAPI();
      });
  };

  const deleteSelectedIndustrySector = (
    industryGroupSector: IndustrySectorType
  ) => {
    setDeleteModalLoading(true);
    deleteIndustrySector(industryGroupSector.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_SECTOR_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "PARTNERS_INDUSTRY_SECTOR_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getIndustryGroupsAPI();
      });
  };

  return (
    <div>
      {showIndustryGroupModalProps.show && (
        <AddNewIndustryGroupModal
          modalProps={showIndustryGroupModalProps}
          closeModal={() => {
            setShowIndustryGroupModalProps({
              show: false,
              type: "new",
              industryGroup: undefined,
            });
          }}
          getIndustryGroupsAPI={getIndustryGroupsAPI}
        />
      )}

      {showIndustrySectorModalProps.show && (
        <AddNewIndustrySectorModal
          modalProps={showIndustrySectorModalProps}
          closeModal={() => {
            setShowIndustrySectorModalProps({
              show: false,
              industryGroup: undefined,
            });
          }}
          getIndustryGroupsAPI={getIndustryGroupsAPI}
        />
      )}

      {showDisplayIndustrySectorModalProps.show && (
        <DisplayIndustryGroupModal
          // @ts-ignore
          modalProps={showDisplayIndustrySectorModalProps}
          closeModal={() => {
            setShowDisplayIndustrySectorModalProps({
              show: false,
              industryGroup: undefined,
            });
          }}
          getIndustryGroupsAPI={getIndustryGroupsAPI}
        />
      )}

      <div className="row mx-0">
        <div className="col-12">
          <div className="card p-4 d-flex flex-row justify-content-between ">
            <div className="d-flex flex-row search-container">
              <div className="d-flex align-items-center">
                <SVGICON
                  src={SearchIcon}
                  className="svg-icon svg-icon-1 position-absolute ms-6"
                />
                <input
                  type="text"
                  className="form-control form-control-solid w-250px ps-15 mx-4"
                  placeholder={intl.formatMessage({
                    id: "PARTNERS_INDUSTRY_SEARCH_PLACEHOLDER",
                  })}
                  onChange={(e) => {
                    onSearchTextChange(e.target.value);
                  }}
                />
              </div>
            </div>

            {/* add new category button */}
            <button
              className="btn  btn-primary"
              onClick={() => {
                setShowIndustryGroupModalProps({
                  show: true,
                  type: "new",
                  industryGroup: undefined,
                });
              }}
            >
              + {intl.formatMessage({ id: "PARTNERS_INDUSTRY_ADD_NEW_GROUP" })}
            </button>
          </div>
        </div>
        {displayIndustryGroups.map((industryGroup, i) => {
          const displaySectors = (industryGroup.sectors || []).slice(0, 4);
          const remainingSectors =
            (industryGroup.sectors || []).length - displaySectors.length;
          return (
            <div className="col-md-6 mt-6" key={i}>
              <div className="card card-flush h-md-100">
                <div className="card-header d-flex">
                  <div className="card-title">
                    <h2>{industryGroup.name}</h2>
                  </div>
                  <div className="card-toolbar">
                    <div>
                      <button
                        className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                        onClick={() => {
                          setShowIndustryGroupModalProps({
                            show: true,
                            type: "edit",
                            industryGroup: industryGroup,
                          });
                        }}
                      >
                        <SVGICON src={EditIcon} className="svg-icon-3" />
                      </button>
                      <button
                        className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                        onClick={() => {
                          showDeleteConfirmModal(
                            intl.formatMessage({
                              id: "PARTNERS_INDUSTRY_GROUP_DELETE_CONFIRMATION_MESSAGE",
                            }),
                            () => {
                              deleteSelectedIndustryGroup(industryGroup);
                            },
                            intl.formatMessage({ id: "COMMON_YES_DELETE" })
                          );
                        }}
                      >
                        <SVGICON src={DeleteIcon} className="svg-icon-3" />
                      </button>
                      <button
                        className="btn btn-icon btn-primary btn-active-light-primary btn-sm"
                        onClick={() => {
                          setShowIndustrySectorModalProps({
                            show: true,
                            industryGroup: industryGroup,
                          });
                        }}
                      >
                        <SVGICON src={PlusIcon} className="svg-icon-3" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body pt-0">
                  {(!industryGroup.sectors ||
                    industryGroup.sectors.length === 0) && (
                    <div className="fw-bold text-gray-600 mb-5">
                      {intl.formatMessage({
                        id: "PARTNERS_INDUSTRY_GROUP_SECTORS_EMPTY_MESSAGE",
                      })}
                    </div>
                  )}

                  <div className="d-flex flex-column text-gray-600">
                    {displaySectors.map((industryGroupSector, j) => {
                      return (
                        <div className="d-flex align-items-center py-3" key={j}>
                          <div
                            role="button"
                            onClick={() => {
                              showDeleteConfirmModal(
                                intl.formatMessage({
                                  id: "PARTNERS_INDUSTRY_SECTOR_DELETE_CONFIRMATION_MESSAGE",
                                }),
                                () => {
                                  deleteSelectedIndustrySector(
                                    industryGroupSector
                                  );
                                },
                                intl.formatMessage({ id: "COMMON_YES_DELETE" })
                              );
                            }}
                          >
                            <SVGICON
                              src={DeleteIcon}
                              className="svg-icon-2 svg-icon-danger me-2"
                            />
                          </div>
                          <span className="fs-6 text-gray-700">
                            {industryGroupSector.name}
                          </span>
                        </div>
                      );
                    })}

                    {remainingSectors > 0 && (
                      <div className="d-flex align-items-center mt-3  text-primary fw-bold">
                        <span className="me-2"></span>
                        <em
                          role={"button"}
                          onClick={() => {
                            setShowDisplayIndustrySectorModalProps({
                              show: true,
                              industryGroup,
                            });
                          }}
                        >
                          {`...  `}
                          {`and ${remainingSectors} more`}
                        </em>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* no data */}
        {!isLoading && displayIndustryGroups.length === 0 && (
          <table className="d-flex justify-content-center align-items-center mt-2">
            <tbody>
              <tr>
                <td valign="top" colSpan={7} className="dataTables_empty">
                  <div className="d-flex flex-column flex-center">
                    <img
                      src={notFound}
                      className="mh-400px"
                      alt=""
                      style={{ color: "inherit" }}
                    />
                    <div className="fs-1 fw-bolder text-dark mb-4">
                      {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default IndustriesAndSectors;
