import axios from 'axios'

// custom
import {sortGroupsParams} from '../types/request'
// import {GroupsResponseType} from '../types/GetGroupsListResponseType'
import {CreateNewGroupResponseType} from '../types/CreateNewGroupResponseType'
import {GenericResponseType} from '../../../types/GenericReponse'
import {LabelsListResponseType} from '../types/GetLabelsListResponseType'

// get all groups list
export function getLabelsList() {
  return axios.get<LabelsListResponseType>(`/labels`)
}

// create nbew group
export function createNewLabel(name: string) {
  return axios.post<GenericResponseType>(`/labels`, {
    name,
  })
}

// edit existing group
export function updateLabel(id: number, name: string) {
  return axios.post<GenericResponseType>(`/labels/${id}`, {
    name: name,
  })
}

// delete existing group
export function deleteLabel(id: number) {
  return axios.delete<CreateNewGroupResponseType>(`/labels/${id}`)
}

// mass delete existing groups
export function massDeleteLabels(labels_id: Array<number>) {
  return axios.post<GenericResponseType>(`/labels/massDelete`, {
    labels_id,
  })
}

// sort existing group
export function sortLabels(labels_sorting: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/labels/sorting`, {
    labels_sorting,
  })
}
