import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";

// custom
import { UserType } from "../types/getUsersListResponseType";
import { updateUserPersonalDetails } from "../redux";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
// import {useDispatch} from 'react-redux'

import { FileType } from "../../filemanager/types/getFilemanagerDataResponseType";
import { useIntl } from "react-intl";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import SelectFileModal from "../../../sharedComponents/selectFileModal/selectFileModal";

interface Props {
  userDetails: UserType;
  getUserDetailsAPI: () => void;
}

const UserProfileDetails: FC<Props> = ({ userDetails, getUserDetailsAPI }) => {
  // console.log(userDetails);
  // const dispatch = useDispatch()
  const intl = useIntl();
  const SALUATION_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
  ) as any[];
  const TITLES_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_TITLES_OPTIONS" })
  ) as any[];

  // state
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserType>(userDetails);
  const [showSelectFileModal, setShowSelectFileModal] =
    useState<boolean>(false);
  const [userImageFile, setUserImageFile] = useState<FileType>();
  const [porfilePhotoUrl, setProfilePhotoUrl] = useState<any>(
    userDetails.profile_photo_url
      ? userDetails.profile_photo_url
      : userDetails.profile_photo
  );
  const [profile_photo, setProfile_photo] = useState<any>();
  const [isImageChanged, setIsImageChanged] = useState<boolean>(false);

  const editUserProfile = Yup.object().shape({
    salutation: Yup.string().required(
      intl.formatMessage({ id: "USERS_SALUATION_REQUIRED" })
    ),
    firstname: Yup.string()
      .max(50, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "USERS_FIRSTNAME_REQUIRED" })),
    lastname: Yup.string()
      .max(50, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "USERS_LASTNAME_REQUIRED" })),
    // username: Yup.string().matches(
    //   /^[a-zA-Z0-9]*$/,
    //   intl.formatMessage({id: 'USERS_USERNAME_FIELD_ERROR'})
    // ),
  });

  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  const initialValues = {
    salutation: user?.salutation_id || 1,
    title: user?.title_id || 0,
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    username: user?.username || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editUserProfile,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let bodyFormData = new FormData();
      //@ts-ignore
      bodyFormData.append("salutation_id", values.salutation);
      bodyFormData.append("first_name", values.firstname);
      bodyFormData.append("last_name", values.lastname);
      bodyFormData.append("username", values.username);
      if (isImageChanged) {
        bodyFormData.append(
          "profile_photo",
          profile_photo ? profile_photo : ""
        );
      }

      if (values.title) {
        //@ts-ignore
        bodyFormData.append("title_id", values.title);
      }
      updateUserPersonalDetails(user.id, bodyFormData)
        .then(() => {
          SuccessAlert(
            intl.formatMessage({ id: "USERS_ADDED_SUCCESS_MESSAGE" }),
            () => {},
            intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorAlert(intl.formatMessage({ id: "USERS_ADDED_FAILURE_MESSAGE" }));
        })
        .finally(() => {
          setLoading(false);
          getUserDetailsAPI();
        });
    },
  });

  const closeModal = () => {
    setShowSelectFileModal(false);
  };

  // const onSelectFile = (file: any) => {
  //   closeModal();
  //   setUserImageFile(file);
  // };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setProfile_photo(selectedFiles?.[0]);
    setProfilePhotoUrl(URL.createObjectURL(selectedFiles?.[0]));
    setIsImageChanged(true);
  };

  return (
    <div className={loading ? "pe-none" : ""}>
      {/* <SelectFileModal
        show={showSelectFileModal}
        closeModal={closeModal}
        onSelectFile={onSelectFile}
        isSelectionModal={true}
      /> */}

      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {intl.formatMessage({ id: "USERS_PROFILE_DETAILS_TITLE" })}
            </h3>
          </div>
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  {intl.formatMessage({ id: "USERS_AVATAR_TITLE" })}
                </label>

                <div className="col-lg-8">
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                  >
                    <img
                      // src={mainFile?.file.file_path || event.event_image_url || BlankAvatar}
                      src={porfilePhotoUrl ? porfilePhotoUrl : BlankAvatar}
                      alt="img"
                      style={{ objectFit: "fill" }}
                      className="image-input-wrapper w-125px h-125px"
                      draggable={false}
                    />

                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title=""
                      data-bs-original-title="Change avatar"
                      htmlFor="file"
                    >
                      <input
                        type="file"
                        id="file"
                        accept="image/jpeg,image/jpg,image/png"
                        multiple={false}
                        data-original-title="upload photos"
                        style={{ display: "none" }}
                        onChange={handleChange}
                      />
                      <i className="bi bi-pencil-fill fs-7"></i>
                    </label>
                    {porfilePhotoUrl && (
                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow removeIcon"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Remove Avatar"
                        onClick={() => {
                          setProfile_photo(null);
                          setProfilePhotoUrl(null);
                          setIsImageChanged(true);
                        }}
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    )}
                  </div>

                  <div className="form-text">
                    {intl.formatMessage({ id: "USERS_FORM_INFO" })}
                  </div>
                </div>
              </div>

              {/* salutation */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">
                    {intl.formatMessage({ id: "USERS_SALUATION_TITLE" })}
                  </span>
                </label>

                <div className="col-lg-8 fv-row">
                  <DropDown
                    hideSearch
                    id="users-salutaions-dropdown"
                    items={SALUATION_OPTIONS}
                    displayFunc={(item) => {
                      return item.name;
                    }}
                    displayValue={
                      SALUATION_OPTIONS.find(
                        (SALUATION_OPTION) =>
                          SALUATION_OPTION.id === formik.values.salutation
                      )?.name || ""
                    }
                    onSelectItem={(item) => {
                      //@ts-ignore
                      formik.setFieldValue("salutation", item.id);
                    }}
                    selectedItem={SALUATION_OPTIONS.find(
                      (SALUATION_OPTION) =>
                        SALUATION_OPTION.id === formik.values.salutation
                    )}
                  />
                  {formik.touched.salutation && formik.errors.salutation && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.salutation}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* titile */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">
                    {intl.formatMessage({ id: "USERS_TITLES_NAME" })}
                  </span>
                </label>

                <div className="col-lg-8 fv-row">
                  <DropDown
                    hideSearch
                    id="users-titles-dropdown"
                    items={TITLES_OPTIONS}
                    displayFunc={(item) => {
                      return item.name;
                    }}
                    displayValue={
                      TITLES_OPTIONS.find(
                        (TITLES_OPTION) =>
                          TITLES_OPTION.id === formik.values.title
                      )?.name || ""
                    }
                    onSelectItem={(item) => {
                      //@ts-ignore
                      formik.setFieldValue("title", item.id);
                    }}
                    selectedItem={TITLES_OPTIONS.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.id === formik.values.title
                    )}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.title}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* fullname : firstname, lastname */}
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {intl.formatMessage({ id: "USERS_FULL_NAME" })}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        {...formik.getFieldProps("firstname")}
                      />
                      {formik.touched.firstname && formik.errors.firstname && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.firstname}</span>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        {...formik.getFieldProps("lastname")}
                      />
                      {formik.touched.lastname && formik.errors.lastname && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.lastname}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* username */}
              <div className="row">
                <label className=" col-lg-4 col-form-label  fw-bold fs-6">
                  {intl.formatMessage({
                    id: "FORM_VALIDATION_FIELD_LABEL_USERNAME",
                  })}
                </label>

                <div className="col-lg-8">
                  <div className="fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      {...formik.getFieldProps("username")}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.username}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {!loading && intl.formatMessage({ id: "SAVE_BUTTON" })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfileDetails;
