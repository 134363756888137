import React, { FC, useState } from "react";
import { DealType } from "../types/DealResponseType";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import { useIntl } from "react-intl";
import DealLogs from "./DealLogs";
import Notes from "../../notes/Notes";
import Tasks from "../../tasks/Tasks";

interface Props {
  deal: DealType;
}

const ActivityComponent: FC<Props> = ({ deal }) => {
  // selector
  const intl = useIntl();

  // state
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  return (
    <div
      className="card"
      style={{
        flex: 1,
      }}
    >
      <div
        className="card px-10 mt-3 ml-10"
        style={{
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <Tabs
          tabs={[
            intl.formatMessage({ id: "DEAL_ACTIVITY_TAB1" }),
            intl.formatMessage({ id: "DEAL_ACTIVITY_TAB2" }),
            intl.formatMessage({ id: "DEAL_ACTIVITY_TAB3" }),
            intl.formatMessage({ id: "DEAL_DETAILS_TAB2" }),
          ]}
          // {intl.formatMessage({id:'POST.EDIT_POST.POST_TYPE_SECTION.TITLE'})}
          activeIndex={tabActiveIndex}
          onActiveIndexChange={(index) => {
            onTabChange(index);
          }}
        />
      </div>
      <div className="mt-8">
        {tabActiveIndex === 0 && (
          <div className="d-flex justify-content-center align-items-center">
            <div className="fs-6 text-muted">No data available.</div>
          </div>
        )}
        {tabActiveIndex === 1 && (
          <div className="px-8">
            <Notes deals_id={deal.id} />
          </div>
        )}
        {tabActiveIndex === 2 && (
          <div className="px-8">
            <Tasks deals_id={deal.id} />
          </div>
        )}
        {tabActiveIndex === 3 && (
          <div className="">
            <DealLogs deal={deal} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityComponent;
