import React, { FC, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import de from "date-fns/locale/de"; // the locale you want

// custom
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";
// import {string} from 'yup'
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { useIntl } from "react-intl";

registerLocale("de", de);

interface Props {
  selectedDate: Date | undefined;
  onDateChange: (date: Date) => void;
  onBlur?: () => void;
  type?: string;
  disabledRes?: boolean;
  onCalendarClose?: (onSelectedDate: Date | undefined) => any;
  minDate?: Date;
  maxDate?: Date;
  hideTimeSelect?: boolean;
  noMinDate?: boolean;
  hidePlaceholder?: boolean;
  placeholder?: string;
}

const DateTimePicker: FC<Props> = ({
  selectedDate,
  onDateChange,
  onBlur,
  type,
  disabledRes,
  onCalendarClose,
  minDate,
  maxDate,
  hideTimeSelect,
  noMinDate,
  hidePlaceholder,
  placeholder,
}) => {
  const intl = useIntl();
  const local = useLang();

  // state
  const [localSelectedDate, setLocalSelectedDate] = useState<Date>();

  // effects
  useEffect(() => {
    if (localSelectedDate) {
      onDateChange(localSelectedDate);
    }
  }, [localSelectedDate]);

  // const today = moment().toDate()

  //@ts-ignore
  const filterPassedTime = (time) => {
    const bool = moment(time).isAfter(moment(), "second");
    return bool;
  };

  // @ts-ignore
  const filterTimepublish = (time) => {
    const bool = moment(time).isBefore(moment(), "second");
    return bool;
  };

  return (
    <div>
      <DatePicker
        locale={local === "de" ? "de" : "en"}
        minDate={
          minDate
            ? moment(minDate).toDate()
            : type ===
              ("Publish" ||
                "regEndDate" ||
                "regStartDate" ||
                "startDate" ||
                "endDate")
            ? undefined
            : noMinDate
            ? undefined
            : moment().toDate()
        }
        maxDate={
          maxDate
            ? moment(maxDate).toDate()
            : type === "Publish"
            ? moment().toDate()
            : undefined
        }
        filterTime={
          type === "Publish"
            ? filterTimepublish
            : type ===
              ("regEndDate" || "regStartDate" || "startDate" || "endDate")
            ? undefined
            : filterPassedTime
        }
        selected={selectedDate}
        onChange={(date: Date) => {
          setLocalSelectedDate(date);
        }}
        placeholderText={hidePlaceholder ? "" : placeholder ? placeholder : ""}
        className="form-control  form-control-solid"
        dateFormat={
          local === "de"
            ? `dd.MM.yyyy${hideTimeSelect ? "" : " HH:mm"}`
            : `yyyy-MM-dd${hideTimeSelect ? "" : " HH:mm"}`
        }
        showTimeSelect={!hideTimeSelect}
        timeFormat="HH:mm"
        clearButtonTitle={"x"}
        dayClassName={(day) => {
          return "fs-6 rdp-day";
        }}
        shouldCloseOnSelect={true}
        disabledKeyboardNavigation
        renderDayContents={(day) => {
          return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              {day}
            </div>
          );
        }}
        onCalendarClose={() => {
          onCalendarClose?.(localSelectedDate);
        }}
        onClickOutside={() => {
          onBlur?.();
        }}
        disabled={disabledRes}
      />
    </div>
  );
};

// h-100 w-100 d-flex flex-row justify-content-center align-items-center bg-hover-light-primary text-hover-primary

// react-datepicker__day text-gray-600 fs-6 rdp-day react-datepicker__day--003 react-datepicker__day--selected react-datepicker__day--today

export default DateTimePicker;
