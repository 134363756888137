import {FC, useState} from 'react'
// import {useIntl} from 'react-intl'

// custom
import RolesAndResourcesHeader from './RolesAndResourcesHeader'
import RolesList from './RolesList'

interface Props {}

const UserRolesAndResources: FC<Props> = () => {
  // const intl = useIntl()

  // state
  const [tabActiveIndex, setTabActiveIndex] = useState<number>(0)

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index)
    }
  }

  return (
    <div>
      <RolesAndResourcesHeader tabActiveIndex={tabActiveIndex} onTabChange={onTabChange} />
      <div className='mt-10'>
        <RolesList />
      </div>
    </div>
  )
}

export default UserRolesAndResources
