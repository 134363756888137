import React, {FC, ReactNode} from 'react'

interface Props {
  textComponent: ReactNode
}

const InformationText: FC<Props> = ({textComponent}) => {
  return (
    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6'>
      <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <rect opacity='0.3' x='2' y='2' width='20' height='20' rx='10' fill='black'></rect>
          <rect
            x='11'
            y='14'
            width='7'
            height='2'
            rx='1'
            transform='rotate(-90 11 14)'
            fill='black'
          ></rect>
          <rect
            x='11'
            y='17'
            width='2'
            height='2'
            rx='1'
            transform='rotate(-90 11 17)'
            fill='black'
          ></rect>
        </svg>
      </span>

      <div className='d-flex flex-stack flex-grow-1'>
        <div className='fw-bold'>{textComponent}</div>
      </div>
    </div>
  )
}

export default InformationText
