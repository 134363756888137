import { el } from "date-fns/locale";
import config from "../config";
// import { RoleType } from "../modules/RolesAndPermissions/types/getRolesResponseType";
import { UserType } from "../modules/userManagement/types/getUsersListResponseType";
import { RoleType } from "../modules/RolesAndPermissions/types/getRolesResponseType";

export const isPermissionEnabledForResource = (
  role: RoleType | undefined,
  resource_slug: string
) => {
  // all controls for super-admin : roleid = 1

  let enablePermission = false;
  if (role?.id === 1) {
    enablePermission = true;
    return true;
  }
  role?.resources.map((resource) => {
    // check if resource doesnt exist for role, and read(id:1) permissions is enabled
    if (
      resource.resource_slug === resource_slug &&
      resource.permission_id === 1
    ) {
      enablePermission = true;
    }
  });

  return enablePermission;
};

// const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;

export const isPermissionEnabled = (
  userData: UserType,
  module: string,
  panel?: string
) => {
  //  if(userData.is_admin){
  //   return true
  //  }

  if (
    userData.partner_admin &&
    (module === "template" || module === "users" || module === "device")
  ) {
    return false;
  } else if (
    userData.is_customer &&
    (module === "template" || module === "users" || module === "device")
  ) {
    return false;
  } else {
    return true;
  }
};

export type Roles = "is_admin" | "is_customer" | "partner_admin";

export const isAccessable = (userData: UserType, roles: Roles[]): boolean => {
  // console.log('ROLE::', userData[roles[0]], userData, roles)
  // if(userData.is_admin) {
  //   return true
  // }
  let isAccess = false;
  roles.forEach((role) => {
    if (userData[role]) {
      isAccess = true;
    }
  });
  return isAccess;
};
