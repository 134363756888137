import moment from 'moment'

export const getDiffFromNow = (date: string) => {
  return moment(date).fromNow()
}

export const getDateInFormat = (date: string, format: string) => {
  return moment(date).format(format)
}

export const formatDate = (date:any, time?:any) => {
  const _date = new Date(date);
  function addZero(i:any) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // const days = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];
  const month = _date.getMonth();
  const day = _date.getDate();
  const year = _date.getFullYear();
  const hours = addZero(_date.getHours());
  const minutes = addZero(_date.getMinutes());
  return `${months[month]} ${day}, ${year} ${
    time ? `${hours}:${minutes}` : ""
  }`;
};
