import axios from "axios";
import {
  GetTeamsResponseType,
  TeamByIDResponseType,
} from "../types/TeamsResponseType";
import { GenericResponseType } from "../../../types/GenericReponse";

export function getTeams() {
  return axios.get<GetTeamsResponseType>(`/teams`);
}

export function createTeam(name: string) {
  return axios.post<GenericResponseType>(`/teams`, {
    name,
  });
}

export function updateTeam(id: number, name: string) {
  return axios.post<GenericResponseType>(`/teams/${id}`, {
    name,
  });
}

export function deleteTeam(id: number) {
  return axios.delete<GenericResponseType>(`/teams/${id}`);
}

export function deleteMultipleTeams(teams_id: any[]) {
  return axios.post(`/teams/massDelete`, {
    teams_id,
  });
}

export function getTeamByID(id: number) {
  return axios.get<TeamByIDResponseType>(`/teams/${id}`);
}

export function addTeamUser(id: number, users_id: any[]) {
  return axios.post(`/teams/users/${id}`, {
    users_id,
  });
}

export function deleteTeamUser(id: number, users_id: any[]) {
  return axios.post(`/teams/users/delete/${id}`, {
    users_id,
  });
}

export function sortTeam(teams_sorting: any[]) {
  return axios.post(`/teams/sorting`, {
    teams_sorting,
  });
}
