/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import {useDispatch} from 'react-redux'
// import * as Yup from 'yup'
// import clsx from 'clsx'
import { Link } from "react-router-dom";
// import {useFormik} from 'formik'
// import * as auth from '../redux/AuthRedux'
// import {login} from '../redux/AuthCRUD'
// import LoginTwoFactorAuthModal from '../../twoFactorAuth/components/LoginTwoFactorAuthModal'
import { useIntl } from "react-intl";

// const loginSchema = Yup.object().shape({
//   email: Yup.string().email('Wrong email format').required('Email is required'),
//   password: Yup.string().required('Password is required'),
// })

// const initialValues = {
//   email: '',
//   password: '',
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function EmailSuccess() {
  const intl = useIntl();

  return (
    <div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: "AUTH_EMAIL_SUCCESS_TITLE" })}
        </h1>
      </div>
      <div className="text-gray-400 fw-bold fs-4 mb-5">
        {intl.formatMessage({ id: "AUTH_EMAIL_SUCCESS_SUB_TITLE" })}
      </div>
      <div className="text-center mb-10">
        <div className="text-gray-400 fw-bold fs-6">
          {intl.formatMessage({ id: "AUTH_EMAIL_SUCCESS_NO_EMAIL" })}
        </div>
      </div>

      <div className="text-center">
        <Link to={"/login"}>
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-light-primary fw-bolder me-4"
          >
            <span className="indicator-label">
              {intl.formatMessage({ id: "AUTH_EMAIL_SUCCESS_LOGIN" })}
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
