import React, { FC } from "react";

// custom
import { CustomerType, UserType } from "../../auth/types/LoginResponseTypes";
import UploadCustomerProfilePicture from "./UploadCustomerProfilePicture";
import CustomerContactTypes from "./CustomerContactTypes";
import BillingAddress from "./BillingAddress";
import CustomerProfileDetails from "./CustomerProfileDetails";

interface Props {
  userDetails: UserType;
  customerDetails: CustomerType;
  getCustomerDetailsAPI: (callback?: (customer: CustomerType) => void) => void;
}

const CustomerProfile: FC<Props> = ({
  userDetails,
  customerDetails,
  getCustomerDetailsAPI,
}) => {
  return (
    <div className="customer-profile-tab">
      <UploadCustomerProfilePicture
        // @ts-ignore
        customer={userDetails}
        getCustomerDetailsAPI={getCustomerDetailsAPI}
      />
      <hr className="my-8" />
      <CustomerProfileDetails
        userDetails={userDetails}
        customerDetails={customerDetails}
        // @ts-ignore
        getCustomerDetailsAPI={getCustomerDetailsAPI}
      />
      <hr className="my-8" />
      <CustomerContactTypes
        // @ts-ignore
        customerDetails={customerDetails}
        getCustomerDetailsAPI={getCustomerDetailsAPI}
      />
      <hr className="my-8" />
      <BillingAddress
        // @ts-ignore
        userDetails={userDetails}
        // @ts-ignore
        customerDetails={customerDetails}
        // @ts-ignore
        getCustomerDetailsAPI={getCustomerDetailsAPI}
      />
    </div>
  );
};

export default CustomerProfile;
