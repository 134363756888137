import axios from "axios";
import { GenericResponseType } from "../../../types/GenericReponse";
import { UserDetailsResponseType } from "../../userManagement/types/getUserDetailsResponseType";
import { AuthModel } from "../models/AuthModel";
import { ClientTokensResponseType } from "../types/GetClientTokenResponseType";
import { LoginResponseType } from "../types/LoginResponseTypes";
import { RefreshAccessTokenResponseType } from "../types/RefreshAccessTokenResponseType";
import { NotificationsResponseType } from "../../Notifications/types/getNotificationsResponseType";

export const LOGIN_URL = `/user/login`;
export const GET_CLIENT_TOKEN_URL = `/client/token?channel=backend`;
export const REGISTER_URL = `/user/register`;
export const REQUEST_PASSWORD_URL = `/forgot_password`;

// get client ID and cleint Secret
export function getClientToken() {
  return axios.get<ClientTokensResponseType>(`${GET_CLIENT_TOKEN_URL}`);
}

// get new refresh token and access token
export function refreshAccessToken(
  client_id: number,
  client_secret: string,
  refresh_token: string
) {
  return axios.post<RefreshAccessTokenResponseType>("/client/refresh", {
    client_id,
    client_secret,
    refresh_token,
    grant_type: "refresh_token",
  });
}

// Server should return AuthModel
export function login(
  username: string,
  password: string,
  client_id: string,
  client_secret: string
) {
  return axios.post<LoginResponseType>(LOGIN_URL, {
    client_id,
    client_secret,
    username,
    password,
    grant_type: "password",
  });
}

// Server should return AuthModel
export function register(
  salutation_id: number,
  title_id: number,
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  password_confirmation: string,
  toc: number
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    salutation_id,
    title_id: title_id ? title_id : null,
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
    toc,
  });
}

export function requestPassword(email: string) {
  return axios.get<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  });
}

// confirm user password
export function confirmPassword(id: number, password: string) {
  return axios.post<GenericResponseType>(`/user/passwordConfirmation/${id}`, {
    password,
  });
}

//send email
export function sendEmail(email: string, technology: string) {
  return axios.post<GenericResponseType>(`/forgotPassword/email`, {
    email,
    technology,
  });
}

// get user notifications
export function getAllNotifications(id: number) {
  return axios.get<NotificationsResponseType>(`/user/notifications/${id}`);
}

//code verification
export function codeVerification(email: string, code: string) {
  return axios.post<GenericResponseType>(`/forgotPassword/code`, {
    email,
    code,
  });
}

//new password
export function newPassword(
  email: string,
  password: string,
  password_confirmation: string
) {
  return axios.post<GenericResponseType>(`/forgotPassword/reset`, {
    email,
    password,
    password_confirmation,
  });
}

//get login suer profile details
export function getMyProfile() {
  return axios.get<UserDetailsResponseType>(`/user/profile`);
}

export async function fetchLatandLong(address: string) {
  const response = await axios.get(
    `https://nominatim.openstreetmap.org/search?q=${address}&format=json`
  );
  return response.data[0];
}
