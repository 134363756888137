import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { sendEmail } from "../redux/AuthCRUD";
import { useIntl } from "react-intl";

const initialValues = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const goToCodeVerification = false;
  const intl = useIntl();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        sendEmail(values.email, "system")
          .then((response) => {
            if (response.status === 210) {
              setHasErrors(true);
              setSubmitting(false);
              setStatus("The email is not registered");
            } else {
              setHasErrors(false);
              // SuccessAlert('Code is successfully sent to email')
              history.push(`/email-success`);
            }
          })
          .catch(() => {
            setHasErrors(true);
            setSubmitting(false);
            setStatus("The email is not registered");
          })
          .finally(() => {
            setLoading(false);
          });
      }, 1000);
    },
  });
  return (
    <>
      {goToCodeVerification ? (
        <Redirect to={"/code-verification"} />
      ) : (
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_password_reset_form"
          onSubmit={formik.handleSubmit}
        >
          <div className="text-center mb-10">
            {/* begin::Title */}
            <h1 className="text-dark mb-3">
              {intl.formatMessage({ id: "AUTH_FORGOT_PASSWORD_TITLE" })}
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className="text-gray-400 fw-bold fs-4">
              {intl.formatMessage({ id: "AUTH_FORGOT_PASSWORD_EMAIL_MESSAGE" })}
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">
                {intl.formatMessage({
                  id: "AUTH_FORGOT_PASSWORD_ERROR_MESSAGE",
                })}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className="mb-10 bg-info p-8 rounded">
              <div className="text-info">
                {intl.formatMessage({
                  id: "AUTH_FORGOT_PASSWORD_SUCCESS_MESSAGE",
                })}
              </div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className="fv-row mb-10">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              {intl.formatMessage({ id: "AUTH_FORGOT_PASSWORD_EMAIL" })}
            </label>
            <input
              type="email"
              placeholder=""
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
                // {'is-invalid': formik.touched.email && formik.errors.email},
                // {
                //   'is-valid': formik.touched.email && !formik.errors.email,
                // }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="d-flex flex-wrap justify-content-center pb-lg-0">
            <button
              type="submit"
              id="kt_password_reset_submit"
              className="btn btn-lg btn-primary fw-bolder me-4"
            >
              {!loading && intl.formatMessage({ id: "SAVE_BUTTON" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({ id: "WORDPRESS_LOADING_MESSAGE" })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <Link to="/login">
              <button
                type="button"
                id="kt_login_password_reset_form_cancel_button"
                className="btn btn-lg btn-primary fw-bolder"
              >
                {intl.formatMessage({ id: "CANCEL_BUTTON" })}
              </button>
            </Link>{" "}
          </div>
          {/* end::Form group */}
        </form>
      )}
    </>
  );
}
