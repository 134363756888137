import React, {FC, InputHTMLAttributes, useState} from 'react'
import clsx from 'clsx'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  showRandomHash?: boolean
}

const PasswordInput: FC<Props> = ({showRandomHash, className, value, onFocus, ...rest}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [changed, setChanged] = useState(false)
  const [showRandomHash1, setShowRandomHash1] = useState(showRandomHash)

  return (
    <div className='position-relative'>
      <input
        className={clsx('form-control form-control-lg form-control-solid pe-12', className)}
        type={showPassword ? 'text' : 'password'}
        autoComplete='off'
        onFocus={(e) => {
          setShowRandomHash1(false)
          onFocus?.(e)
        }}
        value={value ? value : showRandomHash1 ? '********************' : ''}
        {...rest}
      />
      {showRandomHash1 ? null : (
        <div
          className='position-absolute'
          role={'button'}
          style={{top: 14, right: 18}}
          onClick={() => {
            setShowPassword(!showPassword)
          }}
        >
          <i
            className={clsx(
              'bi fs-3 fw-bolder text-gray-500',
              showPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'
            )}
          />
        </div>
      )}
    </div>
  )
}

export default PasswordInput
