import axios from "axios";

// custom
import { sortGroupsParams } from "../types/request";
import { GroupsResponseType } from "../types/GetGroupsListResponseType";
import { CreateNewGroupResponseType } from "../types/CreateNewGroupResponseType";

// get all conatcttypes list
export function getContactTitlesList() {
  return axios.get<GroupsResponseType>(`/contactsTitles`);
}

// create new contact type
export function createNewContactTitle(name: string) {
  return axios.post<CreateNewGroupResponseType>(`/contactsTitles`, {
    name: name,
  });
}

// edit existing contact type
export function updateContactTitle(id: number, name: string) {
  return axios.post<CreateNewGroupResponseType>(`/contactsTitles/${id}`, {
    name: name,
  });
}

// delete existing contact type
export function deleteContactTitle(id: number) {
  return axios.delete<CreateNewGroupResponseType>(`/contactsTitles/${id}`);
}

// mass delete existing contact types
export function massDeleteContactTitles(contact_title_id: Array<number>) {
  return axios.post<CreateNewGroupResponseType>(`/contactsTitles/massDelete`, {
    contact_title_id,
  });
}

// sort existing contact types
export function sortContactTitles(contacts_title_sorting: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/contactsTitles/sorting`, {
    contacts_title_sorting,
  });
}
