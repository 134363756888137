import React, { FC } from "react";
import { useIntl } from "react-intl";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

// custom
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import {
  NoteDetailsType,
  NoteModalProps,
} from "../types/getNotesResponseTypes";
import { deleteNote } from "../redux";

interface Props {
  noteDetails: NoteDetailsType;
  getNotesListAPI: () => void;
  onEdit: (editObject: NoteModalProps) => void;
}

const NotesListActionDropdown: FC<Props> = ({
  noteDetails,
  getNotesListAPI,
  onEdit,
}) => {
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEdituserClick = () => {
    onEdit({
      show: true,
      note: noteDetails,
    });
  };

  const deleteSelectedNote = () => {
    setDeleteModalLoading(true);
    deleteNote(noteDetails.notes_id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_DELETE_NOTE_SUCCESS" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_DELETE_NOTE_FAILED" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getNotesListAPI?.();
      });
  };

  const dropdownOptions = [
    {
      name: intl.formatMessage({ id: "COMMON_EDIT" }),
      onClick: onEdituserClick,
    },
    {
      name: intl.formatMessage({ id: "COMMON_DELETE" }),
      onClick: () => {
        showDeleteConfirmModal(
          intl.formatMessage({
            id: "CUSTOMER_MANAGEMENT_NOTE_ACTION_DELETE_MESSAGE",
          }),
          () => {
            deleteSelectedNote();
          }
        );
      },
      className: "text-danger",
    },
  ];

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
      <ActionsDropdown options={dropdownOptions} />
    </div>
  );
};

export default NotesListActionDropdown;
