import { FC } from "react";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";

const Loader: FC = () => {
  return (
    <table className="d-flex justify-content-center align-items-center mt-2">
      <tbody>
        <tr>
          <td valign="top" colSpan={7} className="dataTables_empty">
            <div className="d-flex flex-column flex-center">
              <SimpleLoader />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Loader;
