import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import closeIcon from "../../../../_metronic/assets/icons/close.svg";
import { SystemMessageType } from "../../masterdata/types/SystemMessagesResponseType";
import { getSystemMessages } from "../../masterdata/redux/SystemMessagesAPI";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSubmit: (message: string) => void;
}

const EndPhaseReasonModal: FC<Props> = ({ show, closeModal, onSubmit }) => {
  // selector
  const intl = useIntl();
  const DEALS_ENDPHASE_OTHER_REASON = JSON.parse(
    intl.formatMessage({ id: "DEALS_ENDPHASE_OTHER_REASON" })
  ) as { id: number; name: string; title: string }[];

  // state
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState<SystemMessageType[]>([]);
  const [defaultValue, setDefaultValue] = useState(true)

  const reason=reasons.find((option)=>option?.is_default===1)?.message

  const initialValues = {
    workflow: DEALS_ENDPHASE_OTHER_REASON[0].name || "",
    message: reason || "",
    otherReasonMesage: "",
  };


  const AddEndReasonSchema = Yup.object().shape({
    message: Yup.string().required(
      intl.formatMessage({
        id: "MASTERDATA_SYSTEM_MESSAGE_MESSAGE_REQUIRED_MESSAGE",
      })
    ),
    otherReasonMesage: Yup.string()
      .ensure()
      .when("message", {
        is: "Others",
        then: Yup.string().required(
          intl.formatMessage({
            id: "MASTERDATA_SYSTEM_MESSAGE_MESSAGE_REQUIRED_MESSAGE",
          })
        ),
      }),
    // workflow: Yup.string().required(
    //   intl.formatMessage({
    //     id: "MASTERDATA_SYSTEM_MESSAGE_WORK_FLOW_REQUIRED_MESSAGE",
    //   })
    // ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddEndReasonSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (values.message !== "Others") {
        onSubmit(values.message);
      } else {
        onSubmit(values.otherReasonMesage);
      }
    },
  });

  useEffect(() => {
    if (formik.values.workflow && formik.values.workflow.length > 0) {
      getSystemMessages()
        .then(({ data: { data } }) => {
          const res = data.filter(
            (message) => message.workflow === formik.values.workflow
          );
          if (res && res.length > 0) {
            let obj: SystemMessageType = {
              id: res.length + 1,
              workflow: "",
              message: "Others",
              display_order: 0,
              created_at: "",
              updated_at: "",
            };
            setReasons([...res, obj]);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [formik.values.workflow]); 

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : "AddNew"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "DEALS_END_PHASE_REASON_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <Modal.Body>
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="mb-10 alert alert-warning">
              <div className="alert-text font-weight-bold">
                {intl.formatMessage({ id: "DEALS_ENDPHASE_REASON_NOTE" })}
              </div>
            </div>
            {/* <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGE_ADD_WORKFLOW_TITLE",
                  })}
                </span>
              </label>

              <div className="fv-row">
                <DropDown
                  hideSearch
                  id="users-titles-dropdown"
                  items={
                    DEALS_ENDPHASE_OTHER_REASON.filter(
                      (data) => data.id !== 2 && data.id !== 3
                    ) || []
                  }
                  displayFunc={(item) => {
                    // @ts-ignore
                    return item.title;
                  }}
                  displayValue={
                    DEALS_ENDPHASE_OTHER_REASON.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.name === formik.values.workflow
                    )?.title || ""
                  }
                  onSelectItem={(item) => {
                    //@ts-ignore
                    formik.setFieldValue("workflow", item.name);
                  }}
                  selectedItem={DEALS_ENDPHASE_OTHER_REASON.find(
                    (TITLES_OPTION) =>
                      TITLES_OPTION.name === formik.values.workflow
                  )}
                />
                {formik.touched.workflow && formik.errors.workflow && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.workflow}</span>
                  </div>
                )}
              </div>
            </div> */}

            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGE_ADD_MESSAGE_TITLE",
                  })}
                </span>
              </label>

              <div className="fv-row">
                <DropDown
                  hideSearch
                  id="users-titles-dropdown"
                  //   @ts-ignore
                  items={reasons}
                  displayFunc={(item) => {
                    // @ts-ignore
                    return item.message;
                  }}
                  displayValue={
                    defaultValue ? reasons.find((option)=>option?.is_default===1)?.message :  reasons.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.message === formik.values.message
                    )?.message || ""
                  }
                  onSelectItem={(item) => {
                    //@ts-ignore
                    formik.setFieldValue("message", item.message);
                    setDefaultValue(false)
                  }} //@ts-ignore
                  selectedItem={
                    defaultValue ? reasons.find((option)=>option?.is_default===1)?.message : reasons.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.message === formik.values.message
                    )}
                  
                />
                {formik.touched.message && formik.errors.message && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.message}</span>
                  </div>
                )}
              </div>
              {formik.values.message === "Others" && (
                <div className="fv-row mt-4">
                  <textarea
                    rows={3}
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder=""
                    {...formik.getFieldProps("otherReasonMesage")}
                    style={{ resize: "none" }}
                  ></textarea>
                  {formik.touched.otherReasonMesage && formik.errors.otherReasonMesage && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.otherReasonMesage}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {/* <Button type='submit' id='kt_sign_in_submit' className='btn btn-light-primary'> */}
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          {/* </Button> */}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EndPhaseReasonModal;
