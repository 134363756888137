import axios from "axios";
import {
  AddPartnerResponseType,
  PartnerGeneratedContactsResponseType,
} from "../types/addPartnerResponseType";
import { PartnerDetailsResponseType } from "../types/getPartnerDetailsResponseType";
import {
  PartnerFullListResponseType,
  PartnersListResponseType,
} from "../types/getPartnersListResponseType";
import {
  UpdateGeneralDetailsResponseType,
  UpdatePartnerSupportResponseType,
  UpdatePartnerSalesResponseType,
  UpdatePartnerRemarksResponseType,
  DeletePartnerResponse,
  CountryListResponseType,
} from "../types/updatePartnerResponseTypes";
import { GenericResponseType } from "../../../types/GenericReponse";

// get all partners list
export function getPartners(
  countries_id?: null | number,
  city?: null | string,
  zip_code?: null | number | string,
  limit?: number,
  search_keyword?: null | string,
  page?: number | null,
  page_size?: number | null
) {
  let path = "/partners/filter";
  if (page) {
    path = path + `?page=${page}`;
  }
  if (page && page_size) {
    path = path;
  }
  return axios.post<PartnersListResponseType>(path, {
    countries_id: countries_id ? countries_id : null,
    city: city ? city : null,
    zip_code: zip_code ? zip_code : null,
    search_keyword: search_keyword ? search_keyword : null,
    limit,
  });
}

// get partner details
export function getPartnerDetails(id: number) {
  return axios.get<PartnerDetailsResponseType>(`/partners/${id}`);
}

export function createPartner(
  name: string
  // email: string
  //    phone: null | number,
  //    address_line_1: null | string,
  //    address_line_2: null | string,
  //    city: null | string,
  //    zip_code: null | number,
  //    country_id: null | number,
) {
  return axios.post<AddPartnerResponseType>("/partners", {
    name,
    // email,
  });
}

//update partner notes
export function updatePartnerNotes(partner_id: number, notes: string) {
  return axios.post<GenericResponseType>(`/partners/notes/${partner_id}`, {
    notes,
  });
}

export function massDeletePartners(partners_id: number[]) {
  return axios.post<AddPartnerResponseType>(`/partners/massDelete`, {
    partners_id,
  });
}

// update partner sectors
export function updatePartnerIndustrySectors(
  partner_id: number,
  industries_sectors_id: number[]
) {
  return axios.post<GenericResponseType>(
    `/partners/industries/sectors/${partner_id}`,
    {
      industries_sectors_id,
    }
  );
}

// update partner groups
export function updatePartnerGroups(partner_id: number, groups_id: number[]) {
  return axios.post<GenericResponseType>(`/partners/groups/${partner_id}`, {
    groups_id,
  });
}

// update partner categories
export function updatePartnerCategories(
  partner_id: number,
  categories_id: number[]
) {
  return axios.post<GenericResponseType>(`/partners/categories/${partner_id}`, {
    categories_id,
  });
}

// update partner tags
export function updatePartnerTags(partner_id: number, tags_id: number[]) {
  return axios.post<GenericResponseType>(`/partners/tags/${partner_id}`, {
    tags_id,
  });
}

// update partner labels
export function updatePartnerLabels(partner_id: number, labels_id: number[]) {
  return axios.post<GenericResponseType>(`/partners/labels/${partner_id}`, {
    labels_id,
  });
}

export function updatePartnerGenericDetails(
  id: number,
  name: string,
  address_1: null | string,
  address_2: null | string,
  city: null | string,
  zip_code: null | number,
  countries_id: null | number,
  email: string,
  website: string,
  telefone: string
) {
  return axios.post<UpdateGeneralDetailsResponseType>(
    `/partners/general/${id}`,
    {
      name,
      address_1: address_1 ? address_1 : null,
      address_2: address_2 ? address_2 : null,
      city: city ? city : null,
      zip_code: zip_code ? zip_code : null,
      countries_id,
      email,
      website,
      telefone,
    }
  );
}

export function updatePartnerSupportDetails(
  id: number,
  support_phone: null | number,
  support_email: null | string
) {
  return axios.post<UpdatePartnerSupportResponseType>(
    `/partners/support/${id}`,
    {
      support_phone,
      support_email,
    }
  );
}

export function updatePartnerSalesDetails(
  id: number,
  sales_phone: null | number,
  sales_email: null | string
) {
  return axios.post<UpdatePartnerSalesResponseType>(`/partners/sales/${id}`, {
    sales_phone,
    sales_email,
  });
}

export function updatePartnerRemarksDetails(
  id: number,
  remarks: string | null
) {
  return axios.post<UpdatePartnerRemarksResponseType>(
    `/partners/remarks/${id}`,
    {
      remarks,
    }
  );
}

export function deletePartner(id: number) {
  return axios.delete<DeletePartnerResponse>(`/partners/${id}`);
}

export function getCountryList() {
  return axios.get<CountryListResponseType>(`/countries`);
}

export function partnerAdminAdd(partnerId: number, customerId: number) {
  return axios.post<DeletePartnerResponse>(`/partners/admin/add/${partnerId}`, {
    customer_id: customerId,
  });
}

export function partnerAdminRemove(partnerId: number, customerId: number) {
  return axios.post<DeletePartnerResponse>(
    `/partners/admin/remove/${partnerId}/${customerId}`
  );
}

export function getPartnersFullList() {
  return axios.get<PartnerFullListResponseType>("/fullList/partner");
}

export function updatePartnerLogo(partner_id: number, body: any) {
  return axios.post<GenericResponseType>(
    `/partners/logos/${partner_id}`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        // console.log('progressEventprogressEventprogressEvent', progressEvent)
      },
    }
  );
}

export function deletePartnerLogo(partner_id: number) {
  return axios.post<GenericResponseType>(
    `/partners/logos/delete/${partner_id}`
  );
}

//update partner finance details
export function updatePartnerFinanceDetails(
  partner_id: number,
  tax_number: string,
  ust_id: string,
  debtor_number: string,
  creditor_number: string,
  invoices_seperated: 0 | 1,
  billing_email: string,
  payment_target_days: number | null
) {
  return axios.post<GenericResponseType>(`/partners/finance/${partner_id}`, {
    tax_number,
    ust_id,
    debtor_number,
    creditor_number,
    invoices_seperated,
    billing_email,
    payment_target_days,
  });
}

// add partner contact details
export function addPartnerContactDetails(
  partner_id: number,
  contact_detail_id: number,
  description: string
) {
  return axios.post<GenericResponseType>(
    `/partners/contactDetails/create/${partner_id}`,
    {
      contact_detail_id,
      description,
    }
  );
}

// update partner contact details
export function updatePartnerContactDetails(
  partner_id: number,
  contact_detail_id: number,
  description: string
) {
  return axios.post<GenericResponseType>(
    `/partners/contactDetails/update/${partner_id}`,
    {
      contact_detail_id,
      description,
    }
  );
}

// delete partner contact details
export function deletePartnerContactDetails(
  partner_id: number,
  contact_detail_id: number
) {
  return axios.post<GenericResponseType>(
    `/partners/contactDetails/delete/${partner_id}/${contact_detail_id}`
  );
}

export function getPartnerContacts(partners_id: number) {
  return axios.get<PartnerGeneratedContactsResponseType>(
    `/partners/customers/index/${partners_id}`
  );
}

export function addPartnerContacts(
  partners_id: number,
  contacts_ids: number[]
) {
  return axios.post<PartnerGeneratedContactsResponseType>(
    `/partners/customers/add/${partners_id}`,
    { contacts_ids }
  );
}
export function deletePartnerContacts(
  partners_id: number,
  contacts_ids: number[]
) {
  return axios.post<PartnerGeneratedContactsResponseType>(
    `/partners/customers/delete/${partners_id}`,
    { contacts_ids }
  );
}
