import { FC } from "preact/compat";
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import SunEditorComponent from "../../../sharedComponents/textEditor/SunEditor";
import DateTimePicker from "../../../sharedComponents/dateTimePicker/DateTimePicker";
import moment from "moment";
import { createEvent, updateEvent } from "../redux/CalendarAPI";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { googleAccountRefreshToken } from "../../userManagement/redux";
import { CalendarEventType } from "./Calendar";

interface Props {
  show: boolean;
  closeModal: () => void;
  getAllEvents: () => void;
  type: string;
  event?: CalendarEventType;
}

const AddEventModal: FC<Props> = ({
  show,
  closeModal,
  getAllEvents,
  type,
  event,
}) => {
  // context
  const intl = useIntl();

  // selector
  const userDetails = useSelector<RootState>(
    ({ auth }) => auth.user
  ) as UserType;

  // state
  const [loading, setLoading] = useState(false);

  const initialValues = {
    summary: type === "edit" ? event?.summary : "",
    description: type === "edit" ? event?.title : "",
    location: type === "edit" ? event?.location : "",
    start_date_time: type === "edit" && event ? new Date(event?.start) : "",
    end_date_time: type === "edit" && event ? new Date(event?.end) : "",
  };

  const AddEventSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: AddEventSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        setLoading(true);
        createEvent(
          userDetails.id,
          userDetails.connected_accounts.google[0]?.id,
          values.summary || "",
          values.description || "",
          values.location || "",
          moment(values.start_date_time).format(),
          moment(values.end_date_time).format(),
          new window.Intl.DateTimeFormat().resolvedOptions().timeZone
        )
          .then((data) => {})
          .catch(() => {})
          .finally(() => {
            setLoading(false);
            getAllEvents();
            closeModal();
          });
      } else {
        if (event && event.id) {
          setLoading(true);
          updateEvent(
            userDetails.id,
            userDetails.connected_accounts.google[0]?.id,
            event?.id,
            values.summary || "",
            values.description || "",
            values.location || "",
            moment(values.start_date_time).format(),
            moment(values.end_date_time).format(),
            new window.Intl.DateTimeFormat().resolvedOptions().timeZone
          )
            .then((data) => {})
            .catch(() => {})
            .finally(() => {
              setLoading(false);
              getAllEvents();
              closeModal();
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "GOOGLE_ADD_EVENT_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <Modal.Body>
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="mb-4">
              <label className="required fw-bold fs-6 mb-2 mb-2">
                {intl.formatMessage({
                  id: "GOOGLE_EVENT_SUMMARY_TITLE",
                })}
              </label>
              <textarea
                rows={2}
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                autoComplete="off"
                placeholder=""
                {...formik.getFieldProps("summary")}
                style={{ resize: "none" }}
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="required fw-bold fs-6 mb-2 mb-2">
                {intl.formatMessage({
                  id: "GOOGLE_EVENT_DESCRIPTION_TITLE",
                })}
              </label>
              <textarea
                rows={2}
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                autoComplete="off"
                placeholder=""
                {...formik.getFieldProps("description")}
                style={{ resize: "none" }}
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="required fw-bold fs-6 mb-2 mb-2">
                {intl.formatMessage({
                  id: "GOOGLE_EVENT_LOCATION_TITLE",
                })}
              </label>

              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Location"
                {...formik.getFieldProps("location")}
              />
            </div>
            <div className="mb-4">
              <label className="required fw-bold fs-6 mb-2 mb-2">
                {intl.formatMessage({
                  id: "GOOGLE_EVENT_START_DATE_TITLE",
                })}
              </label>

              <DateTimePicker
                onDateChange={(date) => {
                  formik.setFieldValue("start_date_time", date);
                }}
                onCalendarClose={(date) => {}}
                // @ts-ignore
                selectedDate={formik.values.start_date_time}
                type={"date"}
                minDate={moment().subtract(2, "months").toDate()}
              />
            </div>
            <div className="mb-4">
              <label className="required fw-bold fs-6 mb-2 mb-2">
                {intl.formatMessage({
                  id: "GOOGLE_EVENT_END_DATE_TITLE",
                })}
              </label>

              <DateTimePicker
                onDateChange={(date) => {
                  formik.setFieldValue("end_date_time", date);
                }}
                onCalendarClose={(date) => {}}
                // @ts-ignore
                selectedDate={formik.values.end_date_time}
                type={"date"}
                minDate={moment().subtract(2, "months").toDate()}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {/* <Button type='submit' id='kt_sign_in_submit' className='btn btn-light-primary'> */}
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          {/* </Button> */}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEventModal;
