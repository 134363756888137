import axios from "axios";
import {
  GoogleAccessTokenResponseType,
  GoogleAccountsListResponseType,
} from "../types/googleResponseType";
import { GenericResponseType } from "../../../types/GenericReponse";

export function getGoogleAccountsList() {
  return axios.get<GoogleAccountsListResponseType>(`/googles`);
}

// add google account
export function addGoogleAccount(
  app_name: string,
  client_id: string,
  client_secret: string,
  callback_url: string,
  scope: string
) {
  return axios.post<GoogleAccountsListResponseType>(`/googles`, {
    app_name,
    client_id,
    client_secret,
    callback_url,
    scope,
  });
}

// update google account
export function updateGoogleAccount(
  account_id: number,
  app_name: string,
  client_id: string,
  client_secret: string,
  callback_url: string,
  scope: string
) {
  return axios.post<GenericResponseType>(`/googles/${account_id}`, {
    app_name,
    client_id,
    client_secret,
    callback_url,
    scope,
  });
}

// delete google account
export function deleteGoogleAccount(account_id: number) {
  return axios.delete<any>(`/googles/${account_id}`);
}

// get google oauth accesstoken
export function getGoogleAccessToken(google_id: number, user_id: number) {
  return axios.get<GoogleAccessTokenResponseType>(
    `/googles/users/tokens/${google_id}/${user_id}`
  );
}
