/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React, { FC } from "react";
import { useLayout } from "../../core";
import { DefaultTitle } from "../header/page-title/DefaultTitle";
import { useLocation } from "react-router-dom";

const Toolbar1: FC = () => {
  const { classes } = useLayout();
  const location = useLocation();
  return (
    <div
      className="toolbar  mt-5 mx-5  customToolbar1"
      id="kt_toolbar"
      // style={location.pathname?.includes("device") ? { top: "115px" } : {}}
    >
      {/* begin::Container */}
      <div
        id="kt_toolbar_container"
        className={clsx(
          classes.toolbarContainer.join(" "),
          "d-flex flex-stack"
        )}
      >
        <DefaultTitle />

        {/* begin::Actions */}

        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Toolbar1 };
