import { useFormik } from "formik";
import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { PipelineType } from "../../pipelines/types/PipelinesResponseType";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSubmitHandle: (piplineId: number | undefined) => void;
  id: number;
}

const EndPhaseTransitionModal: FC<Props> = ({
  show,
  closeModal,
  onSubmitHandle,
  id,
}) => {
  const intl = useIntl();

  // state
  const [selectedPipeline, setSelectedPipeline] = useState<number>();
  const [loading, setLoading] = useState(false);

  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelinesList
  ) as PipelineType[];

  const initialValues = {};
  const formik = useFormik({
    initialValues,
    validationSchema: {},
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {},
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={"AddNew"}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "DEAL_END_PHASE_TRANSITION_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body>
          <div className="p-4">
            <div className="">
              <div className="alert alert-warning d-flex align-items-center flex-wrap">
                {intl.formatMessage({ id: "DEAL_END_PHASE_TRANSITION_INFO" })}
              </div>
            </div>
            <div>
              {/* Role */}
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="">
                  {intl.formatMessage({ id: "DEAL_PIPLINE_TITLE" })}
                </span>
              </label>
              <DropDown
                id="UserRoleTypeDropDown"
                hideSearch
                items={pipelineList.filter((data) => data.id !== id) || []}
                displayValue={
                  pipelineList.find((role) => role.id === selectedPipeline)
                    ?.name || ""
                }
                selectedItem={pipelineList.find(
                  (role) => role.id === selectedPipeline
                )}
                onSelectItem={(item) => {
                  setSelectedPipeline(item.id);
                }}
                displayFunc={(item) => {
                  return item.name;
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => {
              if (selectedPipeline) {
                onSubmitHandle(selectedPipeline);
              }
            }}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "MASTERDATA_TAGS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EndPhaseTransitionModal;
