import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, Link } from "react-router-dom";

const DeviceToolbar = () => {
  const location = useLocation();
  const intl = useIntl();
  return (
    <div
      className="toolbar  mt-5 mx-5 pt-3 customToolbar"
      id="kt_toolbar"
      style={{
        top: "115px",
        paddingLeft: "28px",
      }}
    >
      <div className="d-flex h-100">
        <Link
          to="/device/overview"
          className={`d-flex align-items-center deviceMenuCont h-100 ${
            location.pathname?.includes("device/overview")
              ? "bg-grey activeBorderBottom"
              : ""
          }`}
        >
          <h5
            className={`mb-0 ${
              location.pathname?.includes("device/overview")
                ? "brandBlue fw-bolder"
                : "text-muted fw-bold"
            }`}
          >
            {intl.formatMessage({ id: "MENU_SYSTEM_LOCATION" })}
          </h5>
        </Link>

        <Link
          to="/device/masterdata/device-types"
          className={`d-flex align-items-center deviceMenuCont ${
            location.pathname?.includes("device-types")
              ? "bg-white activeBorderBottom"
              : ""
          }`}
        >
          <h5
            className={`mb-0 ${
              location.pathname?.includes("device-types")
                ? "brandBlue fw-bolder"
                : "text-muted fw-bold"
            }`}
          >
            {intl.formatMessage({ id: "DEVICE_MANAGEMENT_DEVICE_TYPES_TITLE" })}
          </h5>
        </Link>
        <Link
          to="/device/masterdata/manufacturer"
          className={`d-flex align-items-center deviceMenuCont ${
            location.pathname?.includes("manufacturer")
              ? "bg-white activeBorderBottom"
              : ""
          }`}
        >
          <h5
            className={`mb-0 ${
              location.pathname?.includes("manufacturer")
                ? "brandBlue fw-bolder"
                : "text-muted fw-bold"
            }`}
          >
            {intl.formatMessage({ id: "DEVICE_MANAGEMENT_MANUFACTURER_TITLE" })}
          </h5>
        </Link>
        <Link
          to="/device/masterdata/model"
          className={`d-flex align-items-center deviceMenuCont ${
            location.pathname?.includes("model")
              ? "bg-white activeBorderBottom"
              : ""
          }`}
        >
          <h5
            className={`mb-0 ${
              location.pathname?.includes("model")
                ? "brandBlue fw-bolder"
                : "text-muted fw-bold"
            }`}
          >
            {intl.formatMessage({
              id: "DEVICE_MANAGEMENT_MANUFACTURER_DEVICE_TYPE_TITLE",
            })}
          </h5>
        </Link>
        <Link
          to="/device/masterdata/topology"
          className={`d-flex align-items-center deviceMenuCont ${
            location.pathname?.includes("topology")
              ? "bg-white activeBorderBottom"
              : ""
          }`}
        >
          <h5
            className={`mb-0 ${
              location.pathname?.includes("topology")
                ? "brandBlue fw-bolder"
                : "text-muted fw-bold"
            }`}
          >
            {intl.formatMessage({ id: "DEVICE_MANAGEMENT_TOPOLOGY_TITLE" })}
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default DeviceToolbar;
