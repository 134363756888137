import clsx from "clsx";
import React, { FC } from "react";
import { shallowEqual, useSelector } from "react-redux";
// import { actions } from "../../../../app/modules/auth";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup";

// custom
// import { KTSVG } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { usePageData } from "../../core";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";
// toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  // const { config } = useLayout();
  const { pageTitle } = usePageData();
  // const dispatch = useDispatch();

  const user = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Drawer toggle */}
      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
      {/* end::Drawer toggle */}
      {/* </div> */}
      {/* Quick links */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu wrapper */}
      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
      {/* <QuickLinks /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* Notifications */}
        <div
          className={clsx(
            "btn btn-icon bgBrandLightBlueHover position-relative brandBlueHover",
            toolbarButtonHeightClass,
            pageTitle === "Notifications"
              ? "pe-none bg-light-primary btn-icon-primary"
              : ""
          )}
          id="kt_drawer_chat_toggle"
          onClick={() => {}}
        >
          {/* <SVGICON src={BellIcon} className={'svg-icon-2x'} /> */}
          <i className="bi bi-bell-fill fs-2"></i>
        </div>
      </div>

      {/* begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {user && (user.profile_photo_url || user.profile_photo) ? (
            <div className="symbol symbol-50px me-5">
              <img
                alt="Logo"
                src={user.profile_photo || user.profile_photo_url || ""}
              />
            </div>
          ) : (
            <div className="symbol-label bgBrandLightPink brandPink me-5 p-3  ">
              {((user && user.firstname) || " ")[0]}
              {((user && user.lastname) || " ")[0]}
            </div>
          )}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  );
};

export { Topbar };
