import React, {FC} from 'react'

interface Props {
  onClose?: () => void
}

const CloseButton: FC<Props> = ({onClose}) => {
  return (
    <div className='' role={'button'} onClick={onClose}>
      <span className='svg-icon svg-icon-1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <rect
            opacity='0.5'
            x='6'
            y='17.3137'
            width='16'
            height='2'
            rx='1'
            transform='rotate(-45 6 17.3137)'
            fill='black'
          ></rect>
          <rect
            x='7.41422'
            y='6'
            width='16'
            height='2'
            rx='1'
            transform='rotate(45 7.41422 6)'
            fill='black'
          ></rect>
        </svg>
      </span>
    </div>
  )
}

export default CloseButton
