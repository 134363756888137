import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import CloseButton from "../../../sharedComponents/closeButton/CloseButton";

// custom
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import CustomersList from "./CustomersList";
import { PartnersContactDetailType } from "../../partnerManagement/types/getPartnersListResponseType";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectCustomers: (customers: UserType[]) => void;
  isSelectionModal?: boolean;
  selectedContacts?: PartnersContactDetailType[] | undefined | any;
}

const SelectCustomerModal: FC<Props> = ({
  show,
  closeModal,
  onSelectCustomers,
  isSelectionModal,
  selectedContacts,
}) => {
  const intl = useIntl();
  // state
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      className={`${isFilterOpen ? "d-none" : "d-block"}`}
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between  ">
          <Modal.Title>
            {intl.formatMessage({ id: "SELECT_CUSTOMER_MODAL_TITLE" })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${isSelectionModal ? "selections_modal" : ""}`}
        style={{ padding: "0px" }}
      >
        <CustomersList
          type={"select"}
          // @ts-ignore
          onSelectCustomers={onSelectCustomers}
          isSelectionModal={isSelectionModal}
          selectedContacts={selectedContacts}
          setIsFilterOpen={setIsFilterOpen}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SelectCustomerModal;
