import React, { FC } from "react";
import { useIntl } from "react-intl";

// images
import MenuIcon from "../../../_metronic/assets/icons/menu.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { deleteTask } from "../redux/tasksAPI";
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { TaskType } from "../types/TasksResponseType";

interface Props {
  taskDetails: TaskType;
  getTasksListAPI?: () => void;
  setShowAddTaskModal?: () => void;
}

const TasksListActionsDropdown: FC<Props> = ({
  taskDetails,
  getTasksListAPI,
  setShowAddTaskModal,
}) => {
  const intl = useIntl();

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEditProductClick = () => {
    console.log(taskDetails);
    setShowAddTaskModal?.();
  };

  const deleteExistingPost = (task_id: number) => {
    setDeleteModalLoading(true);
    deleteTask(task_id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "TASKS_DELETE_POST_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "TASKS_DELETE_POST_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        // recall  get  list API
        getTasksListAPI?.();
      });
  };

  const dropdownOptions = [
    {
      name: intl.formatMessage({ id: "COMMON_EDIT" }),
      onClick: onEditProductClick,
    },
    {
      name: intl.formatMessage({ id: "COMMON_DELETE" }),
      onClick: () => {
        showDeleteConfirmModal(
          intl.formatMessage({ id: "TASKS_DELETE_POST_CONFIRM_MESSAGE" }),
          () => {
            deleteExistingPost(taskDetails?.id);
          }
        );
      },
      className: "text-danger",
    },
  ];

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
      <ActionsDropdown options={dropdownOptions} />
    </div>
  );
};

export default TasksListActionsDropdown;
