/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
// import {Link} from 'react-router-dom'
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login } from "../redux/AuthCRUD";
import LoginTwoFactorAuthModal from "../../twoFactorAuth/components/LoginTwoFactorAuthModal";
import { useIntl } from "react-intl";
import { id } from "date-fns/locale";
import { Link } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const dispatch = useDispatch();
  const intl = useIntl();
  // @ts-ignore
  const clientDetails_id = sessionStorage.getItem("clientDetails_id") || "";
  const clientDetails_secret =
    sessionStorage.getItem("clientDetails_secret") || "";

  // state
  const [loading, setLoading] = useState(false);
  const [showLoginTwoFactorAuthModal, setShowLoginTwoFactorAuthModal] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<number | undefined>();

  const saveAccessToken = (accessToken: string) => {
    sessionStorage.setItem("accessToken", accessToken);
    setTimeout((accessToken: string) => {
      dispatch(auth.actions.login(accessToken));
    }, 0);
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "WRONG_EMAIL_FORMAT" }))
      .required(intl.formatMessage({ id: "EMAIL_IS_REQUIRED" })),
    password: Yup.string().required(
      intl.formatMessage({ id: "PASSWORD_IS_REQUIRED" })
    ),
  });
  const saveRefreshToken = (refresh_token: string) => {
    localStorage.setItem("refreshToken", refresh_token);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      login(
        values.email,
        values.password,
        clientDetails_id,
        clientDetails_secret
      )
        .then(({ data }) => {
          if (data.userDetails && data.tokenDetails) {
            dispatch(auth.actions.setUser(data.userDetails));
            let { access_token, refresh_token } = data.tokenDetails;
            access_token && saveAccessToken(access_token);
            refresh_token && saveRefreshToken(refresh_token);
            setLoading(false);
          } else {
            // @ts-ignore
            const { has2FA, id } = data.userDetails;
            if (has2FA) {
              setUserId(id);
              setShowLoginTwoFactorAuthModal(true);
            }
          }
        })
        .catch((err) => {
          err.response?.data?.error
            ? setStatus(err.response.data?.error)
            : err.response?.data?.error?.email
              ? setStatus(err.response.data?.error?.email)
              : setStatus("The login details are incorrect");
          setLoading(false);
          setSubmitting(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const closeModal = () => {
    setShowLoginTwoFactorAuthModal(false);
  };

  return (
    <div>
      {showLoginTwoFactorAuthModal && (
        <LoginTwoFactorAuthModal
          show={showLoginTwoFactorAuthModal}
          closeModal={closeModal}
          userId={userId}
          email={formik.values.email}
          password={formik.values.password}
        />
      )}
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-10">
          <h1 className="brandBlue mb-3">
            {intl.formatMessage({ id: "SIGN_IN_TITLE" })}
          </h1>
          {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
        </div>
        {/* begin::Heading */}

        {formik.status && (
          <div className="mb-10 alert alert-danger text-break">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder brandBlue">
            {intl.formatMessage({ id: "SIGN_IN_EMAIL" })}
          </label>
          <input
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              // {'is-invalid': formik.touched.email && formik.errors.email},
              // {
              //   'is-valid': formik.touched.email && !formik.errors.email,
              // }
            )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-danger">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <div className="d-flex justify-content-between mt-n5">
            <div className="d-flex flex-stack mb-2">
              {/* begin::Label */}
              <label className="form-label fw-bolder brandBlue fs-6 mb-0">
                {intl.formatMessage({ id: "SIGN_IN_PASSWORD" })}
              </label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to="/forgot-password"
                className="link-primary fs-6 fw-bolder"
                style={{ marginLeft: "5px" }}
              >
                {intl.formatMessage({ id: "AUTH_LOGIN_FORGOT_PASSWORD" })}
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <input
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              // {
              //   'is-invalid': formik.touched.password && formik.errors.password,
              // },
              // {
              //   'is-valid': formik.touched.password && !formik.errors.password,
              // }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="text-danger">
              <span role="alert">{formik.errors.password}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "AUTH_LOGIN_CONTINUE" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "SIGN_IN_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          {/* begin::Separator */}
          {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
          {/* end::Separator */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
          {/* end::Google link */}
        </div>

        {/* end::Action */}
      </form>
    </div>
  );
}
