/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { register } from "../redux/AuthCRUD";
import { Link } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useIntl } from "react-intl";

const initialValues = {
  salutation: 1,
  title: 0,
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
  salutation: Yup.string().required("Please enter Salutation"),
  firstname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  lastname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),

  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  changepassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  acceptTerms: Yup.bool().equals(
    [true],
    "You must accept the terms and conditions"
  ),
});

export function Registration() {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      register(
        values.salutation,
        values.title,
        values.firstname,
        values.lastname,
        values.email,
        values.password,
        values.changepassword,
        values.acceptTerms ? 0 : 1
      )
        .then(({ data: { accessToken } }) => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
          setStatus("Registration process has broken");
        });
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: "AUTH_REGISTRATION_TITLE" })}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-400 fw-bold fs-4">
          {intl.formatMessage({ id: "AUTH_REGISTRATION_ALREADY_HAVE" })}
          <Link
            to="/login"
            className="link-primary fw-bolder"
            style={{ marginLeft: "5px" }}
          >
            {intl.formatMessage({ id: "AUTH_REGISTRATION_LOGIN" })}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* salutations */}
      <div className="mb-8">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "AUTH_REGISTRATION_SALUTATION" })}
        </label>
        <select
          className="form-select form-select-solid"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          onChange={(e) => {
            formik.setFieldValue(
              "salutation",
              e.target.value ? parseInt(e.target.value) : e.target.value,
              true
            );
          }}
          defaultValue={1}
          // value={formik.getFieldProps('salutation').value}
        >
          <option value={1}>
            {intl.formatMessage({ id: "AUTH_REGISTRATION_SALUTATION_MR" })}
          </option>
          <option value={2}>
            {intl.formatMessage({ id: "AUTH_REGISTRATION_SALUTATION_MRS" })}
          </option>
        </select>
      </div>

      {/* Titles */}
      <div className="mb-8">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "AUTH_REGISTRATION_TITLES" })}
        </label>
        <select
          className="form-select form-select-solid"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          onChange={(e) => {
            formik.setFieldValue(
              "title",
              e.target.value ? parseInt(e.target.value) : e.target.value,
              true
            );
          }}
          defaultValue={0}
        >
          <option value={0}></option>
          <option value={1}>
            {intl.formatMessage({ id: "AUTH_REGISTRATION_TITLES_DR" })}
          </option>
          <option value={2}>
            {intl.formatMessage({ id: "AUTH_REGISTRATION_TITLES_PROF" })}
          </option>
          <option value={3}>
            {intl.formatMessage({ id: "AUTH_REGISTRATION_TITLES_PROF_DR" })}
          </option>
        </select>
      </div>

      {/* begin::Form group Firstname */}
      <div className="row fv-row  align-items-center mb-8">
        <div className="col-xl-6">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH_REGISTRATION_FIRST_NAME" })}
          </label>
          <input
            placeholder="First name"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("firstname")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className="text-danger">
              <span role="alert">{formik.errors.firstname}</span>
            </div>
          )}
        </div>
        <div className="col-xl-6">
          {/* begin::Form group Lastname */}
          <div className="fv-row">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "AUTH_REGISTRATION_LAST_NAME" })}
            </label>
            <input
              placeholder="Last name"
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("lastname")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className="text-danger">
                <span role="alert">{formik.errors.lastname}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "AUTH_REGISTRATION_EMAIL" })}
        </label>
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx("form-control form-control-lg form-control-solid")}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="text-danger">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH_REGISTRATION_PASSWORD" })}
          </label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-danger">
                <span role="alert">{formik.errors.password}</span>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          {/* <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div> */}
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          {intl.formatMessage({ id: "AUTH_REGISTRATION_PASSWORD_MESSAGE" })}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "AUTH_REGISTRATION_CONFIRM_PASSWORD" })}
        </label>
        <input
          type="password"
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps("changepassword")}
          className={clsx("form-control form-control-lg form-control-solid")}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="text-danger">
            <span role="alert">{formik.errors.changepassword}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
            onChange={(e) => {
              formik.setFieldValue('acceptTerms', e.target.checked, true)
            }}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
        </div>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='text-danger'>
            <span role='alert'>{formik.errors.acceptTerms}</span>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-light-primary w-100 mb-5"
          // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: "AUTH_REGISTRATION_SUBMIT" })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "AUTH_REGISTRATION_LOADING_MESSAGE" })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            {intl.formatMessage({ id: "AUTH_REGISTRATION_CANCEL" })}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
