import React, { FC, useEffect, useState } from "react";
import { ProductType } from "../types/ProductsResponseType";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";
// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import { getProductTypesList } from "../../masterdata/redux/ProductTypesAPI";
import { useDispatch } from "react-redux";
import { actions as masterActions } from "../../masterdata/redux/MasterdataRedux";
import { Button, Modal } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { deleteProductTypes, updateProductTypes } from "../redux/ProductsAPI";
import { id } from "date-fns/locale";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
}
interface ModelComponentProps {
  modalProps: AddProductTypeModalProps;
  closeModal: () => void;
  getProductDetailsAPI: () => void;
  product: ProductType;
}

interface AddProductTypeModalProps {
  show: boolean;
  type?: string;
  pType?: any;
}

const AddProuctTypesModal: FC<ModelComponentProps> = ({
  modalProps,
  closeModal,
  getProductDetailsAPI,
  product,
}) => {
  const productTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.productTypesList
  ) as GroupType[];
  const { show, type, pType } = modalProps;
  const intl = useIntl();
  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState<
    GroupType | undefined
  >();
  const [productTypeValues, setProductTypeValues] = useState<
    | {
        products_types_ids: number;
        values: number;
      }
    | undefined
  >();

  // const handleProductTypeSelect = (item: GroupType) => {
  //   const selectedItemsArr = selectedProductTypes;
  //   const index = selectedItemsArr.findIndex(
  //     (selectedItem) => selectedItem.id === item.id
  //   );
  //   // if doesnt exist push item
  //   if (index === -1) {
  //     setSelectedProductTypes(() => [...selectedProductTypes, item]);
  //   } else {
  //     //@ts-ignore
  //     setSelectedProductTypes((prevState) => {
  //       if (prevState) {
  //         prevState.splice(index, 1);
  //         return [...prevState];
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (type === "new") {
      setSelectedProductTypes(undefined);
      setProductTypeValues(undefined);
    } else {
      setSelectedProductTypes(pType);
    }
  }, [modalProps]);

  const handleSubmit = () => {
    setLoading(true);
    updateProductTypes(product.id, [productTypeValues])
      .then((data) => {
        SuccessAlert(
          intl.formatMessage({
            id: "PRODUCT_PRODUCTTYPES_ADD_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        errorAlert(
          intl.formatMessage({ id: "PRODUCT_PRODUCTTYPES_ADD_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setLoading(false);
        getProductDetailsAPI();
        closeModal();
      });
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : "AddNew"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_EDIT_CONTACT_TITLE",
              })
            : intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_ADD_CONTACT_TITLE",
              })}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="p-4">
          <div className="fv-row mb-8">
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span className="required">
                {intl.formatMessage({
                  id: "PRODUCT_PRODUCT_TYPE",
                })}
              </span>
            </label>
            <DropDown
              id="contactTypesDropdown"
              items={productTypesList}
              selectedItem={selectedProductTypes}
              // @ts-ignore
              onSelectItem={(item: GroupType) => {
                setSelectedProductTypes(item);
              }}
              displayValue={selectedProductTypes?.name}
              // @ts-ignore
              displayFunc={(item: GroupType) => {
                return item.name;
              }}
              disabled={type === "edit"}
            />
          </div>
          <div className="mb-4">
            <span className="text-muted fs-6 fw-bold">
              {intl.formatMessage({ id: "PRODUCT_PRODUCTTYPE_INFO" })}
            </span>
          </div>
          {selectedProductTypes && (
            <div className="mb-8">
              <div className="mb-4">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="required">{selectedProductTypes?.name}</span>
                </label>
                <input
                  type="number"
                  placeholder={""}
                  className={"form-control form-control-lg form-control-solid"}
                  min={1}
                  value={productTypeValues?.values || pType?.value}
                  autoComplete="off"
                  onChange={(e) => {
                    let obj = {
                      products_types_ids: selectedProductTypes?.id,
                      values: e.target.value,
                    };
                    // @ts-ignore
                    setProductTypeValues(obj);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button
          variant="secondary"
          onClick={() => {
            setSelectedProductTypes(undefined);
            setProductTypeValues(undefined);
            closeModal();
          }}
        >
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          type="submit"
          variant="primary"
          id="kt_customer_contact_submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_CONTACT_TYPES_LOADING_MESSAGE",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ProductTypesSelect: FC<Props> = ({ product, getProductDetailsAPI }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const productTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.productTypesList
  ) as GroupType[];
  // context
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();
  //   state
  const [showAddProductTypeeModal, setShowAddProductTypeeModal] =
    useState<AddProductTypeModalProps>({
      show: false,
    });

  useEffect(() => {
    getProductTypesList().then(({ data: { data } }) => {
      dispatch(masterActions.setProductTypesList(data));
    });
  }, []);

  const closeModal = () => {
    setShowAddProductTypeeModal({
      show: false,
      type: "new",
      pType: undefined,
    });
  };

  const allProductTypesAdded = () => {
    let flag = true;
    productTypesList.forEach((pType) => {
      const index = product.products_types?.findIndex(
        // @ts-ignore
        (contactDetail) => contactDetail.id === pType.id
      );
      if (index === -1) {
        flag = false;
      }
    });
    return flag;
  };

  const deleteProductType = (pType: any) => {
    setDeleteModalLoading(true);
    deleteProductTypes(product.id, [pType.id])
      .then((data) => {
        SuccessAlert(
          intl.formatMessage({
            id: "PRODUCT_PRODUCTTYPE_DELETE__SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "PRODUCT_PRODUCTTYPE_DELETE__FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getProductDetailsAPI();
      });
  };

  return (
    <>
      <AddProuctTypesModal
        modalProps={showAddProductTypeeModal}
        closeModal={closeModal}
        getProductDetailsAPI={getProductDetailsAPI}
        product={product}
      />
      <div className="card card-flush">
        <div className="card-header d-flex flex-fill flex-row align-items-center justify-content-between">
          <div
            className="border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_contact_types"
          >
            <div className="card-title m-0 flex-fill">
              <h3 className="fw-bolder m-0">
                {intl.formatMessage({ id: "PRODUCT_PRODUCTTYPES_HEADER" })}
              </h3>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (allProductTypesAdded()) {
                  SuccessAlert(
                    intl.formatMessage({
                      id: "PRODUCT_PRODUCT_TYPES_SUCCESS_MESSAGE",
                    }),
                    () => {},
                    intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
                  );
                } else {
                  setShowAddProductTypeeModal({
                    show: true,
                    type: "new",
                    pType: undefined,
                  });
                }
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
              {intl.formatMessage({
                id: "PRODUCT_PRODUCTTYPES_ADD_CONTACT_BUTTON",
              })}
            </button>
          </div>
        </div>

        <div id="kt_account_contact_types" className="collapse show">
          <div className="card-body border-top p-9">
            {(!product?.products_types ||
              product?.products_types.length === 0) && (
              <span className="fw-bold text-gray-600">
                {intl.formatMessage({
                  id: "PRODUCT_PRODUCT_TYPES_NO_PRODUCT_TYPES",
                })}
              </span>
            )}
            {product?.products_types?.map(
              (pType: { id: number; name: string; value: number }, i) => {
                return (
                  <div key={i}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <div className="fs-6 fw-bolder mb-1">{pType?.name}</div>
                        <div className="fw-bold text-gray-600">
                          {pType?.value}
                        </div>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-icon btn-light btn-active-light-primary me-1"
                          onClick={() => {
                            setShowAddProductTypeeModal({
                              show: true,
                              type: "edit",
                              pType,
                            });
                          }}
                        >
                          <SVGICON src={EditIcon} className="svg-icon-3" />
                        </button>
                        <button
                          className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                          onClick={() => {
                            showDeleteConfirmModal(
                              intl.formatMessage({
                                id: "PRODUCT_PRODUCTTYPE_DELETE_CONFIRM_MESSAGE",
                              }),
                              () => {
                                deleteProductType(pType);
                              }
                            );
                          }}
                        >
                          <SVGICON src={DeleteIcon} className="svg-icon-3" />
                        </button>
                      </div>
                    </div>
                    {i !== product?.products_types?.length - 1 && (
                      <div className="separator separator-dashed my-6"></div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTypesSelect;
