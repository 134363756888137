// import {Formik} from 'formik'
// import moment from 'moment'
import React, { FC } from "react";
import { successToast } from "../../../sharedComponents/toasts/Toasts";

// custom
import { useIntl } from "react-intl";
import { PartnerType } from "../types/getPartnersListResponseType";

interface Props {
  partner: PartnerType;
}

const PartnerCodeAndCountry: FC<Props> = ({ partner }) => {
  // console.log(partner);
  const intl = useIntl();
  return (
    <div className="card card-flush py-4">
      <div className="card-header">
        <div className="card-title">
          <h2
            role={"button"}
            onClick={() => {
              try {
                navigator.clipboard.writeText(partner.code);
                successToast(
                  intl.formatMessage({
                    id: "PARTNERS_COUNTRY_CODE_COPY_SUCCESS",
                  })
                );
              } catch (e) {}
            }}
          >
            {partner.code}
          </h2>
        </div>
        <div className="card-toolbar">
          <span style={{ fontSize: "30px" }}>{partner.country_emoji}</span>
        </div>
      </div>
    </div>
  );
};

export default PartnerCodeAndCountry;
