import React, { useEffect, useRef, useState } from "react";
import { SVGICON } from "../../../../_metronic/helpers";
// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

import { useIntl } from "react-intl";
import {
  Sortpipeline,
  deletePipeline,
  getPipelinesList,
  massDeletePipelines,
} from "../redux/PipelinesAPI";
import { useDispatch } from "react-redux";
import { actions } from "../redux/PipelinesRedux";
import { actions as DealActions } from "../../deals/redux/DealsRedux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import {
  PipelinePhaseType,
  PipelineType,
} from "../types/PipelinesResponseType";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import AddPipelineModal from "./AddPipelineModal";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import { sortGroupsParams } from "../types/request";
import { useHistory } from "react-router-dom";
import Constants from "../../../config/Constants";
import _ from "lodash";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { getDealPipelines } from "../../deals/redux/DealsAPI";

const Pipelines = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelinesList
  ) as PipelineType[];

  //   state
  const [loading, setLoading] = useState(false);
  const [showPipelineModal, setShowPipelineModal] = useState<{
    show: boolean;
    type: string;
    pipeline: PipelineType | undefined;
  }>({
    show: false,
    type: "new",
    pipeline: undefined,
  });
  const [displayPipelineList, setDisplayPipelineList] = useState(pipelineList);
  const [checkedPipeline, setCheckedPipeline] = useState<PipelineType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllPipeline, setCheckAllPipeline] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);

  const updateContentPipelines = () => {
    getDealPipelines().then(({ data: { pipelines } }) => {
      dispatch(DealActions.setDealPipelines(pipelines));
    });
  };

  const getPipelines = () => {
    setLoading(true);
    getPipelinesList()
      .then(({ data: { pipelines } }) => {
        dispatch(actions.setPipelinesList(pipelines));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
        updateContentPipelines();
      });
  };

  useEffect(() => {
    getPipelines();
  }, []);

  const handleDeletePipeline = (id: number) => {
    setDeleteModalLoading(true);
    deletePipeline(id)
      .then((data) => {
        SuccessAlert(
          intl.formatMessage({
            id: "PROEJCTS_PIPELINE_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "PROEJCTS_PIPELINE_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getPipelines();
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  };

  const handlePipelineSort = (sortedList: PipelineType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing tags API Call
    Sortpipeline(newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getPipelines();
      });
  };

  const dragPipeline = useRef<number>(0);
  const draggedOverPipeline = useRef<number>(0);

  const updateDisplayPipelinesList = () => {
    const updatedPipelinesList = pipelineList.filter((Pipeline) => {
      if (
        (Pipeline.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayPipelineList(updatedPipelinesList);
  };

  useEffect(() => {
    updateDisplayPipelinesList();

    return () => {
      setDisplayPipelineList(pipelineList);
    };
  }, [pipelineList, searchText]);

  useEffect(() => {
    if (checkAllPipeline && checkedPipeline.length !== pipelineList.length) {
      setCheckAllPipeline(false);
    }
    if (
      checkedPipeline.length > 0 &&
      checkedPipeline.length === pipelineList.length
    ) {
      setCheckAllPipeline(true);
    }
  }, [checkedPipeline]);

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const onPipelineCheckChange = (pipline: PipelineType) => {
    let checkedPipelinesCopy = _.clone(checkedPipeline);
    // check if already exists in the checked list
    const index = checkedPipelinesCopy.findIndex(
      (checkedPipeline) => checkedPipeline.id === pipline.id
    );
    // if exists remove
    if (index > -1) {
      checkedPipelinesCopy.splice(index, 1);
    }
    // if doesnt exist push to checked tabs
    else {
      checkedPipelinesCopy.push(pipline);
    }
    setCheckedPipeline(checkedPipelinesCopy);
  };

  const isPipelineChecked = (pipline: PipelineType) => {
    const index = checkedPipeline.findIndex(
      (checkedPipeline) => checkedPipeline.id === pipline.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const onAllPipelinesCheckChange = () => {
    const updatedCheckAllPipelines = !checkAllPipeline;
    setCheckAllPipeline(updatedCheckAllPipelines);
    if (updatedCheckAllPipelines) {
      setCheckedPipeline(pipelineList);
    } else {
      setCheckedPipeline([]);
    }
  };

  const deleteMultiplePipelines = () => {
    setDeleteModalLoading(true);
    const ids = checkedPipeline.map((checkedTab) => {
      return checkedTab.id;
    });
    massDeletePipelines(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "PROEJCTS_PIPELINE_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "PROEJCTS_PIPELINE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getPipelines();
        setCheckedPipeline([]);
      });
  };
  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedPipelines =
    displayPipelineList && displayPipelineList.length > 10
      ? displayPipelineList.slice(startIndex, startIndex + itemsPerPage)
      : displayPipelineList;

  useEffect(() => {
    if (paginatedPipelines.length === 0 && displayPipelineList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedPipelines]);

  return (
    <div>
      {showPipelineModal.show && (
        <AddPipelineModal
          show={showPipelineModal.show}
          type={showPipelineModal.type}
          closeModal={() => {
            setShowPipelineModal({
              show: false,
              type: "new",
              pipeline: undefined,
            });
          }}
          getPipelines={getPipelines}
          pipeline={showPipelineModal.pipeline}
        />
      )}
      {/* search tabs */}
      <div className="card p-4 d-flex flex-row justify-content-between ">
        <div className="d-flex flex-row">
          <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={onAllPipelinesCheckChange}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={checkAllPipeline}
            />
          </div>
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "PROJECTS_PIPELINE_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>
        {/* add new button */}
        <div className="d-flex align-items-center">
          {checkedPipeline.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedPipeline.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "PROJECTS_PIPELINES_MULTI_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultiplePipelines();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
            <button
              className="btn  btn-primary"
              onClick={() => {
                setShowPipelineModal({
                  show: true,
                  type: "new",
                  pipeline: undefined,
                });
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
              {intl.formatMessage({ id: "PROJECTS_PIPELINE_ADD_NEW_BUTTON" })}
            </button>
          )}
        </div>
      </div>
      <div className="">
        <div className=" d-flex flex-column ">
          {!loading && displayPipelineList.length === 0 && (
            <table className="d-flex justify-content-center align-items-center mt-2">
              <tbody>
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <img
                        src={notFound}
                        className="mh-400px"
                        alt=""
                        style={{ color: "inherit" }}
                      />
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          {paginatedPipelines.map((pipeline, index) => (
            <div
              className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
              key={index}
              // style={{
              //   background: "#F5F8FA",
              // }}
              draggable
              onDragStart={() => (dragPipeline.current = index)}
              onDragEnter={() => (draggedOverPipeline.current = index)}
              onDragEnd={() => {
                if (dragPipeline.current !== draggedOverPipeline.current) {
                  const clonedPipelineList = [...paginatedPipelines];
                  const deletedSlice = clonedPipelineList.splice(
                    dragPipeline.current,
                    1
                  )[0];
                  clonedPipelineList.splice(
                    draggedOverPipeline.current,
                    0,
                    deletedSlice
                  );
                  // const temp = clonedPipelineList[dragPipeline.current];
                  // clonedPipelineList[dragPipeline.current] =
                  //   clonedPipelineList[draggedOverPipeline.current];
                  // clonedPipelineList[draggedOverPipeline.current] = temp;
                  handlePipelineSort(clonedPipelineList);
                }
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <div className="d-flex flex-row align-items-center">
                <div className="form-check form-check-sm form-check-custom form-check-solid ">
                  <input
                    onChange={(e) => {
                      onPipelineCheckChange(pipeline);
                    }}
                    className="form-check-input widget-9-check"
                    type="checkbox"
                    checked={isPipelineChecked(pipeline)}
                  />
                </div>
                <div className="mx-5">
                  <SVGICON
                    src={DragIcon}
                    className="svg-icon-2 svg-hover-primary"
                  />
                </div>

                <div
                  className="fw-bold mx-5"
                  role="button"
                  onClick={() => {
                    const tempPipeline = Object.assign({}, pipeline);
                    const result: PipelinePhaseType[] = [];
                    pipeline.phases.map((val, index) => {
                      if (
                        val.condition !== "end" ||
                        (val.condition === "end" && val.display_order)
                      ) {
                        result.push(val);
                      }
                      if (pipeline.phases.length - 1 === index) {
                        result.push(
                          pipeline.phases.filter(
                            (data) => data.condition === "end"
                          )[0]
                        );
                      }
                    });
                    tempPipeline.phases = result;
                    history.push(`/projects/pipelines/editPipeline`, {
                      pipelineDetails: tempPipeline,
                    });
                    dispatch(actions.setPipelineDetails(tempPipeline));
                  }}
                >
                  {" "}
                  {pipeline.name}
                </div>
                <div className="min-w-125px pe-2">
                  <span className="badge badge-light-primary">
                    {pipeline.transition_type}
                  </span>
                </div>
              </div>

              <div>
                <button
                  className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                  onClick={() => {
                    const tempPipeline = Object.assign({}, pipeline);
                    const result: PipelinePhaseType[] = [];
                    pipeline.phases.map((val, index) => {
                      if (
                        val.condition !== "end" ||
                        (val.condition === "end" && val.display_order)
                      ) {
                        result.push(val);
                      }
                      if (pipeline.phases.length - 1 === index) {
                        result.push(
                          pipeline.phases.filter(
                            (data) => data.condition === "end"
                          )[0]
                        );
                      }
                    });
                    tempPipeline.phases = result;
                    history.push(`/projects/pipelines/editPipeline`, {
                      pipelineDetails: tempPipeline,
                    });
                    dispatch(actions.setPipelineDetails(tempPipeline));
                  }}
                >
                  <SVGICON src={EditIcon} className="svg-icon-3" />
                </button>
                <button
                  className="btn btn-icon btn-light btn-active-light-danger btn-sm"
                  onClick={() => {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE",
                      }),
                      () => {
                        handleDeletePipeline(pipeline.id);
                      }
                    );
                  }}
                >
                  <SVGICON src={DeleteIcon} className="svg-icon-3" />
                </button>
              </div>
            </div>
          ))}

          {displayPipelineList.length > 0 && (
            <div className="card mt-5 pe-3">
              <Pagination
                totalPages={Math.ceil(
                  displayPipelineList.length / itemsPerPage
                )}
                activePage={
                  Math.ceil(displayPipelineList.length / itemsPerPage) === 1
                    ? 1
                    : activePage
                }
                onPageClick={onPageClick}
                noCard
                itemsPerPage={itemsPerPage}
                showItemsPerPage
                setItemsPerPage={(count) => {
                  setItemsPerPage(count);
                  setActivePage(1);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pipelines;
