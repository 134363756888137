import React, { createContext, useState } from 'react';
import { LogType } from '../types/DealResponseType';

export const FieldLogsContext = createContext({})

//@ts-ignore
export const FieldLogsProvider = ({ children }) => {
  const [fieldLogsInEditPage, setFieldLogsInEditPage] = useState<LogType[]>([]);

  return (
    <FieldLogsContext.Provider value={{ fieldLogsInEditPage, setFieldLogsInEditPage }}>
      {children}
    </FieldLogsContext.Provider>
  );
};
