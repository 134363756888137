import axios from "axios";
import {
  TabsByIdResponseType,
  TabsResponseType,
  tabPanelResponseType,
} from "../types/TabsResponseType";

export function getTabsList() {
  return axios.get<TabsResponseType>(`/tabs`);
}

export function createTab(name: any) {
  return axios.post(`/tabs`, {
    name,
  });
}

export function getTabByID(id: number) {
  return axios.get<TabsByIdResponseType>(`/tabs/${id}`);
}

export function updateTab(id: number | undefined, name: string) {
  return axios.post(`/tabs/${id}`, {
    name,
  });
}

export function deleteTab(id: number) {
  return axios.delete(`/tabs/${id}`);
}

export function massDeleteTabs(tabs_id: any[]) {
  return axios.post(`/tabs/massDelete`, {
    tabs_id,
  });
}

export function sortTab(tabs_sorting: any[]) {
  return axios.post(`/tabs/sorting`, {
    tabs_sorting,
  });
}

export function getTabPanelsList() {
  return axios.get<tabPanelResponseType>(`/tab/panels`);
}

export function storeTabPanels(tabs_id: number, panels_id: number) {
  return axios.post(`/tab/panels`, {
    tabs_id,
    panels_id,
  });
}

export function storeSectionFields(
  panels_id: number,
  panels_sections_id: number,
  fields_data: any[]
) {
  return axios.post(`/tab/panels/sections/fields/${panels_id}`, {
    panels_sections_id,
    fields_data,
  });
}

export function updateIsRequired(
  tab_panel_id: number,
  section_id: number,
  field_id: number,
  is_required: number
) {
  return axios.post(
    `/tab/panels/fields/isrequired/${tab_panel_id}/${section_id}/${field_id}`,
    {
      is_required,
    }
  );
}

export function deleteSection(tab_panel_id: number, section_id: number) {
  return axios.delete(
    `/tab/panels/delete/section/${tab_panel_id}/${section_id}`
  );
}

export function sortFieldSections(
  tab_panel_id: number,
  section_id: number,
  section_fields_sorting: any[]
) {
  return axios.post(
    `/tab/panels/sections/fields/sorting/${tab_panel_id}/${section_id}`,
    {
      section_fields_sorting,
    }
  );
}

export function sortPanels(id: number, tabs_panels_sorting: any[]) {
  return axios.post(`/tab/panels/sorting/${id}`, {
    tabs_panels_sorting,
  });
}

export function deletePanels(tab_panel_id: any[]) {
  return axios.post(`/tab/delete/panels`, {
    tab_panel_id,
  });
}
