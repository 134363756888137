import React from "react";
import "./ProductsPage.scss";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Helmet } from "react-helmet";
import Config from "../../config";
import { Redirect, Route, Switch } from "react-router-dom";
import ProductsList from "./components/ProductsList";
import EditProducts from "./components/EditProducts";

const ProductsPage = () => {
  const intl = useIntl();
  const productsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
      path: "/dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU_PRODUCTS" }),
      path: "/sales/products",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{Config.APP_NAME}-Products</title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/sales/products/overview">
          <PageTitle
            breadcrumbs={[
              ...productsBreadCrumbs,
              {
                title: intl.formatMessage({ id: "ROUTES_OVERVIEW" }),
                path: "/sales/products/overview",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "PRODUCTS_TITLE" })}
          </PageTitle>
          <ProductsList />
        </Route>
        <Route path="/sales/products/edit">
          <PageTitle
            breadcrumbs={[
              ...productsBreadCrumbs,
              {
                title: intl.formatMessage({
                  id: "ROUTES_PRODUCTS_EDIT",
                }),
                path: "/sales/products/edit",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "PRODUCTS_TITLE" })}
          </PageTitle>
          <EditProducts />
        </Route>

        <Redirect
          from="/sales/products"
          exact={true}
          to="/sales/products/overview"
        />
        <Redirect to="/sales/products/overview" />
      </Switch>
    </div>
  );
};

export default ProductsPage;
