import clsx from 'clsx'
import React, {FC} from 'react'

interface Props {
  activeIndex: number
  tabs: string[]
  onActiveIndexChange: (index: number) => void
  className?: string
}

const Tabs: FC<Props> = ({activeIndex, tabs, onActiveIndexChange, className}) => {
  return (
    <div>
      <ul className={`nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bold ${className}`}>
        {tabs.map((tab, i) => {
          if (tab) {
            return (
              <li
                className='nav-item'
                key={i}
                onClick={() => {
                  onActiveIndexChange?.(i)
                }}
              >
                <span
                  className={clsx(
                    'nav-link text-active-primary pb-4',
                    activeIndex === i ? 'active' : ''
                  )}
                  role={'button'}
                >
                  {tab}
                </span>
              </li>
            )
          }else{
            return null
          }
        })}
      </ul>
    </div>
  )
}

export default Tabs
