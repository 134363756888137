import axios from "axios";

// custom
import { sortGroupsParams } from "../types/request";
import { GroupsResponseType } from "../types/GetGroupsListResponseType";
import { CreateNewGroupResponseType } from "../types/CreateNewGroupResponseType";

// get all categories list
export function getCategoriesList() {
  return axios.get<GroupsResponseType>(`/categories`);
}

// create new category
export function createNewCategory(
  name: string
  // seo_title: string,
  // seo_description: string,
  // seo_picture_id: string,
  // is_visible:number
) {
  return axios.post<CreateNewGroupResponseType>(`/categories`, {
    name: name,
    // seo_title,
    // seo_description,
    // seo_picture_id,
    // is_visible
  });
}

// edit existing category
export function updateCategory(
  id: number,
  name: string
  // seo_title: string,
  // seo_description: string,
  // seo_picture_id: string,
  // is_visible: number
) {
  return axios.post<CreateNewGroupResponseType>(`/categories/${id}`, {
    name: name,
    // seo_title,
    // seo_description,
    // seo_picture_id,
    // is_visible,
  });
}

// delete existing category
export function deleteCategory(id: number) {
  return axios.delete<CreateNewGroupResponseType>(`/categories/${id}`);
}

// mass delete existing categories
export function massDeleteCategories(categories_id: Array<number>) {
  return axios.post<CreateNewGroupResponseType>(`/categories/massDelete`, {
    categories_id,
  });
}

// sort existing categories
export function sortCategories(categories_sorting: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/categories/sorting`, {
    categories_sorting,
  });
}
