import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// custom
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";
import Pipelines from "./components/Pipelines";
import EditPipeline from "./components/EditPipeline";

const PipelinesPage: React.FC = () => {
  const intl = useIntl();

  const pipelineBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
      path: "/dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU_PIPELINES" }),
      path: "/projects/pipelines",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "MENU_PIPELINES" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/projects/pipelines/overview">
          <PageTitle breadcrumbs={pipelineBreadCrumbs}>
            {intl.formatMessage({ id: "MENU_PIPELINES" })}
          </PageTitle>
          <Pipelines />
        </Route>

        <Route exact path="/projects/pipelines/editPipeline">
          <PageTitle
            breadcrumbs={[
              ...pipelineBreadCrumbs,
              {
                title: "",
                path: "",
                isSeparator: true,
                isActive: false,
              },

              {
                title: intl.formatMessage({
                  id: "PROJECTS_EDIT_PIPELINES_TITLE",
                }),
                path: "/projects/pipelines/editPipeline",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "PROJECTS_EDIT_PIPELINES_TITLE" })}
          </PageTitle>
          <EditPipeline />
        </Route>

        <Redirect
          from="/projects"
          exact={true}
          to="/projects/pipelines/overview"
        />
        <Redirect to="/projects/pipelines/overview" />
      </Switch>
    </div>
  );
};

export default PipelinesPage;
