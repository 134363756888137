import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  PartnerType,
  PartnersFilterType,
} from "../types/getPartnersListResponseType";

const initialState: partnerInitState = {
  partnersList: [],
  partnerDetails: {} as PartnerType,
  selfPartnerDetails: {} as PartnerType,
  storeProgress: 0,
  filters: {} as PartnersFilterType,
};

export interface partnerInitState {
  partnersList: any[];
  partnerDetails: PartnerType;
  selfPartnerDetails: PartnerType;
  storeProgress: any;
  filters: PartnersFilterType;
}

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_PARTNERS_LIST: "SET_PARTNERS_LIST",
  SET_PARTNER_DETAILS: "SET_PARTNER_DETAILS",
  SET_SELF_PARTNER_DETAILS: "SET_SELF_PARTNER_DETAILS",
  SET_PARTNER_FILTERS: "SET_PARTNER_FILTERS",
};

export const PartnerReducer = persistReducer(
  { storage, key: "server", whitelist: ["serverDetails", "storeProgress"] },
  // @ts-ignore
  (state: any = initialState, action: ActionWithPayload<partnerInitState>) => {
    switch (action.type) {
      case actionTypes.SET_PARTNERS_LIST: {
        const partners = action.payload || [];
        return { ...state, partnersList: partners };
      }
      case actionTypes.SET_PARTNER_DETAILS: {
        const partnerDetails = action.payload || {};
        return { ...state, partnerDetails };
      }
      case actionTypes.SET_SELF_PARTNER_DETAILS: {
        const partnerDetails = action.payload || {};
        return { ...state, selfPartnerDetails: partnerDetails };
      }

      case actionTypes.SET_PARTNER_FILTERS: {
        const filters = action.payload || {};
        return { ...state, filters };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setPartnersList: (partnersList: PartnerType[]) => ({
    type: actionTypes.SET_PARTNERS_LIST,
    payload: partnersList,
  }),
  setPartnerDetails: (partnerDetails: PartnerType) => ({
    type: actionTypes.SET_PARTNER_DETAILS,
    payload: partnerDetails,
  }),
  setSelfPartnerDetails: (partnerDetails: PartnerType) => ({
    type: actionTypes.SET_SELF_PARTNER_DETAILS,
    payload: partnerDetails,
  }),

  setFilters: (filters: PartnersFilterType) => ({
    type: actionTypes.SET_PARTNER_FILTERS,
    payload: filters,
  }),
};
