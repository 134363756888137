/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../modules/auth";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { RootState } from "../../setup";
import { MasterInit } from "../../_metronic/layout/MasterInit";
import { ConnnectedGoogleAuthCheck } from "../modules/auth/ConnectedGoogleAuthCheck";

const Routes: FC = () => {
  // @ts-ignore
  // to reload the component to navigate to dashbaord
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.isAuthorized
  ) as boolean;
  const accessToken = sessionStorage.getItem("accessToken");

  return (
    <>
      <Switch>
        <Route exact path="/authcode" component={ConnnectedGoogleAuthCheck} />
        {!accessToken ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />
      </Switch>
      <MasterInit />
    </>
  );
};

export { Routes };
