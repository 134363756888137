import { Action } from "redux";
import { ProductType, ProductsFilterType } from "../types/ProductsResponseType";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export interface productInitState {
  productsList: any[];
  productDetails: ProductType;
  selfProductDetails: ProductType;
  storeProgress: any;
  filters: ProductsFilterType;
}

const initialState: productInitState = {
  productsList: [],
  productDetails: {} as ProductType,
  selfProductDetails: {} as ProductType,
  storeProgress: 0,
  filters: {} as ProductsFilterType,
};

export const actionTypes = {
  SET_PRODUCT_LIST: "SET_PRODUCT_LIST",
  SET_PRODUCT_DETAILS: "SET_PRODUCT_DETAILS",
  SET_SELF_PRODUCT_DETAILS: "SET_SELF_PRODUCT_DETAILS",
  SET_PRODUCT_FILTERS: "SET_PRODUCT_FILTERS",
};

export const ProductReducer = persistReducer(
  { storage, key: "server", whitelist: ["serverDetails", "storeProgress"] },
  // @ts-ignore
  (state: any = initialState, action: ActionWithPayload<partnerInitState>) => {
    switch (action.type) {
      case actionTypes.SET_PRODUCT_LIST: {
        const partners = action.payload || [];
        return { ...state, partnersList: partners };
      }
      case actionTypes.SET_PRODUCT_DETAILS: {
        const partnerDetails = action.payload || {};
        return { ...state, partnerDetails };
      }
      case actionTypes.SET_SELF_PRODUCT_DETAILS: {
        const partnerDetails = action.payload || {};
        return { ...state, selfPartnerDetails: partnerDetails };
      }

      case actionTypes.SET_PRODUCT_FILTERS: {
        const filters = action.payload || {};
        return { ...state, filters };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setPartnersList: (partnersList: ProductType[]) => ({
    type: actionTypes.SET_PRODUCT_LIST,
    payload: partnersList,
  }),
  setPartnerDetails: (partnerDetails: ProductType) => ({
    type: actionTypes.SET_PRODUCT_DETAILS,
    payload: partnerDetails,
  }),
  setSelfPartnerDetails: (partnerDetails: ProductType) => ({
    type: actionTypes.SET_SELF_PRODUCT_DETAILS,
    payload: partnerDetails,
  }),

  setFilters: (filters: ProductsFilterType) => ({
    type: actionTypes.SET_PRODUCT_FILTERS,
    payload: filters,
  }),
};
