import clsx from 'clsx'
import React, {FC} from 'react'

// scss
import './ActionsDropdown.scss'

// images
import MenuIcon from '../../../_metronic/assets/icons/menu.svg'

// custom
import {SVGICON} from '../../../_metronic/helpers'

interface DropdownOption {
  name: string
  onClick: () => void
  className?: string
}

interface Props {
  options: Array<DropdownOption | null>
}

const ActionsDropdown: FC<Props> = ({options}) => {
  return (
    <>
      <button
        type='button'
        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-4 dropdown-toggl'
        id='dropdownMenuButton1'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <SVGICON src={MenuIcon} />
      </button>
      <div
        className='dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4 custom-dropdown-transform'
        aria-labelledby='dropdownMenuButton1'
      >
        {options.map((option, i) => {
          if (option)
            return (
              <div
                key={i}
                className='menu-item px-3'
                onClick={() => {
                  option.onClick?.()
                }}
              >
                <span className={`menu-link px-3 ${option.className || ''}`}>{option.name}</span>
              </div>
            )
        })}
        {/* <div
          className='menu-item px-3'
          onClick={() => {
            onEdituserClick()
          }}
        >
          <span className='menu-link px-3'>Edit</span>
        </div>
        {loggedUser?.id !== userDetails?.id && (
          <div
            className='menu-item px-3'
            onClick={() => {
              showDeleteConfirmModal(
                'Are you sure you want to deletethis user?',
                deleteSelectedUser
              )
            }}
          >
            <span className='menu-link px-3 text-danger'>Delete</span>
          </div> */}
        {/* )} */}
      </div>
    </>
  )
}

export default ActionsDropdown
