import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";

// custom

// import Constants from '../../../config/Constants'
// import DropDown from '../../../sharedComponents/dropdown/Dropdown'
import { SuccessAlert } from "../../../sharedComponents/Alert";
// import {useDispatch} from 'react-redux'
// import {useHistory} from 'react-router'
import { useIntl } from "react-intl";
import {
  addIndustryGroup,
  updateIndustryGroup,
} from "../redux/PartnersMasterdataAPI";
import { IndustryGroupType } from "../types/getIndustryGroupsResponseType";

interface ModalProps {
  show: boolean;
  type: "edit" | "new";
  industryGroup: IndustryGroupType | undefined;
}

interface Props {
  modalProps: ModalProps;
  closeModal: () => void;
  getIndustryGroupsAPI: () => void;
}

const AddNewIndustryGroupModal: FC<Props> = ({
  modalProps,
  closeModal,
  getIndustryGroupsAPI,
}) => {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const intl = useIntl();

  const { show, type, industryGroup } = modalProps;

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    name: type === "edit" ? industryGroup?.name || "" : "",
  };

  const AddNewIndustryGroupSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: "PARTNERS_ADD_NEW_INDUSTRY_GROUP_NAME_VALIDATION",
      })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewIndustryGroupSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "edit" && industryGroup?.id) {
        setLoading(true);
        updateIndustryGroup(industryGroup.id, values.name)
          .then(() => {
            closeModal();
            SuccessAlert(
              intl.formatMessage({
                id: "PARTNERS_INDUSTRY_GROUP_UPDATE_SUCCESS_MESSAGE",
              }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((err: any) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "PARTNERS_INDUSTRY_GROUP_UPDATE_ERROR_MESSAGE",
                  })
                );
          })
          .finally(() => {
            setLoading(false);
            getIndustryGroupsAPI();
          });
      } else {
        setLoading(true);
        addIndustryGroup(values.name)
          .then(() => {
            closeModal();
            SuccessAlert(
              intl.formatMessage({
                id: "INDUSTRIES_GROUPS_ADD_SUCCESS_MESSAGE",
              }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((err: any) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "INDUSTRIES_GROUPS_ADD_FAILURE_MESSAGE",
                  })
                );
          })
          .finally(() => {
            setLoading(false);
            getIndustryGroupsAPI();
          });
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{"New group"}</Modal.Title>
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_post_basic_form"
      >
        <Modal.Body>
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {type === "new" && (
              <p className="text-muted fs-5 fw-bold mb-10">
                {intl.formatMessage({
                  id: "PARTNERS_INDUSTRY_GROUP_MODAL_MESSAGE",
                })}
              </p>
            )}

            {/* Name */}
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span className="required">
                {intl.formatMessage({
                  id: "PARTNERS_INDUSTRY_GROUP_NAME_LABEL_TITLE",
                })}
              </span>
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("name")}
              autoComplete="off"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger">
                <span role="alert">{formik.errors.name}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "FORM_VALIDATION_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddNewIndustryGroupModal;
