import axios from "axios";
import { SystemMessageResponseType } from "../types/SystemMessagesResponseType";
import { GenericResponseType } from "../../../types/GenericReponse";
import { CreateNewGroupResponseType } from "../types/CreateNewGroupResponseType";
import { sortGroupsParams } from "../types/request";

export function getSystemMessages() {
  return axios.get<SystemMessageResponseType>(`/systemWorkflowMessages`);
}

export function createSystemMessages(workflow: string, message: string,is_default?:number) {
  return axios.post(`/systemWorkflowMessages`, {
    workflow,
    message,
    ...(is_default !== undefined && { is_default })
  });
}

export function isDefault(id:number,is_default?:number){
  return axios.post(`/systemWorkflowMessages/isDefault/${id}`,{
    ...(is_default !== undefined && {is_default})
  })
}

export function updateSystemMesasge(
  id: number,
  workflow: string,
  message: string,
  is_default?: number
) {
  return axios.post(`/update/systemWorkflowMessages/${id}`, {
    workflow,
    message,
    ...(is_default !== undefined && {is_default})
  });
}

export function deleteSystemMessage(id: number) {
  return axios.delete<GenericResponseType>(`/systemWorkflowMessages/${id}`);
}

export function deleteMultipleSystemMessages(workflow_id: number[]) {
  return axios.post(`/systemWorkflowMessages/massDelete`, {
    workflow_id,
  });
}

export function sortSystemMessages(workflow_id: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/systemWorkflowMessages/sorting`, {
    workflow_id,
  });
}
