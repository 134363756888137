/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// import backGround from "../../../_metronic/assets/icons/login.png";

// custom
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import { useSelector } from "react-redux";
// import { RootState } from "../../../setup";
// import LegalTextModal from "../../../_metronic/layout/components/LegalTextModal";

// import sha256 from 'crypto-js/sha256'
// import hmacSHA512 from 'crypto-js/hmac-sha512'
// import Base64 from 'crypto-js/enc-base64'
// import CryptoJS from 'crypto-js'
// import config from '../../config'
// import axios from 'axios'
// import {CodeVerification} from './components/CodeVerification'
// import {NewPassword} from './components/NewPassword'
import { EmailSuccess } from "./components/EmailSuccess";
import CodeVerificationWrapper from "./components/CodeVerificationWrapper";

export function AuthPage() {
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage:
          "url('../../../_metronic/assets/icons/authBackground.png')",
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 bodyBackground">
        <div className="mb-8">
          <img
            alt=""
            src="/media/logos/solarware.svg"
            style={{ width: "auto", height: "120px" }}
          />
        </div>
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/registration" component={Registration} />
            <Route
              path="/forgot-password/:email"
              component={CodeVerificationWrapper}
            />
            <Route path="/forgot-password" component={ForgotPassword} />
            {/* <Route path='/code-verification' component={CodeVerification} /> */}
            <Route path={"/email-success"} component={EmailSuccess} />
            <Redirect to="/login" />
          </Switch>
        </div>
      </div>
    </div>
  );
}
