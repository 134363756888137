import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

// custom
import Store from "../redux/Store";
import config from "../../app/config";
import { actions } from "../../app/modules/auth";
import { errorAlert } from "../../app/sharedComponents/Alert";
import Constants from "../../app/config/Constants";

const clearStorage = () => {
  sessionStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window?.location?.replace("/login");
  setTimeout(() => {
    Store.dispatch(actions.logout());
  }, 0);
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const refresh_token = () => {
  const axiosInstance = axios.create();
  // const google_id = sessionStorage.getItem("gId") || "";
  // const user_id = sessionStorage.getItem("uId") || "";

  // if (google_id && user_id) {
  //   return axiosInstance.post(
  //     `${config.API_URL}/googles/refreshToken/${google_id}/${user_id}`
  //   );
  // }

  const clientDetails_id = sessionStorage.getItem("clientDetails_id") || "";
  const clientDetails_secret =
    sessionStorage.getItem("clientDetails_secret") || "";
  const refreshToken = localStorage.getItem("refreshToken") || "";
  return axiosInstance.post(`${config.API_URL}/client/refreshToken`, {
    client_id: clientDetails_id,
    client_secret: clientDetails_secret,
    refresh_token: refreshToken,
    grant_type: "refresh_token",
  });
};

export default function setupAxios(axios: any, store: any) {
  let isRefreshing = false;
  let pendingQueue: AxiosRequestConfig[] = [];

  // @ts-ignore
  const processQueue = (error) => {
    pendingQueue.forEach((prom) => {
      if (error) {
        // @ts-ignore
        prom?.reject(error);
      } else {
        // @ts-ignore
        prom?.resolve();
      }
    });

    pendingQueue = [];
  };

  axios.defaults.baseURL = config.API_URL;
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: any) => {
      const accessToken = sessionStorage.getItem("accessToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    function (response: AxiosResponse) {
      if (response?.status === 210) {
        return Promise.reject({
          code: 210,
          response: response,
        });
      }
      return response;
    },
    async function (error: AxiosError) {
      const originalRequest = error.config;
      // @ts-ignore
      if (error.response?.status === 450) {
        const window_location = window?.location?.pathname || "";
        const API_URL = originalRequest?.url || "";
        Constants.unauthorizedErrorpopupAllowedRoutes.map((allowedRoute) => {
          if (
            window_location.includes(allowedRoute.route) &&
            API_URL.includes(allowedRoute.apiUrl)
          ) {
            errorAlert("You are not authorized to perform this operation!");
          }
          // return allowedRoute;
        });
        return Promise.reject(error);
      }

      // @ts-ignore
      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            // @ts-ignore
            pendingQueue.push({ resolve, reject });
          })
            .then(() => {
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          isRefreshing = true;
          try {
            let res = await refresh_token();
            if (res.data.access_token && res.data.refresh_token) {
              sessionStorage.setItem("accessToken", res.data.access_token);
              localStorage.setItem("refreshToken", res.data.refresh_token);
              isRefreshing = false;
              processQueue(null);
              return axios(originalRequest);
            } else {
              source.cancel();
              clearStorage();
            }
          } catch (e) {
            source.cancel();
            clearStorage();
          }
          isRefreshing = false;
        }
      }
    }
  );
}
