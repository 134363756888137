import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import { CodeVerification } from './CodeVerification'
import { NewPassword } from './NewPassword'

function CodeVerificationWrapper() {
    const [codeVerified, SetCodeVerified] = useState(false);
    // @ts-ignore
    const { email } = useParams();
    const submitted = () => {
            SetCodeVerified(true)
        }

  return (
        codeVerified ? <NewPassword email={email}/> : <CodeVerification email={email} submitted={submitted} /> 
  )
}

export default CodeVerificationWrapper