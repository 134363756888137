import axios from 'axios'

// custom
import {CountriesListResponseType} from '../types/GetCountriesListResponseTypes'
import {StatesListResponseType} from '../types/GetStatesListResponseType'

// get Countries list
export function getCountriesList() {
  return axios.get<CountriesListResponseType>('/client/countries')
}

// get States list
export function getStatesList(id: number) {
  return axios.get<StatesListResponseType>(`/client/states/${id}`)
}
