import { FC } from "react";

const CustomerActivity: FC = () => {
  return (
    <div>
      <p>No Data Available</p>
    </div>
  );
};

export default CustomerActivity;
