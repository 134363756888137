import { Action } from "@reduxjs/toolkit";
import { PipelineType } from "../types/PipelinesResponseType";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_PPIPELINES_LIST: "SET_PPIPELINES_LIST",
  SET_PIPELINE_DETAILS: "SET_PIPELINE_DETAILS",
};

export interface PipelinesInitState {
  pipelinesList: PipelineType[];
  pipelineDetails: PipelineType;
}

const initialState: PipelinesInitState = {
  pipelinesList: [],
  pipelineDetails: {
    id: 0,
    name: "",
    display_order: 0,
    phases: [],
    created_at: null,
    updated_at: null,
    condition: "",
    transition_type: "",
  },
};

export const PipelineReducer = (
  state = initialState,
  action: ActionWithPayload<PipelinesInitState>
) => {
  switch (action.type) {
    case actionTypes.SET_PPIPELINES_LIST: {
      const pipelines = action.payload?.pipelinesList || [];
      return { ...state, pipelinesList: pipelines };
    }
    case actionTypes.SET_PIPELINE_DETAILS: {
      const pipelineDetails = action.payload?.pipelineDetails || [];
      return { ...state, pipelineDetails: pipelineDetails };
    }

    default:
      return state;
  }
};

export const actions = {
  setPipelinesList: (pipelinesList: PipelineType[]) => ({
    type: actionTypes.SET_PPIPELINES_LIST,
    payload: { pipelinesList },
  }),
  setPipelineDetails: (pipelineDetails: PipelineType) => ({
    type: actionTypes.SET_PIPELINE_DETAILS,
    payload: { pipelineDetails },
  }),
};
