/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Tooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";

import { useLang } from "../../../_metronic/i18n/Metronici18n";

import "./Tasks.scss";
import "react-datepicker/dist/react-datepicker.css";

import { SVGICON } from "../../../_metronic/helpers";
import PlusIcon from "../../../_metronic/assets/icons/plus.svg";
import CloseIcon from "../../../_metronic/assets/icons/close.svg";
import FilterIcon from "../../../_metronic/assets/icons/filter.svg";
import notFound from "../../../_metronic/assets/icons/notFound.png";
import CallIcon from "../../../_metronic/assets/icons/telephone.svg";
import EmailIcon from "../../../_metronic/assets/icons/envelope.svg";
import OthersIcon from "../../../_metronic/assets/icons/clipboard.svg";

import { RootState } from "../../../setup";
import Constants from "../../config/Constants";

import { errorAlert, SuccessAlert } from "../../sharedComponents/Alert";
import Checkbox from "../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../sharedComponents/Loader/SimpleLoader";
import { useGlobalModalContext } from "../../sharedComponents/modals/ModalContext";
import Pagination from "../../sharedComponents/pagination/Pagination";

import TasksListActionsDropdown from "./components/TasksListActionDropDown";
import AddTaskModal from "./components/AddTaskModal";
import ProductsFilterModal from "./components/ProductsFilterModal";

import { PaginationType } from "./types/getPartnersListResponseType";
import { GroupType } from "../masterdata/types/GetGroupsListResponseType";
import {
  ProductsFilterType,
  ProductType,
  TaskType,
} from "./types/TasksResponseType";

import { deleteMultipleTasks, getProducts, getTasks } from "./redux/tasksAPI";
import { actions } from "./redux/tasksRedux";
import Tabs from "../../sharedComponents/tabs/Tabs";

interface Props {
  deals_id: number;
  type?: string;
  onSelectProductss?: (products: TaskType[]) => void;
  category?: GroupType[];
}

export interface TaskModalProps {
  deals_id: number;
  show: boolean;
  action?: string;
  taskDetails?: TaskType;
}

export interface ModalComponentProps {
  modalProps: TaskModalProps;
  closeModal: () => void;
  getAllTasksAPI: () => void;
  activeTab: string;
}

const TasksList: FC<Props> = ({
  deals_id,
  type,
  onSelectProductss,
  category,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  // selectors
  const filters = useSelector<RootState>(
    ({ products }) => products.filters
  ) as ProductsFilterType;

  // state
  const [productsList, setProductsList] = useState<ProductType[]>([]);
  const [checkedProducts, setCheckedProducts] = useState<TaskType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [getProductsAPICompleted, setGetProductsAPICompleted] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showAddTaskModal, setShowAddTaskModal] = useState<TaskModalProps>({
    deals_id: deals_id,
    show: false,
  });
  const [showProductsFilterModal, setShowshowProductsFilterModal] =
    useState<boolean>(false);
  const [paginationDetails, setPaginationDetails] = useState<PaginationType>();
  const [tempSearchText, setTempSearchText] = useState<string>("");
  const [APIloading, setAPILoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [description, setDescription] = useState();

  const local = useLang();
  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [filteredTasks, setFilteredTasks] = useState<TaskType[]>([]);
  const [filterDate, setFilterDate] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false); // State to control visibility of DatePicker

  const FILTER_TABS = JSON.parse(
    intl.formatMessage({ id: "TASKS_FILTER_TABS" })
  ) as any[];

  const getTasksListAPI = () => {
    setLoading(true);
    getTasks(deals_id)
      .then((data) => {
        // console.log(data?.data?.data);
        setTasks(data?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // data API
  const getProductsListAPI = (page?: number) => {
    // fallback to  the current page
    if (page && activePage !== page) {
      setActivePage(page);
    }
    // reset checked items
    setCheckAllItems(false);
    setCheckedProducts([]);

    setGetProductsAPICompleted(false);
    setAPILoading(true);

    getProducts(
      isSelectProductType() ? category?.[0].id : filters.Category,
      filters.Location,
      filters.Article_no,
      filters.productTypes,
      filters.EAN,
      description,
      filters.Instructions,
      itemsPerPage,
      searchText,
      page ? page : activePage
    )
      .then(({ data: { products = [], products_pagination } }) => {
        setProductsList(products);
        setPaginationDetails(products_pagination);
      })
      .finally(() => {
        setAPILoading(false);
        setGetProductsAPICompleted(true);
      });
  };

  // effects
  useEffect(() => {
    // set initial page to 1
    getProductsListAPI(1);
    getTasksListAPI();

    return () => {
      setProductsList([]);
    };
  }, [searchText, filters, itemsPerPage]);

  useEffect(() => {
    // update checked items
    checkedProducts.length === 0 && setCheckAllItems(false);
    checkedProducts.length !== 0 &&
      checkedProducts.length === productsList.length &&
      setCheckAllItems(true);
  }, [checkedProducts]);

  const onPageClick = (page: number) => {
    setActivePage(page);
    getProductsListAPI(page);
  };

  const onSearchTextChange = (text: string) => {
    setTempSearchText(text || "");
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedProducts(tasks);
    } else {
      setCheckedProducts([]);
    }
  };

  const onCheckedChange = (task: TaskType) => {
    let checkedProductCopy = _.clone(checkedProducts);
    const index = checkedProductCopy.findIndex(
      (checkedProduct) => checkedProduct.id === task.id
    );
    if (index > -1) {
      checkedProductCopy.splice(index, 1);
      setCheckAllItems(false);
    } else {
      checkedProductCopy.push(task);
    }
    setCheckedProducts(checkedProductCopy);
  };

  const isChecked = (task: TaskType) => {
    const index = checkedProducts.findIndex(
      (checkedProduct) => checkedProduct.id === task.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteSelectedProducts = () => {
    const ids = checkedProducts.map((checkedProduct) => {
      return checkedProduct.id;
    });
    setDeleteModalLoading(true);
    deleteMultipleTasks(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "PRODUCTS_MASS_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
        setCheckedProducts([]);
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "PRODUCTS_MASS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTasksListAPI();
      });
  };

  const hasFilters = (filters: ProductsFilterType) => {
    if (
      filters.Article_no ||
      filters.Instructions ||
      filters.Category ||
      filters.EAN ||
      filters.productTypes ||
      filters.Location
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (tempSearchText.length === 0) {
      setTempSearchText("");
      setSearchText("");
    }
  }, [tempSearchText]);

  const isSelectProductType = () => {
    return type === "select";
  };

  const isProductDisabled = (product: ProductType) => {
    // check diabled posts

    const index = productsList?.findIndex(
      (disabledId) => product.id === disabledId.id
    );
    if (index !== -1) return true;
    return false;
  };

  const onTabChange = (index: number) => {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index);
      setActivePage(1);
    }
  };

  const getFilteredTasks = () => {
    switch (activeTabIndex) {
      case 1:
        return setFilteredTasks(
          tasks.filter((item) => item.status === "to-do")
        );
      case 2:
        return setFilteredTasks(
          tasks.filter(
            (item) =>
              moment(item.due_date, "YYYY-MM-DD HH:mm:ss").isBefore(moment()) &&
              item.status === "to-do"
          )
        );
      case 3:
        return setFilteredTasks(
          tasks.filter((item) =>
            moment(item.due_date, "YYYY-MM-DD HH:mm:ss").isSame(moment(), "day")
          )
        );
      case 4:
        return setFilteredTasks(
          tasks.filter((item) =>
            moment(item.due_date, "YYYY-MM-DD HH:mm:ss").isSame(
              moment().add(1, "days"),
              "day"
            )
          )
        );
      case 5:
        return setFilteredTasks(
          tasks.filter((item) =>
            moment(item.due_date, "YYYY-MM-DD HH:mm:ss").isSame(
              moment(),
              "week"
            )
          )
        );
      case 6:
        return setFilteredTasks(
          tasks.filter((task) =>
            moment(task.due_date, "YYYY-MM-DD HH:mm:ss").isSame(
              filterDate,
              "day"
            )
          )
        );
      default:
        return setFilteredTasks(tasks);
    }
  };

  useEffect(() => {
    getFilteredTasks();
  }, [activeTabIndex, filterDate, tasks]);
  useEffect(() => {
    getFilteredTasks();
  }, []);

  const TaskItem = (data: { task: TaskType }) => {
    const { task } = data;

    const isDateOverdue = (dateTimeString: string) => {
      return new Date(dateTimeString) < new Date();
    };

    const getTextColor = (status: string) => {
      if (status === "done") {
        return "text-muted text-decoration-line-through";
      } else if (isDateOverdue(task.due_date)) {
        return "text-danger";
      } else if (status === "to-do") {
        return "text-dark";
      }
    };

    const displayAssignees = (task?.assignees || []).slice(0, 4);
    const remainingAssignees =
      (task?.assignees || []).length - displayAssignees.length;
    return (
      <>
        {/* checkbox */}
        <td className="py-0">
          <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
            <Checkbox
              onChange={() => {
                onCheckedChange(task);
              }}
              checked={isChecked(task)}
              disabled={false}
            />
          </div>
        </td>
        {/* Due Date */}
        <td className="py-0">
          {task?.due_date && (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {local === "de"
                ? moment(task?.due_date).format("DD.MM.YYYY HH:mm")
                : moment(task?.due_date).format("YYYY-MM-DD h:mm A")}
            </span>
          )}
        </td>
        {/* Task Name */}
        <td className="py-0">
          {task?.title && (
            <div className="d-flex align-items-center">
              <SVGICON
                src={
                  task?.type === "call"
                    ? CallIcon
                    : task?.type === "email"
                      ? EmailIcon
                      : task?.type === "other"
                        ? OthersIcon
                        : null
                }
                className="svg-icon-5 me-2"
              />
              <span
                className={clsx("fs-6 fw-bold", getTextColor(task?.status))}
              >
                {task?.title}
              </span>
            </div>
          )}
        </td>
        {/* Deal Name */}
        <td className="py-0">
          {task?.deal_details?.name && (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal_details?.name}
            </span>
          )}
        </td>
        {/* Deal Contact/Organisation Name  */}
        <td className="py-0">
          {(task?.deal_details?.contacts?.firstname ||
            task?.deal_details?.contacts?.lastname) && (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal_details?.contacts?.firstname}{" "}
              {task?.deal_details?.contacts?.lastname}
            </span>
          )}
          {(task?.deal_details?.partners?.firstname ||
            task?.deal_details?.partners?.lastname) && (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal_details?.partners?.firstname}{" "}
              {task?.deal_details?.partners?.lastname}
            </span>
          )}
        </td>
        {/* Deal Contact/Organisation Phone */}
        <td className="py-0">
          {task?.deal_details?.contacts?.telephone && (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal_details?.contacts?.telephone}
            </span>
          )}
          {task?.deal_details?.partners?.telephone && (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal_details?.partners?.telephone}
            </span>
          )}
        </td>
        {/* Responsible Persons */}
        <td className="py-0">
          <div className="symbol-group symbol-hover flex-nowrap">
            {task?.assignees &&
              task?.assignees?.length > 0 &&
              task?.assignees?.slice(0, 4).map((data, i) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      className={`symbol symbol-35px symbol-circle toolip_container_${data.user_id}`}
                      style={{ position: "relative" }}
                      onMouseOver={() => {
                        const tooltipEle = document.getElementById(
                          `role-user-tooltip-${data.firstname}${i}`
                        );
                        if (tooltipEle) {
                          tooltipEle.style.display = "flex";
                        }
                      }}
                      onMouseOut={() => {
                        const tooltipEle = document.getElementById(
                          `role-user-tooltip-${data.firstname}${i}`
                        );
                        if (tooltipEle) {
                          tooltipEle.style.display = "none";
                        }
                      }}
                    >
                      <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                        {data.firstname[0]}
                      </span>
                    </div>
                    <Tooltip anchorSelect={`.toolip_container_${data.user_id}`}>
                      {data?.firstname ? (
                        <div>
                          {(data?.firstname ? data.firstname : "") +
                            " " +
                            (data?.lastname ? data.lastname : "")}
                        </div>
                      ) : (
                        <div>{data?.email}</div>
                      )}
                    </Tooltip>
                  </React.Fragment>
                );
              })}
            {remainingAssignees > 0 && (
              <div className="d-flex align-items-center mt-3  text-primary fw-bold">
                <span className="me-2"></span>
                <em
                  role={"button"}
                  // onClick={() => {
                  //   setShowAllUsersModal({
                  //     show: true,
                  //     team: team,
                  //   });
                  //   onHideModal?.(true);
                  // }}
                >
                  {`...  `}
                  {`and ${remainingAssignees} more`}
                </em>
              </div>
            )}
          </div>
        </td>

        {/* actions */}
        <td>
          <TasksListActionsDropdown
            taskDetails={task}
            getTasksListAPI={getTasksListAPI}
            setShowAddTaskModal={() => {
              setShowAddTaskModal((prev) => ({
                ...prev,
                show: true,
                action: "edit",
                taskDetails: task,
              }));
            }}
          />
        </td>
      </>
    );
  };

  return (
    <div className="card border-0">
      {showAddTaskModal.show && (
        <AddTaskModal
          modalProps={showAddTaskModal}
          closeModal={() => {
            setShowAddTaskModal((prev) => ({
              ...prev,
              show: false,
              action: "",
              taskDetails: undefined,
            }));
          }}
          getTasksListAPI={getTasksListAPI}
        />
      )}
      {showProductsFilterModal && (
        <ProductsFilterModal
          show={showProductsFilterModal}
          getProductDetailsAPI={getProductsListAPI}
          closeModal={() => setShowshowProductsFilterModal(false)}
          loading={loading}
        />
      )}
      {/* begin::Header */}
      <div className="card-header d-block border-0 pt-8 mb-2 px-0">
        <div className="d-flex justify-content-end align-items-center">
          {/* <span className="me-4">{filterDate ? filterDate : "None"}</span> */}
          {hasFilters(filters) && (
            <>
              {Object.keys(filters).map((key, i) => {
                if (
                  // @ts-ignore
                  filters[key] !== null
                ) {
                  return (
                    <button
                      key={i}
                      id="filter"
                      className={clsx(
                        "btn btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2"
                      )}
                      style={{ borderRadius: "20px" }}
                      onClick={() => {
                        const filtersCopy = { ...filters };
                        // @ts-ignore
                        filtersCopy[key] = null;
                        dispatch(actions.setFilters(filtersCopy));
                      }}
                    >
                      <SVGICON src={CloseIcon} className="svg-icon-3" />
                      {key}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </>
          )}

          {isSelectProductType() && category && (
            <div>
              <button
                id="filter"
                className={clsx(
                  "btn   btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2"
                )}
                style={{ borderRadius: "20px" }}
              >
                <SVGICON src={CloseIcon} className="svg-icon-3" />
                {category[0].name}
              </button>
            </div>
          )}

          {checkedProducts.length > 0 ? (
            <>
              {isSelectProductType() ? (
                <>
                  <button
                    className="btn  btn-primary"
                    onClick={() => {
                      onSelectProductss?.(checkedProducts);
                    }}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        {intl.formatMessage({
                          id: "APPLY_BUTTON",
                        })}
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({
                          id: "EVENTS_FOLLOW_UP_ADD_LOADING",
                        })}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </>
              ) : (
                <>
                  <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                    {checkedProducts.length}{" "}
                    {intl.formatMessage({ id: "PARTNERS_LIST_SELECTED" })}
                  </span>
                  <button
                    className={"btn btn-danger"}
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "PRODUCTS_DELETE_POST_CONFIRM_MESSAGE",
                        }),
                        deleteSelectedProducts
                      );
                    }}
                  >
                    {intl.formatMessage({
                      id: "PARTNERS_LIST_DELETE_SELECTED",
                    })}
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              {/* add new task */}
              {isSelectProductType() ? null : (
                <button
                  type="button"
                  className="btn btn-primary me-4 px-4"
                  onClick={() => {
                    setShowAddTaskModal((prev) => ({
                      ...prev,
                      show: true,
                      action: "new",
                    }));
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  <span className="align-middle">
                    {intl.formatMessage({ id: "TASKS_ADD_NEW_TASK" })}
                  </span>
                </button>
              )}
              <button
                type="button"
                className="btn btn-light px-4"
                onClick={() => {
                  setShowshowProductsFilterModal(true);
                }}
              >
                <SVGICON src={FilterIcon} className="svg-icon-2 me-0" />
                {/* {intl.formatMessage({ id: "FILTER_BUTTON" })} */}
              </button>
            </>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <div className="border-bottom border-1 w-100 d-flex justify-content-end p-5 pb-0 mt-5">
            <Tabs
              className="justify-content-end"
              activeIndex={activeTabIndex}
              onActiveIndexChange={(index) => {
                if (index !== 6) {
                  onTabChange(index);
                }
              }}
              tabs={[
                ...FILTER_TABS.map((item) => item.name).slice(0, 6),
                <DatePicker
                  name="filter_date"
                  selected={filterDate ? new Date(filterDate) : null}
                  onChange={(val) => {
                    setFilterDate(moment(val).format("YYYY-MM-DD"));
                    setActiveTabIndex(6);
                    setIsOpen(false);
                  }}
                  customInput={<span>Select Date</span>}
                />,
                // <div
                //   onMouseEnter={() => setIsOpen(true)}
                //   // onClick={() => setIsOpen(true)}
                //   onMouseLeave={() => setIsOpen(false)}
                //   style={{
                //     display: "inline-block",
                //     position: "relative",
                //     cursor: "pointer",
                //   }}
                // >
                //   <span>Select Date</span>
                //   {isOpen && (
                //     <div
                //       style={{
                //         // position: "fixed",
                //         // top: "100%",
                //         position: "absolute",
                //         top: 0,
                //         right: 0,
                //         zIndex: 999,
                //       }}
                //     >
                //       <DatePicker
                //         name="filter_date"
                //         selected={filterDate ? new Date(filterDate) : null}
                //         onChange={(val) => {
                //           setFilterDate(moment(val).format("YYYY-MM-DD"));
                //           setActiveTabIndex(6);
                //           setIsOpen(false);
                //         }}
                //         inline
                //       />
                //     </div>
                //   )}
                // </div>,
              ]}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-3 px-0">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            {/* begin::Table head */}
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                <th className="w-25px">
                  {isSelectProductType() ? null : (
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <Checkbox
                        onChange={onAllItemsCheckChange}
                        checked={checkAllItems}
                      />
                    </div>
                  )}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "TASKS_COLUMN_DUE_DATE" })}
                </th>
                {/* <th className='min-w-125px'>{intl.formatMessage({id: 'PRODUCTS_ID_NAME'})}</th> */}
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "TASKS_COLUMN_TASK_NAME" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "TASKS_COLUMN_DEAL" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "TASKS_COLUMN_CONTACT" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "TASKS_COLUMN_PHONE" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "TASKS_COLUMN_RESPONSIBLE" })}
                </th>
                <th className="min-w-100px text-end">
                  {intl.formatMessage({ id: "TASKS_COLUMN_ACTIONS" })}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {APIloading ? (
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <SimpleLoader />
                  </td>
                </tr>
              ) : (
                <>
                  {filteredTasks.map((task, i) => (
                    <tr key={i}>
                      <TaskItem task={task} />
                    </tr>
                  ))}
                </>
              )}

              {/* no data */}
              {!loading && filteredTasks.length === 0 && (
                <tr className="odd">
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <img
                        alt="not-found"
                        src={notFound}
                        className="mw-400px"
                      />
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        {intl.formatMessage({
                          id: "TASKS_LIST_NOITEMS_FOUND",
                        })}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>

          {/* end::Table */}
          {productsList.length > 0 && (
            <Pagination
              totalPages={paginationDetails?.number_of_pages || 1}
              activePage={activePage}
              onPageClick={onPageClick}
              showItemsPerPage
              itemsPerPage={itemsPerPage}
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
                setActivePage(1);
              }}
            />
          )}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  );
};

export default TasksList;
