/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { SuccessAlert } from "../../../sharedComponents/Alert";
import { newPassword } from "../redux/AuthCRUD";

const newPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("This field is required"),
  retypePassword: Yup.string()
    .required("This field is required")
    .when("newPassword", {
      is: (val: string | any[]) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both password need to be the same"
      ),
    }),
});

const initialValues = {
  newPassword: "",
  retypePassword: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export interface NewPasswordProps {
  email: string;
}

export function NewPassword({ email }: NewPasswordProps) {
  const intl = useIntl();
  const history = useHistory();

  // state
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        newPassword(email, values.newPassword, values.retypePassword)
          .then(() => {
            setLoading(false);
            SuccessAlert("Password is reset successfully");
            history.push(`/login`);
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus("The login detail is incorrect");
          });
      }, 1000);
    },
  });
  //   console.log(email);

  return (
    <div>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_new_password_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "AUTH_NEW_PASSWORD_TITLE" })}
          </h1>
          {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
        </div>
        {/* begin::Heading */}

        {formik.status && (
          <div className="mb-10 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">
            {intl.formatMessage({ id: "AUTH_NEW_PASSWORD_NEW_PASSWORD" })}
          </label>
          <input
            {...formik.getFieldProps("newPassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              // {'is-invalid': formik.touched.email && formik.errors.email},
              // {
              //   'is-valid': formik.touched.email && !formik.errors.email,
              // }
            )}
            type="password"
            name="newPassword"
            autoComplete="off"
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className="text-danger">
              <span role="alert">{formik.errors.newPassword}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <div className="d-flex justify-content-between mt-n5">
            <div className="d-flex flex-stack mb-2">
              {/* begin::Label */}
              <label className="form-label fw-bolder text-dark fs-6 mb-0">
                {intl.formatMessage({
                  id: "AUTH_NEW_PASSWORD_CONFIRM_PASSWORD",
                })}
              </label>
              {/* end::Label */}
            </div>
          </div>
          <input
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("retypePassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              // {
              //   'is-invalid': formik.touched.password && formik.errors.password,
              // },
              // {
              //   'is-valid': formik.touched.password && !formik.errors.password,
              // }
            )}
          />
          {formik.touched.retypePassword && formik.errors.retypePassword && (
            <div className="text-danger">
              <span role="alert">{formik.errors.retypePassword}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "AUTH_NEW_PASSWORD_CONTINUE" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "SIGN_IN_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          {/* begin::Separator */}
          {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
          {/* end::Separator */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
          {/* end::Google link */}
        </div>

        {/* end::Action */}
      </form>
    </div>
  );
}
