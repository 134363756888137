import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ProductActivityType,
  ProductType,
} from "../types/ProductsResponseType";
import { getByProductID, getProductActivities } from "../redux/ProductsAPI";
import { SVGICON } from "../../../../_metronic/helpers";
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import ProductStock from "./ProductStock";
import ProductGeneralDetails from "./ProductGeneralDetails";
import ProductTypeSelect from "./ProductTypesSelect";
import ProductAssembleInstructions from "./ProductAssembleInstructions";
import ProductManufactur from "./ProductManufactur";
import ProductActivity from "./ProductActivity";
import ProductDocuments from "./ProductDocuments";

const EditProducts = () => {
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();

  // @ts-ignore
  const productInformation: ProductType = state.productDetails || {};
  // state
  const [product, setProduct] = useState<ProductType>(productInformation);
  const [productActivities, setProductActivities] =
    useState<ProductActivityType[]>();

  const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  useEffect(() => {
    setProduct(productInformation);
  }, [productInformation]);

  const getProductDetailsAPI = () => {
    if (productInformation?.id) {
      getByProductID(productInformation?.id).then(({ data }) => {
        const { productDetails } = data || {};

        setProduct(productDetails);
        history.replace({ state: { productDetails: productDetails } });
      });
    }
  };

  const getProductActivityDetailsAPI = () => {
    getProductActivities(product.id).then(({ data }) => {
      const { product_activities } = data || {};
      setProductActivities(product_activities);
    });
  };

  useEffect(() => {
    if (tabActiveIndex === 1) {
      getProductActivityDetailsAPI();
    }
  }, [tabActiveIndex]);

  return (
    <>
      <div className="card sababab bgi-position-y-center bgi-no-repeat mb-10">
        <div className="p-8 d-flex flex-row align-items-center">
          <div className="me-5">
            <SVGICON
              src={FilemanagerIcon}
              className="svg-icon-primary svg-icon-3x"
            />
          </div>
          <div className="me-5">
            <span className="text-gray-800 text-hover-primary fs-2 fw-bolder ">
              {intl.formatMessage({ id: "PRODUCTS_TITLE" })}
            </span>
          </div>
        </div>
        <div className="px-10 mt-2 ml-10">
          <Tabs
            tabs={[
              intl.formatMessage({ id: "PRODUCTS_TAB1" }),
              intl.formatMessage({ id: "PRODUCTS_TAB2" }),
              intl.formatMessage({ id: "PRODUCTS_TAB3" }),
            ]}
            // {intl.formatMessage({id:'POST.EDIT_POST.POST_TYPE_SECTION.TITLE'})}
            activeIndex={tabActiveIndex}
            onActiveIndexChange={(index) => {
              onTabChange(index);
            }}
          />
        </div>
      </div>
      {tabActiveIndex === 0 && (
        <div className="d-flex flex-column flex-lg-row">
          <div
            className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-500px mb-7 me-lg-10"
            style={{ flex: "0 0 500px" }}
          >
            <ProductStock
              product={product}
              getProductDetailsAPI={getProductDetailsAPI}
            />
            <ProductManufactur
              product={product}
              getProductDetailsAPI={getProductDetailsAPI}
            />
          </div>
          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <ProductGeneralDetails
              product={product}
              getProductDetailsAPI={getProductDetailsAPI}
            />

            <ProductTypeSelect
              product={product}
              getProductDetailsAPI={getProductDetailsAPI}
            />
            <ProductAssembleInstructions
              product={product}
              getProductDetailsAPI={getProductDetailsAPI}
            />
          </div>
        </div>
      )}
      {tabActiveIndex === 1 && (
        <div className="d-flex flex-column flex-row ">
          <div className="d-flex flex-column ">
            <ProductActivity
              product={product}
              getProductDetailsAPI={getProductDetailsAPI}
              activities={productActivities}
              getProductActivityDetailsAPI={getProductActivityDetailsAPI}
            />
          </div>
        </div>
      )}

      {tabActiveIndex === 2 && (
        <div className="d-flex flex-column flex-row ">
          <div className="d-flex flex-column ">
            <ProductDocuments
              product={product}
              getProductDetailsAPI={getProductDetailsAPI}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EditProducts;
