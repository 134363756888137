import { ConnectLinkedInType } from "./../types/getUsersListResponseType";
import { Action } from "@reduxjs/toolkit";

//custom
import {
  connectedAccountType,
  UserType,
} from "../types/getUsersListResponseType";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_USERS_LIST: "SET_USERS_LIST",
  SET_USERS_DETAILS: "SET_USERS_DETAILS",
  SET_USERS_PAGE_DETAILS: "SET_USERS_PAGE_DETAILS",
  SET_CONNECTED_GOOOGLE_DETAILS: "SET_CONNECTED_GOOOGLE_DETAILS",
  SET_CONNECTED_LINKEDIN_DETAILS: "SET_CONNECTED_LINKEDIN_DETAILS",
};

const initialState: UserManagementIState = {
  users: [],
  userDetails: {} as UserType,
  usersPageDetails: {
    activePage: null,
    itemsPerPage: null,
  },
  connectedGoogleAccount: [],
  connectedLinkedInAccount: [],
};

export interface UserManagementIState {
  users: UserType[];
  userDetails: UserType | null;
  usersPageDetails: {
    activePage: null;
    itemsPerPage: null;
  };
  connectedGoogleAccount: connectedAccountType[] | null;
  connectedLinkedInAccount: ConnectLinkedInType[] | null;
}

export const UsermanagementReducer = (
  state: UserManagementIState = initialState,
  action: ActionWithPayload<UserManagementIState>
) => {
  switch (action.type) {
    case actionTypes.SET_USERS_LIST: {
      const users = action.payload;
      return {
        ...state,
        users,
      };
    }
    case actionTypes.SET_USERS_DETAILS: {
      const userDetails = action.payload;
      return {
        ...state,
        userDetails,
      };
    }
    case actionTypes.SET_USERS_PAGE_DETAILS: {
      const usersPageDetails = action.payload;
      return {
        ...state,
        usersPageDetails,
      };
    }
    case actionTypes.SET_CONNECTED_GOOOGLE_DETAILS: {
      const connectedGoogleAccount = action.payload;
      return {
        ...state,
        connectedGoogleAccount,
      };
    }
    case actionTypes.SET_CONNECTED_LINKEDIN_DETAILS: {
      const connectedLinkedInAccount = action.payload;
      return {
        ...state,
        connectedLinkedInAccount,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setUsersList: (users: UserType[]) => ({
    type: actionTypes.SET_USERS_LIST,
    payload: users,
  }),
  setUserDetails: (userDetails: UserType) => ({
    type: actionTypes.SET_USERS_DETAILS,
    payload: userDetails,
  }),
  setUsersPageDetails: (usersPageDetails: any) => ({
    type: actionTypes.SET_USERS_PAGE_DETAILS,
    payload: usersPageDetails,
  }),
  setGoogleAccount: (connectedGoogleAccount: connectedAccountType[]) => ({
    type: actionTypes.SET_CONNECTED_GOOOGLE_DETAILS,
    payload: connectedGoogleAccount,
  }),
  setLinkedInAccount: (connectedLinkedInAccount: ConnectLinkedInType[]) => ({
    type: actionTypes.SET_CONNECTED_LINKEDIN_DETAILS,
    payload: connectedLinkedInAccount,
  }),
};
