import axios from "axios";
import { ArchiveResponseType } from "../types/ArchivedResponseType";

export function getArchivedDeals() {
  return axios.get<ArchiveResponseType>(`/deals/retrieve`);
}

export function restoreDeal(id: number) {
  return axios.post(`/deals/restore/${id}`);
}

export function forceDeleteDeal(id: number) {
  return axios.post(`/deals/forceDelete/${id}`);
}
