import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface Props {
  show: boolean;
  closeModal: () => void;
  recoveryCodes: string[] | [];
}

const ShowRecoveryCodesModal: FC<Props> = ({
  show,
  closeModal,
  recoveryCodes,
}) => {
  const intl = useIntl();

  const handleCopyClick = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      successToast(
        intl.formatMessage({
          id: "USERS_2FA_RECOVERY_CODE_COPY_SUCCESS_MESSAGE",
        })
      );
      closeModal();
    } catch (e) {
      errorToast(
        intl.formatMessage({ id: "USERS_2FA_RECOVERY_CODE_COPY_ERROR_MESSAGE" })
      );
    }
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "USERS_2FA_RECOVERY_CODES_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="p-4 py-2">
          <div className="pb-7 text-gray-500 fw-bold fs-5">
            {intl.formatMessage({
              id: "USERS_2FA_RECOVERY_CODES_MODAL_COPY_CODE_MESSAGE",
            })}
          </div>
          <div className="d-flex flex-wrap gap-3">
            {recoveryCodes.map((code, index) => {
              return (
                <span
                  onClick={() => {
                    handleCopyClick(code);
                  }}
                  className="bg-light-primary fw-bold px-2 py-1 rounded border border-1 cursor-pointer"
                  key={index}
                >
                  {code}
                </span>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowRecoveryCodesModal;
