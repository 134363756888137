import { FC } from "react";
import { useIntl } from "react-intl";
import DropDown from "../dropdown/Dropdown";

interface NumberItemsPerPageProps {
  itemsPerPage: number;
  setItemsPerPage?: (count: number) => void;
}

const NumberItemsPerPage: FC<NumberItemsPerPageProps> = ({
  itemsPerPage,
  setItemsPerPage,
}) => {
  const intl = useIntl();
  const NUMBER_ITEMS_PER_PAGE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "NUMBER_ITEMS_PER_PAGE_OPTIONS" })
  ) as any[];
  return (
    <div className="d-flex align-items-center">
      <span className="me-2">
        {intl.formatMessage({ id: "PAGINATION_ITEMS_PER_PAGE" })}
      </span>
      <DropDown
        hideSearch
        id="users-titles-dropdown"
        size={"small"}
        items={NUMBER_ITEMS_PER_PAGE_OPTIONS}
        displayFunc={(item) => {
          // @ts-ignore
          return item.value;
        }}
        displayValue={
          NUMBER_ITEMS_PER_PAGE_OPTIONS.find(
            (NUMBER_ITEMS_PER_PAGE_OPTION) =>
              NUMBER_ITEMS_PER_PAGE_OPTION.value === itemsPerPage
          )?.value || ""
        }
        onSelectItem={(item) => {
          // @ts-ignore
          setItemsPerPage?.(item.value);
        }}
        selectedItem={NUMBER_ITEMS_PER_PAGE_OPTIONS.find(
          (NUMBER_ITEMS_PER_PAGE_OPTION) =>
            NUMBER_ITEMS_PER_PAGE_OPTION.value === itemsPerPage
        )}
      />
    </div>
  );
};

export default NumberItemsPerPage;
