import {Action} from '@reduxjs/toolkit'
import {CustomerType, UserType} from '../../auth/types/LoginResponseTypes'
import {CustomersFilterType} from '../types/getCustomersListResponseType'

//custom

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SET_CUSTOMERS_LIST: 'SET_CUSTOMERS_LIST',
  SET_CUSTOMERS_DETAILS: 'SET_CUSTOMERS_DETAILS',
  SET_FILTERS: 'SET_FILTERS',
  SET_CUSTOMER_SEARCH: 'SET_CUSTOMER_SEARCH',
  SET_CUSTOMERS_ACTIVE_PAGE: 'SET_CUSTOMERS_ACTIVE_PAGE',
}

const initialState: UserManagementIState = {
  customers: [],
  customerDetails: {} as CustomerType,
  filters: {} as CustomersFilterType,
  search: '',
  activePage: 1
}

export interface UserManagementIState {
  customers: UserType[]
  customerDetails: CustomerType | null
  filters: CustomersFilterType
  search: string
  activePage: number
}

export const CustomerManagementReducer = (
  state: UserManagementIState = initialState,
  action: ActionWithPayload<UserManagementIState>
) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMERS_LIST: {
      const customers = action.payload
      return {
        ...state,
        customers,
      }
    }
    case actionTypes.SET_CUSTOMERS_DETAILS: {
      const customerDetails = action.payload
      return {
        ...state,
        customerDetails,
      }
    }
    case actionTypes.SET_FILTERS: {
      const filters = action.payload || {}
      return {...state, filters}
    }
    case actionTypes.SET_CUSTOMER_SEARCH: {
      const search = action.payload || ''
      return {...state, search}
    }
    case actionTypes.SET_CUSTOMERS_ACTIVE_PAGE: {
      const activePage = action.payload || 1
      return {...state, activePage}
    }

    default:
      return state
  }
}

export const actions = {
  setCustomersList: (customers: UserType[]) => ({
    type: actionTypes.SET_CUSTOMERS_LIST,
    payload: customers,
  }),
  setCustomerDetails: (customerDetails: CustomerType) => ({
    type: actionTypes.SET_CUSTOMERS_DETAILS,
    payload: customerDetails,
  }),
  setFilters: (filters: CustomersFilterType) => ({
    type: actionTypes.SET_FILTERS,
    payload: filters,
  }),
  setCustomerSearch: (search: string) => ({
    type: actionTypes.SET_CUSTOMER_SEARCH,
    payload: search,
  }),
  setCustomersActivePage: (activePage: number) => ({
    type: actionTypes.SET_CUSTOMERS_ACTIVE_PAGE,
    payload: activePage,
  }),
}
