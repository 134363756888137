import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'

const Content: React.FC = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
    const tooltipElement = document.querySelector(".gantt_tooltip");
    if (tooltipElement) {
      //@ts-ignore
      tooltipElement.parentNode.removeChild(tooltipElement);
    }
  }, [location])

  return (
    <div
      id='kt_content_container'
      style={location.pathname?.includes("device") ?{position: 'relative', marginTop:"60px"} : {position: 'relative'}}
      className={`${clsx(classes.contentContainer.join(' '))} minWidth100 p-0`}
    >
      {children}
    </div>
  )
}

export {Content}
