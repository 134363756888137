import React from "react";
import ReactApexChart from "react-apexcharts";
import { MixedWidget7 } from "../../../../_metronic/partials/widgets";
import { KTSVG } from "../../../../_metronic/helpers";

// class ApexChart extends React.Component {
//   // @ts-ignore
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [70],
//       options: {
//         chart: {
//           height: 350,
//           type: "radialBar",
//         },
//         plotOptions: {
//           radialBar: {
//             hollow: {
//               size: "70%",
//             },
//           },
//         },
//         labels: ["Cricket"],
//       },
//     };
//   }

//   render() {
//     return (
//       <div>
//         <div id="chart">
//           <ReactApexChart
//             // @ts-ignore
//             options={this.state.options}
//             // @ts-ignore
//             series={this.state.series}
//             type="radialBar"
//             height={350}
//           />
//         </div>
//         <div id="html-dist"></div>
//       </div>
//     );
//   }
// }

const ProgressCard = () => {
  return (
    <>
      <MixedWidget7 className="" chartColor="primary" chartHeight="270" />
      {/* <div className="card card-xl-stretch">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">Action Needed</span>
            <span className="text-muted fw-semibold fs-7">
              Complete your profile setup
            </span>
          </h3>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
              // @ts-ignore
              fdprocessedid="mfopj"
            >
              <i className="ki-duotone ki-category fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
            </button>
            <div
              className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
              data-kt-menu="true"
            >
              <div className="px-7 py-5">
                <div className="fs-5 text-gray-900 fw-bolder">
                  Filter Options
                </div>
              </div>
              <div className="separator border-gray-200"></div>
              <div className="px-7 py-5">
                <div className="mb-10">
                  <label className="form-label fw-bold">Status:</label>
                  <div>
                    <select
                      className="form-select form-select-solid"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                    >
                      <option></option>

                      <option
                        value="1"
                        // @ts-ignore
                        selected=""
                      >
                        Approved
                      </option>
                      <option value="2">Pending</option>
                      <option value="3">In Process</option>
                      <option value="4">Rejected</option>
                    </select>
                  </div>
                </div>
                <div className="mb-10">
                  <label className="form-label fw-bold">Member Type:</label>
                  <div className="d-flex">
                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                      />
                      <span className="form-check-label">Author</span>
                    </label>
                    <label className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="2"
                        // @ts-ignore
                        checked=""
                      />
                      <span className="form-check-label">Customer</span>
                    </label>
                  </div>
                </div>
                <div className="mb-10">
                  <label className="form-label fw-bold">Notifications:</label>
                  <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="notifications"
                      value=""
                      // @ts-ignore
                      checked=""
                    />
                    <label className="form-check-label">Enabled</label>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="reset"
                    className="btn btn-sm btn-secondary btn-active-light-primary me-2"
                    data-kt-menu-dismiss="true"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    data-kt-menu-dismiss="true"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body d-flex flex-column">
          <div className="flex-grow-1">
            <div
              className="mixed-widget-4-chart"
              style={{ minHeight: "203.7px" }}
            >
              <div
                id="apexcharts916godmv"
                className="apexcharts-canvas apexcharts916godmv apexcharts-theme-light"
                style={{ width: "250px", height: "203.7px" }}
              >
                <ApexChart />
                {/* <svg
                id="SvgjsSvg1158"
                width="344"
                height="203.70000000000002"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.dev"
                className="apexcharts-svg"
                xmlns:data="ApexChartsNS"
                transform="translate(0, 0)"
                // @ts-ignore
                style="background: transparent"
              >
                <g
                  id="SvgjsG1160"
                  className="apexcharts-inner apexcharts-graphical"
                  transform="translate(72.5, 0)"
                >
                  <defs id="SvgjsDefs1159">
                    <clipPath id="gridRectMask916godmv">
                      <rect
                        id="SvgjsRect1162"
                        width="207"
                        height="225"
                        x="-3"
                        y="-1"
                        rx="0"
                        ry="0"
                        opacity="1"
                        stroke-width="0"
                        stroke="none"
                        stroke-dasharray="0"
                        fill="#fff"
                      ></rect>
                    </clipPath>
                    <clipPath id="forecastMask916godmv"></clipPath>
                    <clipPath id="nonForecastMask916godmv"></clipPath>
                    <clipPath id="gridRectMarkerMask916godmv">
                      <rect
                        id="SvgjsRect1163"
                        width="205"
                        height="227"
                        x="-2"
                        y="-2"
                        rx="0"
                        ry="0"
                        opacity="1"
                        stroke-width="0"
                        stroke="none"
                        stroke-dasharray="0"
                        fill="#fff"
                      ></rect>
                    </clipPath>
                  </defs>
                  <g id="SvgjsG1164" className="apexcharts-radialbar">
                    <g id="SvgjsG1165">
                      <g id="SvgjsG1166" className="apexcharts-tracks">
                        <g
                          id="SvgjsG1167"
                          className="apexcharts-radialbar-track apexcharts-track"
                          // @ts-ignore
                          rel="1"
                        >
                          <path
                            id="apexcharts-radialbarTrack-0"
                            d="M 100.5 30.114024390243898 A 70.3859756097561 70.3859756097561 0 1 1 100.48771532984617 30.114025462283607"
                            fill="none"
                            fill-opacity="1"
                            stroke="rgba(233,243,255,0.85)"
                            stroke-opacity="1"
                            stroke-linecap="round"
                            stroke-width="11.108536585365854"
                            stroke-dasharray="0"
                            className="apexcharts-radialbar-area"
                            // @ts-ignore
                            data:pathOrig="M 100.5 30.114024390243898 A 70.3859756097561 70.3859756097561 0 1 1 100.48771532984617 30.114025462283607"
                          ></path>
                        </g>
                      </g>
                      <g id="SvgjsG1169">
                        <g
                          id="SvgjsG1173"
                          className="apexcharts-series apexcharts-radial-series"
                          // @ts-ignore
                          seriesName="Progress"
                          rel="1"
                          data:realIndex="0"
                        >
                          <path
                            id="SvgjsPath1174"
                            d="M 100.5 30.114024390243898 A 70.3859756097561 70.3859756097561 0 1 1 30.28548108924251 105.4098774595766"
                            fill="none"
                            fill-opacity="0.85"
                            stroke="rgba(27,132,255,0.85)"
                            stroke-opacity="1"
                            stroke-linecap="round"
                            stroke-width="11.108536585365854"
                            stroke-dasharray="0"
                            className="apexcharts-radialbar-area apexcharts-radialbar-slice-0"
                            // @ts-ignore
                            data:angle="266"
                            data:value="74"
                            index="0"
                            j="0"
                            data:pathOrig="M 100.5 30.114024390243898 A 70.3859756097561 70.3859756097561 0 1 1 30.28548108924251 105.4098774595766"
                          ></path>
                        </g>
                        <circle
                          id="SvgjsCircle1170"
                          r="64.83170731707318"
                          cx="100.5"
                          cy="100.5"
                          className="apexcharts-radialbar-hollow"
                          fill="transparent"
                        ></circle>
                        <g
                          id="SvgjsG1171"
                          className="apexcharts-datalabels-group"
                          transform="translate(0, 0) scale(1)"
                          // @ts-ignore
                          style="opacity: 1"
                        >
                          <text
                            id="SvgjsText1172"
                            font-family="inherit"
                            x="100.5"
                            y="112.5"
                            text-anchor="middle"
                            dominant-baseline="auto"
                            font-size="30px"
                            font-weight="700"
                            fill="#4b5675"
                            className="apexcharts-text apexcharts-datalabel-value"
                            // @ts-ignore
                            style="font-family: inherit"
                          >
                            74%
                          </text>
                        </g>
                      </g>
                    </g>
                  </g>
                  <line
                    id="SvgjsLine1175"
                    x1="0"
                    y1="0"
                    x2="201"
                    y2="0"
                    stroke="#b6b6b6"
                    stroke-dasharray="0"
                    stroke-width="1"
                    stroke-linecap="butt"
                    className="apexcharts-ycrosshairs"
                  ></line>
                  <line
                    id="SvgjsLine1176"
                    x1="0"
                    y1="0"
                    x2="201"
                    y2="0"
                    stroke-dasharray="0"
                    stroke-width="0"
                    stroke-linecap="butt"
                    className="apexcharts-ycrosshairs-hidden"
                  ></line>
                </g>
                <g id="SvgjsG1161" className="apexcharts-annotations"></g>
              </svg>
                <div className="apexcharts-legend"></div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <p className="text-center fs-6 pb-5">
              <span className="badge badge-light-danger fs-8">Notes:</span>
              &nbsp; Current sprint requires stakeholders
              <br />
              to approve newly amended policies
            </p>
            <a href="#" className="btn btn-primary w-100 py-3">
              Take Action
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ProgressCard;
