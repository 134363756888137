import clsx from "clsx";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import notFound from "../../../../_metronic/assets/icons/notFound.png";

import _ from "lodash";
// @ts-ignore
import DragSortableList from "react-drag-sortable";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { sortGroupsParams } from "../types/request";
import { FieldType } from "../types/FieldsReponseTypes";
import {
  createField,
  deleteField,
  fieldSort,
  getFieldsList,
  massDeleteFields,
  updateFields,
} from "../redux/FieldsAPI";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { actions } from "../redux/ContentMasterRedux";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { FieldSelectModalProps } from "./SelectFieldsModal";
import { Tooltip } from "react-tooltip";

export interface FieldsModalProps {
  show: boolean;
  type?: string;
  field?: FieldType;
}

interface ModelComponentProps {
  ModalProps: FieldsModalProps;
  closeModal: () => void;
  getFieldsListAPI: () => void;
}

interface SortedObject {
  field: FieldType;
}

export const FieldsModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getFieldsListAPI,
}) => {
  const { show, type, field } = ModalProps;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const MASTERDATA_FIELD_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_FIELD_TYPE_OPTIONS" })
  ) as any[];
  const [optionsFieldsDetails, setOptionsFieldsDetails] = useState<any[]>([]);
  const [optionsFields, setOptionsFields] = useState([true]);
  const [additionalFieldError, setAdditionalFieldError] = useState({});
  const initialValues = {
    fieldName: type === "edit" ? field?.name : "",
    fieldType: type === "edit" ? field?.type : "",
    is_fixed: type === "edit" ? field?.is_fixed : "",
    fieldPlaceholder: type === "edit" ? field?.placeholder : "",
    enable_tooltip: type === "edit" ? field?.enable_tooltip : "",
    tooltipInformation: type === "edit" ? field?.tooltip : "",
    options: [],
  };

  const AddNewFieldsSchema = Yup.object().shape({
    fieldName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "MASTERDATA_FIELDS_NAME_REQUIRED" })),
    fieldType: Yup.string().required(
      intl.formatMessage({ id: "MASTERDATA_FIELDS_TYPE_REQUIRED" })
    ),
  });

  useEffect(() => {
    const parsedData = field?.options || [];
    setOptionsFieldsDetails(parsedData);
    let arr = [];
    // @ts-ignore
    for (let i = 0; i <= parsedData?.length; i++) {
      if (parsedData?.[i]) {
        arr.push(true);
      } else {
        if (field?.options === null || field?.options === undefined) {
          arr.push(true);
        }
      }
    }
    setOptionsFields(arr);

    return () => {
      setOptionsFieldsDetails([]);
      setOptionsFields([true]);
      setAdditionalFieldError({});
    };
  }, [type]);

  const formik = useFormik({
    initialValues,
    // validationSchema: AddNewFieldsSchema,
    validate: (values) => {
      let errors = {};
      const additionals =
        optionsFieldsDetails &&
        optionsFieldsDetails.length > 0 &&
        optionsFieldsDetails.filter((data) => {
          if (data.name && data.name.length > 0) {
            return data;
          } else {
            return false;
          }
        });
      if (
        (values.fieldType === "radio" ||
          values.fieldType === "checkbox" ||
          values.fieldType === "dropdown") &&
        !additionals
      ) {
        // @ts-ignore
        errors.options =
          "Atleast one option name should be entered for the selected field type.";
      }
      if (!values.fieldName) {
        // @ts-ignore
        errors.fieldName = intl.formatMessage({
          id: "MASTERDATA_FIELDS_NAME_REQUIRED",
        });
      } else if (values.fieldName && values.fieldName.length > 59) {
        // @ts-ignore
        errors.fieldName = intl.formatMessage({
          id: "MASTERDATA_60SYMBOLS_REQUIRED",
        });
      }
      if (!values.fieldType) {
        // @ts-ignore
        errors.fieldType = intl.formatMessage({
          id: "MASTERDATA_FIELDS_TYPE_REQUIRED",
        });
      }
      if (values.enable_tooltip && !values.tooltipInformation) {
        // @ts-ignore
        errors.tooltipInformation = intl.formatMessage({
          id: "MASTERDATA_TEXTAREA_FIELD_REQUIRED",
        });
      }
      return errors;
    },
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const additionals =
        optionsFieldsDetails &&
        optionsFieldsDetails.length > 0 &&
        optionsFieldsDetails.filter((data) => {
          if (data.name && data.name.length > 0) {
            return data;
          } else {
            return false;
          }
        });
      if (formik.isValid) {
        if (type === "new") {
          if (values.fieldName && values.fieldType) {
            setLoading(true);
            createField(
              values.fieldName,
              values.fieldType,
              values.is_fixed ? 1 : 0,
              values.enable_tooltip ? values.tooltipInformation : "",
              values.fieldPlaceholder,
              values.enable_tooltip ? 1 : 0,
              additionals
            )
              .then(() => {
                closeModal();
                SuccessAlert(
                  intl.formatMessage({
                    id: "MASTERDATA_FIELDS_ADD_SUCCESS_MESSAGE",
                  }),
                  () => {},
                  intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
                );
              })
              .catch((err) => {
                err.response?.data?.errors?.name
                  ? setStatus(err.response.data?.errors?.name)
                  : setStatus(
                      intl.formatMessage({
                        id: "MASTERDATA_FIELDS_ADD_FAILURE_MESSAGE",
                      })
                    );
              })
              .finally(() => {
                // recall  get categories list API
                getFieldsListAPI();
                setLoading(false);
              });
          }
        } else {
          if (values.fieldName && values.fieldType) {
            setLoading(true);
            updateFields(
              field?.id,
              values.fieldName,
              values.fieldType,
              values.is_fixed ? 1 : 0,
              values.fieldType === "radio" ||
                values.fieldType === "checkbox" ||
                values.fieldType === "dropdown"
                ? additionals
                : null,
              values.enable_tooltip ? values.tooltipInformation : "",
              values.fieldPlaceholder,
              values.enable_tooltip ? 1 : 0
            )
              .then(() => {
                closeModal();
                SuccessAlert(
                  intl.formatMessage({
                    id: "MASTERDATA_FIELDS_ADD_SUCCESS_MESSAGE",
                  }),
                  () => {},
                  intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
                );
              })
              .catch((err) => {
                err.response?.data?.errors?.name
                  ? setStatus(err.response.data?.errors?.name)
                  : setStatus(
                      intl.formatMessage({
                        id: "MASTERDATA_FIELDS_ADD_FAILURE_MESSAGE",
                      })
                    );
              })
              .finally(() => {
                // recall  get categories list API
                getFieldsListAPI();
                setLoading(false);
              });
          }
        }
      }
    },
  });

  useEffect(() => {
    if (
      formik.values.tooltipInformation &&
      formik.values.tooltipInformation.length > 0
    ) {
      formik.setFieldValue("enable_tooltip", true);
    }
  }, [formik.values.tooltipInformation]);

  useEffect(() => {
    if (type === "edit") {
      if (field?.type !== formik.values.fieldType) {
        setOptionsFieldsDetails([]);
        setOptionsFields([true]);
        setAdditionalFieldError({});
      }
    }
  }, [type, formik.values.fieldType]);

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : "AddNew"}
      dialogClassName={"medium-size-modal"}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_EDIT_FIELDS_TITLE" })
            : intl.formatMessage({
                id: "MASTERDATA_CREATE_NEW_FIELD_TITLE",
              })}
        </Modal.Title>
      </Modal.Header>

      <form
        className="form w-100 overflow-scroll"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body>
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_FIELD_NAME_FIELD_NAME",
                  })}
                </span>
              </label>
              <input
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                autoComplete="off"
                type="text"
                {...formik.getFieldProps("fieldName")}
              />
              {formik.touched.fieldName && formik.errors.fieldName && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.fieldName}</span>
                </div>
              )}
            </div>
            {/* titile */}
            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({ id: "MASTERDATA_FIELD_TYPE" })}
                </span>
              </label>

              <div className="fv-row">
                <DropDown
                  hideSearch
                  id="users-titles-dropdown"
                  items={MASTERDATA_FIELD_TYPE_OPTIONS}
                  displayFunc={(item) => {
                    // @ts-ignore
                    return item.title;
                  }}
                  displayValue={
                    MASTERDATA_FIELD_TYPE_OPTIONS.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.name === formik.values.fieldType
                    )?.title || ""
                  }
                  onSelectItem={(item) => {
                    //@ts-ignore
                    formik.setFieldValue("fieldType", item.name);
                  }}
                  selectedItem={MASTERDATA_FIELD_TYPE_OPTIONS.find(
                    (TITLES_OPTION) =>
                      TITLES_OPTION.id === formik.values.fieldType
                  )}
                />
                {formik.touched.fieldType && formik.errors.fieldType && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.fieldType}</span>
                  </div>
                )}
              </div>
            </div>
            {(formik.values.fieldType === "radio" ||
              formik.values.fieldType === "checkbox" ||
              formik.values.fieldType === "dropdown") && (
              <div className="mb-8">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="required">
                    {intl.formatMessage({ id: "FIELDS_OPTIONS_TITLE" })}
                  </span>
                </label>

                <div
                  style={{
                    height: "auto",
                    maxHeight: "300px",
                    overflow: "scroll",
                  }}
                >
                  {optionsFields &&
                    optionsFields.map((data, index) => {
                      if (data) {
                        return (
                          <div
                            className="d-flex   my-4"
                            role={"button"}
                            key={index}
                          >
                            <div className="flex-1 me-2">
                              <input
                                autoFocus
                                placeholder="name"
                                type="text"
                                autoComplete="off"
                                className={clsx(
                                  "form-control form-control-lg form-control-solid"
                                )}
                                value={
                                  optionsFieldsDetails?.[index]?.name || ""
                                }
                                onChange={(data) => {
                                  const object = {
                                    name: "",
                                  };
                                  const res = [...optionsFieldsDetails];
                                  if (res && res.length > 0) {
                                    if (res[index]) {
                                      object.name = data.target.value;
                                      res[index] = object;
                                    } else {
                                      object.name = data.target.value;
                                      res.push(object);
                                    }
                                  } else {
                                    object.name = data.target.value;
                                    res.push(object);
                                  }

                                  setOptionsFieldsDetails(res);
                                }}
                                onBlur={() => {
                                  if (!optionsFieldsDetails?.[index]?.name) {
                                    setAdditionalFieldError({
                                      ...additionalFieldError,
                                      [`name_${index}`]: true,
                                    });
                                  } else {
                                    setAdditionalFieldError({
                                      ...additionalFieldError,
                                      [`name_${index}`]: false,
                                    });
                                  }
                                }}
                              />
                              {additionalFieldError &&
                                //@ts-ignore
                                additionalFieldError?.[`name_${index}`] && (
                                  <span className="text-danger">
                                    Field is required
                                  </span>
                                )}
                            </div>

                            <div
                              className=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  optionsFields?.filter((data) => data === true)
                                    .length > 1
                                ) {
                                  const data = [...optionsFields];
                                  data[index] = false;
                                  setOptionsFields(data);
                                  const details = [...optionsFieldsDetails];
                                  details.splice(index);
                                  setOptionsFieldsDetails(details);
                                  setAdditionalFieldError({
                                    ...additionalFieldError,
                                    [`name_${index}`]: false,
                                  });
                                }
                              }}
                            >
                              <button
                                className="btn btn-icon btn-light btn-active-light-danger "
                                role="button"
                              >
                                <SVGICON
                                  src={DeleteIcon}
                                  className="svg-icon-2"
                                />
                              </button>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
                {optionsFields.some((data) => data !== true) ||
                optionsFields.some((data) => data !== false) ? (
                  <div
                    className="d-flex form-check-label fw-bold  fs-6 text-primary "
                    role={"button"}
                  >
                    <span
                      onClick={() => {
                        // const index = optionsFields.findIndex(
                        //   (data) => data === false
                        // );
                        const data = [...optionsFields];
                        data[data.length] = true;
                        setOptionsFields(data);
                      }}
                    >
                      {intl.formatMessage({
                        id: "FIELDS_OPTIONS_ADD_OPTIONS_TITLE",
                      })}
                    </span>
                  </div>
                ) : null}
                {formik.touched.options && formik.errors.options && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.options}</span>
                  </div>
                )}
              </div>
            )}

            <div className="mb-8 d-flex align-items-center">
              <label className=" fw-bold fs-6 me-1">
                {intl.formatMessage({
                  id: "FIELDS_IS_FIXED_TITLE",
                })}
              </label>
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <span
                  data-tooltip-id="my-enable-tooltip"
                  data-tooltip-content={intl.formatMessage({
                    id: "MASTERDATA_TOOLTIP_INFO",
                  })}
                  data-tooltip-place="top"
                >
                  <i className="bi bi-info-circle-fill fs-3 svg-icon mx-2"></i>
                </span>

                <Tooltip
                  id="my-enable-tooltip"
                  place="top"
                  style={{
                    backgroundColor: "#f5f8fa",
                    color: "#a1a5b7",
                    zIndex: 99,
                    whiteSpace: "normal",
                    maxWidth: "250px",
                    wordWrap: "break-word",
                  }}
                  border="1px solid #a1a5b7"
                  className="btn text-dark my-2"
                />
              </div>
              <div className="mx-1 mb-2 form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifications"
                  value=""
                  checked={!!formik.values.is_fixed}
                  style={{ width: "50px", height: "25px" }}
                  onChange={(e) => {
                    formik.setFieldValue("is_fixed", e.target.checked);
                  }}
                />
              </div>
            </div>
            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>
                  {intl.formatMessage({
                    id: "MASTERDATA_PLACEHOLDER_FIELD",
                  })}
                </span>
              </label>
              <input
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                autoComplete="off"
                type="text"
                {...formik.getFieldProps("fieldPlaceholder")}
              />
            </div>
            <div className="mb-8 d-flex align-items-center">
              <label className=" fw-bold fs-6 me-4">
                {intl.formatMessage({
                  id: "MASTERDATA_TOOLTIP_FIELD",
                })}
              </label>
              <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifications"
                  value=""
                  checked={!!formik.values.enable_tooltip}
                  style={{ width: "50px", height: "25px" }}
                  onChange={(e) => {
                    formik.setFieldValue("enable_tooltip", e.target.checked);
                  }}
                />
              </div>
            </div>
            {formik.values.enable_tooltip && (
              <div className="mb-8">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="required">
                    {intl.formatMessage({
                      id: "MASTERDATA_TEXTAREA_FIELD",
                    })}
                  </span>
                </label>
                <textarea
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  rows={5}
                  autoComplete="off"
                  {...formik.getFieldProps("tooltipInformation")}
                ></textarea>
                {formik.touched.tooltipInformation &&
                  formik.errors.tooltipInformation && (
                    <div className="text-danger">
                      <span role="alert">
                        {formik.errors.tooltipInformation}
                      </span>
                    </div>
                  )}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "MASTERDATA_CATEGORY_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

interface Props {
  onSelectedFields?: (fields: FieldSelectModalProps[]) => void;
  isSelectionModal?: boolean;
  selectedFields?: any[];
}

const Fields: FC<Props> = ({
  onSelectedFields,
  isSelectionModal,
  selectedFields,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const fieldsList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.fieldsList
  ) as FieldType[];
  // context
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();

  // state
  const [displayFieldsList, setDisplayFieldsList] = useState(fieldsList);
  const [showFieldsModal, setShowFieldsModal] = useState<FieldsModalProps>({
    show: false,
  });
  const [checkedFields, setCheckedFields] = useState<FieldType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllFields, setCheckAllFields] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);
  const [checkedRequiredFields, setCheckedRequiredFields] = useState<
    FieldSelectModalProps[]
  >([]);

  const getFieldsListAPI = () => {
    // getGroupsList()
    getFieldsList()
      .then(({ data: { fields } }) => {
        dispatch(actions.setFieldssList(fields));
      })
      .catch((e) => {
        // console.log(`ERROR: getCategoriesListAPI`, e)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const isFieldSelectModal = () => {
    return isSelectionModal === undefined ? false : isSelectionModal;
  };

  useEffect(() => {
    isFieldSelectModal();
  }, [isSelectionModal]);

  const updateDisplayFieldsList = () => {
    const updatedFieldsList = fieldsList.filter((field) => {
      if (
        (field.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayFieldsList(updatedFieldsList);
  };

  useEffect(() => {
    setIsLoading(true);
    // get categories API call
    getFieldsListAPI();
  }, []);

  useEffect(() => {
    updateDisplayFieldsList();

    return () => {
      setDisplayFieldsList(fieldsList);
    };
  }, [fieldsList, searchText]);

  useEffect(() => {
    if (checkAllFields && checkedFields.length !== fieldsList.length) {
      setCheckAllFields(false);
    }
    if (
      checkedFields.length > 0 &&
      checkedFields.length === fieldsList.length
    ) {
      setCheckAllFields(true);
    }
  }, [checkedFields]);

  const closeFieldsModal = () => {
    setShowFieldsModal({
      show: false,
      type: "new",
      field: undefined,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteExistingField = (id: number) => {
    setDeleteModalLoading(true);
    deleteField(id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_FIELDS_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_FIELDS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeFieldsModal();
        setCheckAllFields(false);
        setCheckedFields([]);
        // recall  get categories list API
        getFieldsListAPI();
      });
  };

  const onFieldsSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedCategoriesArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedCategoriesArr.push({
        id: sortedListItem.field.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing categories API Call
    fieldSort(newSortedCategoriesArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getFieldsListAPI();
      });
  };

  const onFieldCheckChange = (field: FieldType) => {
    if (isFieldSelectModal()) {
      let checkedFieldsCopy = _.clone(checkedRequiredFields);
      const index = checkedFieldsCopy.findIndex(
        (checkedField) => checkedField.field_id === field.id
      );
      if (index > -1) {
        checkedFieldsCopy.splice(index, 1);
      } else {
        checkedFieldsCopy.push({
          field_id: field.id,
          is_required: 0,
        });
      }
      setCheckedRequiredFields(checkedFieldsCopy);
    } else {
      let checkedFieldsCopy = _.clone(checkedFields);
      // check if already exists in the checked list
      const index = checkedFieldsCopy.findIndex(
        (checkedField) => checkedField.id === field.id
      );
      // if exists remove
      if (index > -1) {
        checkedFieldsCopy.splice(index, 1);
      }
      // if doesnt exist push to checked categories
      else {
        checkedFieldsCopy.push(field);
      }
      setCheckedFields(checkedFieldsCopy);
    }
  };

  const isFieldChecked = (field: FieldType) => {
    if (isFieldSelectModal()) {
      const index = checkedRequiredFields?.findIndex(
        (checkedFiled) => checkedFiled.field_id === field.id
      );
      if (index > -1) {
        return true;
      }
      return false;
    } else {
      const index = checkedFields.findIndex(
        (checkedFiled) => checkedFiled.id === field.id
      );
      if (index > -1) {
        return true;
      }
      return false;
    }
  };

  const isRequiredFieldChecked = (field: FieldType) => {
    console.log("render");
    if (isFieldSelectModal()) {
      const index = checkedRequiredFields?.findIndex(
        (checkedFiled) => checkedFiled.field_id === field.id
      );
      if (checkedRequiredFields[index].is_required === 1) {
        return true;
      }
      return false;
    }
  };

  const deleteMultipleFields = () => {
    setDeleteModalLoading(true);
    const ids = checkedFields.map((checkedField) => {
      return checkedField.id;
    });
    massDeleteFields(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_FIELDS_MASS_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_FIELDS_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getFieldsListAPI();
        setCheckedFields([]);
      });
  };

  const onAllFieldsCheckChange = () => {
    const updatedCheckAllFields = !checkAllFields;
    setCheckAllFields(updatedCheckAllFields);
    if (updatedCheckAllFields) {
      setCheckedFields(fieldsList);
    } else {
      setCheckedFields([]);
    }
  };

  const FieldItem = (field: FieldType, index: number) => {
    const currenSelectedField = isFieldSelectModal()
      ? (checkedRequiredFields &&
          checkedRequiredFields.filter(
            (data) => data.field_id === field.id
          )[0]) ||
        []
      : [];

    // context
    const {
      showDeleteConfirmModal,
      setDeleteModalLoading,
      hideDeleteConfirmModal,
    } = useGlobalModalContext();
    return (
      <div
        className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
        key={index}
      >
        <div className="d-flex flex-row align-items-center">
          <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={(e) => {
                onFieldCheckChange(field);
              }}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={isFieldChecked(field)}
              disabled={field.is_fixed && !isFieldSelectModal() ? true : false}
            />
          </div>

          {!isFieldSelectModal() && (
            <div className="mx-5">
              <SVGICON
                src={DragIcon}
                className="svg-icon-2 svg-icon-hover-primary"
              />
            </div>
          )}

          <div
            className="fw-bold mx-5 d-flex"
            role="button"
            onClick={() => {
              if (!isFieldSelectModal()) {
                setShowFieldsModal({
                  show: true,
                  type: "edit",
                  field: field,
                });
              }
            }}
          >
            <div className="d-flex align-items-center">
              <div> {field.name}</div>
              {field?.tooltip && (
                <div
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={intl.formatMessage({
                      id: `${field.tooltip}`,
                    })}
                    data-tooltip-place="top"
                  >
                    <i className="bi bi-info-circle-fill fs-3 svg-icon mx-2"></i>
                  </span>

                  <Tooltip
                    id="my-tooltip"
                    place="top"
                    style={{
                      color: "black",
                      backgroundColor: "#e9ecef",
                      zIndex: 999,
                      whiteSpace: "normal",
                      maxWidth: "250px",
                      wordWrap: "break-word",
                    }}
                    className="btn text-dark"
                    border="1px solid #6c757d"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex">
          {isFieldSelectModal() &&
            checkedRequiredFields?.length > 0 &&
            checkedRequiredFields.findIndex(
              (data) => data.field_id === field.id
            ) !== -1 && (
              <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid me-4">
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="notifications"
                  value=""
                  checked={isRequiredFieldChecked(field)}
                  style={{ width: "45px", height: "22px" }}
                  onChange={(e) => {
                    if (checkedRequiredFields.length > 0) {
                      let checkedFieldsCopy = _.clone(checkedRequiredFields);
                      const index = checkedFieldsCopy.findIndex(
                        (checkedField) => checkedField.field_id === field.id
                      );

                      checkedFieldsCopy.splice(index, 1);
                      checkedFieldsCopy.push({
                        field_id: field.id,
                        is_required: e.target.checked ? 1 : 0,
                      });
                      setCheckedRequiredFields(checkedFieldsCopy);
                    }
                  }}
                />
              </div>
            )}
          {!isFieldSelectModal() ? (
            <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
              onClick={(e) => {
                e.preventDefault();
                setShowFieldsModal({
                  show: true,
                  type: "edit",
                  field: field,
                });
              }}
            >
              <SVGICON src={EditIcon} className="svg-icon-3" />
            </button>
          ) : null}
          {!field.is_fixed && !isFieldSelectModal() && (
            <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm"
              onClick={() => {
                showDeleteConfirmModal(
                  intl.formatMessage({
                    id: "MASERDATA_FIELD_DELETE_CONFIRM_MESSAGE",
                  }),
                  () => {
                    deleteExistingField(field.id);
                  }
                );
              }}
            >
              <SVGICON src={DeleteIcon} className="svg-icon-3" />
            </button>
          )}
        </div>
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedFields =
    displayFieldsList && displayFieldsList.length > 10
      ? displayFieldsList.slice(startIndex, startIndex + itemsPerPage)
      : displayFieldsList;

  useEffect(() => {
    if (paginatedFields.length === 0 && displayFieldsList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedFields]);

  // draggable categories list
  const list = paginatedFields
    ? paginatedFields.map((field, i) => {
        return {
          content: (
            <div
              className={searchText || isFieldSelectModal() ? "no-drag" : ""}
            >
              {FieldItem(field, i)}
            </div>
          ),
          field: field,
        };
      })
    : [];

  return (
    <>
      <div>
        {showFieldsModal.show && (
          <FieldsModal
            ModalProps={showFieldsModal}
            closeModal={closeFieldsModal}
            getFieldsListAPI={getFieldsListAPI}
          />
        )}

        {/* search categories */}
        <div className="card p-4 d-flex flex-row justify-content-between ">
          <div className="d-flex flex-row search-container">
            {!isFieldSelectModal() && (
              <div className="form-check form-check-sm form-check-custom form-check-solid ">
                <input
                  onChange={onAllFieldsCheckChange}
                  className="form-check-input widget-9-check"
                  type="checkbox"
                  checked={checkAllFields}
                />
              </div>
            )}
            <div className="d-flex align-items-center">
              <SVGICON
                src={SearchIcon}
                className="svg-icon svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                className="form-control form-control-solid w-250px ps-15 mx-4"
                placeholder={intl.formatMessage({
                  id: "MASTERDATA_CATEGORY_SEARCH_PLACEHOLDER",
                })}
                onChange={(e) => {
                  onSearchTextChange(e.target.value);
                }}
              />
            </div>
          </div>

          {/* add new category button */}
          <div className="d-flex align-items-center">
            {(isFieldSelectModal() ? checkedRequiredFields : checkedFields)
              .length > 0 ? (
              <>
                <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                  {
                    (isFieldSelectModal()
                      ? checkedRequiredFields
                      : checkedFields
                    ).length
                  }{" "}
                  {intl.formatMessage({ id: "SELECTED" })}
                </span>
                {isFieldSelectModal() ? (
                  <button
                    className="btn btn-primary d-flex align-items-center"
                    onClick={() => {
                      onSelectedFields?.(checkedRequiredFields);
                    }}
                  >
                    {/* <i className={"bi bi-trash3-fill fs-6"}></i> */}
                    {intl.formatMessage({ id: "APPLY_BUTTON" })}
                  </button>
                ) : (
                  <button
                    className="btn btn-danger d-flex align-items-center"
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "MASTERDATA_FIELDS_SELECTED_DELETE_CONFIRM_MESSAGE",
                        }),
                        () => {
                          deleteMultipleFields();
                        }
                      );
                    }}
                  >
                    <i className={"bi bi-trash3-fill fs-6"}></i>
                    {intl.formatMessage({ id: "DELETE_BUTTON" })}
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn  btn-primary"
                onClick={() => {
                  setShowFieldsModal({
                    show: true,
                    type: "new",
                  });
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                {intl.formatMessage({
                  id: "MASTERDATA_FIELD_ADD_NEW_BUTTON",
                })}
              </button>
            )}
          </div>
        </div>
        <DragSortableList
          items={list}
          // @ts-ignore
          onSort={(sortedList, dropEvent) => {
            onFieldsSortChange(sortedList);
          }}
          type="vertical"
        />
      </div>
      {/* no data */}
      {!isLoading && displayFieldsList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <tr>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <img
                    src={notFound}
                    className="mh-400px"
                    alt=""
                    style={{ color: "inherit" }}
                  />
                  <div className="fs-1 fw-bolder text-dark mb-4">
                    {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {displayFieldsList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(displayFieldsList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayFieldsList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
      {/* {displayCategoriesList.length === 0 && (
        <div className='d-flex flex-column flex-center w-100 bg-body'>
          <img src={notFound} className='mh-400px' alt='' style={{color: 'inherit'}} />
          <div className='fs-1 fw-bolder text-dark mb-4'>
            {intl.formatMessage({id: 'NO_ITEMS_FOUND'})}
          </div>
        </div>
      )} */}
    </>
  );
};

export default Fields;
