import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TabType, panels } from "../types/TabsResponseType";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";
import { FieldType } from "../types/FieldsReponseTypes";
import { FileUpload } from "../../products/components/ProductDocuments";
import clsx from "clsx";
import DateTimePicker from "../../../sharedComponents/dateTimePicker/DateTimePicker";
import moment from "moment";
import SunEditorComponent from "../../../sharedComponents/textEditor/SunEditor";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { DealPipelineDetailsType } from "../../deals/types/DealResponseType";
import SelectProductCategories from "../../deals/components/SelectProductCategories";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import SelectProduct from "../../deals/components/SelectProduct";
import { useDispatch } from "react-redux";
import { actions } from "../redux";
import { ProductType } from "../../products/types/ProductsResponseType";
import { getByProductID } from "../../products/redux/ProductsAPI";
import { Tooltip } from "react-tooltip";
interface Props {
  currPhase: DealPipelineDetailsType | undefined;
  dealTab: TabType;
  type: string;
  onSubmitHandle?: (id: any, fId: any, value: any, value_json?: any) => void;
  deleteFile?: (
    pipelines_phases_tabs_id: number,
    tabs_panels_fields_id: number,
    file_id: number
  ) => void;
}

const TabPreview: FC<Props> = ({
  currPhase,
  dealTab,
  type,
  onSubmitHandle,
  deleteFile,
}) => {
  const { state = {} } = useLocation();
  const intl = useIntl();
  // @ts-ignore
  const tab: TabType = state.tabDetails || dealTab || {};

  // state
  const [currPanel, setCurrPanel] = useState<panels[]>(tab.panels);

  useEffect(() => {
    if (tab && tab.panels && tab.panels.length > 0) {
      const data = tab.panels.filter((panel) => {
        return panel.sections.some((section) => section.fields_data.length > 0);
      });
      if (data && data.length > 0) {
        setCurrPanel(data);
      }
    }
  }, [tab]);

  return (
    <div className={type === "edit" ? "card-body p-0" : "card-body p-0 mt-8"}>
      {currPanel &&
        currPanel.length > 0 &&
        currPanel.map((panel, index) => {
          return (
            <div
              className={clsx(
                "card d-flex mb-8 accordion accordion-icon-toggle"
              )}
              key={index}
              id={`kt_accordion_${index}`}
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id={`kt_accordion_${index}_header_${index}`}
                >
                  <button
                    className="accordion-button fs-4 fw-semibold"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#kt_accordion_${index}_body_${index}`}
                    aria-expanded="true"
                    aria-controls={`kt_accordion_${index}_body_${index}`}
                    title={panel.heading}
                  >
                    <h4
                      className=""
                      style={{
                        maxWidth: "500px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {panel.heading}
                    </h4>
                  </button>
                </h2>
                <div
                  id={`kt_accordion_${index}_body_${index}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`kt_accordion_${index}_header_${index}`}
                  data-bs-parent={`#kt_accordion_${index}`}
                >
                  <div className="accordion-body">
                    <div className="card-body d-flex gap-12 p-0">
                      {panel.sections && panel.sections.length > 0 ? (
                        panel.sections.map((section, index) => (
                          <div style={{ flex: 1 }} key={index}>
                            {
                              section.fields_data &&
                              section.fields_data.length > 0
                                ? section.fields_data.map((field, index) => (
                                    <div
                                      style={{
                                        flex: 1,
                                      }}
                                      key={index}
                                      className="mb-4"
                                    >
                                      <DynamicFormInput
                                        currPhase={currPhase}
                                        field={field}
                                        type={type}
                                        id={field.id}
                                        onSubmitHandle={(
                                          fId,
                                          value,
                                          value_json
                                        ) => {
                                          onSubmitHandle?.(
                                            field.pipeline_phase_tab_id,
                                            fId,
                                            value,
                                            value_json
                                          );
                                        }}
                                        index={index}
                                        deleteFile={(tId, fId, file_id) => {
                                          deleteFile?.(tId, fId, file_id);
                                        }}
                                      />
                                    </div>
                                  ))
                                : null
                              // <div className="text-muted fs-6 d-flex justify-content-center align-items-center">
                              //   {intl.formatMessage({
                              //     id: "NO_FIELDS_MESSAGE",
                              //   })}
                              // </div>
                            }
                          </div>
                        ))
                      ) : (
                        <div className="text-muted fs-6 d-flex justify-content-center align-items-center">
                          {intl.formatMessage({ id: "NO_SECTION_MESSAGE" })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TabPreview;

interface FormProps {
  field: FieldType;
  type: string;
  id: number;
  onSubmitHandle: (fId: any, value: any, value_json?: any) => void;
  index: number;
  deleteFile: (tab_id: number, field_id: number, file_id: number) => void;
  currPhase: DealPipelineDetailsType | undefined;
}

export const DynamicFormInput: FC<FormProps> = ({
  field,
  type,
  id,
  onSubmitHandle,
  index,
  deleteFile,
  currPhase,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const fieldProductList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.fieldProductDetails
  ) as {
    product: ProductType;
    field_id: number | undefined;
  }[];

  // state
  const [selectDropdown, setSelectDropdown] = useState<
    { id: number; name: string }[]
  >(
    (type === "edit" && field.type === "dropdown" && JSON.parse(field.value)) ||
      []
  );

  const [checkedBoxes, setCheckedBoxes] = useState<{ name: string }[]>(
    (type === "edit" && field.type === "checkbox" && JSON.parse(field.value)) ||
      []
  );

  const [dropdownOptions, setDropdownOptions] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const [selectedFiles, setSelectedFiles] = useState<
    File[] | { id: number; url: string }[]
  >((type === "edit" && field.type === "uploader" && field.value_json) || []);

  const [openProductModal, setOpenProductModal] = useState<{
    show: boolean;
    category: GroupType[] | undefined;
    field_id: undefined | FieldType;
  }>({
    show: false,
    category: undefined,
    field_id: undefined,
  });
  const [openProductCategoryModal, setOpenProductCategoryModal] = useState<{
    show: boolean;
    field: undefined | FieldType;
  }>({
    show: false,
    field: undefined,
  });

  const isDisabled =
    userData.role.id === 1 ||
    (currPhase && currPhase.roles.length === 0) ||
    (currPhase?.roles &&
      currPhase?.roles?.length > 0 &&
      currPhase?.roles.filter((role) => role.id === userData.role.id).length >
        0)
      ? false
      : true;

  const initialValues = {
    textBox:
      type === "edit" && field.type === "text" && id === field.id
        ? field.value || ""
        : "",
    longText:
      type === "edit" && field.type === "longtext" && id === field.id
        ? field.value || ""
        : "",
    dateDetails:
      type === "edit" && field.type === "date" && id === field.id
        ? field.value
        : "",
  };

  const AddNewFieldSchema = Yup.object().shape({
    textBox: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(field.name + " " + "is required"),
    longText: Yup.string().required(field.name + " " + "is required"),
    dateDetails: Yup.string()
      .required(field.name + " " + "is required")
      .nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: field.is_required === 1 && AddNewFieldSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {},
  });

  useEffect(() => {
    if (type && type === "edit") {
      formik.handleSubmit();
    }
  }, [field]);

  const handleFileUpload = (files: File[]) => {
    onSubmitHandle?.(field.tab_panels_fields_id, null, files);
  };

  const handleOnSelectItem = (item: { id: number; name: string }) => {
    const selectedItemsArr = selectDropdown || [];
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    // if doesnt exist push item
    if (index === -1) {
      formik.handleSubmit();
      onSubmitHandle?.(
        field.tab_panels_fields_id,
        JSON.stringify([...selectDropdown, item])
      );
      setSelectDropdown(() => [...selectDropdown, item]);
    }
    // if already exists remove item
    else {
      selectDropdown.splice(index, 1);
      formik.handleSubmit();
      onSubmitHandle?.(
        field.tab_panels_fields_id,
        JSON.stringify(selectDropdown)
      );
      setSelectDropdown((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  const handleOnCheckItem = (item: { name: string }) => {
    const selectedItemsArr = checkedBoxes || [];
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.name === item.name
    );
    // if doesnt exist push item
    if (index === -1) {
      setCheckedBoxes(() => [...(checkedBoxes || []), item]);
    }
    // if already exists remove item
    else {
      setCheckedBoxes((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  useEffect(() => {
    if (type === "edit" && field.type === "checkbox") {
      setCheckedBoxes(JSON.parse(field.value));
    }
    if (type === "edit" && field.type === "dropdown") {
      const resData: {
        id: number;
        name: string;
      }[] = [];
      field.options?.map((f: { name: string }, index) => {
        let obj: {
          id: number;
          name: string;
        } = {
          id: 0,
          name: "",
        };
        obj.id += index;
        obj.name = f.name;
        resData.push(obj);
      });
      setDropdownOptions(resData);
    }
  }, [field]);

  const isChecked = (item: { name: string }) => {
    const index = checkedBoxes?.findIndex(
      (checkedPipeline) => checkedPipeline.name === item.name
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const handleFieldValue = (field: FieldType) => {
    if (
      fieldProductList &&
      fieldProductList.length > 0 &&
      field.value &&
      field.value !== undefined
    ) {
      const removedData = fieldProductList.filter(
        (data) => data.field_id === field.id
      )[0];
      return removedData?.product?.name;
    } else {
      return "";
    }
  };

  const getProductDetails = (product_id: number, field_id: number) => {
    getByProductID(product_id).then(({ data }) => {
      let result: {
        product: ProductType;
        field_id: number | undefined;
      }[] = [];
      const { productDetails } = data || {};
      const obj = {
        product: productDetails,
        field_id: field_id,
      };
      result = [...fieldProductList];
      result.push(obj);
      dispatch(actions.setFieldProductDetails(result));
    });
  };

  useEffect(() => {
    if (
      field.type === "product" &&
      field?.value &&
      field?.value !== undefined &&
      id === field.id
    ) {
      getProductDetails(Number(field.value), field.id);
    }

    if (field.type === "dropdown" && type === "edit") {
      if (field?.value && field?.value !== null && id === field.id) {
        setSelectDropdown(JSON.parse(field.value));
        console.log(JSON.parse(field.value), field);
      }
    }

    if (field.type === "longtext" && type === "edit" && id === field.id) {
      formik.setFieldValue("longText", field.value);
    }
  }, [field]);

  return (
    <div key={index}>
      {/* {openProductCategoryModal.show && openProductCategoryModal.field ? (
        <SelectProductCategories
          show={openProductCategoryModal.show}
          closeModal={() => {
            setOpenProductCategoryModal({
              show: false,
              field: undefined,
            });
          }}
          onSelectCategory={(category) => {
            setOpenProductModal({
              show: true,
              category,
              field_id: openProductCategoryModal.field,
            });
            setOpenProductCategoryModal({
              show: false,
              field: undefined,
            });
          }}
        />
      ) : null} */}

      {openProductModal.show ? (
        <SelectProduct
          show={openProductModal.show}
          closeModal={() => {
            setOpenProductModal({
              show: false,
              category: undefined,
              field_id: undefined,
            });
          }}
          onSelectProduct={(product) => {
            const obj = {
              product: product[0],
              field_id: openProductModal.field_id?.id,
            };
            let result: {
              product: ProductType;
              field_id: number | undefined;
            }[] = [];
            if (fieldProductList && fieldProductList.length > 0) {
              const index = fieldProductList.findIndex(
                (data) => data.field_id === openProductModal.field_id?.id
              );

              if (index === -1) {
                result = [...fieldProductList, obj];
              } else {
                result = [...fieldProductList];
                result.splice(index, 1);
                result.push(obj);
              }
            } else {
              result.push(obj);
            }

            dispatch(actions.setFieldProductDetails(result));
            onSubmitHandle(
              openProductModal.field_id?.tab_panels_fields_id,
              product[0].id,
              ""
            );
            formik.handleSubmit();
            setOpenProductModal({
              ...openProductModal,
              show: false,
            });
          }}
        />
      ) : null}
      <div className="d-flex align-items-center">
        <label
          className={
            field.is_required
              ? "fw-bold fs-6 mb-1 required"
              : "fw-bold fs-6 mb-1"
          }
        >
          {field.name}
        </label>
        {field?.tooltip && (
          <div
            style={{
              position: "relative",
              cursor: "pointer",
            }}
          >
            <span
              data-tooltip-id="my-preview-tooltip"
              data-tooltip-content={field.tooltip}
              data-tooltip-place="top"
            >
              <i className="bi bi-info-circle-fill fs-3 svg-icon mx-2"></i>
            </span>

            <Tooltip
              id="my-preview-tooltip"
              place="top"
              style={{
                color: "black",
                backgroundColor: "#e9ecef",
                zIndex: 999,
                whiteSpace: "normal",
                maxWidth: "250px",
                wordWrap: "break-word",
              }}
              className="btn text-dark"
              border="1px solid #6c757d"
            />
          </div>
        )}
      </div>
      {field.type === "product" ? (
        <>
          <div className="d-flex">
            <input
              type="text"
              className="form-control form-control-lg form-control-solid me-4"
              disabled={true}
              placeholder={field?.placeholder && field?.placeholder}
              value={field.id === id ? handleFieldValue(field) : ""}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                setOpenProductModal({
                  show: true,
                  category: undefined,
                  field_id: field,
                });
              }}
            >
              {intl.formatMessage({ id: "CUSTOMERS_SELECT_PARTNER_TITLE" })}
            </button>
          </div>
          <div>
            {field.is_required === 1 && field.value == undefined && (
              <div className="text-danger">
                <span role="alert">{field.name} is required</span>
              </div>
            )}
          </div>
        </>
      ) : null}
      {field.type === "text" ? (
        <div>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid mb-3 "
            disabled={type === "edit" && !isDisabled ? false : true}
            placeholder={field?.placeholder && field?.placeholder}
            {...formik.getFieldProps("textBox")}
            onBlur={(e) => {
              if (field.value !== e.target.value && e.target.value.length > 0) {
                formik.handleSubmit();
                onSubmitHandle?.(field.tab_panels_fields_id, e.target.value);
              }
            }}
          />
          {field.is_required === 1 && field.value == undefined && (
            <div className="text-danger">
              <span role="alert">{`${field.name} is required`}</span>
            </div>
          )}
        </div>
      ) : field.type === "uploader" ? (
        <div className={type === "edit" && !isDisabled ? "" : "pe-none"}>
          <FileUpload
            onFileUpload={(files: File[]) => {
              handleFileUpload(files);
            }}
            loading={false}
            isDisabled={type === "edit" && !isDisabled ? false : true}
          />
          {field.is_required === 1 && field.value_json.length === 0 && (
            <div className="text-danger">
              <span role="alert">{field.name} is required</span>
            </div>
          )}
          <div className="d-flex flex-wrap">
            {field.value_json.map((file, index) => {
              return (
                <div className="card me-5 mb-2 " key={index}>
                  <div className="d-flex flex-column align-items-center">
                  <img
                    src={file.url}
                    height={100}
                    width="auto"
                    style={{ objectFit: "contain" }}
                    alt=""
                  />
                  <span>{file?.name}</span>
                  </div>
                  <div
                    className="position-absolute bg-light btn btn-icon btn-active-light-danger d-flex justify-content-center align-items-center"
                    style={{
                      right: -15,
                      top: -10,
                      zIndex: 99,
                      borderRadius: "50px",
                      height: "25px",
                      width: "25px",
                    }}
                    role={"button"}
                    onClick={() => {
                      showDeleteConfirmModal(
                        "Are you sure want to delete the file? ",
                        () => {
                          deleteFile(
                            Number(field.pipeline_phase_tab_id),
                            field.tab_panels_fields_id,
                            file.id
                          );
                        }
                      );
                    }}
                  >
                    <SVGICON
                      src={CloseIcon}
                      className="svg-icon svg-icon-1 svg-icon-grey-800"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : field.type === "longtext" ? (
        <div>
          <SunEditorComponent
            initHtml={formik.values.longText || ""}
            handleBlur={(html) => {
              formik.setFieldValue("longText", html);
              if (field.value !== html && html.length > 0) {
                onSubmitHandle?.(field.tab_panels_fields_id, html);
                formik.handleSubmit();
              }
            }}
            isDiasbled={type === "edit" && !isDisabled ? false : true}
            wrapperPosition={field.id}
            loading={false}
            hideSubmit={false}
          />
          {field.is_required === 1 && field.value == undefined && (
            <div className="text-danger">
              <span role="alert">{`${field.name} is required`}</span>
            </div>
          )}
        </div>
      ) : field.type === "date" ? (
        <div>
          <DateTimePicker
            onDateChange={(date) => {}}
            onCalendarClose={(date) => {
              formik.setFieldValue("dateDetails", date);
              onSubmitHandle?.(field.tab_panels_fields_id, date);
              formik.handleSubmit();
            }}
            // @ts-ignore
            selectedDate={
              formik.values.dateDetails
                ? new Date(formik.values.dateDetails)
                : ""
            }
            placeholder={field?.placeholder && field?.placeholder}
            hideTimeSelect
            type={"date"}
            minDate={moment().subtract(2, "months").toDate()}
            disabledRes={type === "edit" && !isDisabled ? false : true}
          />
          {field.is_required === 1 && field.value == undefined && (
            <div className="text-danger">
              <span role="alert">{`${field.name} is required`}</span>
            </div>
          )}
        </div>
      ) : field.type === "dropdown" ? (
        <div>
          <DropDown
            id="tab-parent-dropdown"
            multiSelect
            // @ts-ignore
            items={dropdownOptions || []}
            // @ts-ignore
            selectedItems={selectDropdown}
            onSelectItem={(item) => {
              //   @ts-ignore
              handleOnSelectItem(item);
            }}
            // @ts-ignore
            displayFunc={(item) => {
              return item.name;
            }}
            onMultiSelectBlur={() => {
              // formik.handleSubmit();
              // onSubmitHandle?.(
              //   field.tab_panels_fields_id,
              //   JSON.stringify(selectDropdown)
              // );
            }}
            disabled={type === "edit" && !isDisabled ? false : true}
            searchPlaceholder={field?.placeholder && field?.placeholder}
          />
          {field.is_required === 1 && selectDropdown.length === 0 && (
            <div className="text-danger">
              <span role="alert">{field.name} is required</span>
            </div>
          )}
        </div>
      ) : field.type === "radio" ? (
        <>
          <div className="d-flex">
            {field.options?.map((option: { name: string }, index) => (
              <div
                className="form-check form-switch form-switch-sm form-check-custom form-check-solid "
                style={{
                  flex: 1,
                }}
                key={index}
              >
                <label className={"fw-bold fs-6 me-2"}>{option.name}</label>
                <input
                  className="form-check-input "
                  type="checkbox"
                  name={option.name}
                  checked={field.value === option.name ? true : false}
                  style={{ width: "45px", height: "22px" }}
                  onChange={() => {
                    formik.handleSubmit();
                    onSubmitHandle?.(field.tab_panels_fields_id, option.name);
                  }}
                  disabled={type === "edit" && !isDisabled ? false : true}
                />
              </div>
            ))}
          </div>
          <div>
            {field.is_required === 1 && field.value == undefined && (
              <div className="text-danger">
                <span role="alert">{field.name} is required</span>
              </div>
            )}
          </div>
        </>
      ) : field.type === "checkbox" ? (
        <>
          <div className="d-flex">
            {field.options?.map((option: { name: string }, index) => {
              return (
                <div
                  className="form-check form-check-sm form-check-custom form-check-solid "
                  style={{
                    flex: 1,
                  }}
                  key={index}
                >
                  <input
                    onChange={(e) => {
                      handleOnCheckItem(option);
                    }}
                    className="form-check-input widget-9-check me-2"
                    type="checkbox"
                    checked={isChecked(option)}
                    onBlur={() => {
                      formik.handleSubmit();
                      onSubmitHandle?.(
                        field.tab_panels_fields_id,
                        checkedBoxes && checkedBoxes.length > 0
                          ? JSON.stringify(checkedBoxes)
                          : null
                      );
                    }}
                    disabled={type === "edit" && !isDisabled ? false : true}
                  />
                  <label className={"fw-bold fs-6 me-2"}>{option.name}</label>
                </div>
              );
            })}
          </div>
          <div>
            {field.is_required === 1 && field.value == undefined && (
              <div className="text-danger">
                <span role="alert">{field.name} is required</span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};
