import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

// images
import MenuIcon from "../../../../_metronic/assets/icons/menu.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import { UserType } from "../../auth/types/LoginResponseTypes";
import { deleteCustomer } from "../redux";
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";

interface Props {
  userDetails: UserType;
  getCustomersListAPI: () => void;
}

const UsersListActionsDropdown: FC<Props> = ({
  userDetails,
  getCustomersListAPI,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEdituserClick = () => {
    history.push(`/crm/contacts/editcustomer`, { userDetails: userDetails });
  };

  const deleteSelectedCustomer = () => {
    setDeleteModalLoading(true);
    deleteCustomer(userDetails.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_DELETE_USER_SUCCESS" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_DELETE_USER_FAILED" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getCustomersListAPI?.();
      });
  };

  const dropdownOptions = [
    {
      name: intl.formatMessage({ id: "COMMON_EDIT" }),
      onClick: onEdituserClick,
    },
    {
      name: intl.formatMessage({ id: "COMMON_DELETE" }),
      onClick: () => {
        showDeleteConfirmModal(
          intl.formatMessage({
            id: "CUSTOMER_MANAGEMENT_USER_ACTION_DELETE_MESSAGE",
          }),
          () => {
            deleteSelectedCustomer();
          }
        );
      },
      className: "text-danger",
    },
  ];

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
      <ActionsDropdown options={dropdownOptions} />
    </div>
  );
};

export default UsersListActionsDropdown;
