/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
// import _ from "lodash";

// custom
import { PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";

// import SimpleLoader from "../../sharedComponents/Loader/SimpleLoader";
// import moment from "moment";
import { MixedWidget8 } from "../../../_metronic/partials/widgets";
// const DashboardStatistics = () => {
//   return <></>;
// };

const DashboardPage: FC = () => {
  return (
    <>
      {/* begin::Row */}

      {/* end::Row */}

      {/* begin::Row */}

      {/* end::Row */}

      {/* begin::Row */}

      <div className="row gy-5 g-xl-8">
        {/* <div className='col-xl-4'>

        <ListsWidget2 className='card-xl-stretch mb-xl-8' />

      </div>

      <div className='col-xl-4'>

        <ListsWidget6 className='card-xl-stretch mb-xl-8' />

      </div>

      <div className='col-xl-4'>

        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />

 

      </div> */}
      </div>

      {/* end::Row */}

      <div className="row g-5 gx-xxl-8">
        <div className="col-xxl-4">
          <MixedWidget8
            className="card-xxl-stretch mb-xl-3"
            chartColor="success"
            chartHeight="150px"
          />
        </div>
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <Helmet>
        <title>{config.APP_NAME}-Dashboard</title>
      </Helmet>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU_DASHBOARD" })}
      </PageTitle>
    </>
  );
};

export { DashboardWrapper };
