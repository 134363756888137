import axios from "axios";

// custom
import { sortGroupsParams } from "../types/request";
import { GroupsResponseType } from "../types/GetGroupsListResponseType";
import { CreateNewGroupResponseType } from "../types/CreateNewGroupResponseType";

// get all tags list
export function getTagsList() {
  return axios.get<GroupsResponseType>(`/tags`);
}

// create nbew tag
export function createNewTag(
  name: string,
  colour: string
  // seo_title: string,
  // seo_description: string,
  // seo_picture_id: string,
  // is_visible:number
) {
  return axios.post<CreateNewGroupResponseType>(`/tags`, {
    name,
    colour,

    // seo_title,
    // seo_description,
    // seo_picture_id,
    // is_visible
  });
}

// edit existing tags
export function updateTag(
  id: number,
  name: string,
  colour: string
  // seo_title: string,
  // seo_description: string,
  // seo_picture_id: string,
  // is_visible:number
) {
  return axios.post<CreateNewGroupResponseType>(`/tags/${id}`, {
    name,
    colour,
    // seo_title,
    // seo_description,
    // seo_picture_id,
    // is_visible
  });
}

// delete existing tag
export function deleteTag(id: number) {
  return axios.delete<CreateNewGroupResponseType>(`/tags/${id}`);
}

// mass delete existing tags
export function massDeleteTags(tags_id: Array<number>) {
  return axios.post<CreateNewGroupResponseType>(`/tags/massDelete`, {
    tags_id,
  });
}

// sort existing tag
export function sortTags(tags_sorting: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/tags/sorting`, {
    tags_sorting,
  });
}
