import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import CloseButton from "../../../sharedComponents/closeButton/CloseButton";
import Categories from "../../masterdata/components/Categories";
import { ProductType } from "../../products/types/ProductsResponseType";
import ProductsList from "../../products/components/ProductsList";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectProduct: (product: ProductType[]) => void;
  loading?: boolean;
  isSelectionModal?: boolean;
  category?: GroupType[];
}

const SelectProduct: FC<Props> = ({
  show,
  closeModal,
  onSelectProduct,
  loading,
  isSelectionModal,
  category,
}) => {
  const intl = useIntl();
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between  ">
          <Modal.Title>
            {intl.formatMessage({
              id: "DEAL_SELECT_PRODUCT_MODAL_TITLE",
            })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${isSelectionModal ? "selections_modal" : ""}`}
        // style={{ padding: "0px" }}
      >
        <ProductsList
          type="select"
          onSelectProductss={(product) => {
            onSelectProduct(product);
          }}
          isSelectionModal
          loading={loading}
          category={category}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SelectProduct;
