import {useLang} from '../../_metronic/i18n/Metronici18n'
import {AddressType} from '../modules/auth/types/LoginResponseTypes'

export const useAddress = () => {
  const lang = useLang()

  return (adress: AddressType) => {
    let address = ''
    if (adress?.address_1) {
      address = address + adress?.address_1
    }
    if (adress?.address_2) {
      address = address + `${address ? ', ' : ''}${adress?.address_2}`
    }
    if (lang === 'de') {
      if (adress?.zip_code) {
        address = address + `${address ? ', ' : ''}${adress?.zip_code}`
      }
      if (adress?.city) {
        address = address + `${address ? ', ' : ''}${adress?.city}`
      }
    } else {
      if (adress?.city) {
        address = address + `${address ? ', ' : ''}${adress?.city}`
      }
      if (adress?.zip_code) {
        address = address + `${address ? ', ' : ''}${adress?.zip_code}`
      }
    }
    if (adress?.state) {
      address = address + `${address ? ', ' : ''}${adress?.state}`
    }

    return address
  }
}
