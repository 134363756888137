import axios from "axios";

// custom
import { sortGroupsParams } from "../types/request";
import { GroupsResponseType } from "../types/GetGroupsListResponseType";
import { CreateNewGroupResponseType } from "../types/CreateNewGroupResponseType";

// get all groups list
export function getGroupsList() {
  return axios.get<GroupsResponseType>(`/groups`);
}

// create nbew group
export function createNewGroup(
  name: string
  // seo_title: string,
  // seo_description: string,
  // seo_picture_id: string,
  // is_visible:number
) {
  return axios.post<CreateNewGroupResponseType>(`/groups`, {
    name: name,
    // seo_title,
    // seo_description,
    // seo_picture_id,
    // is_visible
  });
}

// edit existing group
export function updateGroup(
  id: number,
  name: string
  // seo_title: string,
  // seo_description: string,
  // seo_picture_id: string,
  // is_visible: number
) {
  return axios.post<CreateNewGroupResponseType>(`/groups/${id}`, {
    name: name,
    // seo_title,
    // seo_description,
    // seo_picture_id,
    // is_visible,
  });
}

// delete existing group
export function deleteGroup(id: number) {
  return axios.delete<CreateNewGroupResponseType>(`/groups/${id}`);
}

// mass delete existing groups
export function massDeleteGroups(groups_id: Array<number>) {
  return axios.post<CreateNewGroupResponseType>(`/groups/massDelete`, {
    groups_id,
  });
}

// sort existing group
export function sortGroups(groups_sorting: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/groups/sorting`, {
    groups_sorting,
  });
}
