import React, { FC, useContext, useEffect, useState } from "react";
import { DealType, PhaseLogType } from "../types/DealResponseType";
import { getPhaseLogs } from "../redux/DealsAPI";
import moment from "moment";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import ImageIcon from "../../../../_metronic/assets/icons/imageIcon.svg";
import VideoIcon from "../../../../_metronic/assets/icons/video.svg";
import AudioIcon from "../../../../_metronic/assets/icons/audio.svg";
import PDFIcon from "../../../../_metronic/assets/icons/pdf.svg";
import FileIcon from "../../../../_metronic/assets/icons/defaultFile.svg";

import { FieldLogsContext } from "./context";
import { imageURLType } from "../../products/types/ProductsResponseType";
import { getFile } from "../../filemanager/redux";
import { bytesToSize } from "../../../utils/BytesToSize";
import ProductFilePreviewModal from "../../products/components/ProductFilePreviewModal";

interface Props {
  deal: DealType;
}

const DealLogs: FC<Props> = ({ deal }) => {
  // state
  const [phaseLogs, setPhaseLogs] = useState<PhaseLogType[]>([]);
  const { fieldLogsInEditPage } = useContext<any>(FieldLogsContext);
  const [activePage, setActivePage] = useState<number>(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewLoading, setPreviewLoading] = useState<string | undefined>();
  const [previewFileURL, setPreviewFileURL] = useState<string>();
  const [previewFileExtension, setPreviewFileExtension] = useState<any>();
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [loadig, setLoading] = useState(false);

  const lang = useLang();

  const onPageClick = (page: number) => {
    setActivePage(page);
  };
  useEffect(() => {
    setLoading(true);
    getPhaseLogs(deal.id)
      .then(({ data: { logs } }) => {
        setPhaseLogs(logs);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const mergeAndSortLogs = (
    phaseLogs: PhaseLogType[],
    fieldLogsInEditPage: any
  ) => {
    const mergedLogs = [...phaseLogs, ...fieldLogsInEditPage];

    mergedLogs.forEach((log) => {
      const sanitizedCreatedAt = log?.created_at?.split(".")[0] + "Z";

      const date = new Date(sanitizedCreatedAt);
      // @ts-ignore
      if (isNaN(date)) {
        console.error(`Invalid Date: ${sanitizedCreatedAt}`);
      }
    });

    mergedLogs.sort((a, b) => {
      const dateA = new Date(a?.created_at?.split(".")[0] + "Z").getTime();
      const dateB = new Date(b?.created_at?.split(".")[0] + "Z").getTime();
      return dateB - dateA;
    });

    return mergedLogs;
  };

  const sortedLogs = mergeAndSortLogs(phaseLogs, fieldLogsInEditPage);

  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedLogs: any =
    sortedLogs && sortedLogs.length > 10
      ? sortedLogs.slice(startIndex, startIndex + itemsPerPage)
      : sortedLogs;

  useEffect(() => {
    if (paginatedLogs.length === 0 && phaseLogs.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedLogs]);

  const handleShowModal = (file: React.SetStateAction<null>) => {
    console.log(file, "file data");
    setSelectedFile(file);
    setShowModal(true);
  };

  const getIconPath = (fieldType: any) => {
    switch (fieldType) {
      case "checkbox":
        return "/media/icons/duotune/logs/checkbox.svg";
      case "text":
        return "/media/icons/duotune/logs/text.svg";
      case "dropdown":
        return "/media/icons/duotune/logs/dropdown.svg";
      case "date":
        return "/media/icons/duotune/logs/date.svg";
      case "radio":
        return "/media/icons/duotune/logs/radio.svg"
      case "uploader":
        return "/media/icons/duotune/logs/contact.svg";
      case "Number":
        return "/media/icons/duotune/logs/number.svg";
      default:
        return "/media/icons/duotune/communication/com009.svg";
    }
  };

  function getFileExtensionFromUrl(url: string) {
    const match = url?.match(/\.([^.]+)$/);
    return match ? match[1] : null;
  }

  const handlePreviewURL = (fileUrl: imageURLType) => {
    //@ts-ignore
    setPreviewLoading(fileUrl.file_path); //@ts-ignore
    const fileExtension = getFileExtensionFromUrl(fileUrl.file_path);
    setPreviewFileExtension(fileExtension);
    getFile(fileUrl.id)
      .then((res: { data: { fileDetails: { file_url: any } } }) => {
        // console.log(response.data);
        let fileObjURL = (res && res.data.fileDetails.file_url) || undefined;
        // handleTaskFilePreview(true);
        setPreviewFileURL(fileObjURL);
        setShowPreviewModal(true);
      })
      .catch((err: any) => {})
      .finally(() => {
        setPreviewLoading(undefined);
      });
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
    // handleTaskFilePreview(false);
  };

  const generateLogMessage = (log: {
    field_name: any;
    pipelines_phases_from_name?: any;
    pipelines_phases_to_name?: any;
    created_at?: moment.MomentInput;
    recorded_by_name?:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    action?: any;
  }) => {
    //@ts-ignore
    const { field_name, action, fileData } = log;
    console.log(log);
    //@ts-ignore
    if (field_name === "Chekbox") {
      let newValueParsed = JSON.parse(action.newValue);
      let checkboxValue = newValueParsed
        .map((item: { name: any }) => item.name)
        .join(", ");

      let oldValueParsed = action.oldValue ? JSON.parse(action.oldValue) : [];
      let oldCheckboxValue = oldValueParsed
        .map((item: { name: any }) => item.name)
        .join(", ");

      return (
        <span className="fw-600 fs-5 d-block text-start ps-0">
          {oldCheckboxValue ? (
            <span className="fw-600  d-block text-start ps-0">
              (<strong>{field_name}</strong> field is updated from{" "}
              <strong>{oldCheckboxValue}</strong> to{" "}
              <strong>{checkboxValue}</strong>)
            </span>
          ) : (
            <span className="fw-600  d-block text-start ps-0">
              <strong>{field_name}</strong> field is added as{" "}
              <strong>{checkboxValue}</strong>
            </span>
          )}
        </span>
      );
    } else {
      if (!action.oldValue || action?.oldValue === "[]") {
        let message = (
          <span className="fw-500 fs-5 d-block text-start ps-0">
            <strong>{field_name}</strong> field is added as{" "}
            <strong>{action.newValue}</strong>
          </span>
        );

        if (fileData?.length > 0 && action?.newValue) {
          const newValueIds = JSON.parse(action?.newValue);

          const matchingNewObjects = fileData.filter((item: { id: any }) =>
            newValueIds.includes(item?.id)
          );
          const newNames = matchingNewObjects
            .map((item: { name: any }) => item.name)
            .join(", ");
          message = (
            <span className="fw-500 fs-5 d-block text-start ps-0">
              <strong>{fileData?.length}</strong> file is uploaded in{" "}
              <strong>{field_name}</strong>
            </span>
          );
        }

        return message;
      } else {
        let message = (
          <span className="fw-500 fs-5 d-block text-start ps-0">
            <strong>{field_name}</strong> field is updated from{" "}
            <strong>{action.oldValue}</strong> to{" "}
            <strong>{action.newValue}</strong>
          </span>
        );

        if (fileData?.length > 0) {
          const oldValueIds = action.oldValue
            ? JSON.parse(action.oldValue)
            : [];
          const newValueIds = action.newValue
            ? JSON.parse(action.newValue)
            : [];

          const matchingOldObjects = fileData.filter((item: { id: any }) =>
            oldValueIds.includes(item.id)
          );
          const matchingNewObjects = fileData.filter((item: { id: any }) =>
            newValueIds.includes(item.id)
          );

          const oldNames = matchingOldObjects
            .map((item: { name: any }) => item.name)
            .join(", ");
          const newNames = matchingNewObjects
            .map((item: { name: any }) => item.name)
            .join(", ");

          message = (
            <span className="fw-500 fs-5 d-block text-start ps-0">
              <strong>{fileData?.length}</strong> files are uploaded in this{" "}
              <strong>{field_name}</strong>
            </span>
          );
        }
        return message;
      }
    }
  };

  return (
    <div
      className=""
      style={{
        flex: 1,
      }}
    >
      <div className="card-header border-0 pt-2 ">
        <div className="card-title m-0 d-flex ">
          <div className=" ">
            <span>{deal.name}</span>
            <div>
              <span className="text-muted fs-8">{`Below are the logs for ${deal.phase_name} pipeline`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body ">
        {loadig && (
          <tr className="odd d-flex align-items-center justify-content-center">
            <td valign="top" colSpan={7} className="dataTables_empty">
              <div className="d-flex flex-column flex-center">
                <SimpleLoader />
              </div>
            </td>
          </tr>
        )}
        <div className="timeline">
          {paginatedLogs.length > 0 ? (
            <>
              {paginatedLogs?.map(
                (
                  log: {
                    field_name: any;
                    pipelines_phases_from_name: any;
                    pipelines_phases_to_name: any;
                    created_at: moment.MomentInput;
                    recorded_by_name:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  index: React.Key | null | undefined
                ) => {
                  return (
                    <>
                      {/* @ts-ignore */}
                      {log?.field_name ? (
                        <>
                          <div className="timeline-item" key={index}>
                            <div className="timeline-line w-40px"></div>

                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                              <div className="symbol-label bg-light">
                                <KTSVG
                                  //@ts-ignore
                                  path={getIconPath(log?.field_type)}
                                  className="svg-icon-2 svg-icon-gray-500"
                                />
                              </div>
                            </div>

                            <div className="timeline-content mb-10 mt-n2">
                              <div className="overflow-auto pe-3">
                                <div className="mb-2">
                                  {generateLogMessage(log)}
                                </div>

                                <div className="d-flex align-items-center mt-1 fs-6">
                                  <div className="text-muted me-2 fs-7">
                                    {`on ${moment(log.created_at).format(
                                      lang === "en"
                                        ? "DD-MM-YYYY"
                                        : "DD.MM.YYYY"
                                    )} by`}
                                  </div>

                                  <div
                                    className="symbol symbol-circle symbol-25px"
                                    style={{ color: "#3766C7" }}
                                    data-bs-toggle="tooltip"
                                    data-bs-boundary="window"
                                    data-bs-placement="top"
                                  >
                                    {/* <img
                                src={log.}
                                alt="img"
                              /> */}
                                    <span>{log.recorded_by_name}</span>
                                  </div>
                                </div>
                                <>
                                {/* @ts-ignore */}
                                  {log?.field_type === "uploader" && (
                                    <div className="d-flex flex-row align-items-center">
                                      {/* @ts-ignore */}
                                      {log?.fileData?.map((file) => {
                                        return (
                                          <div
                                            onClick={() =>
                                              handleShowModal(file)
                                            }
                                            className="d-flex flex-row align-items-center mt-2 cursor-pointer"
                                          >
                                            {console.log(file, "file")}
                                            <div className="d-flex align-items-center">
                                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3 mx-3">
                                                <div className="symbol-label">
                                                  <div className="symbol-label fs-3 bg-light-primary text-danger text-uppercase">
                                                    <SVGICON
                                                      src={
                                                        file.type === "pdf"
                                                          ? PDFIcon
                                                          : file.type ===
                                                                "mp4" ||
                                                              file.type ===
                                                                "m4v"
                                                            ? VideoIcon
                                                            : file.type ===
                                                                "mp3"
                                                              ? AudioIcon
                                                              : file.type ===
                                                                    "jpg" ||
                                                                  file.type ===
                                                                    "png" ||
                                                                  file.type ===
                                                                    "jpeg"
                                                                ? ImageIcon
                                                                : FileIcon
                                                      }
                                                      className="svg-icon-primary svg-icon-2x "
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="d-flex flex-column"
                                                onClick={() => {
                                                  handlePreviewURL(file);
                                                }}
                                              >
                                                <span
                                                  className="text-gray-800 fw-bold text-hover-primary  mb-1"
                                                  role="button"
                                                  title={file.photo_url}
                                                  style={{
                                                    maxWidth: "450px",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {file.name}
                                                </span>
                                               {file?.size &&  <div className="d-flex  align-items-center">
                                                  <span className="badge badge-light-success fw-bolder    me-2">
                                                    {bytesToSize(file?.size, 1)}
                                                  </span>
                                                </div>}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {previewFileURL && (
                                    <div>
                                      <ProductFilePreviewModal
                                        show={showPreviewModal}
                                        previewFileURL={previewFileURL}
                                        previewFileExtension={
                                          previewFileExtension
                                        }
                                        closeModal={closePreviewModal}
                                        showCloseButton
                                      />
                                    </div>
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="timeline-item" key={index}>
                          <div className="timeline-line w-40px"></div>

                          <div className="timeline-icon symbol symbol-circle symbol-40px">
                            <div className="symbol-label bg-light">
                              <KTSVG
                                path="/media/icons/duotune/communication/com009.svg"
                                className="svg-icon-2 svg-icon-gray-500"
                              />
                            </div>
                          </div>

                          <div className="timeline-content mb-10 mt-n2">
                            <div className="overflow-auto pe-3">
                              <div className="fs-5 mb-2">
                                <span>
                                  Deal moved from{" "}
                                  <strong>
                                    {log.pipelines_phases_from_name}
                                  </strong>{" "}
                                  to{" "}
                                  <strong>
                                    {log.pipelines_phases_to_name}
                                  </strong>
                                </span>
                              </div>

                              <div className="d-flex align-items-center mt-1 fs-6">
                                <div className="text-muted me-2 fs-7">
                                  {`moved at ${moment(log.created_at).format(
                                    lang === "en" ? "DD-MM-YYYY" : "DD.MM.YYYY"
                                  )} by`}
                                </div>

                                <div
                                  className="symbol symbol-circle symbol-25px"
                                  style={{ color: "#3766C7" }}
                                  data-bs-toggle="tooltip"
                                  data-bs-boundary="window"
                                  data-bs-placement="top"
                                >
                                  {/* <img
                                src={log.}
                                alt="img"
                              /> */}
                                  <span>{log.recorded_by_name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              )}
            </>
          ) : (
            <div className="text-muted fs-8 d-flex align-items-center justify-content-center">
              Logs not created
            </div>
          )}
        </div>
      </div>

      {/* end::Table */}
      {sortedLogs.length > 0 && (
        <Pagination
          totalPages={Math.ceil(sortedLogs.length / itemsPerPage)}
          activePage={
            Math.ceil(sortedLogs.length / itemsPerPage) === 1 ? 1 : activePage
          }
          onPageClick={onPageClick}
          noCard
          itemsPerPage={itemsPerPage}
          showItemsPerPage
          setItemsPerPage={(count) => {
            setItemsPerPage(count);
          }}
        />
      )}
    </div>
  );
};

export default DealLogs;
