import React, {FC} from 'react'

interface CheckboxProps {
  onChange?: () => void
  checked?: boolean
  disabled?: boolean
}

const Checkbox: FC<CheckboxProps> = ({onChange, checked, disabled}) => {
  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid '>
      <input
        onChange={(e) => {
          onChange?.()
        }}
        className='form-check-input widget-9-check'
        type='checkbox'
        checked={checked}
        disabled={disabled}
      />
    </div>
  )
}

export default Checkbox
