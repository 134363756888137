/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup";
import { Languages } from "./Languages";
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    window.location.replace("/login");
    dispatch(auth.actions.logout());
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  const handleOnThemeToggle = (checked: boolean) => {
    localStorage.setItem("theme", checked ? "dark" : "light");
    window?.location?.reload();
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          {user && user.profile_photo && user.profile_photo ? (
            <div className="symbol symbol-50px me-5">
              <img alt="Logo" src={user.profile_photo} />
            </div>
          ) : (
            <div className="symbol-label bgBrandLightPink brandPink me-5 p-3  ">
              {((user && user.firstname) || " ")[0]}
              {((user && user.lastname) || " ")[0]}
            </div>
          )}

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
            <span className="d-inline-block text-truncate" style={{maxWidth: "180px"}}>
              {(user && user.firstname) || ""} {(user && user.lastname) || ""}
            </span>
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a
              className="d-inline-block text-truncate fw-bold text-muted text-hover-primary fs-7"
              style={{
                wordBreak: "break-word",
                maxWidth: "180px"
              }}
            >
              {(user && user.email) || ""}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      {/* <div className='separator my-2'></div> */}

      <Languages />

      <div className="menu-item px-5 my-1">
        <a
          onClick={() => {
            history.push(`/account/edit`);
          }}
          className="menu-link px-5"
        >
          {intl.formatMessage({ id: "ACCOUNT_ACCOUNT_SETTINGS" })}
        </a>
      </div>

      <div className="menu-item px-5">
        <div onClick={logout} className="menu-link px-5">
          {intl.formatMessage({ id: "ACCOUNT_SIGN_OUT" })}
        </div>
      </div>

      {/* <div className="separator my-2"></div> */}

      {/* <div className="menu-item px-5">
        <div className="menu-content px-5">
          <label className="form-check form-switch form-check-custom form-check-solid pulse pulse-success">
            <input
              className="form-check-input w-30px h-20px"
              type="checkbox"
              value=""
              name="mode"
              checked={localStorage.getItem("theme") === "dark" ? true : false}
              onChange={(e) => {
                handleOnThemeToggle(e.target.checked);
              }}
            />
            <span className="pulse-ring ms-n1"></span>
            <span className="form-check-label text-gray-600 fs-7">
              Dark Mode
            </span>
          </label>
        </div>
      </div> */}
    </div>
  );
};

export { HeaderUserMenu };
