import {Action} from '@reduxjs/toolkit'

// custom
import {ResourceType} from '../types/getResourcesListResponseType'
import {RoleType} from '../types/getRolesResponseType'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SET_RESOURCES_LIST: 'SET_RESOURCES_LIST',
  SET_ROLES_LIST: 'SET_ROLES_LIST',
}

const initialState: RolesAndPermissionsIState = {
  resources: [],
  roles: [],
}

export interface RolesAndPermissionsIState {
  resources: ResourceType[]
  roles: RoleType[]
}

export const RolesAndPermissionsReducer = (
  state: RolesAndPermissionsIState = initialState,
  action: ActionWithPayload<RolesAndPermissionsIState>
) => {
  switch (action.type) {
    case actionTypes.SET_RESOURCES_LIST: {
      const resources = action.payload
      return {
        ...state,
        resources,
      }
    }
    case actionTypes.SET_ROLES_LIST: {
      const roles = action.payload
      return {
        ...state,
        roles,
      }
    }

    default:
      return state
  }
}

export const actions = {
  setResourcesList: (resources: ResourceType[]) => ({
    type: actionTypes.SET_RESOURCES_LIST,
    payload: resources,
  }),
  setRolesList: (roles: RoleType[]) => ({
    type: actionTypes.SET_ROLES_LIST,
    payload: roles,
  }),
}
