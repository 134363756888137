import { useFormik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";

// custom
import { useIntl } from "react-intl";
import { SuccessAlert } from "../../../sharedComponents/Alert";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import { ResourceType } from "../types/getResourcesListResponseType";
import { RoleType } from "../types/getRolesResponseType";

import { PermissionType } from "../../userManagement/types/getPermissionsResponseType";
import { updateRolesAndPermissions } from "../redux";
import RolesAndPermissionsCard from "./RolesAndPermissionsCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
// import LegalTextDetailsList from '../../legalTexts/components/LegalTextDetailsList'

interface ModalProps {
  show: boolean;
  role: RoleType;
}

interface Props {
  modalProps: ModalProps;
  closeModal: () => void;
  getRolesListAPI: () => void;
}

const EditRoleModal: FC<Props> = ({
  modalProps,
  closeModal,
  getRolesListAPI,
}) => {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const intl = useIntl();

  const { show, role } = modalProps;
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  const RESOURCE_PERMISSION_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCE_PERMISSION_TYPES" })
  ) as PermissionType[];
  let RESOURCES_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCES_TYPES" })
  ) as ResourceType[];

  const getRolePermissions = () => {
    const obj = {};
    // initialize object
    RESOURCES_TYPES.forEach((resource) => {
      // @ts-ignore
      obj[resource.id] = [];
    });
    role.resources?.forEach((resource) => {
      // @ts-ignore
      obj[resource.resource_id]?.push(resource.permission_id);
    });
    return obj;
  };

  // state
  const [rolePermissions, setrolePermissions] =
    useState<any>(getRolePermissions());
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState(false);
  const [toggles, setToggles] = useState<any>(
    // getIsLegalTechEnabled()
    {}
  );
  const [subToggles, setSubToggles] = useState<any>({});

  const projectIds = [6, 19];
  const managementIds = [1, 4, 5, 7, 11, 13, 21];
  const editorIds = [2, 3, 12, 15, 16, 20, 22, 24, 25, 26, 29]; // editor (i.e. masterdata)
  const settingsIds = [18];

  // formik
  const initialValues = {
    name: role.name || "",
  };
  const EditRoleModalSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "ROLES_PERMISSIONS_EDIT_ROLE_NAME_VALIDATION" })
    ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: EditRoleModalSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const payload = {
        roles_resources: [],
        name: values.name,
      };
      // role_id:1 super admin
      if (role?.id !== 1) {
        Object.keys(rolePermissions).forEach((key) => {
          // @ts-ignore
          payload.roles_resources.push({
            // @ts-ignore
            resource_id: key,
            // @ts-ignore
            permissions_id: rolePermissions[key],
          });
        });
      }

      updateRolesAndPermissions(role.id, payload)
        .then(() => {
          SuccessAlert(
            intl.formatMessage({
              id: "ROLES_PERMISSIONS_UPDATE_SUCCESS_MESSAGE",
            }),
            () => {},
            intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
          );
          // getrolesAndPermissionsAPI()
        })
        .catch(() => {
          setStatus(
            intl.formatMessage({ id: "ROLES_PERMISSIONS_UPDATE_ERROR_MESSAGE" })
          );
        })
        .finally(() => {
          getRolesListAPI();
          setLoading(false);
          closeModal();
        });
    },
  });

  const getSelectAllStatus = () => {
    if (role?.id === 1) {
      return true;
    } else {
      return Object.values(rolePermissions)?.every(
        (item: any) => item.length === 4
      );
    }
  };

  const onSelectAllCheckChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // slect all permissions
      const permission_ids = RESOURCE_PERMISSION_TYPES.map(
        (permission) => permission.id
      );
      const user_permissions: any = {};
      RESOURCES_TYPES.forEach((resource) => {
        user_permissions[resource.id] = permission_ids;
      });
      // setLegalTechEnabled(true);
      setrolePermissions(user_permissions);
      setToggles({
        ...toggles,
        ["project"]: true,
        ["management"]: true,
        ["editor"]: true,
        ["settings"]: true,
      });
      let obj: any = {};
      Object.keys(user_permissions)?.forEach((item) => {
        obj[item] = true;
      });
      setSubToggles(obj);
    } else {
      const obj = {};
      // initialize object
      RESOURCES_TYPES.forEach((resource) => {
        // @ts-ignore
        obj[resource.id] = [];
      });
      setrolePermissions(obj);
      setToggles({});
      setSubToggles({});
      // setrolePermissions(getRolePermissions());
    }
  };

  const updaterolePermissions = (
    resource_id: number,
    permission_id: number
  ) => {
    const rolePermissionsCopy = _.clone(rolePermissions);
    // check if already existing
    const index = (rolePermissions[resource_id] || []).findIndex(
      (id: number) => {
        return id === permission_id;
      }
    );
    // donot exist push the item
    if (index === -1) {
      rolePermissionsCopy[resource_id] = [
        ...(rolePermissionsCopy[resource_id] || []),
        permission_id,
      ];
    }
    // if already exists remove item
    else {
      // is select all is checked, remove check
      selectAll && setSelectAll(false);
      // if read is removed remove all other permissions
      if (permission_id === 1) {
        rolePermissionsCopy[resource_id] = [];
      }
      // remove the unselected permission
      else {
        const ab = _.clone(rolePermissionsCopy[resource_id]);
        ab.splice(index, 1);
        rolePermissionsCopy[resource_id] = ab;
      }
    }

    setrolePermissions(rolePermissionsCopy);
  };

  const handleOnToggle = (bool: boolean, type: string) => {
    setToggles({
      ...toggles,
      [type]: bool,
    });

    if (type === "project" && !bool) {
      let copy = _.clone(rolePermissions);

      projectIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }

    if (type === "management" && !bool) {
      let copy = _.clone(rolePermissions);

      managementIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }

    if (type === "editor" && !bool) {
      let copy = _.clone(rolePermissions);

      editorIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
    if (type === "settings" && !bool) {
      let copy = _.clone(rolePermissions);

      settingsIds.forEach((item: number) => {
        copy[item] = [];
      });

      setrolePermissions(copy);
    }
  };

  const handleOnSubToggle = (bool: boolean, type: string) => {
    setSubToggles({
      ...subToggles,
      [type]: bool,
    });
  };

  const isSubItemsChecked = (type: string) => {
    const dataIds = {
      project: projectIds,
      management: managementIds,
      editor: editorIds,
      settings: settingsIds,
    };
    //@ts-ignore
    const items = dataIds[type];

    return items?.some((item: any) => rolePermissions[item]?.length > 0);
  };

  useEffect(() => {
    setToggles({
      ...toggles,
      ["project"]: isSubItemsChecked("project"),
      ["management"]: isSubItemsChecked("management"),
      ["editor"]: isSubItemsChecked("editor"),
      ["settings"]: isSubItemsChecked("settings"),
    });
  }, [rolePermissions]);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="large-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "ROLES_PERMISSIONS_EDIT_ROLE_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_post_basic_form"
      >
        <Modal.Body>
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div>
              <div className="mt-2">
                <label className="required fw-bold fs-6 mb-2">
                  {intl.formatMessage({ id: "ROLES_PERMISSIONS_ROLE_NAME" })}
                </label>
                <input
                  {...formik.getFieldProps("name")}
                  placeholder={""}
                  className={"form-control form-control-lg form-control-solid"}
                  autoComplete="off"
                  // non editable for super-admin
                  disabled={role.id === 1}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.name}</span>
                  </div>
                )}
              </div>

              <div className="fv-row mt-10">
                <label className="fs-5 fw-bold mb-2">
                  {intl.formatMessage({ id: "ROLES_PERMISSIONS_HEADER" })}
                </label>
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5">
                    <tbody className="text-gray-600 fw-semibold">
                      <tr>
                        <td className="text-gray-800">
                          {intl.formatMessage({
                            id: "ROLES_PERMISSIONS_SUPER_ADMIN_ACCESS",
                          })}
                          <i
                            className="fas fa-exclamation-circle ms-1 fs-7"
                            data-bs-toggle="tooltip"
                            aria-label="Allows a full access to the system"
                            data-kt-initialized="1"
                          ></i>
                        </td>
                        <td className="position-relative w-75px">
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                            <Checkbox
                              checked={getSelectAllStatus()}
                              disabled={role?.id === 1}
                              onChange={onSelectAllCheckChange}
                            />
                            <span
                              className="form-check-label position-absolute "
                              style={{ top: "17px", right: "-26px" }}
                            >
                              {intl.formatMessage({
                                id: "ROLES_PERMISSIONS_SELECT_ALL",
                              })}
                            </span>
                          </label>
                        </td>
                        <td style={{ width: "400px" }}></td>
                      </tr>

                      {/* PROJECTS  */}
                      <RolesAndPermissionsCard
                        type={"project"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={projectIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* PROJECTS  */}

                      {/* MANAGEMENT  */}
                      <RolesAndPermissionsCard
                        type={"management"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={managementIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* MANAGEMENT  */}

                      {/* EDITOR  */}
                      <RolesAndPermissionsCard
                        type={"editor"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={editorIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* EDITOR  */}

                      {/* SETTINGS  */}
                      <RolesAndPermissionsCard
                        type={"settings"}
                        toggles={toggles}
                        handleOnToggle={handleOnToggle}
                        ids={settingsIds}
                        subToggles={subToggles}
                        rolePermissions={rolePermissions}
                        handleOnSubToggle={handleOnSubToggle}
                        updaterolePermissions={updaterolePermissions}
                        role={role}
                      />
                      {/* SETTINGS  */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {/* hide submit for super-admin roleid:1 */}
          {role.id !== 1 && (
            <Button
              variant="primary"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "WEBSITE_GENERAL_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditRoleModal;
