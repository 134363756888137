import { Action } from "@reduxjs/toolkit";

// custom

import { CountryType } from "../types/GetCountriesListResponseTypes";
import { GroupType } from "../types/GetGroupsListResponseType";
import { LabelType } from "../types/GetLabelsListResponseType";
import { IndustryGroupType } from "../../partnersMasterData/types/getIndustryGroupsResponseType";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_CATEGORIES_LIST: "SET_CATEGORIES_LIST",
  SET_GROUPS_LIST: "SET_GROUPS_LIST",
  SET_TAGS_LIST: "SET_TAGS_LIST",
  SET_CONTACTTYPES_LIST: "SET_CONTACTTYPES_LIST",
  SET_CONTACTTITLES_LIST: "SET_CONTACTTITLES_LIST",
  SET_COUNTRIES_LIST: "SET_COUNTRIES_LIST",
  SET_SYSTEM_SETTINGS_LIST: "SET_SYSTEM_SETTINGS_LIST",
  SET_INDUSTRY_GROUPS_LIST: "SET_INDUSTRY_GROUPS_LIST",
  SET_AUTHORS_LIST: "SET_AUTHORS_LIST",
  SET_APPLICATIONS_LIST: "SET_APPLICATIONS_LIST",
  SET_LABELS_LIST: "SET_LABELS_LIST",
  SET_REMINDERS_LIST: "SET_REMINDERS_LIST",
  SET_DEADLINE_GROUP_LIST: "SET_DEADLINE_GROUP_LIST",
  SET_PRODUCTTYPES_LIST: "SET_PRODUCTTYPES_LIST",
};

const initialState: GroupsInitState = {
  categoriesList: [],
  groupsList: [],
  tagsList: [],
  contactTypesList: [],
  contactTitlesList: [],
  countriesList: [],
  systemSettingsList: {},
  labelsList: [],
  industryGroupsList: [],
  productTypesList: [],
};

export interface GroupsInitState {
  categoriesList: GroupType[];
  groupsList: GroupType[];
  tagsList: GroupType[];
  contactTypesList: GroupType[];
  contactTitlesList: GroupType[];
  countriesList: CountryType[];
  systemSettingsList: {};
  labelsList: LabelType[];
  industryGroupsList: IndustryGroupType[];
  productTypesList: GroupType[];
}

export const MasterdataReducer = (
  state = initialState,
  action: ActionWithPayload<GroupsInitState>
) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES_LIST: {
      const categories = action.payload?.categoriesList || [];
      return { ...state, categoriesList: categories };
    }
    case actionTypes.SET_GROUPS_LIST: {
      const groups = action.payload?.groupsList || [];
      return { ...state, groupsList: groups };
    }
    case actionTypes.SET_TAGS_LIST: {
      const tags = action.payload?.tagsList || [];
      return { ...state, tagsList: tags };
    }
    case actionTypes.SET_CONTACTTYPES_LIST: {
      const contactTypes = action.payload?.contactTypesList || [];
      return { ...state, contactTypesList: contactTypes };
    }
    case actionTypes.SET_CONTACTTITLES_LIST: {
      const contactTitles = action.payload?.contactTitlesList || [];
      return { ...state, contactTitlesList: contactTitles };
    }
    case actionTypes.SET_COUNTRIES_LIST: {
      const countries = action.payload?.countriesList || [];
      return { ...state, countriesList: countries };
    }
    case actionTypes.SET_SYSTEM_SETTINGS_LIST: {
      const settings = action.payload?.systemSettingsList || [];
      return { ...state, systemSettingsList: settings };
    }
    case actionTypes.SET_LABELS_LIST: {
      const labelsList = action.payload?.labelsList || [];
      return { ...state, labelsList };
    }
    case actionTypes.SET_INDUSTRY_GROUPS_LIST: {
      const industryGroupsList = action.payload?.industryGroupsList || [];
      return { ...state, industryGroupsList: industryGroupsList };
    }
    case actionTypes.SET_PRODUCTTYPES_LIST: {
      const productTypes = action.payload?.productTypesList || [];
      return { ...state, productTypesList: productTypes };
    }

    default:
      return state;
  }
};

export const actions = {
  setCategoriesList: (categoriesList: GroupType[]) => ({
    type: actionTypes.SET_CATEGORIES_LIST,
    payload: { categoriesList },
  }),
  setGroupsList: (groupsList: GroupType[]) => ({
    type: actionTypes.SET_GROUPS_LIST,
    payload: { groupsList },
  }),
  setTagsList: (tagsList: GroupType[]) => ({
    type: actionTypes.SET_TAGS_LIST,
    payload: { tagsList },
  }),
  setContactTypesList: (contactTypesList: GroupType[]) => ({
    type: actionTypes.SET_CONTACTTYPES_LIST,
    payload: { contactTypesList },
  }),
  setContactTitlesList: (contactTitlesList: GroupType[]) => ({
    type: actionTypes.SET_CONTACTTITLES_LIST,
    payload: { contactTitlesList },
  }),
  setCountriesList: (countriesList: CountryType[]) => ({
    type: actionTypes.SET_COUNTRIES_LIST,
    payload: { countriesList },
  }),
  setSystemSettingsList: (systemSettingsList: {}) => ({
    type: actionTypes.SET_SYSTEM_SETTINGS_LIST,
    payload: { systemSettingsList },
  }),
  setLabelsList: (labelsList: LabelType[]) => ({
    type: actionTypes.SET_LABELS_LIST,
    payload: { labelsList },
  }),
  setIndustryGroupsList: (industryGroupsList: IndustryGroupType[]) => ({
    type: actionTypes.SET_INDUSTRY_GROUPS_LIST,
    payload: { industryGroupsList },
  }),
  setProductTypesList: (productTypesList: GroupType[]) => {
    return {
      type: actionTypes.SET_PRODUCTTYPES_LIST,
      payload: { productTypesList },
    };
  },
};
