import { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap-v5";
import * as Yup from "yup";
import { useFormik } from "formik";
// import clsx from 'clsx'
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { updateUserRole } from "../redux";
import { UserType } from "../../auth/types/LoginResponseTypes";
import { SuccessAlert } from "../../../sharedComponents/Alert";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface ModalProps {
  show: boolean;
  type: "new" | "edit";
  role: RoleType | undefined;
}

interface Props {
  modalProps: ModalProps;
  closeModal: () => void;
  userDetails: UserType;
  getUserDetailsAPI: () => void;
}

const EditUserRoleModal: FC<Props> = ({
  modalProps,
  userDetails,
  closeModal,
  getUserDetailsAPI,
}) => {
  const rolesList = useSelector<RootState>(
    //   @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];
  const intl = useIntl();

  const { show, type, role } = modalProps;

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    user_role_id: type === "edit" ? role?.id || undefined : undefined,
  };

  const EditUserRoleModalSchema = Yup.object().shape({
    user_role_id: Yup.number().required(
      intl.formatMessage({ id: "ADD_ROLE_MODAL_FIELD_NAME_ERROR" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: EditUserRoleModalSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if (values.user_role_id) {
        updateUserRole(userDetails.id, values.user_role_id)
          .then(() => {
            SuccessAlert(
              intl.formatMessage({ id: "ADD_ROLE_SUCCESS_MESSAGE" }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
            closeModal();
          })
          .catch(() => {
            setStatus(intl.formatMessage({ id: "ADD_ROLE_FAILURE_MESSAGE" }));
          })
          .finally(() => {
            getUserDetailsAPI();
            setLoading(false);
          });
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      className="overflow-visible"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{type === "edit" ? "Edit role" : "Add role"}</Modal.Title>
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_post_basic_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            {/* Role */}
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "ADD_ROLE_MODAL_FIELD_NAME" })}
              </span>
            </label>
            <DropDown
              id="UserRoleTypeDropDown"
              hideSearch
              items={rolesList || []}
              displayValue={
                rolesList.find((role) => role.id === formik.values.user_role_id)
                  ?.name || ""
              }
              selectedItem={rolesList.find(
                (role) => role.id === formik.values.user_role_id
              )}
              onSelectItem={(item) => {
                formik.setFieldValue("user_role_id", item.id);
              }}
              displayFunc={(item) => {
                return item.name;
              }}
            />
            {formik.touched.user_role_id && formik.errors.user_role_id && (
              <div className="text-danger">
                <span role="alert">{formik.errors.user_role_id}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "WEBSITE_GENERAL_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditUserRoleModal;
