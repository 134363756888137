import React, { FC, useState } from "react";
import { GoogleAccountType } from "../../google/types/googleResponseType";
import { useIntl } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";

interface Props {
  list: GoogleAccountType[];
  closeModal: (value: GoogleAccountType | undefined) => void;
  show: boolean;
}

const ShowGetListAccounts: FC<Props> = ({ list, closeModal, show }) => {
  // context
  const intl = useIntl();

  // state
  const [selectAccountsList, setSelectAccountsList] =
    useState<GoogleAccountType>();

  return (
    <Modal
      show={show}
      dialogClassName="medium-size-modal AddNew"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
          <Modal.Title>
            {intl.formatMessage({ id: "SELECT_ACCOUNTS_TITLE" })}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <label className="form-label fs-6 fw-bolder mb-3">
            {intl.formatMessage({ id: "CONNECTED_LIST_ACCOUNT_NAME" })}
          </label>
          <DropDown
            id="post-groups-dropdown"
            hideSearch
            //@ts-ignore
            items={(list && list) || []}
            //@ts-ignore
            displayFunc={(item: GoogleAccountType) => item.app_name}
            onSelectItem={(item) => {
              // @ts-ignore
              setSelectAccountsList(item);
            }}
            displayValue={selectAccountsList && selectAccountsList.app_name}
            // @ts-ignore
            selectedItem={selectAccountsList}
          />
        </>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <>
          <Button
            variant="secondary"
            onClick={() => {
              closeModal(undefined);
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {list && list.length > 0 ? (
            <Button
              variant="primary"
              onClick={() => {
                closeModal(selectAccountsList);
              }}
              //@ts-ignore
              // disabled={selectAccountsList && selectAccountsList.length > 0 ? false : true}
            >
              {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            </Button>
          ) : null}
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowGetListAccounts;
