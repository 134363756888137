import React, { FC } from "react";
import { useIntl } from "react-intl";
import { ResourceType } from "../types/getResourcesListResponseType";
import { PermissionType } from "../../userManagement/types/getPermissionsResponseType";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import { RoleType } from "../types/getRolesResponseType";

interface Props {
  type: string;
  toggles: any;
  handleOnToggle: any;
  ids: number[];
  subToggles: any;
  rolePermissions: any;
  handleOnSubToggle: any;
  updaterolePermissions: any;
  role: RoleType;
}

const RolesAndPermissionsCard: FC<Props> = ({
  type,
  toggles,
  handleOnToggle,
  ids,
  subToggles,
  rolePermissions,
  handleOnSubToggle,
  updaterolePermissions,
  role,
}) => {
  const intl = useIntl();

  let RESOURCES_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCES_TYPES" })
  ) as ResourceType[];

  const RESOURCE_PERMISSION_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCE_PERMISSION_TYPES" })
  ) as PermissionType[];

  const getIsPermissionChecked = (
    resource_id: number,
    permission_id: number
  ) => {
    if (role?.id === 1) {
      return true;
    }
    const index = (rolePermissions[resource_id] || []).findIndex(
      (id: number) => {
        return id === permission_id;
      }
    );
    return index >= 0;
  };

  const getIsCheckboxDisabled = (
    resource_id: number,
    permission_id: number
  ) => {
    // permissions cannot be edited for role_id :1 => super admin
    if (role?.id === 1) {
      return true;
    }
    // permission_id:1 => should not be disabled
    if (permission_id === 1) {
      return false;
    }
    const permissions = rolePermissions[resource_id] || [];
    return !permissions?.includes(1);
  };

  return (
    <>
      <tr>
        <td>
          <span className="text-gray-800 fw-bolder flex-1">
            {intl.formatMessage({
              id:
                type === "project"
                  ? "MENU_CONTENT"
                  : type === "management"
                    ? "MENU_SALES"
                    : type === "editor"
                      ? "MENU_PROJECTS"
                      : type === "calender"
                        ? "MENU_CALENDAR"
                        : type === "settings"
                          ? "MENU_SETTINGS"
                          : "MENU_SYSTEM",
            })}
          </span>
        </td>
        <td>
          <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              name="notifications"
              value=""
              checked={toggles?.[type]}
              style={{
                width: "36px",
                height: "20px",
              }}
              onChange={(e) => {
                if (type === "legaltech") {
                  handleOnSubToggle(e.target.checked, "legal-tech");
                  updaterolePermissions(32, 1);
                } else {
                  handleOnToggle(e.target.checked, type);
                }
              }}
            />
          </div>
        </td>
        <td></td>
      </tr>
      {toggles?.[type] &&
        RESOURCES_TYPES.filter((item) => ids?.includes(item.id)).map(
          (resource, i) => {
            if (resource.id === 32) {
              return null;
            }
            return (
              <tr key={i}>
                <td>
                  <span className="text-gray-800 fw-bold border-start border-2 border-primary ms-5 ps-3">
                    {resource.name}
                  </span>
                </td>
                <td>
                  <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="notifications"
                      value=""
                      checked={
                        subToggles?.[resource?.slug] ||
                        rolePermissions[resource?.id]?.length > 0
                      }
                      style={{
                        width: "36px",
                        height: "20px",
                      }}
                      onChange={(e) => {
                        handleOnSubToggle(e.target.checked, resource?.slug);
                        updaterolePermissions(resource.id, 1);
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <>
                      {RESOURCE_PERMISSION_TYPES.map((permission, i) => {
                        return (
                          <label
                            key={i}
                            className="form-check form-check-sm form-check-custom form-check-solid me-3 me-lg-8"
                          >
                            <Checkbox
                              checked={
                                getIsPermissionChecked(
                                  resource.id,
                                  permission.id
                                ) ||
                                (subToggles?.[resource?.slug] && i == 0)
                              }
                              onChange={() => {
                                i !== 0 &&
                                  updaterolePermissions(
                                    resource.id,
                                    permission.id
                                  );
                              }}
                              disabled={
                                getIsCheckboxDisabled(
                                  resource.id,
                                  permission.id
                                ) ||
                                rolePermissions[resource?.id]?.length === 0 ||
                                (rolePermissions[resource?.id]?.includes(1) &&
                                  i == 0)
                              }
                            />
                            <span className="form-check-label">
                              {permission.name}
                            </span>
                          </label>
                        );
                      })}
                    </>
                  </div>
                </td>
              </tr>
            );
          }
        )}
    </>
  );
};

export default RolesAndPermissionsCard;
