import React, { FC, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import clsx from "clsx";
import _ from "lodash";

// images
import CopyIcon from "../../../../_metronic/assets/icons/copy.svg";

// custom
import {
  FileType,
  FolderType,
  ResolutionType,
} from "../types/getFilemanagerDataResponseType";
import { createNewFolder, renameFolder, updateFile } from "../redux";
import { SuccessAlert, errorAlert } from "../../../sharedComponents/Alert";
import Constants from "../../../config/Constants";
import { useIntl } from "react-intl";
import { pdfjs } from "react-pdf";
import { getFile } from "./../redux/FilemanagerAPI";
import { SVGICON } from "../../../../_metronic/helpers";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FolderModalProps {
  show: boolean;
  type?: string;
  item?: FolderType | FileType;
  format?: "folder" | "file";
}

interface Props {
  modalProps: FolderModalProps;
  closeModal: () => void;
  getFilemanagerDataAPI?: (folder_id?: number | null) => void;
  parent_id?: number | null;
}

interface FormValuesType {
  folderName?: string | undefined;
  copyright_text?: string | undefined;
}

const FolderActionsModal: FC<Props> = ({
  modalProps,
  closeModal,
  getFilemanagerDataAPI,
  parent_id,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const { show, type, item, format = "folder" } = modalProps;
  const initialValues = {
    folderName: type === "new" ? "" : item?.name,
    copyright_text: item?.copyright_text || "",
  };

  const iframeRef = useRef<any>();

  const FolderActionsSchema = Yup.object().shape({
    folderName:
      format !== "folder"
        ? Yup.string()
            .max(
              60,
              intl.formatMessage({
                id: "FILEMANAGER_FIELD_60SYBMOLS_REQUIRED_MESSAGE",
              })
            )
            .required(
              intl.formatMessage({
                id: "FILEMANAGER_EDIT_FOLDER_REQUIRED_MESSAGE",
              })
            )
        : Yup.string()
            .max(60, "Maximum 60 symbols")
            .required(
              intl.formatMessage({
                id: "FILEMANAGER_EDIT_FILE_REQUIRED_MESSAGE",
              })
            )
            .matches(
              /^[\d\w]+[\d\w@_-]*$/,
              intl.formatMessage({
                id: "FILEMANAGER_EDIT_FOLDER_FORMAT_MESSAGE",
              })
            ),
    // copyright_text: format !== 'folder' ? Yup.string().max(120, intl.formatMessage({id:'FILEMANAGER_FIELD_120SYBMOLS_REQUIRED_MESSAGE'})).required('copyright_text is required') : Yup.string()
  });

  const getIframeLink = (id: any) => {
    let url;
    getFile(id)
      .then((res) => {
        url = (res && res.data.fileDetails.file_url) || undefined;
        iframeRef.current.src = url;
      })
      .catch((e) => {
        // console.log('this is error', e)
      });
    return url;
  };

  const onFormSubmit = (values: FormValuesType) => {
    if (format === "folder") {
      if (type === "new") {
        if (values.folderName) {
          setLoading(true);
          // @ts-ignore
          createNewFolder(item?.id, values.folderName)
            .then(() => {
              closeModal?.();
              SuccessAlert(
                intl.formatMessage({ id: "FILEMANAGER_CREATE_FOLDER_MESSAGE" }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch((e) => {
              closeModal?.();
              errorAlert(
                intl.formatMessage({
                  id: "FILEMANAGER_CREATE_FOLDER_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              // recall  get categories list API
              // @ts-ignore
              getFilemanagerDataAPI?.(parent_id);
              setLoading(false);
            });
        }
      } else {
        if (values.folderName) {
          setLoading(true);
          // @ts-ignore
          renameFolder(item?.id, item?.folders_id, values.folderName)
            .then(() => {
              closeModal?.();
              SuccessAlert(
                intl.formatMessage({ id: "FILEMANAGER_UPDATE_FOLDER_MESASGE" }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch((e) => {
              closeModal?.();
              errorAlert(
                intl.formatMessage({
                  id: "FILEMANAGER_UPDATE_FOLDER_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              // recall  get categories list API
              // @ts-ignore
              getFilemanagerDataAPI?.(parent_id);
              setLoading(false);
            });
        }
      }
    } else {
      if (values.folderName) {
        setLoading(true);
        updateFile(
          // @ts-ignore
          item?.id,
          item?.folders_id,
          values.folderName,
          values.copyright_text
        )
          .then(() => {
            closeModal?.();
            SuccessAlert(
              intl.formatMessage({ id: "FILEMANAGER_EDIT_FILE_MESSAGE" }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((e) => {
            closeModal?.();
            errorAlert(
              intl.formatMessage({
                id: "FILEMANAGER_EDIT_FILE_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            // recall  get categories list API
            // @ts-ignore
            getFilemanagerDataAPI?.(parent_id);
            setLoading(false);
          });
      }
    }
  };

  return (
    <Modal
      size={type === "edit" && format !== "folder" ? "lg" : undefined}
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {format === "folder"
            ? type === "new"
              ? "Create new Folder"
              : "Edit folder"
            : item?.name}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={FolderActionsSchema}
        onSubmit={(values) => {
          // @ts-ignore
          onFormSubmit(values);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <>
            <Modal.Body>
              {format === "folder" ? null : (
                <div className="p-4">
                  {item ? (
                    <div className="">
                      {/* image file */}
                      {Constants.mimeTypes.image.includes(item.type) && (
                        <div
                          className="d-flex flex-row justify-content-center"
                          style={{ maxHeight: "250px" }}
                        >
                          <img
                            src={item.file_path}
                            alt=""
                            style={{
                              objectFit: "contain",
                              width: "300px",
                              height: "150px",
                            }}
                          />
                        </div>
                      )}
                      {/* document file */}
                      {Constants.mimeTypes.document.includes(item.type) && (
                        <div
                          className="d-flex flex-row justify-content-center"
                          style={{ height: "300px" }}
                        >
                          {/* <DocViewer documents={[{uri:"https://docs.google.com/viewer?url="+item.file_path+"&embedded=true"}]}  pluginRenderers={DocViewerRenderers} /> */}

                          {/* @ts-ignore */}
                          {/* <Document file={"https://docs.google.com/viewerng/viewer?url="+item.file_path+"&embedded=true"}></Document> */}
                          {/* <object
                          data={"https://docs.google.com/viewer?url="+item.file_path+"&embedded=true"}
                          type='application/pdf'
                          width='100%'
                          height='300'
                        > */}

                          <iframe
                            ref={iframeRef}
                            src={getIframeLink(item.id)}
                            height="300"
                            width="500"
                            title={item.name}
                          >
                            {/* <p>
                              {intl.formatMessage({id:'FILEMANAGER_BROWSER_PDF_MESSAGE'})}
                              <a href={item.file_path}>{intl.formatMessage({id:'FILEMANAGER_DOWNLOAD_PDF_BUTTON'})}</a>.
                            </p> */}
                          </iframe>
                          {/* </object> */}
                        </div>
                      )}
                      {/* audio file */}
                      {Constants.mimeTypes.audio.includes(item.type) && (
                        <div
                          className="d-flex flex-row justify-content-center"
                          style={{ maxHeight: "500px" }}
                        >
                          <audio controls autoPlay>
                            <source src={item.file_path} />
                            {intl.formatMessage({
                              id: "FILEMANAGER_BROWSER_AUDIO_MESSAGE",
                            })}
                          </audio>
                        </div>
                      )}
                      {/* video file */}
                      {Constants.mimeTypes.video.includes(item.type) && (
                        <div
                          className="d-flex flex-row justify-content-center"
                          style={{ maxHeight: "500px" }}
                        >
                          <video
                            controls
                            autoPlay
                            style={{ height: "100%", width: "100%" }}
                          >
                            <source src={item.file_path} />
                            {intl.formatMessage({
                              id: "FILEMANAGER_BROWSER_VIDEO_MESSAGE",
                            })}
                          </video>
                        </div>
                      )}
                    </div>
                  ) : (
                    <span>
                      {intl.formatMessage({
                        id: "FILEMANAGER_BROWSER_DISPLAY_FAILURE_MESSAGE",
                      })}
                    </span>
                  )}
                </div>
              )}
              {/* {(type === 'edit' || type === 'new') && ( */}
              <div className="p-4">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="required">
                    {format === "folder"
                      ? intl.formatMessage({
                          id: "FILEMANAGER_EDIT_FILE_FOLDERNAME_FIELD_TITLE",
                        })
                      : intl.formatMessage({
                          id: "FILEMANAGER_EDIT_FILE_FILENAME_FIELD_TITLE",
                        })}
                  </span>
                </label>

                <input
                  placeholder={
                    format === "folder"
                      ? intl.formatMessage({
                          id: "FILEMANAGER_EDIT_FILE_FOLDERNAME_PLACEHOLDER",
                        })
                      : intl.formatMessage({
                          id: "FILEMANAGER_EDIT_FILE_FILENAME_PLACEHOLDER",
                        })
                  }
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  name="folderName"
                  autoComplete="off"
                  onChange={handleChange("folderName")}
                  value={values.folderName}
                />
                {touched.folderName && errors.folderName && (
                  <div className="text-danger">
                    <span role="alert">{errors.folderName}</span>
                  </div>
                )}
              </div>
              {/* )} */}
              {/* {(type === 'edit' || type === 'new') && ( */}
              {format === "file" && (
                <div className="p-4">
                  <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                    <span>
                      {intl.formatMessage({
                        id: "FILEMANAGER_EDIT_FILE_COPYRIGHT_FIELD_TITLE",
                      })}
                    </span>
                  </label>

                  <input
                    placeholder={intl.formatMessage({
                      id: "FILEMANAGER_EDIT_FILE_COPYRIGHT_PLACEHOLDER",
                    })}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                    name="copyright_text"
                    autoComplete="off"
                    onChange={handleChange("copyright_text")}
                    value={values.copyright_text}
                  />
                  {touched.copyright_text && errors.copyright_text && (
                    <div className="text-danger">
                      <span role="alert">{errors.copyright_text}</span>
                    </div>
                  )}
                </div>
              )}

              {/* )} */}
              {type === "edit" && format !== "folder" && (
                <div className="p-4">
                  {
                    // @ts-ignore
                    !_.isEmpty(item.resolutions) && (
                      <>
                        <div className="divider-sm"> </div>
                        {
                          // @ts-ignore
                          item.resolutions.map((resolution: ResolutionType) => {
                            return (
                              <div className="mt-6">
                                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                  {resolution.resolution}
                                </label>
                                <div
                                  className={clsx(
                                    "form-control form-control-lg form-control-solid d-flex align-items-center"
                                  )}
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <span
                                    className="me-2"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {resolution.file_url}
                                  </span>

                                  <div
                                    role="button"
                                    onClick={() => {
                                      //@ts-ignore
                                      navigator.clipboard
                                        ?.writeText(resolution.file_url)
                                        .then(() => {
                                          successToast(
                                            intl.formatMessage({
                                              id: "FILEMANAGER_FILE_LINK_COPY_SUCCESS_MESSAGE",
                                            })
                                          );
                                        });
                                    }}
                                  >
                                    <SVGICON
                                      src={CopyIcon}
                                      className="svg-icon-2x"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        }
                      </>
                    )
                  }
                </div>
              )}
            </Modal.Body>

            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={closeModal}>
                {intl.formatMessage({
                  id: "FILEMANAGER_EDIT_MODAL_CLOSE_BUTTON",
                })}
              </Button>
              {(type === "edit" || type === "new") && (
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {!loading &&
                    intl.formatMessage({
                      id: "FILEMANAGER_EDIT_MODAL_SUBMIT_BUTTON",
                    })}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {intl.formatMessage({
                        id: "FILEMANAGER_EDIT_MODAL_LOADING_MESSAGE",
                      })}{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </Button>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default FolderActionsModal;
