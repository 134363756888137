import React, { FC, useEffect, useState } from "react";
import { DealType } from "../types/DealResponseType";
import { useIntl } from "react-intl";
import {
  FilemanagerDataType,
  FileType,
} from "../../filemanager/types/getFilemanagerDataResponseType";
import { getFilemanagerData } from "../../filemanager/redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { bytesToSize } from "../../../utils/BytesToSize";
import moment from "moment";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";

interface Props {
  deal: DealType;
  pipeline_id: number;
}

const DealProjectFiles: FC<Props> = ({ deal, pipeline_id }) => {
  // selector
  const intl = useIntl();
  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ deals }) => deals.dealPipelines
  ) as any[];

  //   state
  const [files, setFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState(false);

  const getAllFiles = () => {
    getFilemanagerData(null)
      .then(({ data }) => {
        const currentPipeline = pipelineList.filter(
          (res) => res.id === pipeline_id
        )[0];
        if (currentPipeline && currentPipeline.id) {
          const selectedPipeline = data.folders.filter(
            (temp) => temp.name === currentPipeline.name
          )[0];
          if (selectedPipeline.id) {
            getFilemanagerData(selectedPipeline.id).then((data) => {
              const dealData = data.data;
              const currentDeal = dealData.folders.filter(
                (dealInfo) => dealInfo.name === deal.name
              )[0];
              if (currentDeal.id) {
                getFilemanagerData(currentDeal.id).then((data) => {
                  const dealFiles = data.data.files;
                  if (dealFiles && dealFiles.length > 0) {
                    setFiles(dealFiles);
                  }
                });
              }
            });
          }
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllFiles();
  }, []);

  return (
    <div
      className="card p-0"
      style={{
        flex: 1,
      }}
    >
      <div className="card-header">
        <div className="card-title">
          <h2>{intl.formatMessage({ id: "DEAL_PROJECT_FILES_TAB" })}</h2>
        </div>
      </div>
      <div className="card-body">
        {loading && (
          <div>
            <SimpleLoader />
          </div>
        )}
        {!loading && files && files.length > 0 && (
          <>
            <div className="d-flex flex-wrap gap-4">
              {files.map((file) => {
                return (
                  <div className="  border rounded bg-secondary" role="button">
                    <div className="d-flex  gap-4 flex-column ">
                      <img
                        src={file.file_path}
                        height={"auto"}
                        width={"200px"}
                      />
                      <div className="d-flex flex-column align-items-center p-3 pt-0">
                        <span
                          role="button"
                          className="text-gray-900 fw-semibold "
                        >
                          {file.name}
                        </span>
                        <span className="text-gray-700">
                          {bytesToSize(file.size)}{" "}
                        </span>
                        <span className="text-gray-700">
                          {moment(file.created_at).format(
                            "MMM Do YYYY, h:mm a"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <>
          {!loading && files && files.length === 0 && (
            <div className="text-muted fs-6 d-flex align-items-center justify-content-center">
              {intl.formatMessage({ id: "NO_DATA_FOUND" })}
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default DealProjectFiles;
