import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";
import Filemanager from "./Filemanager";

const FileManagerPage = () => {
  const intl = useIntl();

  const fileManagementBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
      path: "/dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "FILEMANAGER_TITLE" }),
      path: "/file-manager",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "FILEMANAGER_TITLE" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/file-manager/overview">
          <PageTitle
            breadcrumbs={[
              ...fileManagementBreadCrumbs,
              {
                title: intl.formatMessage({
                  id: "ROUTES_FILEMANAGER_OVERVIEW",
                }),
                path: "/file-manager/overview",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "FILEMANAGER_TITLE" })}
          </PageTitle>
          <Filemanager isMultiple={true} />
        </Route>

        <Redirect
          from="/file-manager"
          exact={true}
          to="/file-manager/overview"
        />
        <Redirect to="/file-manager/overview" />
      </Switch>
    </div>
  );
};

export default FileManagerPage;
