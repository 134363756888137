import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../../app/modules/auth";

import { UsermanagementReducer } from "../../app/modules/userManagement/redux";

import { CustomerManagementReducer } from "../../app/modules/customerManagement/redux";

import { FilemanagerReducer } from "../../app/modules/filemanager/redux";
import { RolesAndPermissionsReducer } from "../../app/modules/RolesAndPermissions/redux";
import { MasterdataReducer } from "../../app/modules/masterdata/redux";
import { PartnerReducer } from "../../app/modules/partnerManagement/redux";
import { ProductReducer } from "../../app/modules/products/redux/ProductsRedux";
import { ContentMasterdataReducer } from "../../app/modules/projectMasterData/redux/ContentMasterRedux";
import { PipelineReducer } from "../../app/modules/pipelines/redux/PipelinesRedux";
import { DealsdataReducer } from "../../app/modules/deals/redux/DealsRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  filemanager: FilemanagerReducer,
  userManagement: UsermanagementReducer,
  partners: PartnerReducer,
  customerManagement: CustomerManagementReducer,
  rolesAndPermissionsManagement: RolesAndPermissionsReducer,
  masterdata: MasterdataReducer,
  products: ProductReducer,
  contentMaster: ContentMasterdataReducer,
  pipelines: PipelineReducer,
  deals: DealsdataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga()]);
}
