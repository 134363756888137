import { VerifyResponse } from "./../../auth/types/LoginResponseTypes";
import axios from "axios";

// custom
import { RecoveryCodeResponseType } from "../types/getRecoveryCodesResponseType";
import { QRCodeResponseType } from "../types/getQrCodeResponseType";
import { GenericResponseType } from "../../../types/GenericReponse";
import { LoginResponseType } from "../../auth/types/LoginResponseTypes";

export const loginAfter2FAURL = `/user/loginAfter2FA`;

// enable 2-factor auth
export function enableTwoFactorAuthentication(id: number) {
  return axios.post<GenericResponseType>(`/user/twoFactorAuth/enable/${id}`);
}

// disable 2-factor auth
export function disableTwoFactorAuthentication(id: number) {
  return axios.delete<GenericResponseType>(`/user/twoFactorAuth/disable/${id}`);
}

// get QR code
export function getQRCode(id: number) {
  return axios.get<QRCodeResponseType>(`/user/twoFactorAuth/QRCode/${id}`);
}

// get Recovery codes
export function getRecoveryCodes(id: number) {
  return axios.get<RecoveryCodeResponseType>(
    `/user/twoFactorAuth/recoveryCodes/${id}`
  );
}

// verify codes
export function verifyCode(
  id: number,
  recovery_code?: string,
  verify_code?: string
) {
  let obj = {
    recovery_code: recovery_code,
    verify_code: verify_code,
  };
  if (!recovery_code) {
    delete obj["recovery_code"];
  }
  if (!verify_code) {
    delete obj["verify_code"];
  }

  return axios.post<VerifyResponse>(`/user/twoFactorAuth/verify/${id}`, obj);
}

// login after 2FA
export function loginAfter2FA(
  id: number,
  username: string,
  password: string,
  client_id: any,
  client_secret: string
) {
  return axios.post<LoginResponseType>(`${loginAfter2FAURL}/${id}`, {
    client_id,
    client_secret,
    username,
    password,
    grant_type: "password",
  });
}
