import axios from "axios";

// Custom types
import { GenericResponseType } from "../../../types/GenericReponse";
import { NotesResponseType } from "../types/getNotesResponseTypes";

// Utility to generate payload based on presence of users_id and deals_id
const generatePayload = (
  users_id?: number | null,
  deals_id?: number | null,
  note?: string
) => {
  const payload: any = {};
  if (users_id) {
    payload.users_id = users_id;
  }
  if (deals_id) {
    payload.deals_id = deals_id;
  }
  if (note) {
    payload.description = note;
  }
  return payload;
};

// Fetch notes
export function getNotes(users_id?: number | null, deals_id?: number | null) {
  return axios.post<NotesResponseType>(
    "/notes/",
    generatePayload(users_id, deals_id, undefined)
  );
}

// Fetch note by ID
export function getNotesWithId(note_id: number) {
  return axios.get<NotesResponseType>(`/notes/${note_id}`);
}

// Create a new note
export function createNote(
  note: string,
  users_id?: number | null,
  deals_id?: number | null
) {
  return axios.post<NotesResponseType>(
    "/notes/create",
    generatePayload(users_id, deals_id, note)
  );
}

// Update an existing note
export function updateNote(
  note_id: number,
  note: string,
  users_id?: number | null,
  deals_id?: number | null
) {
  return axios.post<NotesResponseType>(
    `/notes/update/${note_id}`,
    generatePayload(users_id, deals_id, note)
  );
}

// Pin or unpin a note
export function updateNotePin(note_id: number, is_pinned: number) {
  return axios.post<NotesResponseType>(`/notes/updateIsPinned/${note_id}`, {
    is_pinned,
  });
}

// Delete a note
export function deleteNote(note_id: number) {
  return axios.delete<GenericResponseType>(`/notes/${note_id}`);
}
