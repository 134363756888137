/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import user from "../../../../_metronic/assets/icons/user.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import CalendarIcon from "../../../../_metronic/assets/icons/calendar.svg";

import role from "../../../assets/icons/role.svg";

// custom
import { useSelector } from "react-redux";

import { UserType } from "../../../../app/modules/userManagement/types/getUsersListResponseType";
import { RootState } from "../../../../setup";
import { actions } from "../../../../app/modules/auth";

import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { PipelineType } from "../../../../app/modules/pipelines/types/PipelinesResponseType";
import { useDispatch } from "react-redux";
import { isPermissionEnabledForResource } from "../../../../app/utils/PermisisionEnabledForResource";
import { RoleType } from "../../../../app/modules/RolesAndPermissions/types/getRolesResponseType";
import { getRoleDetails } from "../../../../app/modules/RolesAndPermissions/redux";

export function AsideMenuMain() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [showloadData, setShowLoadData] = useState(false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ deals }) => deals.dealPipelines
  ) as any[];
  const id = localStorage.getItem("pId");

  useEffect(() => {
    if (userData?.id) {
      setShowLoadData(true);
    }
    if (id) {
      dispatch(actions.setActivePipelineId(Number(id)));
    }
  }, []);

  useEffect(() => {
    getRoleDetails(userData?.role.id).then(({ data: { rolesDetails } }) => {
      dispatch(actions.setRoleDetails(rolesDetails));
    });
  }, []);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      {showloadData && (
        <div>
          <AsideMenuItem
            to="/dashboard"
            title={intl.formatMessage({ id: "MENU_DASHBOARD" })}
            fontIcon="bi-app-indicator"
            icon={"/media/icons/duotune/general/gen001.svg"}
          />
          {
            <div className="pt-1">
              <>
                <AsideMenuItem
                  to="/crm/calendar"
                  title={intl.formatMessage({ id: "MENU_CALENDAR" })}
                  fontIcon="bi-chat-left"
                  icon={CalendarIcon}
                />
              </>
            </div>
          }

          <div className="pt-1">
            {(isPermissionEnabledForResource(userRoleDetails, "file-manager") ||
              isPermissionEnabledForResource(userRoleDetails, "deals") ||
              isPermissionEnabledForResource(userRoleDetails, "pipelines")) && (
              <AsideMenuItemWithSub
                to="/content"
                title={intl.formatMessage({ id: "MENU_CONTENT" })}
                fontIcon="bi-chat-left"
                icon={"/media/icons/duotune/abstract/abs026.svg"}
              >
                {isPermissionEnabledForResource(userRoleDetails, "deals") &&
                pipelineList &&
                pipelineList.length > 0 ? (
                  pipelineList.map((pipeline, index) => (
                    <div
                      onClick={() => {
                        localStorage.setItem("pId", pipeline.id.toString());
                        dispatch(actions.setActivePipelineId(pipeline.id));
                      }}
                      key={index}
                    >
                      <AsideMenuItem
                        key={index}
                        to={`/content/deals`}
                        title={pipeline.name.toUpperCase()}
                        fontIcon="bi-chat-left"
                        icon={"/media/icons/duotune/ecommerce/ecm011.svg"}
                        index={pipeline.id}
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <AsideMenuItem
                      to={`/projects/pipelines`}
                      title={intl.formatMessage({ id: "PROJECTS_PIPELINE_ADD_NEW_BUTTON" })}
                      fontIcon="bi-chat-left"
                      icon={PlusIcon}
                    />
                  </div>
                )}
                {isPermissionEnabledForResource(
                  userRoleDetails,
                  "file-manager"
                ) && (
                  <AsideMenuItem
                    to="/file-manager"
                    title={intl.formatMessage({ id: "MENU_FILEMANAGER" })}
                    fontIcon="bi-chat-left"
                    icon={"/media/icons/duotune/files/folder.svg"}
                  />
                )}
                {isPermissionEnabledForResource(
                  userRoleDetails,
                  "file-manager"
                ) && (
                  <AsideMenuItem
                    to="/deals/archived"
                    title={intl.formatMessage({ id: "MENU_DEALS_ARCHIVED" })}
                    fontIcon="bi-chat-left"
                    icon={"/media/icons/duotune/files/archive.svg"}
                  />
                )}
              </AsideMenuItemWithSub>
            )}
          </div>

          {(isPermissionEnabledForResource(userRoleDetails, "products") ||
            isPermissionEnabledForResource(userRoleDetails, "partners") ||
            isPermissionEnabledForResource(userRoleDetails, "contacts") ||
            isPermissionEnabledForResource(userRoleDetails, "users") ||
            isPermissionEnabledForResource(userRoleDetails, "teams") ||
            isPermissionEnabledForResource(
              userRoleDetails,
              "roles-and-permissions"
            ) ||
            isPermissionEnabledForResource(userRoleDetails, "categories")) && (
            <div className="pt-1">
              <AsideMenuItemWithSub
                to="/sales"
                title={intl.formatMessage({ id: "MENU_SALES" })}
                fontIcon="bi-chat-left"
                icon={"/media/icons/duotune/ecommerce/tag.svg"}
              >
                {isPermissionEnabledForResource(
                  userRoleDetails,
                  "products"
                ) && (
                  <AsideMenuItem
                    to="/sales/products"
                    title={intl.formatMessage({ id: "MENU_PRODUCTS" })}
                    fontIcon="bi-chat-left"
                    icon={"/media/icons/duotune/abstract/abs027.svg"}
                  />
                )}
                {isPermissionEnabledForResource(
                  userRoleDetails,
                  "partners"
                ) && (
                  <AsideMenuItem
                    to="/crm/partner"
                    title={intl.formatMessage({ id: "MENU_SYSTEM_PARTNER" })}
                    fontIcon="bi-chat-left"
                    icon={"/media/icons/duotune/ecommerce/ecm008.svg"}
                  />
                )}
                {isPermissionEnabledForResource(
                  userRoleDetails,
                  "contacts"
                ) && (
                  <AsideMenuItem
                    to="/crm/contacts"
                    title={intl.formatMessage({ id: "MENU_SYSTEM_CUSTOMER" })}
                    fontIcon="bi-chat-left"
                    icon={"/media/icons/duotune/general/user.svg"}
                  />
                )}
                {(userData?.role.id === 1 ||
                  isPermissionEnabledForResource(userRoleDetails, "users")) && (
                  <>
                    <AsideMenuItem
                      to="/system/user"
                      title={intl.formatMessage({ id: "MENU_SYSTEM_USER" })}
                      fontIcon="bi-chat-left"
                      icon={user}
                    />
                  </>
                )}
                {(userData?.role.id === 1 ||
                  isPermissionEnabledForResource(userRoleDetails, "teams")) && (
                  <>
                    <AsideMenuItem
                      to="/system/teams"
                      title={intl.formatMessage({
                        id: "MENU_TEAMS",
                      })}
                      icon="/media/icons/duotune/ecommerce/ecm008.svg"
                    />
                  </>
                )}
                {(userData?.role.id === 1 ||
                  isPermissionEnabledForResource(
                    userRoleDetails,
                    "roles-and-permissions"
                  )) && (
                  <>
                    <AsideMenuItem
                      to="/system/roles-permissions"
                      title={intl.formatMessage({
                        id: "ROLES_AND_PERMISSIONS_TITLE",
                      })}
                      icon={role}
                    />
                  </>
                )}
                {isPermissionEnabledForResource(
                  userRoleDetails,
                  "categories"
                ) && (
                  <AsideMenuItem
                    to="/master-data/categories"
                    title={intl.formatMessage({ id: "MENU_CATEGORIES" })}
                    fontIcon="bi-chat-left"
                    icon={"/media/icons/duotune/files/sub-menu.svg"}
                    // hasBullet={true}
                  />
                )}
              </AsideMenuItemWithSub>
            </div>
          )}

          {(isPermissionEnabledForResource(userRoleDetails, "pipelines") ||
            isPermissionEnabledForResource(userRoleDetails, "fields") ||
            isPermissionEnabledForResource(userRoleDetails, "tabs") ||
            isPermissionEnabledForResource(userRoleDetails, "groups") ||
            isPermissionEnabledForResource(userRoleDetails, "tags") ||
            isPermissionEnabledForResource(userRoleDetails, "labels") ||
            isPermissionEnabledForResource(userRoleDetails, "contacts-types") ||
            isPermissionEnabledForResource(userRoleDetails, "contacts-titles") ||
            isPermissionEnabledForResource(userRoleDetails, "products-types") ||
            isPermissionEnabledForResource(userRoleDetails, "system-messages") ||
            isPermissionEnabledForResource(userRoleDetails, "industries_sectors")) &&
            (
            <AsideMenuItemWithSub
              to=""
              title={intl.formatMessage({ id: "MENU_PROJECTS" })}
              fontIcon="bi-chat-left"
              icon={"/media/icons/duotune/communication/com002.svg"}
            >
              {isPermissionEnabledForResource(userRoleDetails, "pipelines") && (
                <AsideMenuItem
                  to="/projects/pipelines"
                  title={intl.formatMessage({ id: "MENU_PIPELINES" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/communication/com009.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "fields") && (
                <AsideMenuItem
                  to="/projects/master-data/fields"
                  title={intl.formatMessage({ id: "MENU_FIELDS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "tabs") && (
                <AsideMenuItem
                  to="/projects/master-data/tabs"
                  title={intl.formatMessage({ id: "MENU_TABS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "groups") && (
                <AsideMenuItem
                  to="/master-data/groups"
                  title={intl.formatMessage({ id: "MENU_GROUPS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "tags") && (
                <AsideMenuItem
                  to="/master-data/tags"
                  title={intl.formatMessage({ id: "MENU_TAGS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "labels") && (
                <AsideMenuItem
                  to="/master-data/labels"
                  title={intl.formatMessage({ id: "MENU_LABELS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails,"contacts-types") && (
                <AsideMenuItem
                  to="/master-data/contact-types"
                  title={intl.formatMessage({ id: "MENU_CONTACTTYPES" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails,"contacts-titles") && (
                <AsideMenuItem
                  to="/master-data/contact-titles"
                  title={intl.formatMessage({ id: "MENU_CONTACTTITLES" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails,"industries_sectors") && (
                <AsideMenuItem
                  to="/master-data/industries-sectors"
                  title={intl.formatMessage({
                    id: "MASTERDATA_INDUSTRIESSECTORS_TITLE",
                  })}
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails,"products-types") && (
                <AsideMenuItem
                  to="/master-data/product-types"
                  title={intl.formatMessage({
                    id: "MASTERDATA_PRODUCT_TYPES_TITLE",
                  })}
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails,"system-messages") && (
                <AsideMenuItem
                  to="/master-data/system-messages"
                  title={intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGES_TITLE",
                  })}
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                  // hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          )}

          {isPermissionEnabledForResource(userRoleDetails, "google") && (
            <div className="pt-1">
              <AsideMenuItemWithSub
                to="/channel"
                title={intl.formatMessage({ id: "MENU_SETTINGS" })}
                fontIcon="bi-chat-left"
                icon={"/media/icons/duotune/general/satellite.svg"}
              >
                <AsideMenuItem
                  to="/interfaces/google"
                  title={intl.formatMessage({ id: "MENU_GOOGLE" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/communication/google-icon.svg"}
                />
              </AsideMenuItemWithSub>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
