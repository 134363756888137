import React, { FC } from "react";
import { useIntl } from "react-intl";

// custom
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";

import { SVGICON } from "../../../../_metronic/helpers";
// import Tabs from '../../../sharedComponents/tabs/Tabs'

interface Props {
  tabActiveIndex: number;
  onTabChange: (index: number) => void;
}

const UserRolesAndResourcesHeader: FC<Props> = ({
  tabActiveIndex,
  onTabChange,
}) => {
  const intl = useIntl();
  return (
    <div className="card sababab bgi-position-y-center bgi-no-repeat">
      <div className="p-8 d-flex flex-row align-items-center">
        <div className="me-5">
          <SVGICON
            src={FilemanagerIcon}
            className="svg-icon-primary svg-icon-3x"
          />
        </div>
        <div className="me-5">
          <span className="text-gray-800 text-hover-primary fs-2 fw-bolder ">
            {intl.formatMessage({ id: "ROLES_AND_PERMISSIONS_TITLE" })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserRolesAndResourcesHeader;
