import React, { FC } from "react";
import { useDrop } from "react-dnd";
import { useIntl } from "react-intl";

const TabDropzone: FC<{
  accept: any[];
  data: any;
  onDrop: (data: any, item: any) => any;
  path?: any;
  isLast?: any;
}> = ({ data, onDrop, path, isLast, accept }) => {
  const intl = useIntl();
  // const ACCEPTS = ["field", "panel"];

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: accept,
    drop: (item, monitor) => {
      // const didDrop = monitor.didDrop();
      onDrop(data, item);
    },
    canDrop: (item, monitor) => {
      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split("-");
      //   @ts-ignore
      const itemPath = item.path;
      // sidebar items can always be dropped anywhere
      if (!itemPath) {
        // if (data.childrenCount >= 3) {
        //  return false;
        // }
        return true;
      }

      const splitItemPath = itemPath.split("-");

      // limit columns when dragging from one row to another row
      const dropZonePathRowIndex = splitDropZonePath[0];
      const itemPathRowIndex = splitItemPath[0];
      const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
      // if (
      //   diffRow &&
      //   splitDropZonePath.length === 2 &&
      //   data.childrenCount >= 3
      // ) {
      //   return false;
      // }

      // Invalid (Can't drop a parent element (row) into a child (column))
      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      if (parentDropInChild) return false;

      // Current item can't possible move to it's own location
      if (itemPath === dropZonePath) return false;

      // Current area
      if (splitItemPath.length === splitDropZonePath.length) {
        const pathToItem = splitItemPath.slice(0, -1).join("-");
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

        const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
        const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;
          if (nextDropZoneIndex === currentDropZoneIndex) return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  return (
    <div
      ref={drop}
      className={
        accept && accept.length > 0 && accept[0] === "tab"
          ? "dropZone active isLast d-flex justify-content-center align-items-center notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 "
          : isActive
            ? "dropZone active isLast d-flex justify-content-center align-items-center notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 "
            : "dropZone d-flex justify-content-center align-items-center text-muted"
      }
    >
      {accept &&
        accept.length > 0 &&
        accept[0] === "tab" &&
        intl.formatMessage({ id: "DRAG_AND_DROP_MESSAGE" })}
    </div>
  );
};

export default TabDropzone;
