import { Action } from "@reduxjs/toolkit";
import { FieldType } from "../types/FieldsReponseTypes";
import { TabType } from "../types/TabsResponseType";
import { ProductType } from "../../products/types/ProductsResponseType";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_FIELDS_LIST: "SET_FIELDS_LIST",
  SET_TABS_LIST: "SET_TABS_LIST",
  SET_TAB_DETAILS: "SET_TAB_DETAILS",
  SET_FIELD_PRODUCT_DETAILS: "SET_FIELD_PRODUCT_DETAILS",
};

export interface FieldsInitState {
  fieldsList: FieldType[];
  tabsList: TabType[];
  tabDetails: TabType;
  fieldProductDetails: { product: ProductType; field_id: number | undefined }[];
}
const initialState: FieldsInitState = {
  fieldsList: [],
  tabsList: [],
  tabDetails: {
    id: 0,
    name: "",
    panels: [],
  },
  fieldProductDetails: [],
};

export const ContentMasterdataReducer = (
  state = initialState,
  action: ActionWithPayload<FieldsInitState>
) => {
  switch (action.type) {
    case actionTypes.SET_FIELDS_LIST: {
      const fields = action.payload?.fieldsList || [];
      return { ...state, fieldsList: fields };
    }

    case actionTypes.SET_TABS_LIST: {
      const tabs = action.payload?.tabsList || [];
      return { ...state, tabsList: tabs };
    }

    case actionTypes.SET_TAB_DETAILS: {
      const tabDetails = action.payload?.tabDetails || [];
      return { ...state, tabDetails: tabDetails };
    }
    case actionTypes.SET_FIELD_PRODUCT_DETAILS: {
      const fieldProductDetails = action.payload?.fieldProductDetails || [];
      return { ...state, fieldProductDetails: fieldProductDetails };
    }

    default:
      return state;
  }
};

export const actions = {
  setFieldssList: (fieldsList: FieldType[]) => ({
    type: actionTypes.SET_FIELDS_LIST,
    payload: { fieldsList },
  }),
  setTabsList: (tabsList: TabType[]) => ({
    type: actionTypes.SET_TABS_LIST,
    payload: { tabsList },
  }),
  setTabDetails: (tabDetails: TabType) => ({
    type: actionTypes.SET_TAB_DETAILS,
    payload: { tabDetails },
  }),
  setFieldProductDetails: (
    fieldProductDetails: {
      product: ProductType;
      field_id: number | undefined;
    }[]
  ) => ({
    type: actionTypes.SET_FIELD_PRODUCT_DETAILS,
    payload: { fieldProductDetails },
  }),
};
