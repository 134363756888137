import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// custom
import { RootState } from "../../../../setup";
import { UserType } from "../../auth/types/LoginResponseTypes";
// import {useHistory, useLocation} from 'react-router'
import UserProfileDetails from "../../userManagement/components/UserProfileDetails";
import SignInMethod from "../../userManagement/components/SignInMethod";
// import ConnectUserRole from "../../userManagement/components/ConnectUserRole";

// import { getMyProfile } from "../../auth/redux/AuthCRUD";
import { getUserDetails } from "../../userManagement/redux/index";
import { actions } from "../../auth";
import ConnectUserRole from "../../userManagement/components/ConnectUserRole";
import UserTeamDetails from "../../userManagement/components/UserTeamDetails";
import { ConnectedAccounts } from "./settings/cards/ConnectedAccounts";
import ConnectedUserAccounts from "../../userManagement/components/ConnectedUserAccounts";

const EditAccountDetails = () => {
  const dispatch = useDispatch();

  const userDetails = useSelector<RootState>(
    ({ auth }) => auth.user
  ) as UserType;

  // state
  const [user, setUser] = useState<UserType>(userDetails);

  const getUserDetailsAPI = () => {
    if (userDetails) {
      userDetails.id &&
        getUserDetails(userDetails.id).then(({ data: { user } }) => {
          // @ts-ignore
          setUser(user);
          // @ts-ignore
          dispatch(actions.setUser(user));
        });
    }
  };

  return (
    <div>
      <UserProfileDetails
        //@ts-ignore
        userDetails={user}
        getUserDetailsAPI={getUserDetailsAPI}
      />
      <SignInMethod
        //@ts-ignore
        userDetails={user}
        getUserDetailsAPI={getUserDetailsAPI}
      />
      <ConnectUserRole
        userDetails={user}
        getUserDetailsAPI={getUserDetailsAPI}
      />

      <UserTeamDetails
        // @ts-ignore
        userDetails={user}
        getUserDetailsAPI={getUserDetailsAPI}
      />
      {user?.id && (
        <ConnectedUserAccounts
          // @ts-ignore
          userDetails={user}
          getUserDetailsAPI={getUserDetailsAPI}
        />
      )}
    </div>
  );
};

export default EditAccountDetails;
