import axios from 'axios'

// custom
import {sortGroupsParams} from '../types/request'
import {GroupsResponseType} from '../types/GetGroupsListResponseType'
import {CreateNewGroupResponseType} from '../types/CreateNewGroupResponseType'

// get all conatcttypes list
export function getContactTypesList() {
  return axios.get<GroupsResponseType>(`/contactDetails`)
}

// create new contact type
export function createNewContactType(name: string) {
  return axios.post<CreateNewGroupResponseType>(`/contactDetails`, {
    name: name,
  })
}

// edit existing contact type
export function updateContactType(id: number, name: string) {
  return axios.post<CreateNewGroupResponseType>(`/contactDetails/${id}`, {
    name: name,
  })
}

// delete existing contact type
export function deleteContactType(id: number) {
  return axios.delete<CreateNewGroupResponseType>(`/contactDetails/${id}`)
}

// mass delete existing contact types
export function massDeleteContactTypes(contactTypes_id: Array<number>) {
  return axios.post<CreateNewGroupResponseType>(`/contactDetails/massDelete`, {
    contactTypes_id,
  })
}

// sort existing contact types
export function sortContactTypes(details_sorting: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/sortContactDetails/sorting`, {
    details_sorting,
  })
}
