import axios from 'axios'

// custom
import {GenericResponseType} from '../../../types/GenericReponse'
import {IndustryGroupsResponseType} from '../types/getIndustryGroupsResponseType'

// get all industry groups
export function getIndustryGroups() {
  return axios.get<IndustryGroupsResponseType>(`/sectors`)
}

// add industry group
export function addIndustryGroup(name: string) {
  return axios.post<GenericResponseType>(`/sectors/groups`, {
    name,
  })
}

// edit industry group
export function updateIndustryGroup(group_id: number, name: string) {
  return axios.post<GenericResponseType>(`/sectors/groups/${group_id}`, {
    name,
  })
}

// delete industry group
export function deleteIndustryGroup(group_id: number) {
  return axios.delete<GenericResponseType>(`/sectors/groups/${group_id}`)
}

// add industry sector
export function addIndustrySector(group_id: number, name: string) {
  return axios.post<GenericResponseType>(`/sectors/${group_id}`, {
    name,
  })
}

// delete industry sector
export function deleteIndustrySector(sector_id: number) {
  return axios.delete<GenericResponseType>(`/sectors/${sector_id}`)
}
