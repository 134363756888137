import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {Overview} from './components/Overview'
// import {Settings} from './components/settings/Settings'
// import {AccountHeader} from './AccountHeader'
import {Helmet} from 'react-helmet'
import config from '../../config'
import EditAccountDetails from './components/EditAccountDetails'
import {useIntl} from 'react-intl'

const AccountPage: React.FC = () => {
  const intl = useIntl()

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU_DASHBOARD'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <div>
      <Helmet>
        <title>{config.APP_NAME}-Account</title>
      </Helmet>
      <Switch>
        <Route path='/account/edit'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Edit</PageTitle>
          <EditAccountDetails />
        </Route>

        <Redirect from='/account' exact={true} to='/account/edit' />
        <Redirect to='/account/edit' />
      </Switch>
    </div>
  )
}

export default AccountPage
