import { useIntl } from "react-intl";
import { DealType } from "../types/DealResponseType";
import { FC} from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
} from "react-leaflet";
import LocationIcon2 from "../../../../_metronic/assets/icons/locationRedMarker.svg";
import L from "leaflet";

interface LocationProps {
  dealInformation: DealType;
}

const DealLocation: FC<LocationProps> = ({ dealInformation }) => {
  const intl = useIntl();

  return (
    <div
      className="card mb-8"
      style={{
        minHeight: "200px",
      }}
    >
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center fs-5 fw-bolder mb-2">
          <h4>{intl.formatMessage({ id: "DEAL_LOCATION" })}</h4>
        </div>
        <div>
          {(!dealInformation?.contacts?.latitude && !dealInformation?.partners?.latitude) ? (
            <div className="text-muted fs-8 d-flex align-items-center justify-content-center mt-10">
            {intl.formatMessage({
              id: "ADDRESS_NOT_AVAILABLE",
            })}
          </div>
          ) : (
            <div
              className="card-body px-0 py-3 "
              style={{ maxHeight: "200px" }}
            >
              <MapContainer
                // @ts-ignore
                center={[(Number(dealInformation?.contacts?.latitude)|| Number(dealInformation?.partners?.latitude)), (Number(dealInformation?.contacts?.longitude)||Number(dealInformation?.partners?.longitude))]}
                zoom={16}
                scrollWheelZoom={false}
                attributionControl={false} 
                touchZoom
                style={{
                  zIndex: "11",
                  maxHeight: "200px",
                  borderRadius:'5px'
                }}
              >
                <TileLayer
                  attribution=""
                  url="https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}@2x.jpg?key=yogaTYiQoWRwC6wfHQQK"
                />
                <Marker
                  position={[(Number(dealInformation?.contacts?.latitude)|| Number(dealInformation?.partners?.latitude)), (Number(dealInformation?.contacts?.longitude)||Number(dealInformation?.partners?.longitude))]}
                  icon={L.icon({
                    iconUrl: LocationIcon2,
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    className: `blinking`,
                  })}
                ></Marker>
              </MapContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealLocation;
