import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import FilterIcon from "../../../../_metronic/assets/icons/filter.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

import clsx from "clsx";
import { useIntl } from "react-intl";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { ProductsFilterType, ProductType } from "../types/ProductsResponseType";
import { PaginationType } from "../../partnerManagement/types/getPartnersListResponseType";
import { deleteMultipleProducts, getProducts } from "../redux/ProductsAPI";
import { actions } from "../redux/ProductsRedux";
import ProductsListActionsDropdown from "./ProductsListActionDropDown";
import AddProductsModal from "./AddProductModal";
import ProductsFilterModal from "./ProductsFilterModal";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";

interface Props {
  type?: string;
  onSelectProductss?: (products: ProductType[]) => void;
  loading?: boolean;
  isSelectionModal?: boolean;
  category?: GroupType[];
}

const ProductsList: FC<Props> = ({
  type,
  onSelectProductss,
  loading,
  isSelectionModal,
  category,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  // selectors
  const filters = useSelector<RootState>(
    ({ products }) => products.filters
  ) as ProductsFilterType;

  // state
  const [productsList, setProductsList] = useState<ProductType[]>([]);
  const [checkedProducts, setCheckedProducts] = useState<ProductType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [getProductsAPICompleted, setGetProductsAPICompleted] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showAddProductModal, setShowAddProductModal] =
    useState<boolean>(false);
  const [showProductsFilterModal, setShowshowProductsFilterModal] =
    useState<boolean>(false);
  const [paginationDetails, setPaginationDetails] = useState<PaginationType>();
  const [tempSearchText, setTempSearchText] = useState<string>("");
  const [APIloading, setAPILoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [description, setDescription] = useState();

  // data API
  const getProductsListAPI = (page?: number) => {
    // fallback to  the current page
    if (page && activePage !== page) {
      setActivePage(page);
    }
    // reset checked items
    setCheckAllItems(false);
    setCheckedProducts([]);

    setGetProductsAPICompleted(false);
    setAPILoading(true);

    getProducts(
      isSelectProductType() ? category?.[0].id : filters.Category,
      filters.Location,
      filters.Article_no,
      filters.productTypes,
      filters.EAN,
      description,
      filters.Instructions,
      itemsPerPage,
      searchText,
      page ? page : activePage
    )
      .then(({ data: { products = [], products_pagination } }) => {
        setProductsList(products);
        setPaginationDetails(products_pagination);
      })
      .finally(() => {
        setAPILoading(false);
        setGetProductsAPICompleted(true);
      });
  };

  // effects
  useEffect(() => {
    // set initial page to 1
    getProductsListAPI(1);

    return () => {
      setProductsList([]);
    };
  }, [searchText, filters, itemsPerPage]);

  useEffect(() => {
    // update checked items
    checkedProducts.length === 0 && setCheckAllItems(false);
    checkedProducts.length !== 0 &&
      checkedProducts.length === productsList.length &&
      setCheckAllItems(true);
  }, [checkedProducts]);

  const onPageClick = (page: number) => {
    setActivePage(page);
    getProductsListAPI(page);
  };

  const onSearchTextChange = (text: string) => {
    setTempSearchText(text || "");
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedProducts(productsList);
    } else {
      setCheckedProducts([]);
    }
  };

  const onCheckedChange = (product: ProductType) => {
    let checkedProductCopy = _.clone(checkedProducts);
    const index = checkedProductCopy.findIndex(
      (checkedProduct) => checkedProduct.id === product.id
    );
    if (index > -1) {
      checkedProductCopy.splice(index, 1);
      setCheckAllItems(false);
    } else {
      checkedProductCopy.push(product);
    }
    setCheckedProducts(checkedProductCopy);
  };

  const isChecked = (product: ProductType) => {
    const index = checkedProducts.findIndex(
      (checkedProduct) => checkedProduct.id === product.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteSelectedProducts = () => {
    const ids = checkedProducts.map((checkedProduct) => {
      return checkedProduct.id;
    });
    setDeleteModalLoading(true);
    deleteMultipleProducts(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "PRODUCTS_MASS_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
        setCheckedProducts([]);
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "PRODUCTS_MASS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getProductsListAPI(1);
      });
  };

  const hasFilters = (filters: ProductsFilterType) => {
    if (
      filters.Article_no ||
      filters.Instructions ||
      filters.Category ||
      filters.EAN ||
      filters.productTypes ||
      filters.Location
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (tempSearchText.length === 0) {
      setTempSearchText("");
      setSearchText("");
    }
  }, [tempSearchText]);

  const isSelectProductType = () => {
    return type === "select";
  };

  const isProductDisabled = (product: ProductType) => {
    // check diabled posts

    const index = productsList?.findIndex(
      (disabledId) => product.id === disabledId.id
    );
    if (index !== -1) return true;
    return false;
  };

  return (
    <div className="borderNone card">
      {showAddProductModal && (
        <AddProductsModal
          show={showAddProductModal}
          closeModal={() => {
            setShowAddProductModal(false);
          }}
          getProductsListAPI={getProductsListAPI}
        />
      )}
      {showProductsFilterModal && (
        <ProductsFilterModal
          show={showProductsFilterModal}
          getProductDetailsAPI={getProductsListAPI}
          closeModal={() => setShowshowProductsFilterModal(false)}
          loading={loading}
        />
      )}
      {/* begin::Header */}
      <div
        className={`card-header border-0 pt-8 d-flex flex-row justify-content-between mb-2 ${
          isSelectionModal ? "pb-8" : ""
        }`}
      >
        <div className="d-flex align-items-center" style={{ borderWidth: 1 }}>
          <div className="position-relative">
            {tempSearchText && (
              <div
                className="position-absolute"
                style={{
                  right: 8,
                  top: 10,
                  zIndex: 99,
                }}
                role={"button"}
                onClick={() => {
                  setTempSearchText("");
                  setSearchText("");
                }}
              >
                <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 " />
              </div>
            )}
            <input
              type="text"
              className="form-control form-control-solid w-250px position-relative"
              placeholder={intl.formatMessage({
                id: "PARTNERS_LIST_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingRight: "30px",
              }}
              value={tempSearchText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (tempSearchText && tempSearchText.length >= 3) {
                    setSearchText(tempSearchText);
                  }
                }
              }}
            />
            {tempSearchText &&
              tempSearchText.length < 3 &&
              isSelectionModal && (
                <div
                  className="text-danger position-absolute"
                  style={{ top: "50px", width: "max-content" }}
                >
                  <div role="alert">
                    {intl.formatMessage({
                      id: "PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE",
                    })}
                  </div>
                </div>
              )}
          </div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              if (tempSearchText && tempSearchText.length >= 3) {
                setSearchText(tempSearchText);
              }
            }}
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              padding: "11px",
            }}
          >
            <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {hasFilters(filters) && (
            <>
              {Object.keys(filters).map((key, i) => {
                if (
                  // @ts-ignore
                  filters[key] !== null
                ) {
                  return (
                    <button
                      key={i}
                      id="filter"
                      className={clsx(
                        "btn   btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2"
                      )}
                      style={{ borderRadius: "20px" }}
                      onClick={() => {
                        const filtersCopy = { ...filters };
                        // @ts-ignore
                        filtersCopy[key] = null;
                        dispatch(actions.setFilters(filtersCopy));
                      }}
                    >
                      <SVGICON src={CloseIcon} className="svg-icon-3" />
                      {key}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </>
          )}

          {isSelectProductType() && category && (
            <div>
              <button
                id="filter"
                className={clsx(
                  "btn   btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2"
                )}
                style={{ borderRadius: "20px" }}
              >
                <SVGICON src={CloseIcon} className="svg-icon-3" />
                {category[0].name}
              </button>
            </div>
          )}

          {checkedProducts.length > 0 ? (
            <>
              {isSelectProductType() ? (
                <>
                  <button
                    className="btn  btn-primary"
                    onClick={() => {
                      onSelectProductss?.(checkedProducts);
                    }}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        {intl.formatMessage({
                          id: "APPLY_BUTTON",
                        })}
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        {intl.formatMessage({
                          id: "EVENTS_FOLLOW_UP_ADD_LOADING",
                        })}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </>
              ) : (
                <>
                  <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                    {checkedProducts.length}{" "}
                    {intl.formatMessage({ id: "PARTNERS_LIST_SELECTED" })}
                  </span>
                  <button
                    className={"btn btn-danger"}
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "PRODUCTS_DELETE_POST_CONFIRM_MESSAGE",
                        }),
                        deleteSelectedProducts
                      );
                    }}
                  >
                    {intl.formatMessage({
                      id: "PARTNERS_LIST_DELETE_SELECTED",
                    })}
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              {
                <button
                  type="button"
                  className="btn btn-primary me-4"
                  onClick={() => {
                    setShowshowProductsFilterModal(true);
                  }}
                >
                  <SVGICON src={FilterIcon} className="svg-icon-2" />
                  {intl.formatMessage({ id: "FILTER_BUTTON" })}
                </button>
              }

              {/* add new post */}
              {isSelectProductType() ? null : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowAddProductModal(true);
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({ id: "PRODUCTS_ADD_NEW_PRODUCT" })}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {/* end::Header */}

      {/* search text error */}
      <div className="text-danger " style={{ padding: "0 2.25rem" }}>
        <div role="alert">
          {tempSearchText && tempSearchText.length < 3 && !isSelectionModal
            ? intl.formatMessage({
                id: "PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE",
              })
            : ""}
        </div>
      </div>

      {/* begin::Body */}
      <div
        className="card-body py-3"
        style={
          isSelectionModal
            ? { overflowY: "scroll", maxHeight: "calc(100vh - 400px)" }
            : {}
        }
      >
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            {/* begin::Table head */}
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 ">
                <th className="w-25px">
                  {isSelectProductType() ? null : (
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <Checkbox
                        onChange={onAllItemsCheckChange}
                        checked={checkAllItems}
                      />
                    </div>
                  )}
                </th>

                <th className="min-w-125px">
                  {intl.formatMessage({ id: "PRODUCTS_OVERVIEW_NAME" })}
                </th>
                {/* <th className='min-w-125px'>{intl.formatMessage({id: 'PRODUCTS_ID_NAME'})}</th> */}
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "PRODUCTS_PRICE_NAME" })}
                </th>
                <th className="min-w-125px text-center">
                  {intl.formatMessage({ id: "PRODUCTS_GROSS_PROFIT_TITLE" })}
                </th>

                <th className="min-w-125px">
                  {intl.formatMessage({ id: "PRODUCTS_STORAGE_LOCATION" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "PRODUCTS_PRODUCTTYPES_NAME" })}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {APIloading ? (
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <SimpleLoader />
                  </td>
                </tr>
              ) : (
                <>
                  {productsList.map((product, i) => {
                    return (
                      <tr key={i}>
                        {/* checkbox*/}

                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                            <Checkbox
                              onChange={() => {
                                onCheckedChange(product);
                              }}
                              checked={isChecked(product)}
                              disabled={false}
                            />
                          </div>
                        </td>

                        {/* user name,email,pic */}
                        <td>
                          <div className="d-flex align-items-center">
                            {/* <div className='symbol symbol-45px me-5'>
                              <img src={toAbsoluteUrl('/media/avatars/150-11.jpg')} alt='' />
                            </div> */}
                            {/* <div className="symbol  symbol-50px overflow-hidden me-3">
                              <div
                                className="symbol-label"
                                onClick={() => {
                                  history.push(`/sales/products/edit`, {
                                    productDetails: product,
                                  });
                                }}
                              >
                                <div className="symbol-label fs-3 bg-light-primary text-primary text-uppercase  cursor-pointer">
                                  {(product.name || " ")[0]}
                                </div>
                              </div>
                            </div> */}
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                className="text-gray-800 fw-bold text-hover-primary mb-1"
                                role="button"
                                onClick={() => {
                                  if (!isSelectProductType()) {
                                    history.push(`/sales/products/edit`, {
                                      productDetails: product,
                                    });
                                  }
                                }}
                              >
                                {product.name}
                              </span>
                              <div className="d-flex">
                                {product.category_name && (
                                  <div
                                    className="badge badge-light-success fw-bolder me-2 mb-2 "
                                    style={{ width: "fit-content" }}
                                  >
                                    <span>{product.category_name}</span>
                                  </div>
                                )}
                                {product?.stock ? (
                                  <div
                                    className="badge badge-light-danger fw-bolder me-2 mb-2 "
                                    style={{ width: "fit-content" }}
                                  >
                                    <span className="">
                                      {intl.formatMessage({
                                        id: "PRODUCTS_STOCK_TITLE",
                                      })}
                                      : {product.stock}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </td>

                        {/* product_id */}
                        {/* <td>
                          {product.product_id ? (
                            <div className='text-gray-600 fw-bold'>
                              <span>{product.product_id}</span>
                            </div>
                          ) : null}
                        </td> */}

                        <td>
                          <div className="d-flex justify-content-start flex-column">
                            {product?.avg_bought_price && (
                              <div
                                className="badge badge-light fw-bolder me-2 mb-2 text-uppercase"
                                style={{ width: "fit-content" }}
                              >
                                <span>
                                  {intl.formatMessage({
                                    id: "PRODUCTS_AVG_BOUGHT_PRICE_TITLE",
                                  })}
                                  : €{product?.avg_bought_price}
                                </span>
                              </div>
                            )}
                            {product?.selling_price && (
                              <div
                                className="badge badge-light fw-bolder me-2 mb-2 text-uppercase"
                                style={{ width: "fit-content" }}
                              >
                                <span>
                                  {intl.formatMessage({
                                    id: "PRODUCTS_SELLING_PRICE_TITLE",
                                  })}{" "}
                                  : €{product?.selling_price}
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {product?.gross_profit && (
                              <div
                                className=" fw-bold me-2 mb-2 "
                                // style={{ width: "fit-content" }}
                              >
                                <span> €{product?.gross_profit}</span>
                              </div>
                            )}
                          </div>
                        </td>

                        <td>
                          {product.storage_location ? (
                            <div className="text-gray-600 fw-bold">
                              <span>{product.storage_location}</span>
                            </div>
                          ) : null}
                        </td>
                        <td className="">
                          <div className="d-flex flex-fill flex-row flex-wrap ">
                            {(product.products_types || [])?.map(
                              (
                                type: {
                                  id: number;
                                  name: string;
                                  value: number;
                                },
                                i
                              ) => {
                                return (
                                  <div
                                    key={i}
                                    title={type.name}
                                    className="badge badge-light fw-bolder me-2 mb-2"
                                    style={{
                                      maxWidth: "150px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {type.name || ""}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </td>

                        {/* <td>
                          <span className='text-gray-600 fw-bold d-flex justify-content-center'>
                            <span> {product.shippable === 1 ? 'Y' : 'N'}</span>
                          </span>
                        </td> */}

                        {/* actions */}
                        {
                          <td>
                            <ProductsListActionsDropdown
                              productDetails={product}
                              getProductsListAPI={getProductsListAPI}
                            />{" "}
                          </td>
                        }
                      </tr>
                    );
                  })}
                </>
              )}

              {/* no data */}
              {getProductsAPICompleted && productsList.length === 0 && (
                <tr className="odd">
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <img
                        alt="not-found"
                        src={notFound}
                        className="mw-400px"
                      />
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        {intl.formatMessage({
                          id: "PARTNERS_LIST_NOITEMS_FOUND",
                        })}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>

          {/* end::Table */}
          {productsList.length > 0 && (
            <Pagination
              totalPages={paginationDetails?.number_of_pages || 1}
              activePage={activePage}
              onPageClick={onPageClick}
              showItemsPerPage
              itemsPerPage={itemsPerPage}
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
              }}
            />
          )}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  );
};

export default ProductsList;
