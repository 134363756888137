import React, { useEffect } from "react";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/toolbar/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import { ActivityDrawer, Main, InviteUsers, UpgradePlan } from "../partials";
import { MenuComponent } from "../assets/ts/components";
import DeviceToolbar from "./components/toolbar/DeviceToolbar";

const MasterLayout: React.FC = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div className="d-flex flex-row flex-column-fluid bodyBackground ">
        <AsideDefault />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <HeaderWrapper />
          <Toolbar />
          {location.pathname?.includes("device") && <DeviceToolbar />}
          <div
            className="card mx-5"
            style={{
              borderBottomLeftRadius: "9px",
              borderBottomRightRadius: "9px",
            }}
          >
            <div
              id="kt_content"
              className="content d-flex flex-column flex-column-fluid pt-0"
            >
              <div
                className="post d-flex flex-column-fluid mt-5 mx-5 bodyScollContent "
                id="kt_post"
              >
                <Content>{children}</Content>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <ExploreMain /> */}

      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
