import React, { FC, useEffect, useState } from "react";
import {
  DealFilterType,
  DealPipelineDetailsType,
  DealType,
} from "../types/DealResponseType";

import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";

import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import FilterIcon from "../../../../_metronic/assets/icons/filter.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import Arrow from "../../../../_metronic/assets/icons/arrow.svg";
import DownArrow from "../../../../_metronic/assets/icons/downArrow.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import _ from "lodash";
import ProductsListActionsDropdown from "../../products/components/ProductsListActionDropDown";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import moment from "moment";
import Constants from "../../../config/Constants";
import { actions } from "../redux/DealsRedux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import DealsListFilterModal from "./DealsListFilterModal";
import { dealsFilter, dealsSort } from "../redux/DealsAPI";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import clsx from "clsx";
import { PartnerType } from "../../partnerManagement/types/getPartnersListResponseType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";

interface Props {
  phases: DealPipelineDetailsType[];
  pId: number;
}

const getAllDeals = (phases: DealPipelineDetailsType[]) => {
  if (phases && phases.length > 0) {
    const deals: DealType[] = [];
    phases.map((phase) => {
      phase.deals.map((deal) => {
        if (
          deal.id &&
          (deal.status === "inProgress" ||
            (phase.condition === "end" && deal.status === "finished"))
        ) {
          deals.push(deal);
        }
      });
    });
    if (deals && deals.length > 0) {
      return deals;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

interface sortType {
  name: string;
  type: "asc" | "desc" | "";
}

const DealsListView: FC<Props> = ({ phases, pId }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  // selector
  const filterData = useSelector<RootState>(
    // @ts-ignore
    ({ deals }) => deals.filterdetails
  ) as DealFilterType;

  // state
  const [dealsList, setDealsList] = useState<DealType[]>(getAllDeals(phases));
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [sortView, setSortView] = useState<sortType>({
    name: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    handleDealSort();
  }, [sortView]);

  useEffect(() => {
    if (phases && phases.length > 0) {
      const deals: DealType[] = [];
      phases.map((phase) => {
        phase.deals.map((deal) => {
          if (
            deal.id &&
            (deal.status === "inProgress" ||
              (phase.condition === "end" && deal.status === "finished"))
          ) {
            deals.push(deal);
          }
        });
      });

      if (deals && deals.length > 0) {
        setDealsList(deals);
      }
    }
  }, [phases]);

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedDeals =
    dealsList && dealsList.length > 10
      ? dealsList.slice(startIndex, startIndex + itemsPerPage)
      : dealsList;

  useEffect(() => {
    if (paginatedDeals.length === 0 && dealsList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedDeals]);

  const handleFilterSubmit = (
    Responsible_Persons: number[],
    status: string,
    partnerId: any,
    cId: any,
    tags: number | undefined
  ) => {
    setShowFilterModal(false);
    dealsFilter(pId, Responsible_Persons, status, partnerId, cId, tags)
      .then(({ data: { pipeline_deals } }) => {
        const result = getAllDeals(pipeline_deals);
        setDealsList(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filterData) {
      setLoading(true);
      handleFilterSubmit(
        filterData.Responsible_Persons,
        filterData.status,
        // @ts-ignore
        filterData.partner?.id,
        // @ts-ignore
        filterData.contact?.id,
        filterData.tags
      );
    }
  }, [filterData]);

  const hasFilters = (data: DealFilterType) => {
    if (
      data.Responsible_Persons.length > 0 ||
      data.status.length > 0 ||
      data.partner ||
      data.contact
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleDealSort = () => {
    dealsSort(
      pId,
      sortView?.type,
      sortView?.name,
      filterData.Responsible_Persons,
      filterData.status,
      // @ts-ignore
      filterData.partner?.id,
      // @ts-ignore
      filterData.contact?.id
    )
      .then(({ data: { pipeline_deals } }) => {
        const result = getAllDeals(pipeline_deals);
        setDealsList(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {showFilterModal && (
        <DealsListFilterModal
          show={showFilterModal}
          closeModal={() => {
            setShowFilterModal(false);
          }}
          type="new"
          onFilterSubmit={(
            Responsible_Persons: number[],
            status: string,
            pId: any,
            cId: any,
            tags: number | undefined
          ) => {
            handleFilterSubmit(Responsible_Persons, status, pId, cId, tags);
          }}
        />
      )}
      <div className="mt-4 card">
        {/* begin::Header */}
        {/* begin::Header */}
        <div
          className={`card-header border-0 pt-8 d-flex flex-row justify-content-between mb-2 `}
        >
          <div>
            {hasFilters(filterData) && (
              <>
                {Object.keys(filterData).map((key, i) => {
                  if (
                    // @ts-ignore
                    filterData[key]?.length > 0 ||
                    // @ts-ignore
                    filterData[key]?.id ||
                    // @ts-ignore
                    filterData[key] > 0
                  ) {
                    return (
                      <button
                        key={i}
                        id="filter"
                        className={clsx(
                          "btn   btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2"
                        )}
                        style={{ borderRadius: "20px" }}
                        onClick={() => {
                          const filtersCopy = { ...filterData };
                          // @ts-ignore
                          filtersCopy[key] =
                            key === "Responsible_Persons"
                              ? []
                              : key === "partner" || key === "contact"
                                ? {}
                                : "";
                          dispatch(actions.setDealFilterDetails(filtersCopy));
                        }}
                      >
                        <SVGICON src={CloseIcon} className="svg-icon-3" />
                        {key}
                      </button>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <>
              <button
                type="button"
                className="btn btn-primary me-4"
                onClick={() => {
                  setShowFilterModal(true);
                }}
              >
                <SVGICON src={FilterIcon} className="svg-icon-2" />
                {intl.formatMessage({ id: "FILTER_BUTTON" })}
              </button>
            </>
          </div>
        </div>
        {/* end::Header */}
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
              {/* begin::Table head */}
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 ">
                  <th
                    className="min-w-125px"
                    role="button"
                    onClick={() => {
                      if (
                        sortView &&
                        sortView?.name === "deal_name" &&
                        sortView.type === "asc"
                      ) {
                        setSortView({
                          name: "deal_name",
                          type: "desc",
                        });
                        // handleDealSort();
                      } else if (
                        sortView &&
                        sortView?.name === "deal_name" &&
                        sortView.type === "desc"
                      ) {
                        setSortView({
                          name: "deal_name",
                          type: "",
                        });
                        // handleDealSort();
                      } else {
                        setSortView({
                          name: "deal_name",
                          type: "asc",
                        });
                        // handleDealSort();
                      }
                    }}
                  >
                    {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN1" })}
                    {sortView?.name === "deal_name" &&
                      (sortView.type === "asc" || sortView.type === "desc") && (
                        <span>
                          <SVGICON
                            src={sortView.type === "asc" ? Arrow : DownArrow}
                            className="svg-icon-2"
                          />
                        </span>
                      )}
                  </th>
                  {/* <th className='min-w-125px'>{intl.formatMessage({id: 'PRODUCTS_ID_NAME'})}</th> */}
                  <th className="min-w-100px">
                    {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN2" })}
                  </th>
                  <th className="min-w-100px">
                    {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN6" })}
                  </th>
                  <th className="min-w-100px">
                    {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN7" })}
                  </th>

                  <th className="min-w-135px ">
                    {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN3" })}
                  </th>

                  <th
                    className="min-w-100px text-center"
                    role="button"
                    onClick={() => {
                      if (
                        sortView &&
                        sortView?.name === "days" &&
                        sortView.type === "asc"
                      ) {
                        setSortView({
                          name: "days",
                          type: "desc",
                        });
                        // handleDealSort();
                      } else if (
                        sortView &&
                        sortView?.name === "days" &&
                        sortView.type === "desc"
                      ) {
                        setSortView({
                          name: "days",
                          type: "",
                        });
                        // handleDealSort();
                      } else {
                        setSortView({
                          name: "days",
                          type: "asc",
                        });
                        // handleDealSort();
                      }
                    }}
                  >
                    {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN4" })}
                    {sortView?.name === "days" &&
                      (sortView.type === "asc" || sortView.type === "desc") && (
                        <span>
                          <SVGICON
                            src={sortView.type === "asc" ? Arrow : DownArrow}
                            className="svg-icon-2"
                          />
                        </span>
                      )}
                  </th>
                  <th className="min-w-150px">
                    {intl.formatMessage({ id: "DEAL_LIST_VIEW_COLUMN5" })}
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {!paginatedDeals ? (
                  <tr>
                    <td valign="top" colSpan={7} className="dataTables_empty">
                      <SimpleLoader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {paginatedDeals.map((deal, i) => {
                      const currentDealIndex = phases.findIndex((data) =>
                        data.deals.find(
                          (temp) =>
                            temp.id === deal.id && temp.status === "inProgress"
                        )
                      );
                      const isEndPhase = phases.findIndex((data) =>
                        data.deals.find(
                          (temp) =>
                            temp.id === deal.id &&
                            temp.status === "finished" &&
                            data.condition === "end"
                        )
                      );
                      const completedgPhasesLength = phases.slice(
                        0,
                        currentDealIndex
                      );
                      const remainingPhasesLength =
                        phases.slice(currentDealIndex);
                      return (
                        <tr key={i}>
                          {/* checkbox*/}

                          {/* user name,email,pic */}
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span
                                  className="text-gray-800 fw-bold text-hover-primary mb-1"
                                  role="button"
                                  onClick={() => {
                                    history.push(`/content/deals/editDeal`, {
                                      dealDetails: deal,
                                    });
                                    dispatch(
                                      actions.setSingleDealDetails(deal)
                                    );
                                  }}
                                >
                                  {deal.name}
                                </span>
                                <div className="d-flex">
                                  <div
                                    className="badge badge-light-success fw-bolder me-2 mb-2 "
                                    style={{ width: "fit-content" }}
                                  >
                                    <span>{deal.phase_name}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              {deal.status}
                            </div>
                          </td>

                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              {deal.partners !== undefined && (
                                <div className="fw-semibold me-5">
                                  <div className="fs-6 fw-bold text-gray-900 text-hover-primary">
                                    {deal?.partners?.name
                                      ? deal?.partners?.name
                                      : ""}
                                  </div>
                                  <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                    {deal?.partners?.address_1
                                      ? deal?.partners?.address_1 + ", "
                                      : ("" + deal?.partners?.address_2 &&
                                          deal?.partners?.address_2) ||
                                        ""}
                                  </div>
                                  <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                    {(deal?.partners?.city &&
                                      deal?.partners?.city + ",") ||
                                      ""}

                                    {(deal?.partners?.zip_code &&
                                      deal?.partners?.zip_code) ||
                                      ""}
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>

                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              {(deal.contacts !== undefined && (
                                <div className="fw-semibold me-5">
                                  <div className="fs-6 fw-bold text-gray-900 text-hover-primary">
                                    {(deal?.contacts?.firstname &&
                                      deal?.contacts?.firstname) ||
                                      ("" + " " + deal?.contacts?.lastname &&
                                        deal?.contacts?.lastname) ||
                                      ""}
                                  </div>
                                  <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                    {deal?.contacts?.email || ""}
                                  </div>
                                  <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                    {deal?.contacts?.address_1 || ""}{" "}
                                    {deal?.contacts?.address_2 || ""}
                                  </div>
                                  <div className="text-gray-500 fw-semibold fs-7 text-start ps-0">
                                    {deal?.contacts?.city || ""}
                                    {deal?.contacts?.city ? "," : ""}
                                    {deal?.contacts?.zip_code || ""}
                                  </div>
                                </div>
                              )) ||
                                ""}
                            </div>
                          </td>

                          <td>
                            <div className="text-center">
                              <div className="d-flex align-items-center flex-column  w-100">
                                <div className="d-flex justify-content-between fw-bold fs-6 text-primary opacity-75 w-100 mt-auto mb-2">
                                  <span>
                                    {isEndPhase !== -1
                                      ? intl.formatMessage({
                                          id: "DEAL_LIST_VIEW_COMPLETED_MESSAGE",
                                        })
                                      : `${
                                          remainingPhasesLength.length
                                        } ${intl.formatMessage({
                                          id: "DEAL_LIST_VIEW_PHASES_PENDING_MESSAGE",
                                        })}`}
                                  </span>
                                  <span>
                                    {isEndPhase !== -1
                                      ? "100%"
                                      : `${Math.floor(
                                          (completedgPhasesLength.length /
                                            phases.length) *
                                            100
                                        )}
                                    %`}
                                  </span>
                                </div>

                                <div className="h-8px mx-3 w-100 bg-secondary bg-opacity-50 rounded">
                                  <div
                                    className="bg-primary rounded h-8px"
                                    role="progressbar"
                                    style={{
                                      width:
                                        isEndPhase !== -1
                                          ? "100%"
                                          : `${
                                              (completedgPhasesLength.length /
                                                phases.length) *
                                              100
                                            }%`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className="text-center">
                            <div>
                              {moment(new Date()).diff(
                                moment(deal.created_at),
                                "days"
                              )}{" "}
                              days
                            </div>
                          </td>
                          <td className="">
                            <div className="d-flex flex-fill flex-row flex-wrap ">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  {deal.responsible_users &&
                                    deal.responsible_users.length > 0 && (
                                      <div className="">
                                        <div className="symbol-group symbol-hover flex-nowrap">
                                          {deal.responsible_users
                                            .slice(0, 5)
                                            ?.map((user, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="symbol symbol-35px symbol-circle"
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                  onMouseOver={() => {
                                                    const tooltipEle =
                                                      document.getElementById(
                                                        `role-user-tooltip-${index}${deal.id}`
                                                      );
                                                    if (tooltipEle) {
                                                      tooltipEle.style.display =
                                                        "flex";
                                                    }
                                                  }}
                                                  onMouseOut={() => {
                                                    const tooltipEle =
                                                      document.getElementById(
                                                        `role-user-tooltip-${index}${deal.id}`
                                                      );
                                                    if (tooltipEle) {
                                                      tooltipEle.style.display =
                                                        "none";
                                                    }
                                                  }}
                                                >
                                                  <div
                                                    id={`role-user-tooltip-${index}${deal.id}`}
                                                    className="position-absolute bg-light px-3 py-2 fs-8"
                                                    style={{
                                                      display: "none",
                                                      backgroundColor: "white",
                                                      top: -60,
                                                      borderRadius: 5,
                                                    }}
                                                  >
                                                    {`${user.firstname}  ${user.lastname}`}
                                                  </div>
                                                  {
                                                    <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                                      {(user.firstname ||
                                                        " ")[0].toUpperCase()}
                                                    </span>
                                                  }
                                                </div>
                                              );
                                            })}
                                          {deal.responsible_users.length -
                                            deal.responsible_users.slice(0, 5)
                                              .length >
                                            0 && (
                                            <div
                                              className="symbol symbol-35px symbol-circle"
                                              onClick={() => {}}
                                            >
                                              <span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                                                +
                                                {deal.responsible_users.length -
                                                  deal.responsible_users.slice(
                                                    0,
                                                    5
                                                  ).length}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}

                {/* no data */}
                {dealsList.length === 0 && (
                  <tr className="odd">
                    <td valign="top" colSpan={7} className="dataTables_empty">
                      <div className="d-flex flex-column flex-center">
                        <img
                          alt="not-found"
                          src={notFound}
                          className="mw-400px"
                        />
                        <div className="fs-1 fw-bolder text-dark mb-4">
                          {intl.formatMessage({
                            id: "PARTNERS_LIST_NOITEMS_FOUND",
                          })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>

            {/* end::Table */}
            {dealsList.length > 0 && (
              <div className="card mt-5 pe-3 borderNone">
                <Pagination
                  totalPages={Math.ceil(dealsList.length / itemsPerPage)}
                  activePage={
                    Math.ceil(dealsList.length / itemsPerPage) === 1
                      ? 1
                      : activePage
                  }
                  onPageClick={onPageClick}
                  noCard
                  itemsPerPage={itemsPerPage}
                  showItemsPerPage
                  setItemsPerPage={(count) => {
                    setItemsPerPage(count);
                  }}
                />
              </div>
            )}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  );
};

export default DealsListView;
